<template>
  <div>
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
          <div class="page-title-right">
            <h4 class="mb-sm-0 font-size-18">
              Lesson of Module - {{ lesson_name }}
            </h4>
            <a @click="$router.go(-1)" class="btn btn-primary w-md mybutton myleft">
              <box-icon name="chevrons-left" type="solid" color="#ffffff" class="myicon"></box-icon>Back
            </a>
          </div>

          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item">
                <!-- <a href="">Services</a> -->
                <!-- to="/home/servicecategories" -->
                <router-link to="" class="waves-effect">
                  Module
                </router-link>
              </li>
              <li class="breadcrumb-item active">lessons List</li>
            </ol>
            <div>

              <div>

                <div class="dropdown">
                  <a href="javascript:;" class="dropdown-toggle card-drop" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <!-- <box-icon
                              name="dots-horizontal-rounded"
                              class="myddicon"
                            ></box-icon> -->
                    <button class="btn btn-primary waves-effect btn-label waves-light mybutton">
                      Add Lesson By
                    </button>
                  </a>

                  <div class="dropdown-menu dropdown-menu-end dropdown-content w3-animate-zoom" id="myDIV">
                    <!-- <div
                              class="dropdown-menu dropdown-menu-end dropdown-content"
                            > -->
                    <div>

                      <div class="menu-item">
                        <a href="javascript:;" @click="addContentModal($route.params.id,'video')"
                          class="listbox w3-bar-item w3-button">
                          <box-icon name='video-recording' type='solid' class="myicon"></box-icon>
                          Video
                        </a>
                      </div>

                      <div class="menu-item">
                        <a href="javascript:;" @click="addContentModal($route.params.id,'audio')"
                          class="listbox w3-bar-item w3-button">
                          <box-icon name='user-voice' type='solid' class="myicon"></box-icon>
                          Audio
                        </a>
                      </div>

                      <div class="menu-item">
                        <a href="javascript:;" @click="addContentModal($route.params.id,'pdf')"
                          class="listbox w3-bar-item w3-button">
                          <box-icon name='file-pdf' type='solid' class="myicon"></box-icon>
                          PDF
                        </a>
                      </div>

                      <div class="menu-item">
                        <a href="javascript:;" @click="addContentModal($route.params.id,'text')"
                          class="listbox w3-bar-item w3-button">
                          <box-icon name='text' type='solid' class="myicon"></box-icon>
                          Text
                        </a>
                      </div>
                      <!-- <a href="javascript:;" @click="
                        showModal03(
                          item.id,
                          item.title,
                          item.description,
                          item.course_id
                        )
                      " class="listbox w3-bar-item w3-button">
                        <box-icon name="edit" class="myicon"></box-icon>
                        Update Lesson
                      </a>
                      <a href="javascript:;" @click="remove_lesson(item.id)" class="listbox w3-bar-item w3-button">
                        <box-icon type="solid" name="message-alt-x" class="myicon"></box-icon>Delete Lesson
                      </a> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
    <!-- v-if="$store.state.lesson_content_data" -->
    <!-- :key="lesson_key" -->
    <!-- :key="lesson_key"  v-if="lesson_content_data" -->

    <!-- <div  class="descriptionbox" :key="componentkey" v-if="video || audio || text || pdf"> -->
    <!-- <div> -->
    <!-- <vsa-list>
      <vsa-item v-if="video.length > 0" >
        <vsa-heading>
          All Videos
        </vsa-heading>

        <vsa-content>
          <div v-for="item in video" :key="item.id">
            <h2>{{ item.title }}</h2>
            <p>{{ item.description }}</p>
            <video :src='item.url' width="320" height="240" controls></video>
            <a href="javascript:;" @click="
              updateLessonContentModal(
                item.id,
                item.title,
                item.url,
                item.description,
                item.lesson_id,
                item.duration,
                item.type,
                item.image,
                item.artist
              )
            " class="listbox w3-bar-item w3-button">
              <box-icon name="edit" class="myicon"></box-icon>
              Update Video
            </a>
            <a href="javascript:;" @click="remove_vid(item.id)" class="listbox">
              <box-icon type="solid" name="message-alt-x" class="myicon"></box-icon>Delete Video
            </a>
            <hr />
          </div>
        </vsa-content>
      </vsa-item>

      <vsa-item v-if="this.audio.length > 0" >
        <vsa-heading>
          All Audio
        </vsa-heading>

        <vsa-content>
          <div v-for="(item) in this.audio" :key="item.id">
            <h2>{{ item.title }}</h2>
            <p>{{ item.description }}</p>
            <audio controls>
              <source :src="item.url" type="audio/mpeg" />
            </audio>
            <a href="javascript:;" @click="
              updateLessonContentModal(
                item.id,
                item.title,
                item.url,
                item.description,
                item.lesson_id,
                item.duration,
                item.type,
                item.image,
                item.artist
              )
            " class="listbox w3-bar-item w3-button">
              <box-icon name="edit" class="myicon"></box-icon>
              Update Audio
            </a>
            <a href="javascript:;" @click="remove_vid(item.id)" class="listbox">
              <box-icon type="solid" name="message-alt-x" class="myicon"></box-icon>Delete Video
            </a>
            <hr />
          </div>
        </vsa-content>
      </vsa-item>


      <vsa-item v-if="this.pdf.length > 0"> 
        <vsa-heading>
          All PDF
        </vsa-heading>

        <vsa-content>
          <div v-for="(item) in this.pdf" :key="item.id">
            <h2>{{ item.title }}</h2>
            <p>{{ item.description }}</p>
            <a :href="item.url" target="_blank">{{item.url}}</a>

            <a href="javascript:;" @click="
              updateLessonContentModal(
                item.id,
                item.title,
                item.url,
                item.description,
                item.lesson_id,
                item.duration,
                item.type,
                item.image,
                item.artist
              )
            " class="listbox w3-bar-item w3-button">
              <box-icon name="edit" class="myicon"></box-icon>
              Update PDF
            </a>
            <a href="javascript:;" @click="remove_vid(item.id)" class="listbox">
              <box-icon type="solid" name="message-alt-x" class="myicon"></box-icon>Delete PDF
            </a>
            <hr />
          </div>
        </vsa-content>
      </vsa-item>

      <vsa-item v-if="this.text.length > 0">
        <vsa-heading>
          All Text
        </vsa-heading>

        <vsa-content>
          <div v-for="item in text" :key="item.id">
            <h2>{{ item.title }}</h2>
            <vue-editor :value="item.description" />
        
            <a href="javascript:;" @click="
              updateLessonContentModal(
                item.id,
                item.title,
                item.url,
                item.description,
                item.lesson_id,
                item.duration,
                item.type,
                item.image,
                item.artist
              )
            " class="listbox w3-bar-item w3-button">
              <box-icon name="edit" class="myicon"></box-icon>
              Update Text
            </a>
            <a href="javascript:;" @click="remove_vid(item.id)" class="listbox">
              <box-icon type="solid" name="message-alt-x" class="myicon"></box-icon>Delete Video
            </a>
            <hr />
          </div>
        </vsa-content>
      </vsa-item>
    </vsa-list> -->
    <!-- </div></div> -->

    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <div class="card">
            <div class="card-body">
              <span>Show All Records</span> &nbsp;
              <toggle-button @change="changePagiStatus" :value="data_table_pagination" />
              <table class="table table-hover table-bordered" id="audios_table" >
                <thead>
                  <tr class="frown">
                    <th scope="col" style="width: 5%">#</th>
                    <!-- <th scope="col" style="width: 10%">Image</th> -->
                    <th scope="col" style="width: 20%">Title</th>
                    <th scope="col" style="width: 50%">Description</th>
                    <th scope="col" style="width: 50%">Type</th>
                    <th scope="col" style="width: 50%">Order</th>
                    <th scope="col" style="width: 50%">Status</th>
                    <th scope="col" style="width: 5%">Actions</th>
                  </tr>
                </thead>
                <!-- handle:'.my-handle' -->
                <draggable :list="lesson_content_data" :options="{animation:200}" :element="'tbody'" @change="update">
                  <tr v-for="(item, index) in lesson_content_data" :key="index">
                    <td scope="row" class="sorting_1">{{ index + 1 }}</td>
                    <!-- <td>
                        <img :src="item.image" alt="" />
                      </td> -->
                    <td>
                      <h5>
                        {{ item.title }}
                      </h5>
                      <!-- <p class="text-muted mb-0 text-ellipse-250">
                          {{ item.name }}
                        </p> -->
                    </td>

                    <td>
                      <span v-if="item.type == 'text'">
                        <vue-editor disabled="true" :value="item.description" />
                      </span>
                      <p v-else>
                        {{ item.description }}
                      </p>
                    </td>

                    <td>
                      <h5>
                        {{ item.type }}
                      </h5>
                    </td>

                    <td>
                      {{ item.order }}
                    </td>

                    <td>
                        <toggle-button @change="changeStatus(item)" :value="(item.status == 'Active') ? true : false" />
                      </td>

                    <td>
                      <!-- <div class="w3-dropdown-click mydropdown"> -->
                      <div class="dropdown">
                        <a href="javascript:;" class="dropdown-toggle card-drop" data-bs-toggle="dropdown"
                          aria-expanded="false">
                          <box-icon name="dots-horizontal-rounded" class="myddicon"></box-icon>
                        </a>

                        <div class="dropdown-menu dropdown-menu-end dropdown-content w3-animate-zoom" id="myDIV">
                          <div>
                            <a href="javascript:;" @click="
                              updateLessonContentModal(
                                item.id,
                                item.title,
                                item.url,
                                item.description,
                                item.lesson_id,
                                item.duration,
                                item.type,
                                item.image,
                                item.artist,
                                item.cover_image,
                                item.tag
                              )
                            " class="listbox w3-bar-item w3-button">
                              <box-icon name="edit" class="myicon"></box-icon>
                              Update Lesson
                            </a>
                            <a href="javascript:;" @click="remove_vid(item.id)" class="listbox">
                              <box-icon type="solid" name="message-alt-x" class="myicon"></box-icon>Delete Lesson
                            </a>
                          </div>
                        </div>
                      </div>
                    </td>
                    <!-- <td><i class="fa fa-arrows my-handle"></i></td> -->
                  </tr>
                </draggable>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- <NewAccordion :key="lesson_key" :lesson_content_data="lesson_content_data"></NewAccordion> -->
    </div>
  </div>
</template>

<script>
// import {
//   VsaList,
//   VsaItem,
//   VsaHeading,
//   VsaContent,

// } from 'vue-simple-accordion';
import draggable from 'vuedraggable'
import 'vue-simple-accordion/dist/vue-simple-accordion.css';
import api from "../../config/api.js";
// import NewAccordion from "../../components/NewAccordion.vue";
import ModalAddLessonContent from "../Modals/ModalAddLessonContent.vue";
import ModalEditLesson from "../Modals/ModalEditLessonContent.vue";
import { VueEditor } from "vue2-editor";
import $ from "jquery";


export default {
  name: "Libraries",
  props:{
    id:Number,
    title:String,
  },
  components: {
    // NewAccordion , 
    // VsaList,
    // VsaItem,
    // VsaHeading,
    // VsaContent,
    VueEditor,
    draggable,
  },

  data() {
    return {
      is_active: true,
      enabled: true,
      lesson_key: 0,
      all_contents: [],
      all_audios: "",
      all_videos: "",
      lesson_content_data: [],
      lesson_name: "",
      key: 0,
      componentkey: 0,
      video: [],
      audio: [],
      pdf: [],
      text: [],
      dragging: false,
      data_table_pagination: false,
      data_table_setting : "",
      table_opt : "",
    };
  },
  computed: {
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    }
  },

  // updated(e) {
    // if(this.$store.state.filterLessonContentData){
    //   this.inc();
    //   this.$store.commit('changeFilterContentDataStatus');
    // }
  // },

  async mounted() {
    console.log('mounted');
    this.lesson_name = this.$route.params.title;
    // this.$store.dispatch('getLessonContentData',this.$route.params.id)
    // console.log(this.$store.state.lesson_content_data);
  },

  created() {
    this.$root.$refs.Content = this;
    // console.log('computed');
    this.lessonContent();
    // this.$store.dispatch('getLessonContentData',this.$route.params.id)
  },

  methods: {

    async changeStatus(item){
      item.status = !item.status
      try {
        let result = await api.post(`/admin/lesson-content-status?id=${item.id}`);
        this.$toast.success(result.data.message);
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    changePagiStatus(){
      if(!this.data_table_pagination){
        this.data_table_setting.page.len( this.data_table_setting.page.info().recordsTotal ).draw()
        this.data_table_pagination = true;
      }else{
        this.data_table_setting.page.len( 10 ).draw()
        this.data_table_pagination = false;
      }
    },

    async remove_vid(id) {
      // console.log("del id : " + id);

      try {
        this.$swal({
          title: "Are you sure?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete it!",
          cancelButtonText: "No, cancel!",
          confirmButtonClass: "btn btn-success",
          cancelButtonClass: "btn btn-danger",
          buttonsStyling: true,
        }).then(async (res) => {
          if (res.isConfirmed) {
            let result = await api.delete(`/admin/delete-lesson-content/` + id);
            this.lessonContent();
            // this.$store.dispatch('getLessonContentData',this.$route.params.id)
            // this.$store.commit('changeFilterContentDataStatus');
            this.$toast.success(result.data.message);
            this.lessonContent();
            // console.log("I am in Delete mode", result);
            //this.onLoad();
          }
        });
      } catch (error) {
        this.$toast.error(error.response.data.message);
      }
    },

    updateLessonContentModal(aid, aname, apath, adesc, catid, audlong, type, image, artist, cover_image, tag) {
      console.log('update lesson content modal');
      console.log(audlong);
      let tags = JSON.parse(tag);
      this.$modal.show(
        ModalEditLesson,
        {
          // data_length : this.lesson_content_data.length,
          aid: aid,
          aname: aname,
          apath: apath,
          adesc: adesc,
          catid: catid,
          audlong: audlong,
          outside: true,
          type: type,
          image: image,
          artist: artist,
          cover_image,
          atags : tags,
        },
        { draggable: false },
        {
          "before-close": () => {
            //this.allAudios(libid);
          },
        }
      );
    },


    inc() {
      this.lesson_key += 1;
    },

    async lessonContent() {
  
      this.componentkey += 1;
      // console.log("lessonContent function run");
      try {
        let result = await api.get(`/admin/lesson-content`, {
          params: { lesson_id: this.$route.params.id },
        });
        this.lesson_content_data = result.data.content
        // console.log(this.lesson_content_data);
        // this.video = this.lesson_content_data?.filter(item => item.type == "video");
        // this.audio = this.lesson_content_data?.filter(item => item.type == "audio");
        // this.pdf = this.lesson_content_data?.filter(item => item.type == "pdf");
        // this.text = this.lesson_content_data?.filter(item => item.type == "text");

        
        setTimeout(() => {
          // $("#audios_table").DataTable({
          //   paging:true,
          // });
          this.table_opt = $("#audios_table").DataTable();
          this.data_table_setting = this.table_opt;
        }, 0);

        // console.log(this.lesson_content_data);
        // var mydata = JSON.parse(JSON.stringify(data));

        // console.log(this.lesson_content_data);
        // let { data } = await api.get(`/admin/all-lesson-content`);
        // this.all_contents = data?.lessons-content;

      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    async update() {

      this.lesson_content_data.map((dt, index) => {
                    dt.order = index + 1;
                })

      let ids = this.lesson_content_data.map((dt) => {
                    return dt.id
                })

                // console.log(ids);
      // console.log('lesson id');
      // console.log(this.id);
      // console.log('old order');
      // console.log(e.moved.oldIndex + 1);
      // console.log('new order');
      // console.log(e.moved.newIndex + 1);

      const data = {
        "lesson_id" : this.id,
        "ids" : ids
      }
      // console.log('lesson id for sorting');
      // console.log(data);

      try {
        await api.post(`/admin/update-order`, data);
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    
      // this.lesson_content_data.map((dt, index) => {
      //   dt.order = index + 1;
      // })

      // axios.put('/admin/testimonials/updateAll', {
      //   testimonials: this.testimonialsNew
      // }).then((response) => {
      
      // })
    },

    

    addContentModal(lesson_id, type) {
      // var list = document.getElementById("modals-container");
      // list.remove();
      // console.log(list.firstElementChild);
      this.$modal.show(
        ModalAddLessonContent,
        { lessonid: lesson_id, type: type , data_length : this.lesson_content_data.length},
        { draggable: false },
        {
          "before-close": () => {
            this.onLoad();
          },
        }
      );
    },


    async allAudios(id) {
      try {
        let audResult = await api.get(`/admin/lib-audios`, {
          params: {
            library_id: id,
          },
        });

        this.all_audios = audResult.data.audios;
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    showModal() {
      this.$modal.show(
        //ModalAddLibrary,
        { id: this.cat_id },
        { draggable: true },
        {
          "before-close": () => {
            this.alllibrary();
          },
        }
      );
    },

    // showModal03(aid, aname, apath, adesc, libid) {
    //   this.$modal.show(
    //     //ModalEditAudio,
    //     {
    //       aid: aid,
    //       aname: aname,
    //       apath: apath,
    //       adesc: adesc,
    //       libid: libid,
    //     },
    //     { draggable: true },
    //     {
    //       "before-close": () => {
    //         //this.allAudios(libid);
    //       },
    //     }
    //   );
    // },

    callme(id) {
      this.allAudios(id);
      this.alllibrary();
    },

    callme03() {
      this.lessonContent();
    },

    callme02() {
      this.componentkey += 1;
      //this.$root.$refs.tab.forceRerender();
      this.alllibrary();
    },

    // async remove_cat(id, lib_id) {
    //   try {
    //     this.$swal({
    //       title: "Are you sure?",
    //       type: "warning",
    //       showCancelButton: true,
    //       confirmButtonColor: "#3085d6",
    //       cancelButtonColor: "#d33",
    //       confirmButtonText: "Yes, Delete it!",
    //       cancelButtonText: "No, cancel!",
    //       confirmButtonClass: "btn btn-success",
    //       cancelButtonClass: "btn btn-danger",
    //       buttonsStyling: true,
    //     }).then(async (res) => {
    //       if (res.isConfirmed) {
    //         let result = await api.delete(`/admin/delete-audio/` + id);
    //         this.allAudios(lib_id);
    //         this.$toast.success(result.data.message);
    //       }
    //     });
    //   } catch (error) {
    //     this.$toast.error(error.response.data.message);
    //   }
    // },
    async getname_cat(id) {
      let { data } = await api.get(`admin/category-details`, {
        params: {
          category_id: id,
        },
      });
      this.cat_name = data["category_detail"]?.name;
      this.cat_id = data["category_detail"]?.id;
    },

    // navigate_content(id, title) {
    //   console.log(id, title);
    // },
  },
};
</script>

<style scroped>
#modals-container .vm--modal {
  top: 20px !important;
}

.ql-editor {
  background-color: #166D8C;
  max-height: 100px;
}


.thumbnails {
  margin: auto;
  max-width: 300px;
}

.thumbnails .vueperslide {
  box-sizing: border-box;
  border: 1px solid #fff;
  transition: 0.3s ease-in-out;
  opacity: 0.7;
  cursor: pointer;
}

.thumbnails .vueperslide--active {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  opacity: 1;
  border-color: #000;
}

.libbox {
  padding: 30px;
  max-width: 300px;
  max-height: 300px;
  min-height: 100px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #041e4f;
  background: #009ef7;
  margin: 10px 8px;
  float: left;
  background-size: cover;
  border: solid 1px #041e4f;
  border-radius: 8px;
}

.descriptionbox {
  margin-top: 30px;
}

.dataTables_empty {
  padding: 10px 0 20px 0;
  visibility: hidden;
}

#service_table02 h4 {
  line-height: 50px;
}

.indexbox {
  line-height: 50px;
}

.dropdown box-icon {
  line-height: 50px;
}

.listbox {
  float: left;
  margin-bottom: 8px;
  border-bottom: solid 1px #dadada;
  width: 100%;
  padding: 8px 0px;
}

.imgbox {
  /* width: 75px; */
  height: 50px;
  border-radius: 50%;
}

.dropdown,
.dropend,
.dropstart,
.dropup {
  position: relative;
  background: #fff;
  border-radius: 50%;
  padding: 0px 5px;
  margin: 0 !important;
  padding: 0 !important;
  line-height: 20px;
}


h4 {
  /* line-height: 50px !important; */
  font-size: 1.15rem;
}

/* p {
  line-height: 50px !important;
} */
.linebox {
  padding-top: 25px !important;
}


.vsa-list {
  max-width: 100%;
  --vsa-heading-padding: 10px 15px;
}

.vsa-item__trigger__content {
  font-weight: normal;
}

.vsa-item__trigger:focus,
.vsa-item__trigger:hover {
  outline: none;
  background-color: #166d8c;
  color: var(--vsa-bg-color);
}

.vsa-item__trigger__icon--is-default:after,
.vsa-item__trigger__icon--is-default:before {
  background-color: var(--vsa-text-color);
  content: "";
  height: 3px;
  position: absolute;
  top: 10px;
  transition: all .13333s ease-in-out;
  width: 15px;
}

.vsa-item__trigger__icon--is-default:before {
  left: 0;
  transform: rotate(45deg) translate3d(8px, 0px, 0);
  transform-origin: 100%;
}

.vsa-item__trigger__icon--is-default:after {
  transform: rotate(-45deg) translate3d(-8px, 0px, 0);
  right: 0;
  transform-origin: 0;
}


/* .vsa-item__trigger[aria-expanded=true] .vsa-item__trigger__icon--is-default:before {
    transform: rotate(45deg) translate3d(14px,14px,0);
} */

/* .vsa-item__trigger[aria-expanded=true] .vsa-item__trigger__icon--is-default:after {
    transform: rotate(-45deg) translate3d(-14px,14px,0);
} */

.vsa-item__trigger[aria-expanded=true] .vsa-item__trigger__icon--is-default:before {
  transform: rotate(135deg) translate3d(8px, 10px, 0);
}


.vsa-item__trigger[aria-expanded=true] .vsa-item__trigger__icon--is-default:after {
  transform: rotate(45deg) translate3d(-17px, 0px, 0);
}

.vsa-item__trigger__icon--is-default {
  width: 39px;
  height: 24px;
  transform: scale(var(--vsa-default-icon-size));
  margin-top: 8px;
}
</style>
