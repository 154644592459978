<template>
  <div>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      @click="closebtn()"
    >
      &times;
    </button>
    <!-- <button @click="$emit('close')">Close</button> -->
    <div class="modalTop"><h3>Update Subcategory</h3></div>
    <div class="modalDesc">
      <div class="row">
        <div class="col-md-4"><label>Title</label></div>
        <div class="col-md-8">
          <div
            class="form-group"
            :class="{ error: v$.series_name.$errors.length }"
          >
            <input
              type="text"
              placeholder="Subcategory Title"
              v-model="series_name"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.series_name.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </div>
      </div>
      <br /><br />
      <!-- <div class="row">
        <div class="col-md-4"><label>Desctription</label></div>
        <div class="col-md-8">
          <div
            class="form-group"
            :class="{ error: v$.series_desc.$errors.length }"
          >
            <textarea
              type="catname"
              placeholder="Course Description"
              v-model="series_desc"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.series_desc.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </div>
      </div> -->

     
      <!-- <img v-if="imagepath" :src="imagepath" alt="" /> -->

       <!-- <div class="row">
        <div class="col-md-4"><label>Image</label></div>
        <div class="col-md-8">
          <input type="file" placeholder="" @change="onSeriesImageChange" />
          <div id="preview">
            <img v-if="series_image_url" :src="series_image_url" width="200" height="200"/>
          </div>
        </div>
      </div> -->


      <!-- <div class="row imgboxx">
        <div class="col-md-4"><label>Image</label></div>
        <div class="col-md-8">
          <div class="imgbox">
            <img
              v-if="audio_image_url == ''"
              :src="'/assets/media/services/base.jpg'"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <img
              v-if="audio_image_url !== ''"
              :src="audio_image_url"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <i class="fas fa-camera" @click="$refs.audio_image.click()"></i>
            <div class="clear"></div>
            <span v-if="audio_image_url == ''" previmg="false">
              image is required
            </span>

            <input
              class="ml"
              type="file"
              accept="image/jpeg"
              @change="onAudioImageChange"
              ref="audio_image"
              style="display: none"
            />
          </div>
        </div>
      </div> -->
      
      
      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-8">
          <button
            class="btn btn-primary w-md mybutton myleft submitbtn"
            v-on:click="updateVideo"
            :disabled="v$.$invalid"
          >
            Submit
          </button>
        </div>
        <div v-if="error" class="alert alert-danger">
          {{ error }}
        </div>
      </div>
    </div>
    <div class="modalFooter"></div>
  </div>
</template>

<script>
import api from "../../config/api";
import useVuelidate from "@vuelidate/core";
// import { required, minLength } from "@vuelidate/validators";
import { required } from "@vuelidate/validators";
//import AudioFile from "../Admin/AudioFile.vue";
export default {
  name: "ModalEditVideo",

  props: {
    sid: Number,
    sname: String,
    // sdesc: String,
    // simage: String,
  },

  data() {
    return {
      audio_image: "",
      audio_image_url: "",
      series_el: "",
      series_desc: "",
      series_name: "",
      previewImage: "",
      current_id : ""
    };
  },

  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      series_name: {
        required,
        // min: minLength(4),
      },
      // series_desc: {
      //   required,
      // },
    };
  },

  mounted() {
    //this.onLoad();
    this.series_name = this.sname;
    this.current_id = this.sid;
    // this.series_desc = this.sdesc;
    // this.audio_image_url = this.simage;

    // console.log(this.series_name);
    // console.log(this.current_id);
    // console.log(this.series_desc);
    // console.log(this.series_image);
  },

   created() {
    this.$root.$refs.ModalEditVideo = this;
  },

  methods: {
    onAudioImageChange(e){
      const file = e.target.files[0];
      this.audio_image = file;
      this.audio_image_url = URL.createObjectURL(file)
    },

    async updateVideo() {
      try {
        // const credentials = {
        //   title: this.state.sname,
        //   category_id: this.current_id,
        // };
        // console.log("id", this.current_id);
        //  console.log("name", this.series_name);
        //   console.log("desc", this.series_desc);
        //    console.log("image", this.series_image);

        const formData03 = new FormData();
        formData03.append("title", this.series_name);
        formData03.append("id", this.current_id);
        // formData03.append("description", this.series_desc);
        // if(this.audio_image){
        //   formData03.append("image", this.audio_image);
        // }
        let resultss = await api.post("/admin/update-course-cat", formData03);
        if (resultss.status == 200) {
          this.$toast.success(resultss.data.message);
          this.$root.$refs.Hypnosis.callme();
          const list = document.getElementById("modals-container");
          list.removeChild(list.firstElementChild);
          this.value02 = false;
        }
      } catch (error) {
        this.$toast.error(error.response.data.message);
      }
    },
  
    onLoad() {
      this.updateAudio();
    },

    closebtn() {
      // this.$root.$refs.Videos.callme();
      
      const list = document.getElementById("modals-container");
      list.removeChild(list.firstElementChild);
    },
  },
};
</script>

<style scoped>
.vm--container {
  z-index: 9999999999999999 !important;
}

.vm--modal {
  height: auto !important;
}
.modalTop {
  width: 100%;
  background: #041e4f;

  color: #fff;
}
.modalTop h3 {
  font-weight: 600;
  color: #fff;
  font-size: 1.42188rem;
  text-align: center;
  padding: 15px 0;
}

.modalDesc {
  padding: 20px 40px;
  max-height: 700px;
  overflow-y: scroll;
}
.modalDesc label {
  display: inline-block;
  line-height: 34px;
  font-size: 15px;
}
.modalDesc input {
  background: #fff;
  border: solid 1px #dadada;
  border-radius: 8px;
  padding: 8px 16px;
  color: #333;
  outline: none;
  width: 100%;
}
.submitbtn {
  margin-top: 30px;

  text-align: center;
  border-radius: 6px;
  padding: 8px 16px;

  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
.modalFooter {
  position: absolute;
  width: 100%;
  bottom: 0;
  width: 100%;
  background: #041e4f;
  height: 20px;
}
.fas {
  font-size: 30px;
  margin-top: 15px;
}
</style>
