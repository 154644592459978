<template>
  <div>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      @click="closebtn()"
    >
      &times;
    </button>
    <!-- <button @click="$emit('close')">Close</button> -->
    <div class="modalTop"><h3>Update Course</h3></div>
    <div class="modalDesc">
      <div class="row">
        <div class="col-md-4"><label>Title</label></div>
        <div class="col-md-8">
         
            <input
              type="text"
              placeholder="Course Title"
              v-model="series_name"
            />
            <p class="err" v-if="title_error != ''">{{ title_error }}</p>
        </div>
      </div>
      <br /><br />
      <div class="row">
        <div class="col-md-4"><label>Desctription</label></div>
        <div class="col-md-8">
         
            <textarea
              type="catname"
              placeholder="Course Description"
              v-model="series_desc"
            />
            <p class="err" v-if="desc_error != ''">{{ desc_error }}</p>
        </div>
      </div>

     
      <!-- <img v-if="imagepath" :src="imagepath" alt="" /> -->

       <!-- <div class="row">
        <div class="col-md-4"><label>Image</label></div>
        <div class="col-md-8">
          <input type="file" placeholder="" @change="onSeriesImageChange" />
          <div id="preview">
            <img v-if="series_image_url" :src="series_image_url" width="200" height="200"/>
          </div>
        </div>
      </div> -->


      <div class="row imgboxx">
        <div class="col-md-4"><label>Image</label></div>
        <div class="col-md-8">
          <div class="imgbox">
            <img
              v-if="audio_image_url == ''"
              :src="'/assets/media/services/base.jpg'"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <img
              v-if="audio_image_url !== ''"
              :src="audio_image_url"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <i class="fas fa-camera" @click="$refs.audio_image.click()"></i>
            <div class="clear"></div>
            <p class="err" v-if="image_error != ''">{{ image_error }}</p>
            <span v-if="audio_image_url == ''" previmg="false">
              <!-- image is required -->
            </span>

            <input
              class="ml"
              type="file"
              accept="image/jpeg"
              @change="onAudioImageChange"
              ref="audio_image"
              style="display: none"
            />
          </div>
        </div>
      </div>
      
      
      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-8">
          <button
            class="btn btn-primary w-md mybutton myleft submitbtn"
            v-on:click="updateVideo"
          >
            Submit
          </button>
        </div>
        <div v-if="error" class="alert alert-danger">
          {{ error }}
        </div>
      </div>
    </div>
    <div class="modalFooter"></div>
  </div>
</template>

<script>
import api from "../../config/api";
// import useVuelidate from "@vuelidate/core";
// import { required, minLength } from "@vuelidate/validators";
// import { required } from "@vuelidate/validators";
//import AudioFile from "../Admin/AudioFile.vue";
export default {
  name: "ModalEditVideo",

  props: {
    sid: Number,
    sname: String,
    sdesc: String,
    simage: String,
  },

  data() {
    return {
      title_error : "",
      image_error : "",
      desc_error : "",

      audio_image: "",
      audio_image_url: "",
      series_el: "",
      series_desc: "",
      series_name: "",
      previewImage: "",
      current_id : ""
    };
  },

 watch: {
  series_name(newVal) {
      if (newVal != "") {
        this.title_error = "";
      }
    },
    series_desc(newVal) {
      if (newVal != "") {
        this.desc_error = "";
      }
    },
    previewImage(newVal) {
      if (newVal != "") {
        this.image_error = "";
      }
    },
  },

  mounted() {
    //this.onLoad();
    this.series_name = this.sname;
    this.current_id = this.sid;
    this.series_desc = this.sdesc;
    this.audio_image_url = this.simage;

    // console.log(this.series_name);
    // console.log(this.current_id);
    // console.log(this.series_desc);
    // console.log(this.series_image);
  },

   created() {
    this.$root.$refs.ModalEditVideo = this;
  },

  methods: {
    onAudioImageChange(e){
      const file = e.target.files[0];
      this.audio_image = file;
      this.audio_image_url = URL.createObjectURL(file)
    },

    async updateVideo() {
      try {
        this.title_error = "";
        this.desc_error = "";
        this.image_error = "";

        const formData03 = new FormData();
        formData03.append("title", this.series_name);
        formData03.append("id", this.current_id);
        formData03.append("description", this.series_desc);
        if(this.audio_image){
          formData03.append("image", this.audio_image);
        }
        let resultss = await api.post("/admin/update-course", formData03);
        if (resultss.status == 200) {
          this.$toast.success(resultss.data.message);
          this.$root.$refs.Hypnosis.callme();
          const list = document.getElementById("modals-container");
          list.removeChild(list.firstElementChild);
          this.value02 = false;
        }
      } catch (error) {
        if (typeof error.response.data.errors.title !== "undefined") {
          this.title_error = error.response.data.errors.title[0];
        }
        if (typeof error.response.data.errors.description !== "undefined") {
          this.desc_error = error.response.data.errors.description[0];
        }
        if(typeof error.response.data.errors.image !== "undefined") {
          this.image_error = error.response.data.errors.image[0];
        }

        this.$toast.error(error.response.data.message);
      }
    },
  
    onLoad() {
      this.updateAudio();
    },

    closebtn() {
      // this.$root.$refs.Videos.callme();
      
      const list = document.getElementById("modals-container");
      list.removeChild(list.firstElementChild);
    },
  },
};
</script>

<style scoped>
.vm--container {
  z-index: 9999999999999999 !important;
}

.vm--modal {
  height: auto !important;
}
.modalTop {
  width: 100%;
  background: #041e4f;

  color: #fff;
}
.modalTop h3 {
  font-weight: 600;
  color: #fff;
  font-size: 1.42188rem;
  text-align: center;
  padding: 15px 0;
}

.modalDesc {
  padding: 20px 40px;
  max-height: 700px;
  overflow-y: scroll;
}
.modalDesc label {
  display: inline-block;
  line-height: 34px;
  font-size: 15px;
}
.modalDesc input {
  background: #fff;
  border: solid 1px #dadada;
  border-radius: 8px;
  padding: 8px 16px;
  color: #333;
  outline: none;
  width: 100%;
}
.submitbtn {
  margin-top: 30px;

  text-align: center;
  border-radius: 6px;
  padding: 8px 16px;

  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
.modalFooter {
  position: absolute;
  width: 100%;
  bottom: 0;
  width: 100%;
  background: #041e4f;
  height: 20px;
}
.fas {
  font-size: 30px;
  margin-top: 15px;
}
</style>
