<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-sm-flex align-items-center justify-content-between"
        >
          <div class="page-title-right">
            <h4 class="mb-sm-0 font-size-18">Events</h4>
            <a
              @click="$router.go(-1)"
              class="btn btn-primary w-md mybutton myleft"
            >
              <box-icon
                name="chevrons-left"
                type="solid"
                color="#ffffff"
                class="myicon"
              ></box-icon
              >Back</a
            >
          </div>

          <div class="page-title-right">
            <!-- <ol class="breadcrumb m-0">
              <li class="breadcrumb-item">
                <a href="">Services</a>
                <router-link to="/home/servicecategories" class="waves-effect">
                  Categories
                </router-link>
              </li>
              <li class="breadcrumb-item active">Events</li>
            </ol> -->
            <div>
              <button
                class="btn btn-primary waves-effect btn-label waves-light mybutton"
                @click="showModal()"
              >
                Add Events
              </button>
            </div>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
    <div class="events_block">
      <div class="row">
        <div class="col-lg-12">
          <div class="">
            <div class="table-responsive">
              <div class="card">
                <div class="card-body">
                  <table
                    class="table project-list-table table-nowrap align-middle table-borderless"
                    id="event_table"
                  >
                    <thead class="vthead">
                      <tr class="frown">
                        <th scope="col" style="width: 5%">#</th>
                        <th scope="col" style="width: 10%">Date</th>
                        <th scope="col" style="width: 10%">Image</th>
                        <!-- <th scope="col" style="width: 15%">Audio File</th> -->
                        <th scope="col" style="width: 15%">Event's Title</th>
                        <th scope="col" style="width: 20%">
                          Event's Description
                        </th>
                        <th scope="col" style="width: 8%">Link</th>
                        <th scope="col" style="width: 7%">type</th>
                        <th scope="col" style="width: 10%">Start Time</th>
                        <th scope="col" style="width: 10%">End Time</th>
                        <th scope="col" style="width: 5%">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in events" :key="index">
                        <th scope="row">{{ index + 1 }}</th>
                        <th scope="row">{{ item.date }}</th>
                        <td>
                          <img :src="item.image" alt="" />
                        </td>
                        <!-- <td>
                          <audio controls>
                            <source :src="item.path" type="audio/mpeg" />
                          </audio>
                        </td> -->

                        <td>{{ item.name }}</td>
                        <td>{{ item.description }}</td>
                        <td>
                          <a href="javascript:;" @click="gotostreampage(item.link)">Go to Stream</a>
                        </td>
                        <td>{{ item.type }}</td>

                        <td>
                          {{ item.start_time }}
                          <!-- <button
                          @click="changeStatusnew(item._id)"
                          class="badge"
                          v-bind:class="{
                            'bg-success': item.status == 'active',
                            'badge-danger': item.status == 'in-active',
                          }"
                        >
                          {{ item.status }}
                        </button> -->
                        </td>
                        <td>{{ item.end_time }}</td>
                        <!-- <td v-else>
                        <span class="badge badge-danger">{{
                          item.status
                        }}</span>
                      </td> -->
                        <td>
                          <div class="dropdown">
                            <a
                              href="#"
                              class="dropdown-toggle card-drop"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <box-icon
                                name="dots-horizontal-rounded"
                                class="myddicon"
                              ></box-icon>
                            </a>
                            <!-- <button @click="myFunction()" class="w3-button"> -->
                            <!-- <box-icon
                              name="dots-horizontal-rounded"
                              class="myddicon"
                            ></box-icon> -->
                            <!-- </button> -->
                            <!-- <div
                            id="Demo"
                            class="w3-dropdown-content w3-bar-block w3-card-4 w3-animate-zoom"
                          > -->
                            <!-- <div
                            id="myDIV"
                            class="dropdown-content w3-animate-zoom"
                          > -->
                            <div
                              class="dropdown-menu dropdown-menu-end dropdown-content w3-animate-zoom"
                              id="myDIV"
                            >
                              <!-- <div
                              class="dropdown-menu dropdown-menu-end dropdown-content"
                            > -->
                              <div>
                                <a
                                  href="#"
                                  @click="
                                    showModal02(
                                      item.id,
                                      item.name,
                                      item.description,
                                      item.link,
                                      item.image,
                                      item.start_time,
                                      item.end_time,
                                      item.date,
                                      item.type,
                                    )
                                  "
                                  class="listbox w3-bar-item w3-button"
                                >
                                  <box-icon
                                    name="edit"
                                    class="myicon"
                                  ></box-icon>
                                  Update Event</a
                                >
                                <a
                                  href="#"
                                  @click="remove_event(item.id)"
                                  class="listbox w3-bar-item w3-button"
                                  ><box-icon
                                    type="solid"
                                    name="message-alt-x"
                                    class="myicon"
                                  ></box-icon
                                  >Delete Now</a
                                >
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../config/api.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import ModalAddEvent from "../Modals/ModalAddEvent.vue";
import ModalEditEvent from "../Modals/ModalEditEvent.vue";
// import ModalVideobox from "../Modals/ModalVideobox.vue";
//import ModalEditAudio from "../Modals/ModalEditAudio.vue";
import $ from "jquery";

export default {
  name: "Events",
  data() {
    return {
      events: [],
      show_iframe : 1,
    };
  },

  mounted() {
    this.allEvents();
  },
  created() {
    this.$root.$refs.Events = this;
  },

  methods: {

    showModal03(id) {
      this.show_iframe = id;
    },

    gotostreampage(link) {
            console.log('stream');
            console.log(link);
            this.$router.push({
                name: "Stream",
                params: { link },
            });
        },


    async allEvents() {
      try {
        var mydata = [];
        let result = await api.get(`/admin/all-events`);
        mydata = result.data.all_events;
        
        mydata.map((item)=>{
          item.link = 'https://www.youtube.com/embed/' + item.link.substring(item.link.lastIndexOf("/") + 1, item.link.length)
        })
        
        this.events = mydata;

        setTimeout(() => {
          $("#event_table").DataTable();
        }, 0);
      } catch (error) {
        this.$toast.error(error.response.data.message);
      }
    },


    async remove_event(id) {
      try {
        this.$swal({
          title: "Are you sure?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete it!",
          cancelButtonText: "No, cancel!",
          confirmButtonClass: "btn btn-success",
          cancelButtonClass: "btn btn-danger",
          buttonsStyling: true,
        }).then(async (res) => {
          if (res.isConfirmed) {
            let result = await api.delete(`/admin/delete-event/` + id);
            this.allEvents();
            this.$toast.success(result.data.message);
          }
        });
      } catch (error) {
        this.$toast.error(error.response.data.message);
      }
    },

showModal() {
      this.$modal.show(
        ModalAddEvent,
        {  },
        { draggable: true },
        {
          "before-close": () => {
             this.allEvents();
          },
        }
      );
    },


    showModal02(id,name,desc,link,image,starttime,endtime,date,type) {
      this.front_modal = true;
      // this.$root.$refs.ModalVideobox.callme(this.frontModal);
      this.$modal.show(
        ModalEditEvent,
        {
          eid: id,
          ename: name,
          edesc: desc,
          elink: link,
          eimage : image,
          estarttime : starttime,
          eendtime : endtime,
          edate : date,
          etype : type
        },
        { draggable: true },
        {
          "before-close": () => {
            //this.allAudios(libid);
          },
        }
      );
    },

    callme() {
      this.allEvents();
    },

  },


};
</script>

<style>
.table img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}
</style>
