import Vue from 'vue'
import App from './App.vue'
import router from "./routes.js"
import { store } from "./Store/index"
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
//import "gitart-vue-dialog/dist/style.css";
//import { plugin as dialogPlugin } from 'gitart-vue-dialog'
//import { GDialog } from "gitart-vue-dialog";
import VModal from 'vue-js-modal'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
//import VeeValidate from 'vee-validate';
import VueCompositionAPI from '@vue/composition-api'

//this
import VueVideoPlayer from 'vue-video-player'   
 
// require videojs style
//this
import 'video.js/dist/video-js.css'
// import 'vue-video-player/src/custom-theme.css'

import ToggleButton from 'vue-js-toggle-button'
import ProgressBar from 'vuejs-progress-bar'









window.$ = window.jQuery = require('jquery').noConflict(true);

Vue.prototype.$eventBus = new Vue();

Vue.config.productionTip = false
const options = {
  // You can set your default options here
};

Vue.use(ToggleButton)
Vue.use(Toast, options);
Vue.use(VModal);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(VueCompositionAPI)
Vue.use(VueVideoPlayer)
Vue.use(require('vue-moment'));
Vue.use(VueGoodTablePlugin);
Vue.use(ProgressBar)



//Vue.use(VeeValidate);









new Vue({
  render: h => h(App),
  router: router,
  store: store,




}).$mount('#app')


