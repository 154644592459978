<template >
  <div id="modals-container02" v-if="!frontModall">
    <div id="modals-container" class="mymodalbox">
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        @click="closebtn()"
      >
        &times;
      </button>
      <!-- <div class="modalTop"><h3>User Profile</h3></div> -->
      <div class="modalDesc">
        <video-player
          class="video-player-box mymodalbox"
          ref="videoPlayer"
          :options="playerOptions"
          :playsinline="true"
          customEventName="customstatechangedeventname"
          @play="onPlayerPlay($event)"
          @pause="onPlayerPause($event)"
          @ended="onPlayerEnded($event)"
          @waiting="onPlayerWaiting($event)"
          @playing="onPlayerPlaying($event)"
          @loadeddata="onPlayerLoadeddata($event)"
          @timeupdate="onPlayerTimeupdate($event)"
          @canplay="onPlayerCanplay($event)"
          @canplaythrough="onPlayerCanplaythrough($event)"
          @statechanged="playerStateChanged($event)"
          @ready="playerReadied"
        >
        </video-player>
      </div>
      <!-- <div class="modalFooter"></div> -->
    </div>
  </div>
</template>

<script>
//import api from "../../config/api";
export default {
  props: {
    id: Number,
    aname: String,
    apath: String,
    frontModal:Boolean
  },
  data() {
    return {
      playerOptions: {
        // videojs options
        muted: false,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: this.apath,
          },
        ],
        //   poster: "/static/images/author.jpg",
      },
      frontModall:false,
    };
  },
  created() {
    this.$root.$refs.ModalVideobox = this;
  },
  mounted() {
    console.log("this is current player instance object", this.player);
    //this.frontModall = this.frontModal;
  },
  computed: {
    // player() {
    //   return this.$refs.videoPlayer.player;
    // },
  },
  methods: {
    // listen event
    //   onPlayerPlay(player) {
    //     // console.log('player play!', player)
    //   },
    //   onPlayerPause(player) {
    //     // console.log('player pause!', player)
    //   },
    //   // ...player event

    //   // or listen state event
    //   playerStateChanged(playerCurrentState) {
    //     // console.log('player current update state', playerCurrentState)
    //   },

    // player is ready
    playerReadied(player) {
      console.log("the player is readied", player);
      // you can use it to do something...
      // player.[methods]
    },

    closebtn() {
      const list = document.getElementById("modals-container");
      list.removeChild(list.firstElementChild);
    },
    callme(fmod) {
      this.frontModall =  fmod;
      console.log(this.frontModall, "Boolean")
    },
  },
};
</script>

<style scoped>
/* #modals-container {
  height: auto !important;
  width: 1100px !important;
}

.vm--modal {
  height: auto !important;
  width: 1100px !important;
  overflow: visible !important;
} */
.modalTop {
  width: 100%;
  background: #041e4f;

  color: #fff;
}
.modalTop h3 {
  font-weight: 600;
  color: #fff;
  font-size: 1.42188rem;
  text-align: center;
  padding: 15px 0;
}

/* .modalDesc {
  padding: 20px 40px;
} */

.modalDesc {
  padding: 0 !important;
}
.modalDesc label {
  display: inline-block;
  line-height: 34px;
  font-size: 15px;
}
.modalDesc input {
  background: #fff;
  border: solid 1px #dadada;
  border-radius: 8px;
  padding: 8px 16px;
  color: #333;
  outline: none;
  width: 100%;
}
.submitbtn {
  margin-top: 30px;

  text-align: center;
  border-radius: 6px;
  padding: 8px 16px;

  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
.modalFooter {
  position: absolute;
  width: 100%;
  bottom: 0;
  width: 100%;
  background: #041e4f;
  height: 20px;
}
/* .fas {
  font-size: 30px;
  margin-top: 15px;
} */

.imgboxx {
  margin-top: 15px;
  display: table;
  margin: 0 auto 20px auto;
}

.imgboxx label {
  margin-top: 20px;
  line-height: 24px;
}

/* .imgbox {
  margin-right: 15px;
  display: table;
}
.imgbox img {
  margin-right: 15px;
  float: left;
}
.imgbox .fas {
  float: right;
} */
/* .glamproDetail .img-thumbnail {
  float: left;
} */
/* .img-thumbnail {
  margin-right: 15px;
} */
.clear {
  clear: both;
}
.row.inner {
  border-top: solid 1px #dadada;
  border-bottom: solid 1px #fff;
  line-height: 24px;
  padding: 10px 0;
  width: 100%;
}
.areabox {
  float: left;
  margin-right: 6px;
}
.row.inner::first-child {
  border-top: none;
}
.row.inner::last-child {
  border-bottom: none;
}
/* .row h4 {
  margin-bottom: 30px;
} */

.vm--container {
  z-index: 99999999 !important;
}
.mymodalbox {
  width: 600px !important;
  height: 475px !important;
  position: relative;
  z-index: 9999999;
}

.vjs_video_3-dimensions {
  width: 600px !important;
  height: 475px !important;
  z-index: 9999999;
}

.video-js .vjs-big-play-button {
  display: block !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  margin: auto !important;
}

/* .video-js .vjs-tech{
  width: 600px !important;
  height: 475px !important;} */

.video-js {
  width: 600px !important;
  height: 475px !important;
}
</style>
