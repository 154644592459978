// const baseURL ="http://127.0.0.1:8000/api"
const baseURL ="https://virtualrealitycreators.com/Believe-Backend/api"

    //"https://api-believe.staginganideos.com/api";


//import { store } from "../store/store";

import axios from "axios";
import { store } from "../Store/index.js"
// Create axios client, pre-configured with baseURL
let API = axios.create({ baseURL });


// Add a request interceptor
API.interceptors.request.use(
    function (config) {
        let token = localStorage.getItem("loginkey");
        if (!token) delete config.headers.common["Authorization"];
        else config.headers.common["Authorization"] = `Bearer ${token}`;

        // Do something before request is sent(this is for loader display)
        store.state.isLoading = true;
        return config;
    },
    function (error) {
        // Do something with request error
        store.state.isLoading = false;
        return Promise.reject(error);
    }
);

// Add a response interceptor
API.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        store.state.isLoading = false;
        return response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        store.state.isLoading = false;
        return Promise.reject(error);
    }
);


// export const setClientToken = (token) => {
//     if (!token) delete API.defaults.headers.common["Authorization"];
//     else API.defaults.headers.common["Authorization"] = `Bearer ${token}`;
// };



export default API;