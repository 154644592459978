<template>
    <div class="row imgboxx">
        <div class="col-md-4">Tags</div>
        <div class="col-md-8">
            <div class="tags-input-container">
                <input v-model="tagValue" @keyup.enter="addTag">

                <!-- multi tag work start-->
                <div class="tag_box">
                    <span v-for="(tag,index) in allTags" :key="'tag'+index">
                        <span :style="(tag.selected) ? 'background:#009EF7;color:#fff' : ''" @click="tagSelect(tag)">{{
                        tag.tag
                        }}</span>
                    </span>
                </div>
                <!-- multi tag work end-->

                <div class="tag" v-for="(tag, index) in tags" :key="'tag'+index">
                    <span v-if="activeTag !== index" @click="activeTag = index">{{ tag }}</span>
                    <input v-else v-model="tags[index]" v-focus :style="{'width': tag.length + 'ch'}"
                        @keyup.enter="activeTag = null" @blur="activeTag = null" />
                    <span @click="removeTag(index,tag)"><i class="fas fa-times-circle"></i></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from "../config/api";
export default {
    name: "Tag",
    props: ['oldtags'],
    data() {
        return {
            allTags: [],
            tags: [],
            tagValue: '',
            activeTag: null,
        }
    },
    mounted() {
        this.allTagsFunc();
        if(this.oldtags != "")
            if(typeof(this.oldtags) == 'string'){
                this.tags = JSON.parse(this.oldtags);
            }else{
                this.tags = this.oldtags;
            }
    },
    methods: {
        async allTagsFunc() {
            try {
                let result = await api.get(`/admin/all-tags`);
                let tags = result.data.all_tags;
                tags.forEach(object => {
                    if(this.oldtags != "")
                    {
                        if(this.oldtags.includes(object.tag)){
                            object.selected = true;
                        }
                    }else{
                        object.selected = false;
                    }    
                });
                tags = tags.filter(x => x.status == 'Active');
                this.allTags = tags;
            } catch (error) {
                this.error = error.response.data.message;
                this.$toast.error(this.error);
            }
        },
        addTag() {
            if (!this.tagValue == '')
                if (this.tagValue.indexOf(',') > -1) {
                    const arr = this.tagValue.split(',');
                    this.tags = [...this.tags, ...arr];
                    this.$emit('updateTag', this.tags);
                } else {
                    this.tags.push(this.tagValue);
                    this.$emit('updateTag', this.tagValue);
                }
            this.tagValue = '';
        },
        tagSelect(tags) {
            this.allTags.map((tg) => {
               if (tg.id == tags.id) {
                    tg.selected = !tg.selected
                }
                return tg;
            })
            
            if (!this.tags.includes(tags.tag)) {
                this.tags.push(tags.tag);
            } else {
                this.tags = this.tags.filter(x => x != tags.tag);
            }
            this.$emit('updateTag', this.tags);
        },
        tagSelectForUpdate(tags) {
            this.allTags.map((tg) => {
               if (tg.id == tags.id) {
                    tg.selected = !tg.selected
                }
                return tg;
            })
            
            if (!this.tags.includes(tags.tag)) {
                this.tags.push(tags.tag);
            } else {
                this.tags = this.tags.filter(x => x != tags.tag);
            }
            this.$emit('updateTag', this.tags);
        },
        removeTag(index, tag) {
            this.allTags.map((tg) => {
                if (tg.tag == tag) {
                    tg.selected = false
                }
                return tg;
            })
            this.tags.splice(index, 1);
            this.$emit('updateTag', this.tags);
        },

    }
}
</script>

<style scoped>
/* multi tags work start */
.tag_box{
    background: #fff;
    border: solid 1px #dadada;
    border-radius: 8px;
    padding: 8px;
    color: #333;
    outline: none;
    width: 100%;
    height: 150px;
    overflow-y: auto;
    margin: 15px 0;
    word-break: break-word;
}

.tags-input-container > input{
    background: #fff;
    border: solid 1px #dadada;
    border-radius: 8px;
    padding: 8px 16px;
    color: #333;
    outline: none;
    width: 100%;
    margin: 15px 0;
}


.tag_box span {
    display: inline-block;
}

.tag_box span span {
    border: 1px solid grey;
    padding: 4px 10px;
    border-radius: 15px;
    margin: 10px 2px;
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 5px;
}

/* multi tags work end */
</style>