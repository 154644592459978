<template ref="libraries">
  <div>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      @click="closebtn()"
    >
      &times;
    </button>
    <!-- <button @click="$emit('close')">Close</button> -->
    <div class="modalTop"><h3>Update Module</h3></div>
    <div class="modalDesc">
      <div class="row">
        <div class="col-md-4"><label>Update Name</label></div>
        <div class="col-md-8">
          <div
            class="form-group"
            :class="{ error: v$.lesson_name.$errors.length }"
          >
            <input
              type="text"
              placeholder="Module Name"
              v-model="v$.lesson_name.$model"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.lesson_name.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </div>
      </div>
      <br /><br />
      <div class="row">
        <div class="col-md-4"><label>Module Desctription</label></div>
        <div class="col-md-8">
          <div
            class="form-group"
            :class="{ error: v$.lesson_desc.$errors.length }"
          >
            <textarea
              type="catname"
              placeholder="Module Description"
              v-model="v$.lesson_desc.$model"
            />
            <!-- <input
              type="text"
              placeholder="Audio Name"
              v-model="v$.aud_desc.$model"
            /> -->
            <div
              class="input-errors"
              v-for="(error, index) of v$.lesson_desc.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row imgboxx">
        <div class="col-md-4"><label>Image</label></div>
        <div class="col-md-8">
          <div class="imgbox">
            <img
              v-if="audio_image_url == ''"
              :src="'/assets/media/services/base.jpg'"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <img
              v-if="audio_image_url !== ''"
              :src="audio_image_url"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <i class="fas fa-camera" @click="$refs.audio_image.click()"></i>
            <div class="clear"></div>
            <p class="err" v-if="image_error != ''">{{ image_error }}</p>
            <span v-if="audio_image_url == ''" previmg="false">
              <!-- image is required -->
            </span>

            <input
              class="ml"
              type="file"
              accept="image/jpeg"
              @change="onAudioImageChange"
              ref="audio_image"
              style="display: none"
            />
          </div>
        </div>
      </div>

      
      
      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-8">
          <button
            class="btn btn-primary w-md mybutton myleft submitbtn"
            v-on:click="updateLesson"
            :disabled="v$.$invalid"
          >
            Submit
          </button>
        </div>
        <div v-if="error" class="alert alert-danger">
          {{ error }}
        </div>
      </div>
    </div>
    <div class="modalFooter"></div>
  </div>
</template>

<script>
import api from "../../config/api";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
//import AudioFile from "../Admin/AudioFile.vue";
export default {
  name: "ModalEditAudioAff",

  components: {
    //AudioFile,
  },
  props: {
    aid: Number,
    aname: String,    
    adesc: String,
    courseid: Number,
    image: String,
  },

  data() {
    return {
      image_error : "",
      lesson_name: "",
      lesson_desc: "",      
      current_id: "",
      course_id: "",    
      audio_image_url: "",
      audio_image: "",
      
    };
  },

  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      lesson_name: {
        required,
        // min: minLength(4),
      },
      lesson_desc: {
        required,
        // min: minLength(4),
      },
    };
  },

  watch: {
    lesson_name(newVal) {
      if (newVal != "") {
        this.title_error = "";
      }
    },
    lesson_desc(newVal) {
      if (newVal != "") {
        this.desc_error = "";
      }
    },
    previewImage(newVal) {
      if (newVal != "") {
        this.image_error = "";
      }
    },
  },

  mounted() {
    //this.onLoad();

    this.lesson_name = this.aname;
    this.current_id = this.aid;
    this.lesson_desc = this.adesc;
    
    this.course_id = this.courseid;
    this.audio_image_url = this.image;
    //console.log(this.audioFile);
  },

  methods: {

    onAudioImageChange(e){
      const file = e.target.files[0];
      this.audio_image = file;
      this.audio_image_url = URL.createObjectURL(file)
    },

    async updateLesson() {
      try {
        // const credentials = {
        //   title: this.state.sname,
        //   category_id: this.current_id,
        // };
        this.image_error = "";

        const formData03 = new FormData();
        formData03.append("title", this.lesson_name);
        formData03.append("id", this.current_id);
        formData03.append("course_id", this.course_id);
        formData03.append("description", this.lesson_desc);
        
        if(this.audio_image){
          formData03.append("image", this.audio_image);
        }

        // this.$refs.audioFile.files[0] = "";

        // let resultss = await api.post("/admin/edit-category", credentials);

        let resultss = await api.post(
          "/admin/update-lesson",
          formData03
        );
        //console.log("Songs", resultss);
        if (resultss.status == 200) {
          this.$toast.success(resultss.data.message);
          this.$root.$refs.Courses.callme();
          //this.$root.$refs.LibrarysAudios.onload(this.lib_id);
          //this.$root.$refs.Affirmations.callme();

          const list = document.getElementById("modals-container");
          list.removeChild(list.firstElementChild);

          this.value02 = false;
        }
      } catch (error) {
        if(typeof error.response.data.errors.image !== "undefined") {
          this.image_error = error.response.data.errors.image[0];
        }
        this.$toast.error(error.response.data.message);
      }
    },
    uploadAudio(e) {
      this.audioFile = "";
      const audio = e.target.files[0];
      console.log(audio, "My Audio");
      const reader = new FileReader();
      reader.readAsDataURL(audio);
      // reader.onload = (e) => {

      // };
      var audio_el = document.createElement("audio");

      reader.onload = (e) => {
        this.audioFile = e.target.result;
        audio_el.src = e.target.result;
        audio_el.addEventListener("loadedmetadata", () => {
          // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
          var duration = audio_el.duration;
          this.file_duration = duration;

          // example 12.3234 seconds
          // console.log(
          //   "The duration of the song is of: " + this.file_duration + " seconds"
          // );
          // Alternatively, just display the integer value with
          // parseInt(duration)
          // 12 seconds
        });
      };

      reader.readAsDataURL(audio);
      //console.log(this.file_duration, "Song Duration");
      this.count++;
    },

    onLoad() {
      this.updateAudio();
    },

    // uploadImage02(e) {
    //   const image = e.target.files[0];
    //   const reader = new FileReader();
    //   reader.readAsDataURL(image);
    //   reader.onload = (e) => {
    //     this.vpath = e.target.result;
    //     console.log(this.vpath);
    //   };
    // },
    closebtn() {
      // this.$root.$refs.Courses.callme();

      const list = document.getElementById("modals-container");
      list.removeChild(list.firstElementChild);
    },
  },
};
</script>

<style scoped>
.vm--modal {
  height: auto !important;
}
.modalTop {
  width: 100%;
  background: #041e4f;

  color: #fff;
}
.modalTop h3 {
  font-weight: 600;
  color: #fff;
  font-size: 1.42188rem;
  text-align: center;
  padding: 15px 0;
}

.modalDesc {
  padding: 20px 40px;
  max-height: 700px;
  overflow-y: scroll;
}
.modalDesc label {
  display: inline-block;
  line-height: 34px;
  font-size: 15px;
}
.modalDesc input {
  background: #fff;
  border: solid 1px #dadada;
  border-radius: 8px;
  padding: 8px 16px;
  color: #333;
  outline: none;
  width: 100%;
}
.submitbtn {
  margin-top: 30px;

  text-align: center;
  border-radius: 6px;
  padding: 8px 16px;

  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
.modalFooter {
  position: absolute;
  width: 100%;
  bottom: 0;
  width: 100%;
  background: #041e4f;
  height: 20px;
}
.fas {
  font-size: 30px;
  margin-top: 15px;
}
</style>
