<template>
  <div>
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-sm-flex align-items-center justify-content-between"
        >
          <div class="page-title-right">
            <h4 class="mb-sm-0 font-size-18">
              Audois of {{ lib_name }} Library
            </h4>
            <a
              @click="$router.go(-1)"
              class="btn btn-primary w-md mybutton myleft"
            >
              <box-icon
                name="chevrons-left"
                type="solid"
                color="#ffffff"
                class="myicon"
              ></box-icon
              >Back</a
            >
          </div>

          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item">
                <!-- <a href="">Services</a> -->
                <router-link to="/home/all_libraries" class="waves-effect">
                  All Libraries' List
                </router-link>
              </li>
              <li class="breadcrumb-item active">
                All Audios of {{ lib_name }}
              </li>
            </ol>
            <div></div>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
    <!-- end page title -->

    <div class="row">
      <div class="col-lg-12">
        <div class="">
          <div class="table-responsive">
            <div class="card">
              <div class="card-body">
                <table
                  class="table table-hover table-bordered"
                  id="audios_table"
                >
                  <thead>
                    <tr class="frown">
                      <th scope="col" style="width: 5%">#</th>
                      <th scope="col" style="width: 10%">Image</th>
                      <th scope="col" style="width: 15%">Audio Name</th>
                      <th scope="col" style="width: 45%">Audio description</th>
                      <th scope="col" style="width: 20%">Audio File</th>
                      <th scope="col" style="width: 5%">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in all_audios" :key="index">
                      <td scope="row" class="sorting_1">{{ index + 1 }}</td>
                      <td>
                        <img :src="item.image" alt="" />
                      </td>
                      <td>
                        <h5 class="text-truncate font-size-14">
                          {{ item.name }}
                        </h5>
                        <!-- <p class="text-muted mb-0 text-ellipse-250">
                          {{ item.name }}
                        </p> -->
                      </td>

                      <td>
                        <p>
                          {{ item.description }}
                        </p>
                        <!-- <p class="text-muted mb-0 text-ellipse-250">
                          {{ item.name }}
                        </p> -->
                      </td>

                      <td>
                        <audio controls>
                          <source :src="item.path" type="audio/mpeg" />
                        </audio>

                        <!-- <p class="text-muted mb-0 text-ellipse-250">
                          {{ item.name }}
                        </p> -->
                      </td>

                      <!-- <td>
                        <label class="switch">
                          <input
                            type="checkbox"
                            :checked="item.status == 'Active'"
                            @change="changeStatusnew(item.id)"
                          />
                          <span class="slider round"></span>
                        </label>
                      </td> -->

                      <td>
                        <!-- <div class="w3-dropdown-click mydropdown"> -->
                        <div class="dropdown">
                          <a
                            href="#"
                            class="dropdown-toggle card-drop"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <box-icon
                              name="dots-horizontal-rounded"
                              class="myddicon"
                            ></box-icon>
                          </a>
                          <!-- <button @click="myFunction()" class="w3-button"> -->
                          <!-- <box-icon
                              name="dots-horizontal-rounded"
                              class="myddicon"
                            ></box-icon> -->
                          <!-- </button> -->
                          <!-- <div
                            id="Demo"
                            class="w3-dropdown-content w3-bar-block w3-card-4 w3-animate-zoom"
                          > -->
                          <!-- <div
                            id="myDIV"
                            class="dropdown-content w3-animate-zoom"
                          > -->
                          <div
                            class="dropdown-menu dropdown-menu-end dropdown-content w3-animate-zoom"
                            id="myDIV"
                          >
                            <!-- <div
                              class="dropdown-menu dropdown-menu-end dropdown-content"
                            > -->
                            <div>
                              <a
                                href="/home/all_libraries"
                                class="listbox w3-bar-item w3-button"
                              >
                                <box-icon
                                  name="library"
                                  class="myicon"
                                ></box-icon>
                                All Libraries</a
                              >

                              <!-- <router-link
                                :to='all_audios'
                                class="listbox w3-bar-item w3-button"
                                
                              >
                                <box-icon
                                  name="library"
                                  class="myicon"
                                ></box-icon>
                                All Audios</router-link
                              > -->

                              <a
                                href="/home/all_audios"
                                class="listbox w3-bar-item w3-button"
                              >
                                <box-icon
                                  name="podcast"
                                  class="myicon"
                                ></box-icon>

                                All Audios</a
                              >

                              <a
                                href="#"
                                @click="
                                  showModal02(
                                    item.id,
                                    item.name,
                                    item.path,
                                    item.description,
                                    item.library_id,
                                    item.duration
                                  )
                                "
                                class="listbox w3-bar-item w3-button"
                              >
                                <box-icon name="edit" class="myicon"></box-icon>
                                Update Audio</a
                              >
                              <a
                                href="#"
                                @click="remove_aud(item.id)"
                                class="listbox w3-bar-item w3-button"
                                ><box-icon
                                  type="solid"
                                  name="message-alt-x"
                                  class="myicon"
                                ></box-icon
                                >Delete Now</a
                              >
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import ModalEditAudio from "../Modals/ModalEditAudio.vue";
import api from "../../config/api.js";
import $ from "jquery";
export default {
  name: "LibrarysAudios",
  props: {
    name: String,
  },
  data() {
    return {
      all_audios: [],
      all_libraries: [],
      lib_name: "",
      libid: "",
      alllib: [],
    };
  },
  created() {
    this.$root.$refs.LibrarysAudios = this;
  },

  mounted() {
    this.libid = this.$route.params.id;
    this.allaudios();
    
    this.getname_lib(this.libid);
  },
  methods: {
    async allaudios() {
      try {
        let result = await api.get(`/admin/lib-audios`, {
          params: { library_id: this.libid },
        });

        this.all_audios = result.data.audios;

        setTimeout(() => {
          $("#audios_table").DataTable();
        }, 0);
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },
    async remove_aud(id) {
      try {
        this.$swal({
          title: "Are you sure?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete it!",
          cancelButtonText: "No, cancel!",
          confirmButtonClass: "btn btn-success",
          cancelButtonClass: "btn btn-danger",
          buttonsStyling: true,
        }).then(async (res) => {
          if (res.isConfirmed) {
            let result = await api.delete(`/admin/delete-audio/` + id);
            this.allaudios();
            this.$toast.success(result.data.message);
            // console.log("I am in Delete mode", result);
            // this.onLoad();
          }
        });
      } catch (error) {
        this.$toast.error(error.response.data.message);
      }
    },

    async getname_lib(lib_id) {
      try {
        let result = await api.get(`/admin/all-libraries`);
        this.all_libraries = result.data.all_libraries;

        var newArray = this.all_libraries.filter(function (el) {
          return el.id == lib_id;
        });
        this.lib_name = newArray[0].name;
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },
    showModal02(aid, aname, apath, adesc, libid, audlong) {
      this.$modal.show(
        ModalEditAudio,
        {
          aid: aid,
          aname: aname,
          apath: apath,
          adesc: adesc,
          libid: libid,
          audlong: audlong,
          outside02: true,
        },
        { draggable: true },
        {
          "before-close": () => {
            //this.alllibrarieslist();
          },
        }
      );
    },
    onload(id) {
      this.libid=id
      this.allaudios();
    },
  },
};
</script>

<style scoped>
#audios_table h5 {
  line-height: 65px;
}
#audios_table .switch {
  margin-top: 25px;
}
#audios_table .dropdown {
  margin-top: 15px;
}
</style>
