<template>
  <div>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      @click="closebtn()"
    >
      &times;
    </button>
    <div class="modalTop"><h3>Edit Event</h3></div>
    <div class="modalDesc">
      <div class="row">
        <div class="col-md-6">
          <label class="eventLabel">Event Title</label>
          <div
            class="form-group"
            :class="{ error: v$.event_name.$errors.length }"
          >
            <input
              type="text"
              placeholder="Event Name"
              v-model="v$.event_name.$model"
              class="eventInput"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.event_name.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <label for="datemin" class="eventLabel">Event Date</label>
          <div class="form-group" :class="{ error: v$.date.$errors.length }">
            <!-- <datepicker :value="date"></datepicker> -->
            <!-- <datepicker
              label="operationalsince"
              v-model="operationalsince"
              :max="new Date().toISOString()"
              @input="$v.operationalsince.$touch()"
              @blur="$v.operationalsince.$touch()"
              required
            ></datepicker> -->

            <!-- <input
              type="text"
              placeholder="Event Description"
              v-model="v$.date.$model"
            /> -->
            <input
              type="date"
              v-model="v$.date.$model"
              id="datemin"
              name="datemin"
              min="2000-01-02"
              class="eventInput"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.date.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <label class="eventLabel">Event Start Time</label>
          <div
            class="form-group"
            :class="{ error: v$.event_startTime.$errors.length }"
          >
            <!-- <datepicker :value="date"></datepicker> -->

            <input
              type="time"
              placeholder="Event Description"
              v-model="v$.event_startTime.$model"
              onchange="onTimeChange()"
              id="timeInput"
              class="eventInput"
            />

            <div
              class="input-errors"
              v-for="(error, index) of v$.event_startTime.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <label class="eventLabel">Event End Time</label>
          <div
            class="form-group"
            :class="{ error: v$.event_endTime.$errors.length }"
          >
            <!-- <datepicker :value="date"></datepicker> -->

            <input
              type="time"
              placeholder="Event Description"
              v-model="v$.event_endTime.$model"
              onchange="onTimeChange()"
              id="timeInput"
              class="eventInput"
            />

            <div
              class="input-errors"
              v-for="(error, index) of v$.event_endTime.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <label class="eventLabel">Event Description</label>

          <div
            class="form-group"
            :class="{ error: v$.event_desc.$errors.length }"
          >
            <textarea
              type="text"
              placeholder="Event Description"
              v-model="v$.event_desc.$model"
              class="eventInput"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.event_desc.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <label class="eventLabel">Event Link</label>
          <div
            class="form-group"
            :class="{ error: v$.event_link.$errors.length }"
          >
            <input
              type="text"
              placeholder="Event Link"
              v-model="v$.event_link.$model"
              class="eventInput"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.event_link.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row imgboxx">
        <div class="col-md-4"><label>Event Image</label></div>
        <div class="col-md-8">
          <div class="imgbox">
            <img
              v-if="previewImage == ''"
              :src="'/assets/media/services/base.jpg'"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <img
              v-if="previewImage !== ''"
              :src="previewImage"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <i class="fas fa-camera" @click="$refs.imageFile.click()"></i>
            <div class="clear"></div>
            <p class="err" v-if="image_error != ''">{{ image_error }}</p>
            <span v-if="previewImage == ''" previmg="false">
              <!-- image is required -->
            </span>

            <input
              class="ml"
              type="file"
              accept="image/jpeg"
              @change="uploadImage"
              ref="imageFile"
              style="display: none"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-8">
          <button
            class="btn btn-primary w-md mybutton myleft submitbtn"
            v-on:click="addEvent"
            :disabled="v$.$invalid"
          >
            Submit
          </button>
        </div>
        <div v-if="error" class="alert alert-danger">
          {{ error }}
        </div>
      </div>
    </div>
    <div class="modalFooter"></div>
  </div>
</template>

<script>
import api from "../../config/api";
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import moment from 'moment'

// import Datepicker from "vuejs-datepicker";
export default {
  name: "ModalEditEvent",
  props: {
  eid: Number,
  ename: String,
  edesc: String,
  elink: String,
  estarttime: String,
  eendtime: String,
  etype: String,
  edate: String,
  // audpath: String,
  // libid: Number,
  // id: Number,
  eimage: String,
  // status: String,
},
  components: {
    // Datepicker,
  },
  data() {
    return {
      image_error: "",
      event_name: "",
    event_desc: "",
    event_link: "",
    event_startTime: "",
    event_endTime: "",
    event_type: "Upcoming",
    date: "",
    previewImage: "",
    current_id: "",
    cat_status: "",
    };
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      event_name: {
        required,
        min: minLength(4),
      },
      event_desc: {
        required,
        min: minLength(4),
      },
      event_link: {
        required,
      },
      event_startTime: {
        required,
        min: minLength(4),
      },
      event_endTime: {
        required,
        min: minLength(4),
      },
      date: {
        required,
        //   maxValue: maxValue(new Date()),
      },
      
    };
  },


  watch: {
    previewImage(newVal) {
      if (newVal != "") {
        this.image_error = "";
      }
    },
  },

  mounted() {

    this.event_name = this.ename;
    this.event_desc = this.edesc;
    this.event_link = this.elink;
    this.event_startTime = moment(this.estarttime).format('HH:mm:ss');
    this.event_endTime = moment(this.eendtime).format('HH:mm:ss');
    this.previewImage = this.eimage;
    this.date = this.edate;
    this.current_id = this.eid;

  },

  methods: {
    async addEvent() {
      try {
        this.image_error = ""
        const formData_event = new FormData();
        formData_event.append("id", this.current_id);
        formData_event.append("name", this.event_name);
        formData_event.append("description", this.event_desc);
        formData_event.append("start_time", this.event_startTime);
        formData_event.append("end_time", this.event_endTime);
        formData_event.append("type", this.event_type);
        formData_event.append("link", this.event_link);
        formData_event.append("date", this.date);

        if (this.$refs.imageFile.files[0]) {
          formData_event.append("image", this.$refs.imageFile.files[0]);
        }

        let result = await api.post(`/admin/update-event`, formData_event);
        console.log(result.data.event);
         if (result.status == 200) {
          this.$toast.success(result.data.message);
          this.$root.$refs.Events.callme();
          const list = document.getElementById("modals-container");
          list.removeChild(list.firstElementChild);

          // var data = {
          //   id : result.data.event.id,
          //   name : result.data.event.name,
          //   description : result.data.event.description,
          //   link : result.data.event.link,
          //   start_time : result.data.event.start_time,
          //   end_time : result.data.event.end_time,
          //   date : result.data.event.date,
          // }      

        const formData_event2 = new FormData();
        formData_event2.append("id", result.data.event.id);
        formData_event2.append("name", result.data.event.name);
        formData_event2.append("description", result.data.event.description);
        formData_event2.append("start_time", result.data.event.link);
        formData_event2.append("end_time", result.data.event.start_time);
        formData_event2.append("type", result.data.event.end_time);
        formData_event2.append("link", result.data.event.date);
          

        // var json = JSON.stringify(data);
        // console.log(json);

          // let result2 = await api.post('https://cors-anywhere.herokuapp.com/https://script.google.com/macros/s/AKfycbyciorAiXo6C8-70ABvKy8wFX2lb2FvU30H4ugaq5of-yO2rhVHeCgPZbe2y05ujDqXtA/exec', formData_event2)
          //  console.log(result2);
        }
      } catch (error) {
        if(typeof error?.response?.data?.errors?.image !== "undefined") {
          this.image_error = error.response.data.errors.image[0];
        }
        // this.$toast.error(error.response.data.message);
      }
    },

    uploadAudio(e) {
      const audio = e.target.files[0];
      console.log(audio, "My Audio");
      const reader = new FileReader();
      reader.readAsDataURL(audio);
      // reader.onload = (e) => {

      // };
      var audio_el = document.createElement("audio");

      reader.onload = (e) => {
        this.audioFile = e.target.result;
        audio_el.src = e.target.result;
        audio_el.addEventListener(
          "loadedmetadata",
          () => {
            // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
            var duration = audio_el.duration;
            this.file_duration = duration;

            // example 12.3234 seconds
            console.log(
              "The duration of the song is of: " +
                this.file_duration +
                " seconds"
            );
            // Alternatively, just display the integer value with
            // parseInt(duration)
            // 12 seconds
          },
          false
        );
      };

      //reader.readAsDataURL(audio);
      console.log(this.file_duration, "Song Duration");
    },

    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
        console.log(this.previewImage);
      };
    },

    closebtn() {
      //this.$root.$refs.Libraries.callme02();
      const list = document.getElementById("modals-container");
      list.removeChild(list.firstElementChild);
    },

    onTimeChange() {
      let inputEle = document.getElementById("timeInput");
      let timeSplit = inputEle.value.split(":"),
        hours,
        minutes,
        meridian;
      hours = timeSplit[0];
      minutes = timeSplit[1];
      if (hours > 12) {
        meridian = "PM";
        hours -= 12;
      } else if (hours < 12) {
        meridian = "AM";
        if (hours == 0) {
          hours = 12;
        }
      } else {
        meridian = "PM";
      }
      alert(hours + ":" + minutes + " " + meridian);
    },
  },
};
</script>

<style scoped>
#modals-container .vm--modal {
  height: 600px !important;
}

.eventLabel {
  margin: 0 !important;
  padding: 0 !important;
}
.eventInput {
  margin: 0 !important;
}
.modalTop {
  width: 100%;
  background: #041e4f;

  color: #fff;
}
.modalTop h3 {
  font-weight: 600;
  color: #fff;
  font-size: 1.42188rem;
  text-align: center;
  padding: 15px 0;
}

.modalDesc {
  padding: 20px 40px;
  max-height: 700px;
  overflow-y: scroll;
}
.modalDesc label {
  display: inline-block;
  line-height: 34px;
  font-size: 15px;
  margin: 15px 0;
}
.modalDesc input {
  background: #fff;
  border: solid 1px #dadada;
  border-radius: 8px;
  padding: 8px 16px;
  color: #333;
  outline: none;
  width: 100%;
  margin: 15px 0;
}
.modalDesc audio {
  float: left;
  margin-right: 10px;
}
.submitbtn {
  margin-top: 30px;

  text-align: center;
  border-radius: 6px;
  padding: 8px 16px;

  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
.modalFooter {
  position: absolute;
  width: 100%;
  bottom: 0;
  width: 100%;
  background: #041e4f;
  height: 20px;
}
.fas {
  font-size: 30px;
  margin-top: 15px;
}
</style>
