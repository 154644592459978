<template>
  <div>
    <button type="button" class="close" data-dismiss="modal" @click="closebtn()">
      &times;
    </button>
    <div class="modalTop">
      <h3>Add Video</h3>
    </div>
    <div class="modalDesc">
      <div class="row">
        <div class="col-md-4"><label>Video Name</label></div>
        <div class="col-md-8">
          <input type="catname" placeholder="Video Name" v-model="vid_name" />
          <p class="err" v-if="aud_name_err != ''">{{ aud_name_err }}</p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4"><label>Video Description</label></div>
        <div class="col-md-8">
          <textarea type="catname" placeholder="Video Description" v-model="vid_desc" />
          <p class="err" v-if="aud_desc_err != ''">{{ aud_desc_err }}</p>
        </div>
      </div>

      <div class="row imgboxx">
        <div class="col-md-4"><label>Video File</label></div>
        <div class="col-md-8">
          <div class="imgbox">
      
            <uploadVideos
              :fileType="['mp4']"
              @success="uploadSuccess"
              file_type_url="videos"
            />

            <!-- <video id="video-preview" width="100" height="75" controls v-if="videoFile != ''">
              <source :src="videoFile" type="video/mp4" />
            </video>

            <i class="fas fa-video" @click="$refs.videoFile.click()"></i>

            <div class="clear"></div>

            <input class="ml" type="file" accept="video/mp4, web, ogg, wav." @change="uploadVideo" ref="videoFile"
              style="display: none" /> -->

              <p class="err" v-if="audioFile_err != ''">{{ audioFile_err }}</p>
          </div>
        </div>
      </div>
     
      <div class="row" v-if="file_duration != 0 && videoFile != ''">
        <div class="col-md-4">Playback Duration</div>
        <div class="col-md-8">
          {{ file_duration }}
        </div>
      </div>

      <Tag @updateTag="updateTagFromChild" oldtags="" />

      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-8">
          <p class="err" v-if="tags_err != ''">{{ tags_err }}</p>
          <button :disabled="!ready_to_submit" class="btn btn-primary w-md mybutton myleft submitbtn" v-on:click="addVideo">
            Submit
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <progress-bar :value="progress" :options="options" />
        </div>
      </div>

    </div>
    <div class="modalFooter"></div>
  </div>
</template>

<script>
import api from "../../config/api";
import Tag from "../../components/Tag.vue";
import uploadVideos from "../../components/UploadVideos.vue";
// import VueVideoThumbnail from "vue-video-thumbnail";

export default {
  name: "ModalAddVideos",
  //   components: { VueVideoThumbnail },
  components: {
    Tag,uploadVideos
  },
  props: {
    catid: Number,
  },

  created() {
    this.$root.$refs.ModalAddVideos = this;
  },

  data() {
    return {
      audioFile_err: "",
      aud_desc_err: "",
      aud_name_err: "",
      tags_err: "",

      title_error : "",
      path_error : "",
      desc_error : "",
      tag_error : "",

      tags: [],
      videoFile: "",
      video_el: "",
      vid_desc: "",
      vid_name: "",
      cat_id: "",
      file_duration: 0,
      file_duration_db: 0,
      imagepath: null,
      blob: null,
      error: "",
      progress: "0",
      options: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#333333',
          inverted: false
        },
        layout: {
          height: 35,
          width: 500,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line'
        }
      },
      video_file_path: null,
      video_thumbnail: null,
      ready_to_submit: false,
    };
  },
  mounted() {
    this.cat_id = this.catid;
    this.callme;
  },

  watch: {
     vid_name(newVal) {
      if (newVal != "") {
        this.aud_name_err = "";
      }
    },
    vid_desc(newVal) {
      if (newVal != "") {
        this.aud_desc_err = "";
      }
    },
    tags(newVal) {
      if (newVal.length > 0) {
        this.tags_err = "";
      }
    }
  },

  methods: {
    updateTagFromChild(arr){
      this.tags = arr;
    },
    imagehere(path) {
      console.log(path);
    },


    checkFileType(file, num) {
      var types = ['image/jpeg', 'image/png', 'image/jpg']
      var msg = "The image must be a file of type: jpeg, jpg, png";
      if (num == 'video') {
        types = ['video/mp4', 'video/3gp', 'video/mov', 'video/avi', 'video/mkv']
        if (!types.includes(file.type)) {
          this.audioFile_err = "The video must be a file of type: mp4, mov, avi"
          this.videoFile = ""
          return false;
        }
        return true;
      } else {
        if (!types.includes(file.type)) {
          if (num == 'image1') {
            this.audio_image_url_err = msg
            this.audio_image = "";
            this.audio_image_url = ""
          } else if (num == 'image2') {
            this.audio_cover_image_url_err = msg
            this.audio_cover_image = ""
            this.audio_cover_image_url = ""
          }
          return false;
        }
        return true;
      }
    },

    async addVideo() {
      if (this.vid_name != ""
        && this.vid_desc != ""
        // && this.videoFile != ""
        && this.tags.length != 0
      ) {
      try {
        this.title_error = ""
        this.path_error = ""
        this.desc_error = ""
        this.tag_error = ""

        // const formData = new FormData();
        // formData.append("name", this.vid_name);
        // formData.append("cat_id", this.cat_id);
        // formData.append("description", this.vid_desc);
        // formData.append("duration", this.file_duration_db);
        // formData.append("image", this.blob);

        // if(typeof this.tags == 'string'){
        //   formData.append('tag[]', this.tags);
        // }else{
        //   for (var i = 0; i < this.tags.length; i++) {
        //     formData.append('tag[]', this.tags[i]);
        //   }
        // }

        // if (this.$refs.videoFile.files[0]) {
        //   formData.append("path", this.$refs.videoFile.files[0]);
        // }

        let payload = {
            name: this.vid_name,
            cat_id: this.cat_id,
            description: this.vid_desc,
            duration: this.file_duration_db,
            video_thumbnail: this.video_thumbnail,
            video_file_path: this.video_file_path,
            tag: this.tags,
          };



        let result = await api.post("/admin/add-video", payload , {
          onUploadProgress: function (progressEvent) {
            this.progress = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        });
        this.$toast.success(result.data.message);
        // this.$root.$refs.Tabs.callme(this.lib_id);
        this.$root.$refs.Videos.callme();

        const list = document.getElementById("modals-container");
        list.removeChild(list.firstElementChild);

        //this.$refs.ServiceCategoreis.onload(); //like this
      } catch (error) {
        if (typeof error.response.data.errors.name !== "undefined") {
          this.title_error = error.response.data.errors.name[0];
        }
        if (typeof error.response.data.errors.description !== "undefined") {
          this.desc_error = error.response.data.errors.description[0];
        }
        if(typeof error.response.data.errors.tag !== "undefined") {
          this.tag_error = error.response.data.errors.tag[0];
        }
        if (typeof error.response.data.errors.path !== "undefined") {
          this.path_error = "The video is required"
        }
        this.progress = 0;
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    }
      else{
        this.aud_name_err = (this.vid_name == "" && this.aud_name_err == "") ? "title is required" : this.aud_name_err
        this.aud_desc_err = (this.vid_desc == "" && this.aud_desc_err == "") ? "Description is required" : this.aud_desc_err
        this.audioFile_err = (this.videoFile == "" && this.audioFile_err == "") ? "Video file is required" : this.audioFile_err;
        this.tags_err = (this.tags.length == 0 && this.tags_err == '') ? "Tags require" : this.tags_err
      }
    },


    uploadSuccess(data) {
      this.file_duration_db = data?.duration ?? "0";
      this.video_file_path = data.path ?? null;
      this.video_thumbnail = data.thumbnail ?? null;
      this.ready_to_submit = true;
    },


    async uploadVideo(e) {
      this.audioFile_err = "";
      this.videoFile = "";
      this.videoFile = e.target.files[0];

      var status = this.checkFileType(this.videoFile, 'video');
      if (status) {
      const res = await this.generateVideoThumbnail(this.videoFile);
      this.imagepath = res;
      this.blob = this.bast64ToFile(res);
      let video = document.getElementById('video-preview');
      let reader = new FileReader();
      reader.readAsDataURL(this.videoFile);
      const self = this;
      reader.addEventListener('load', function () {
        video.src = reader.result;
        var i = setInterval(function () {
          if (video.readyState > 0) {
            // var minutes = parseInt(video.duration / 60, 10);
            // var seconds = video.duration % 60;
            const minutes = new Date(video.duration * 1000).toISOString().slice(14, 19);
            self.file_duration = minutes;
            this.file_duration_db = video.duration;
            clearInterval(i);
          }
        }.bind(self), 200);
      });
      }
    },

    // async uploadVideo(e) {  
    //   const video = e.target.files[0];
    //   const res = await this.generateVideoThumbnail(video);
    //   console.log(thumbnail);
    // this.imagepath = res;
    // this.blob = this.bast64ToFile(res);
    // console.log(video, "My Video");
    // const reader = new FileReader();
    // reader.readAsDataURL(video);
    // reader.onload = (e) => {

    // };
    // var video_el = document.createElement("video");

    // reader.onload = (e) => {
    //   this.videoFile = e.target.result;
    //   video_el.src = e.target.result;
    //   video_el.addEventListener("loadedmetadata", () => {
    //     var duration = video_el.duration;
    //     this.file_duration = duration;
    //   });
    // };

    //reader.readAsDataURL(audio);
    //   console.log(this.file_duration, "Song Duration");
    // },

    uploadImage02(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.vpath = e.target.result;
        console.log(this.vpath);
      };
    },
    closebtn() {
      //this.$root.$refs.Tabs.callme(this.lib_id);
      //this.$root.$refs.Hypnosis.callme();
      // this.$root.$refs.Videos.callme();
      const list = document.getElementById("modals-container");
      list.removeChild(list.firstElementChild);
    },

    generateVideoThumbnail(file) {
      return new Promise((resolve) => {
        const canvas = document.createElement("canvas");
        const video = document.createElement("video");

        // this is important
        video.autoplay = true;
        video.muted = true;
        video.src = URL.createObjectURL(file);

        video.onloadeddata = () => {
          let ctx = canvas.getContext("2d");

          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;

          ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
          video.pause();
          // const new_file = canvas.toDataURL("image/png");
          // //

          // var arr = new_file.split(","),
          //   mime = arr[0].match(/:(.*?);/)[1],
          //   bstr = atob(arr[1]),
          //   n = bstr.length,
          //   u8arr = new Uint8Array(n);

          // while (n--) {
          //   u8arr[n] = bstr.charCodeAt(n);
          // }

          // let blob = new File([u8arr], "filename", { type: mime });
          return resolve(canvas.toDataURL("image/png"));
          // return resolve({ blob, image: new_file });
        };
      });
    },

    bast64ToFile(new_file) {
      var arr = new_file.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], "filename", { type: mime });
    },
  },
};
</script>

<style scoped>
#modals-container .vm--modal {
  height: 600px !important;
}

.modalTop {
  width: 100%;
  background: #041e4f;

  color: #fff;
}

.modalTop h3 {
  font-weight: 600;
  color: #fff;
  font-size: 1.42188rem;
  text-align: center;
  padding: 15px 0;
}

.modalDesc {
  padding: 20px 40px;
  max-height: 700px;
  overflow-y: scroll;
}

.modalDesc label {
  display: inline-block;
  line-height: 34px;
  font-size: 15px;
  margin: 15px 0;
}

.modalDesc input {
  background: #fff;
  border: solid 1px #dadada;
  border-radius: 8px;
  padding: 8px 16px;
  color: #333;
  outline: none;
  width: 100%;
  margin: 15px 0;
}

.modalDesc audio {
  float: left;
  margin-right: 10px;
}

.submitbtn {
  margin-top: 30px;

  text-align: center;
  border-radius: 6px;
  padding: 8px 16px;

  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}

.modalFooter {
  position: absolute;
  width: 100%;
  bottom: 0;
  width: 100%;
  background: #041e4f;
  height: 20px;
}

.fas {
  font-size: 30px;
  margin-top: 15px;
  margin-right: 15px;
}
</style>
