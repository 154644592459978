<template>
  <div>This is aBaout Us page</div>
</template>

<script>
export default {
  name: "About",
};
</script>

<style scoped></style>
