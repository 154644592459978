<template>
  <!--begin::Header-->
  <div id="kt_header" style="" class="header align-items-stretch">
    <!--begin::Container-->
    <div class="container-fluid d-flex align-items-stretch justify-content-between">
      <!--begin::Aside mobile toggle-->
      <div class="d-flex align-items-center d-lg-none ms-n2 me-2" title="Show aside menu">
        <div class="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
          id="kt_aside_mobile_toggle">
          <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
          <span class="svg-icon svg-icon-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                fill="black" />
              <path opacity="0.3"
                d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                fill="black" />
            </svg>
          </span>
          <!--end::Svg Icon-->
        </div>
      </div>
      <!--end::Aside mobile toggle-->
      <!--begin::Mobile logo-->
      <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
        <a href="/" class="d-lg-none">
          <img alt="Logo" src="/assets/media/logos/logo.png" class="h-30px" />
        </a>
      </div>
      <!--end::Mobile logo-->
      <!--begin::Wrapper-->
      <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
        <!--begin::Navbar-->
        <div class="d-flex align-items-stretch" id="kt_header_nav">
          <!--begin::Menu wrapper-->
          <div class="header-menu align-items-stretch" data-kt-drawer="true" data-kt-drawer-name="header-menu"
            data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true"
            data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="end"
            data-kt-drawer-toggle="#kt_header_menu_mobile_toggle" data-kt-swapper="true" data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}">
            <!--begin::Menu-->
            <div
              class="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch"
              id="#kt_header_menu" data-kt-menu="true">


              <div data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start"
                class="menu-item here show menu-lg-down-accordion me-lg-1">
                <span class="menu-link py-3">
                  <!-- <span class="menu-title">Dashboard</span> -->
                  <span class="menu-arrow d-lg-none"></span>
                </span>
              </div>
            </div>

            <!--end::Menu-->
          </div>
          <!--end::Menu wrapper-->
        </div>
        <!--end::Navbar-->
        <!--begin::Toolbar wrapper-->
        <div class="d-flex align-items-stretch flex-shrink-0">
          <!--begin::Search-->
          <div class="d-flex align-items-stretch ms-1 ms-lg-3">
            <!-- asd -->
          </div>
          <!--end::Search-->

          <!--begin::User menu-->
          <!-- <div
            class="d-flex align-items-center ms-1 ms-lg-3 dropdown"
            id="kt_header_user_menu_toggle"
          > -->
          <div class="dropdown">
            <!--begin::Menu wrapper-->

            <!-- <div
              class="cursor-pointer symbol symbol-30px symbol-md-40px dropdown"
              data-kt-menu-trigger="click"
              data-kt-menu-attach="parent"
              data-kt-menu-placement="bottom-end"
            > 
           
              href="#"
              class="dropdown-toggle card-drop cursor-pointer symbol symbol-30px symbol-md-40px"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            > -->
            <div class=" cursor-pointer symbol symbol-30px symbol-md-40px">
              <img :src="user_image" alt="user" />
              <!-- </a> -->
            </div>
            <!--begin::User account menu-->

            <div
              class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-300px  dropdown-content w3-animate-zoom"
              data-kt-menu="true">
              <!--begin::Menu item-->
              <div class="menu-item px-3">
                <div class="menu-content d-flex align-items-center px-3">
                  <!--begin::Avatar-->
                  <div class="symbol symbol-50px me-5">
                    <img alt="Logo" :src="user_image" />
                  </div>
                  <!--end::Avatar-->
                  <!--begin::Username-->
                  <div class="d-flex flex-column">
                    <div class="fw-bolder d-flex align-items-center fs-5">
                      {{ user_name }}
                      <!-- <span
                        class="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2"
                        >Pro</span
                      > -->
                    </div>
                    <a href="#" class="fw-bold text-muted text-hover-primary fs-7">{{ user_email }}</a>
                  </div>
                  <!--end::Username-->
                </div>
              </div>
              <!--end::Menu item-->
              <!--begin::Menu separator-->

              <!--begin::Menu item-->
              <div class="menu-item px-5">
                <div>
                  <button class="btn dropdown-toggle card-drop mybutton" data-bs-toggle="dropdown" aria-expanded="false"
                    @click="
                    updateProfileModal(
                      user_name,
                      user_email,
                      user_image,
                    )">
                    <box-icon name="user" color="#ff0000"></box-icon>Profile
                  </button>
                </div>

                <div>
                  <button class="btn" v-on:click="logOut()">
                    <box-icon name="power-off" color="#ff0000"></box-icon>Log Out
                  </button>
                </div>

              </div>
              <!--end::Menu item-->
              <!--begin::Menu separator-->
              <div class="separator my-2"></div>
              <!--end::Menu separator-->
              <!--begin::Menu item-->
              <div class="menu-item px-5">
                <!-- <div class="menu-content px-5">
                  <label
                    class="form-check form-switch form-check-custom form-check-solid pulse pulse-success"
                    for="kt_user_menu_dark_mode_toggle"
                  >
                    <input
                      class="form-check-input w-30px h-20px"
                      type="checkbox"
                      value="1"
                      name="mode"
                      id="kt_user_menu_dark_mode_toggle"
                      data-kt-url="dark/index.html"
                    />
                    <span class="pulse-ring ms-n1"></span>
                    <span class="form-check-label text-gray-600 fs-7"
                      >Dark Mode</span
                    >
                  </label>
                </div> -->
              </div>
              <!--end::Menu item-->
            </div>

            <!--end::User account menu-->
            <!--end::Menu wrapper-->
          </div>
          <!--end::User menu-->
          <!--begin::Header menu toggle-->
          <div class="d-flex align-items-center d-lg-none ms-2 me-n3" title="Show header menu">
            <div class="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px d-none"
              id="kt_header_menu_mobile_toggle">
              <!--begin::Svg Icon | path: icons/duotune/text/txt001.svg-->
              <span class="svg-icon svg-icon-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M13 11H3C2.4 11 2 10.6 2 10V9C2 8.4 2.4 8 3 8H13C13.6 8 14 8.4 14 9V10C14 10.6 13.6 11 13 11ZM22 5V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4V5C2 5.6 2.4 6 3 6H21C21.6 6 22 5.6 22 5Z"
                    fill="black" />
                  <path opacity="0.3"
                    d="M21 16H3C2.4 16 2 15.6 2 15V14C2 13.4 2.4 13 3 13H21C21.6 13 22 13.4 22 14V15C22 15.6 21.6 16 21 16ZM14 20V19C14 18.4 13.6 18 13 18H3C2.4 18 2 18.4 2 19V20C2 20.6 2.4 21 3 21H13C13.6 21 14 20.6 14 20Z"
                    fill="black" />
                </svg>
              </span>
              <!--end::Svg Icon-->
            </div>
          </div>
          <!--end::Header menu toggle-->
        </div>
        <!--end::Toolbar wrapper-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Header-->
</template>

<script>
import ModalUpdateProfile from "../Pages/Modals/ModalUpdateProfile.vue";

export default {
  name: "Header",

  data() {
    return {};
  },

  computed: {
    user_name() {
      return this.$store.state.name;
    },
    user_email() {
      return this.$store.state.email;
    },
    user_image() {
      return this.$store.state.image;
    },
  },

  created() {
    this.$root.$refs.Profile = this;
  },

  methods: {
    callme() {
      // console.log('calll me header');
      this.user_name()
      this.user_email()
      this.user_image()
    },
    updateProfileModal(name, email, image) {
      // console.log(name,email,image);
      this.$modal.show(
        ModalUpdateProfile,
        { name, email, image },
        { draggable: false },
        {
          "before-close": () => {
            this.onLoad();
          },
        }
      );
    },

    
    logOut() {
      // console.log("I am In");
      localStorage.clear();
      //localStorage.removeItem(user);

      this.$store.commit("name", "");
      this.$store.commit("type", "");

      this.$router.push({ name: "Login" });
    },







  },
};
</script>

<style scoped>
.btn box-icon {
  float: left;
  margin-right: 3px;
}

.btn {
  line-height: 28px;

  padding: 0 !important;
  margin-top: 12px !important;
}

.dropdown {
  position: relative;
  background: #fff;
  border-radius: 50%;
  padding: 0px 5px;
  margin: 0 !important;
  padding: 0 !important;
  line-height: 20px;
}

.dropdown box-icon {
  line-height: 50px;
}
</style>
