<template>
  <div>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      @click="closebtn()"
    >
      &times;
    </button>
    <div class="modalTop"><h3>Add Library</h3></div>
    <div class="modalDesc">
      <div class="row">
        <div class="col-md-4"><label>Library Name</label></div>
        <div class="col-md-8">
          <div
            class="form-group"
            :class="{ error: v$.lib_name.$errors.length }"
          >
            <input
              type="catname"
              placeholder="Library Name"
              v-model="v$.lib_name.$model"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.lib_name.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row imgboxx">
        <div class="col-md-4"><label>Library Image</label></div>
        <div class="col-md-8">
          <div class="imgbox">
            <img
              v-if="previewImage == ''"
              :src="'/assets/media/services/base.jpg'"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <img
              v-if="previewImage !== ''"
              :src="previewImage"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <i class="fas fa-camera" @click="$refs.imageFile.click()"></i>
            <div class="clear"></div>
            <span v-if="previewImage == ''" previmg="false">
              image is required
            </span>

            <input
              class="ml"
              type="file"
              accept="image/jpeg"
               @change="uploadImage"
              ref="imageFile"
              style="display: none"
              
             
            />
           
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-8">
          <button
            class="btn btn-primary w-md mybutton myleft submitbtn"
            v-on:click="addLibraray"
            :disabled="v$.$invalid"
          >
            Submit
          </button>
        </div>
        <div v-if="error" class="alert alert-danger">
          {{ error }}
        </div>
      </div>
    </div>
    <div class="modalFooter"></div>
  </div>
</template>

<script>
import api from "../../config/api";
import useVuelidate from "@vuelidate/core";
import { required, minLength, } from "@vuelidate/validators";
export default {
  name: "ModalAddLibrary",
  props: {
    id: Number,
  },
  data() {
    return {
      lib_name: "",
      cat_id: "",
      previewImage: "",

      
    };
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      lib_name: {
        required,
        min: minLength(4),
      },
     
    };
  },

  mounted() {
    this.cat_id = this.id;
  },
  methods: {
    async addLibraray() {
      try {
        const formData = new FormData();
        formData.append("name", this.lib_name);
        formData.append("category_id", this.cat_id);
        if (this.$refs.imageFile.files[0]) {
          formData.append("image", this.$refs.imageFile.files[0]);
        }
        let result = await api.post("/admin/add-library", formData);
        console.log(result);
        this.$toast.success(result.data.message);
        this.$root.$refs.Libraries.callme02();
        const list = document.getElementById("modals-container");
        list.removeChild(list.firstElementChild);

        // this.$refs.ServiceCategoreis.onload(); //like this
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
        console.log(this.previewImage);
      };
    },

    uploadImage02(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.vpath = e.target.result;
        console.log(this.vpath);
      };
    },

    closebtn() {
      // this.$root.$refs.Libraries.callme02();
      const list = document.getElementById("modals-container");
      list.removeChild(list.firstElementChild);
    },

    // mounted() {
    //   this.onLoad();
    //this.$modal.show("example");
    // document.getElementsByClassName("vm--modal").style.height = "500px";
    //     "none !important";
    // },
  },
};
</script>

<style scoped>
#modals-container .vm--modal {
  height: 600px !important;
}
.modalTop {
  width: 100%;
  background: #041e4f;

  color: #fff;
}
.modalTop h3 {
  font-weight: 600;
  color: #fff;
  font-size: 1.42188rem;
  text-align: center;
  padding: 15px 0;
}

.modalDesc {
  padding: 20px 40px;
  max-height: 700px;
  overflow-y: scroll;
}
.modalDesc label {
  display: inline-block;
  line-height: 34px;
  font-size: 15px;
}
.modalDesc input {
  background: #fff;
  border: solid 1px #dadada;
  border-radius: 8px;
  padding: 8px 16px;
  color: #333;
  outline: none;
  width: 100%;
}
.submitbtn {
  margin-top: 30px;

  text-align: center;
  border-radius: 6px;
  padding: 8px 16px;

  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
.modalFooter {
  position: absolute;
  width: 100%;
  bottom: 0;
  width: 100%;
  background: #041e4f;
  height: 20px;
}
.fas {
  font-size: 30px;
  margin-top: 15px;
}
</style>
