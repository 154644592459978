<template>
  <div :key="$route.path">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
          <div class="page-title-right">
            <h4 class="mb-sm-0 font-size-18">
              All Audios of Hypnosis's Subcategory - {{ $route.params.title }}
            </h4>
            <a @click="$router.go(-1)" class="btn btn-primary w-md mybutton myleft">
              <box-icon name="chevrons-left" type="solid" color="#ffffff" class="myicon"></box-icon>Back
            </a>
          </div>

          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item">
                <!-- <a href="">Services</a> -->
                <router-link to="/home/hypnosis_categories" class="waves-effect">
                  All Subcategories List
                </router-link>
              </li>
              <li class="breadcrumb-item active">All Audios' List</li>
            </ol>
            <div>
              <button class="btn btn-primary waves-effect btn-label waves-light mybutton"
                @click="showModal($route.params.id)">
                Add Audio
              </button>
            </div>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
    <!-- end page title -->

    <div class="row">
      <div class="col-lg-12">
        <div class="">
          <div class="table-responsive">
            <div class="card">
              <div class="card-body">
                <table class="table table-hover table-bordered" id="audios_table">
                  <thead>
                    <tr class="frown">
                      <th scope="col" style="width: 5%">#</th>
                      <th scope="col" style="width: 10%">Image</th>
                      <th scope="col" style="width: 20%">Audio's Name</th>

                      <th scope="col" style="width: 50%">Description</th>
                      <th scope="col" style="width: 10%">Audio File</th>
                      <th scope="col" style="width: 50%">Status</th>
                      <th scope="col" style="width: 50%">Is Paid</th>
                      <th scope="col" style="width: 5%">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in all_audios" :key="index">
                      <td scope="row" class="sorting_1">{{ index + 1 }}</td>
                      <td>
                        <img :src="item.image" alt="" />
                      </td>
                      <td>
                        <h5>
                          {{ item.name }}
                        </h5>
                        <!-- <p class="text-muted mb-0 text-ellipse-250">
                          {{ item.name }}
                        </p> -->
                      </td>

                      <td>
                        <p>
                          {{ item.description }}
                        </p>
                        <!-- <p class="text-muted mb-0 text-ellipse-250">
                          {{ item.name }}
                        </p> -->
                      </td>

                      <td>
                        <!-- <a :href="item.path" target="_blank">Play</a> -->
                        <a href="javascript:;" @click="play_aud(item.path)" class="listbox w3-bar-item w3-button">
                        Play
                        </a>
                        <!-- <audio controls>
                          <source :src="item.path" type="audio/mpeg" />
                        </audio> -->
                      </td>

                      <td>
                        <toggle-button @change="changeStatus(item)" :value="(item.status == 'Active') ? true : false" />
                      </td>

                      <td>
                        <toggle-button @change="changeSubscriptionStatus(item)"
                          :value="(item.is_paid == 1) ? true : false" />
                      </td>

                      <td>
                        <!-- <div class="w3-dropdown-click mydropdown"> -->
                        <div class="dropdown">
                          <a href="javascript:;" class="dropdown-toggle card-drop" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <box-icon name="dots-horizontal-rounded" class="myddicon"></box-icon>
                          </a>
                          <!-- <button @click="myFunction()" class="w3-button"> -->
                          <!-- <box-icon
                              name="dots-horizontal-rounded"
                              class="myddicon"
                            ></box-icon> -->
                          <!-- </button> -->
                          <!-- <div
                            id="Demo"
                            class="w3-dropdown-content w3-bar-block w3-card-4 w3-animate-zoom"
                          > -->
                          <!-- <div
                            id="myDIV"
                            class="dropdown-content w3-animate-zoom"
                          > -->
                          <div class="dropdown-menu dropdown-menu-end dropdown-content w3-animate-zoom" id="myDIV">
                            <!-- <div
                              class="dropdown-menu dropdown-menu-end dropdown-content"
                            > -->
                            <div>
                              <a href="javascript:;" @click="
                                showModal03(
                                  item.id,
                                  item.name,
                                  item.path,
                                  item.description,
                                  item.cat_id,
                                  item.duration,
                                  item.image,
                                  item.cover_image,
                                  item.artist,
                                  item.tag
                                )
                              " class="listbox w3-bar-item w3-button">
                                <box-icon name="edit" class="myicon"></box-icon>
                                Update Audio
                              </a>
                              <a href="javascript:;" @click="remove_aud(item.id)" class="listbox w3-bar-item w3-button">
                                <box-icon type="solid" name="message-alt-x" class="myicon"></box-icon>Delete Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import ModalEditAudio from "../Modals/ModalEditAudio.vue";
import ModalAddAudioHypnosis from "../Modals/ModalAddAudioHypnosis.vue";
import api from "../../config/api.js";
import $ from "jquery";
export default {
  name: "AllAudios",
  data() {
    return {
      is_active: true,
      all_audios: [],
      hypnosis_cat_id: "",
      catname: "",
    };
  },

  created() {
    this.$root.$refs.Hypnosis = this;
    this.hypnosis_cat_id = this.$route.params.id;
    // console.log(this.$route.params, "Alicreated");

    this.$watch(
      () => this.$route.params,
      () => {
        this.hypnosis_cat_id = this.$route.params.id;
        //this.$router.go();
        this.allHypnosisAudios();
        //this.sayname(name)
        // console.log(this.$route.params)
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
  mounted() {
    //this.allaudios();
    this.hypnosis_cat_id = this.$route.params.id;
    this.allHypnosisAudios();
  },

  methods: {

    async changeStatus(item) {
      item.status = !item.status
      try {
        let result = await api.post(`/admin/hypno-status?id=${item.id}`);
        this.$toast.success(result.data.message);
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    async changeSubscriptionStatus(item) {
      item.is_paid = !item.is_paid
      try {
        const data = {
          "type": "Hypnosis",
          "id": item.id
        }
        let result = await api.post('/admin/toggle-subscription-status', data);
        this.$toast.success(result.data.message);
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    async allHypnosisAudios() {
      try {
        let result = await api.get(`/admin/hypno-cat-audios`, {
          params: { hypno_cat_id: this.hypnosis_cat_id },
        });

        setTimeout(() => {
          $("#audios_table").DataTable();
        }, 0);

        this.all_audios = result.data.audios;
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    async remove_aud(id) {
      try {
        this.$swal({
          title: "Are you sure?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete it!",
          cancelButtonText: "No, cancel!",
          confirmButtonClass: "btn btn-success",
          cancelButtonClass: "btn btn-danger",
          buttonsStyling: true,
        }).then(async (res) => {
          if (res.isConfirmed) {
            let result = await api.delete(`/admin/delete-hypno-audio/` + id);
            this.allHypnosisAudios();
            this.$toast.success(result.data.message);
            // console.log("I am in Delete mode", result);
            //this.onLoad();
          }
        });
      } catch (error) {
        this.$toast.error(error.response.data.message);
      }
    },


    async play_aud(path) {

      this.$swal({
        icon: 'audio',
        title: 'Audio',
        html: ' <audio controls><source src="'+path+'" type="audio/mpeg" /></audio>',
        showCloseButton: true,
        showConfirmButton: false,
      })

      // try {
      //   this.$swal({
      //     title: "Are you sure?",
      //     type: "warning",
      //     showCancelButton: true,
      //     confirmButtonColor: "#3085d6",
      //     cancelButtonColor: "#d33",
      //     confirmButtonText: "Yes, Delete it!",
      //     cancelButtonText: "No, cancel!",
      //     confirmButtonClass: "btn btn-success",
      //     cancelButtonClass: "btn btn-danger",
      //     buttonsStyling: true,
      //   }).then(async (res) => {
      //     if (res.isConfirmed) {
      //       let result = await api.delete(`/admin/delete-hypno-audio/` + id);
      //       this.allHypnosisAudios();
      //       this.$toast.success(result.data.message);
      //       // console.log("I am in Delete mode", result);
      //       //this.onLoad();
      //     }
      //   });
      // } catch (error) {
      //   this.$toast.error(error.response.data.message);
      // }
    },

    showModal(catid) {
      this.$modal.show(
        ModalAddAudioHypnosis,
        { catid: catid },
        { draggable: true },
        {
          "before-close": () => {
            this.onLoad();
          },
        }
      );
    },

    showModal03(aid, aname, apath, adesc, catid, audlong, image, cover_image, artist, tags) {
      this.$modal.show(
        ModalEditAudio,
        {
          aid: aid,
          aname: aname,
          apath: apath,
          adesc: adesc,
          catid: catid,
          audlong: audlong,
          outside: true,
          image: image,
          cover_image: cover_image,
          aartist: artist,
          atags: tags
        },
        { draggable: true },
        {
          "before-close": () => {
            //this.allAudios(libid);
          },
        }
      );
    },

    onload(id) {
      this.allHypnosisAudios(id);
    },
    callme() {
      this.allHypnosisAudios();
    },
    sayname(name) {
      this.catname = name;
    },
  },
};
</script>

<style scoped>
#audios_table h5 {
  /* line-height: 65px; */
  margin-top: 15px;
}

#audios_table .switch {
  margin-top: 25px;
}

#audios_table .dropdown {
  margin-top: 15px;
}
</style>
