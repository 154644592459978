<template>
    <div>
        <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-sm-flex align-items-center justify-content-between"
        >
          <div class="page-title-right">
            <h4 class="mb-sm-0 font-size-18">Live Event</h4>
            <a
              @click="$router.go(-1)"
              class="btn btn-primary w-md mybutton myleft"
            >
              <box-icon
                name="chevrons-left"
                type="solid"
                color="#ffffff"
                class="myicon"
              ></box-icon
              >Back</a
            >
          </div>

        </div>
        <div class="clear"></div>
      </div>
    </div>
    <hr />
    <div class="stream">
      <div class="row">
        <div class="col-lg-12">
            <iframe  width="460" height="215" :src="link" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>
    </div>
</template>
<script>
export default {
    name: "Stream",
    props:["link"],
    data() {
        return {
            stream_link : "",
        }
    },
    // created(){
    //     this.stream_link = this.link;
    // }
    // created(){
    //     this.stream_link = this.$route.params.id 
    // }
}
</script>