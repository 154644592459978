<template>
  <!--begin::Root-->
  <div class="d-flex flex-column flex-root">
    <!--begin::Authentication - New password -->
    <div
      class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style="
        background-image: url('/metronic8/demo1/assets/media/illustrations/sketchy-1/14.png');
      "
    >
      <!--begin::Content-->
      <div
        class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20"
      >
        <!--begin::Logo-->
        <a href="#" class="mb-12">
          <img
            alt="Logo"
            src="/assets/media/logos/logo@03x.png"
            class="h-100px"
          />
        </a>
        <!--end::Logo-->
        <!--begin::Wrapper-->
        <div class="w-lg-550px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
          <!--begin::Form-->
          <form
            class="form w-100"
            novalidate="novalidate"
            id="kt_new_password_form"
          >
            <!--begin::Heading-->
            <div class="text-center mb-10">
              <!--begin::Title-->
              <h1 class="text-dark mb-3">Setup New Password</h1>
              <!--end::Title-->
              <!--begin::Link-->
              <div class="text-gray-400 fw-bold fs-4">
                Already have reset your password ?
                <a href="/login" class="link-primary fw-bolder">Sign in here</a>
              </div>
              <!--end::Link-->
            </div>
            <!--begin::Heading-->
            <!--begin::Input group-->
            <div class="mb-10 fv-row" data-kt-password-meter="true">
              <!--begin::Wrapper-->
              <div class="mb-1">
                <!--begin::Label-->
                <label class="form-label fw-bolder text-dark fs-6"
                  >Password</label
                >
                <!--end::Label-->
                <!--begin::Input wrapper-->
                <div
                  class="form-group"
                  :class="{ error: v$.new_password.$errors.length }"
                >
                  <div class="position-relative mb-3">
                    <input
                      v-if="showPassword"
                      placeholder=""
                      name="newpassword"
                      type="text"
                      class="form-control form-control-lg form-control-solid"
                      v-model="v$.new_password.$model"
                      autocomplete="off"
                    />

                    <input
                      v-else
                      type="password"
                      placeholder=""
                      name="newpassword"
                      class="form-control form-control-lg form-control-solid"
                      v-model="v$.new_password.$model"
                      autocomplete="off"
                    />
                    <!-- <input
                    class="form-control form-control-lg form-control-solid"
                    type="password"
                    placeholder=""
                    name="newpassword"
                    autocomplete="off"
                    v-model="new_password"
                  /> -->
                    <span
                      class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                      data-kt-password-meter-control="visibility"
                    >
                      <button class="button" type="button" @click="toggleShow">
                        <span class="icon is-small is-right">
                          <i
                            class="fas"
                            :class="{
                              'fa-eye-slash': showPassword,
                              'fa-eye': !showPassword,
                            }"
                          ></i>
                        </span>
                      </button>
                      
                      <!-- <i
                      class="far fa-eye"
                      id="togglePassword"
                      style="margin-left: -30px; cursor: pointer"
                    ></i> -->
                      <!-- <i class="bi bi-eye-slash fs-2"></i>
                    <i class="bi bi-eye fs-2 d-none"></i> -->
                    </span>
                    <div
                            class="input-errors"
                            v-for="(error, index) of v$.new_password.$errors"
                            :key="index"
                          >
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>
                  </div>
                </div>
                <!--end::Input wrapper-->
                <!--begin::Meter-->
                <div
                  class="d-flex align-items-center mb-3"
                  data-kt-password-meter-control="highlight"
                >
                  <div
                    class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
                  ></div>
                  <div
                    class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
                  ></div>
                  <div
                    class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
                  ></div>
                  <div
                    class="flex-grow-1 bg-secondary bg-active-success rounded h-5px"
                  ></div>
                </div>
                <!--end::Meter-->
              </div>
              <!--end::Wrapper-->
              <!--begin::Hint-->
              <div class="text-muted">
                Use 8 or more characters with a mix of letters, numbers &amp;
                symbols.
              </div>
              <!--end::Hint-->
            </div>
            <!--end::Input group=-->
            <!--begin::Input group=-->
            <div class="fv-row mb-10">
              <label class="form-label fw-bolder text-dark fs-6"
                >Confirm Password</label
              >
<div
                          class="form-group"
                          :class="{ error: v$.confirm_password.$errors.length }"
                        >
              <input
                class="form-control form-control-lg form-control-solid"
                type="password"
                placeholder=""
                name="confirmpassword"
                autocomplete="off"
                
                v-model="v$.confirm_password.$model"
              />
              <div class="control"></div>
               <div
                            class="alert alert-danger"
                            role="alert"
                            v-if="error"
                          >
                            {{ error }}
                          </div>
            </div>
            </div>
            <div
                            class="input-errors"
                            v-for="(error, index) of v$.confirm_password.$errors"
                            :key="index"
                          >
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>
            <!--end::Input group=-->
            <!--begin::Input group=-->
            <!-- <div class="fv-row mb-10">
              <div
                class="form-check form-check-custom form-check-solid form-check-inline"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="toc"
                  value="1"
                />
                <label class="form-check-label fw-bold text-gray-700 fs-6"
                  >I Agree &amp;
                  <a href="#" class="ms-1 link-primary">Terms and conditions</a
                  >.</label
                >
              </div>
            </div> -->
            <!--end::Input group=-->
            <!--begin::Action-->
            <div class="text-center">
              <button
                type="button"
                v-on:click="newPasswordSubmitted"
                id="kt_new_password_submit"
                
                :disabled="v$.$invalid"
                
                 class="btn btn-lg btn-primary w-100 mb-5"
              >
                <span class="indicator-label">Submit</span>
                
              </button>
              <br />
            </div>
            <!--end::Action-->
          </form>
          <!--end::Form-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Content-->
      <!--begin::Footer-->
      <div class="d-flex flex-center flex-column-auto p-10">
        <!--begin::Links-->
        <div class="d-flex align-items-center fw-bold fs-6">
          <a
            href="https://keenthemes.com/"
            class="text-muted text-hover-primary px-2"
            >About</a
          >
          <a
            href="mailto:support@keenthemes.com"
            class="text-muted text-hover-primary px-2"
            >Contact</a
          >
          <a
            href="https://1.envato.market/EA4JP"
            class="text-muted text-hover-primary px-2"
            >Contact Us</a
          >
        </div>
        <!--end::Links-->
      </div>
      <!--end::Footer-->
    </div>
    <!--end::Authentication - New password-->
  </div>
  <!--end::Root-->
</template>

<script>
import api from "../config/api.js";
import useVuelidate from "@vuelidate/core";
import { required, minLength,sameAs } from "@vuelidate/validators";
export default {
  name: "ConfirmPassword",

  data() {
    return {
      new_password: null,
      confirm_password: null,
      email: "",
      token: "",
      showPassword: false,
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      new_password: { required, min: minLength(6) },
      confirm_password: {
          required,
          sameAsPassword: sameAs(this.new_password),
        },
    };
  },

  computed: {
    user_email() {
      return this.$store.state.user.name;
    },
    user_token() {
      return this.$store.state.user.name;
    },
  },

  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },

    async newPasswordSubmitted() {
      this.email = this.$store.state.email;
      this.token = this.$store.state.token;

      try {
        let result = await api.post(`/admin/confirm-password`, {
          new_password: this.new_password,
          confirm_password: this.confirm_password,
          email: this.email,
          reset_token: this.token,
        });

        console.log(result);
        localStorage.setItem("loginkey", result.data.message);
        this.$toast.success(result.data.message);
        this.$router.push({ name: "Login" });
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    // let togglePassword = document.querySelector('#togglePassword');
    //   let password = document.querySelector('#id_password');

    //   togglePassword.addEventListener('click', function (e) {
    //     // toggle the type attribute
    //     const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
    //     password.setAttribute('type', type);
    //     // toggle the eye slash icon
    //     this.classList.toggle('fa-eye-slash');
    // });
  },
};
</script>

<style scoped>
button {
  border: none !important;
  background: transparent !important;
}
</style>
