<template>
  <!--begin::Root-->
  <div class="d-flex flex-column flex-root">
    <!--begin::Authentication - Sign-in -->
    <div
      class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
    >
      <!--begin::Content-->
      <div
        class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20"
      >
        <!--begin::Logo-->
        <a href="/index.html" class="mb-12">
          <img
            alt="Logo"
            src="/assets/media/logos/logo@03x.png"
            class="h-100px"
          />
        </a>
        <!--end::Logo-->
        <!--begin::Wrapper-->
        <div class="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
          <!--begin::Form-->
          <form
            class="form w-100"
            novalidate="novalidate"
            id="kt_sign_in_form"
            action="#"
            @submit.prevent="onSubmit"
          >
            <!--begin::Heading-->
            <div class="text-center mb-10">
              <!--begin::Title-->
              <h1 class="text-dark mb-3">Sign In to Believe</h1>
              <!--end::Title-->
              <!--begin::Link-->
              <!-- <div class="text-gray-400 fw-bold fs-4">
                New Here?
                <a href="#" class="link-primary fw-bolder">Create an Account</a>
              </div> -->
              <!--end::Link-->
            </div>
            <!--begin::Heading-->
            <!--begin::Input group-->
            <div class="fv-row mb-10">
              <!--begin::Label-->
              <label class="form-label fs-6 fw-bolder text-dark">Email</label>
              <!--end::Label-->
              <!--begin::Input-->
              <div
                class="form-group"
                :class="{ error: v$.form.email.$errors.length }"
              >
                <input
                  class="form-control form-control-lg form-control-solid"
                  name="email"
                  autocomplete="off"
                  type="email"
                  placeholder="Enter Email Address..."
                  id="email"
                  aria-describedby="emailHelp"
                  v-model="v$.form.email.$model"
                  required
                />

                <!--end::Input-->
                <div
                  class="input-errors"
                  v-for="(error, index) of v$.form.email.$errors"
                  :key="index"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="fv-row mb-10">
              <!--begin::Wrapper-->
              <div class="d-flex flex-stack mb-2">
                <!--begin::Label-->
                <label class="form-label fw-bolder text-dark fs-6 mb-0"
                  >Password</label
                >
                <!--end::Label-->
                <!--begin::Link-->
                <!-- <a
                  href="password-reset.html"
                  class="link-primary fs-6 fw-bolder"
                  >Forgot Password ?</a
                > -->
                <router-link
                  to="/forgetpassword"
                  class="link-primary fs-6 fw-bolder"
                  ><box-icon name="lock" type="solid" color="#05a1fb"></box-icon
                  >Forgot your password?</router-link
                >
                <!--end::Link-->
              </div>
              <div
                class="form-group"
                :class="{ error: v$.form.password.$errors.length }"
              >
                <!--end::Wrapper-->
                <!--begin::Input-->
                <input
                  class="form-control form-control-lg form-control-solid"
                  type="password"
                  name="password"
                  autocomplete="off"
                  id="password"
                  placeholder="Enter password"
                  aria-label="Password"
                  aria-describedby="password-addon"
                  required
                  v-model="v$.form.password.$model"
                  @keyup.enter="trigger"
                />
                <!--end::Input-->
              </div>
            </div>
            <!--end::Input group-->
            <!--begin::Actions-->
            <div class="text-center">
              <!--begin::Submit button-->
              <button
                type="button"
                v-on:click="logIn()"
                id="kt_sign_in_submit"
                class="btn btn-lg btn-primary w-100 mb-5"
                :disabled="v$.form.$invalid"
              >
                <span class="indicator-label">Continue</span>
              </button>
              <!--end::Submit button-->
              <!--begin::Separator-->
              <br />
              <div v-if="error" class="alert alert-danger">
                {{ error }}
              </div>
              <div class="text-center text-muted text-uppercase fw-bolder mb-5">
                <!-- or -->
              </div>
              <!-- <div
                class="input-errors"
                v-for="(error, index) of v$.form.password.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div> -->
              <!--end::Separator-->
              <!--begin::Google link-->
              <!-- <a
                href="#"
                class="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
              >
                <img
                  alt="Logo"
                  src="/assets/media/svg/brand-logos/google-icon.svg"
                  class="h-20px me-3"
                />Continue with Google</a
              >
              end::Google link
             begin::Google link
              <a
                href="#"
                class="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
              >
                <img
                  alt="Logo"
                  src="/assets/media/svg/brand-logos/facebook-4.svg"
                  class="h-20px me-3"
                />Continue with Facebook</a
              > 
              end::Google link-
              begin::Google link
              <a
                href="#"
                class="btn btn-flex flex-center btn-light btn-lg w-100"
              >
                <img
                  alt="Logo"
                  src="/assets/media/svg/brand-logos/apple-black.svg"
                  class="h-20px me-3"
                />Continue with Apple</a
              >
             end::Google link-->
            </div>
            <!--end::Actions-->
          </form>
          <!--end::Form-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Content-->
      <!--begin::Footer-->
      <div class="d-flex flex-center flex-column-auto p-10">
        <!--begin::Links-->
        <!-- <div class="d-flex align-items-center fw-bold fs-6">
          <a
            href="https://keenthemes.com/"
            class="text-muted text-hover-primary px-2"
            >About</a
          >
          <a
            href="mailto:support@keenthemes.com"
            class="text-muted text-hover-primary px-2"
            >Contact</a
          >
          <a
            href="https://1.envato.market/EA4JP"
            class="text-muted text-hover-primary px-2"
            >Contact Us</a
          >
        </div> -->
        <!--end::Links-->
      </div>
      <!--end::Footer-->
    </div>
    <!--end::Authentication - Sign-in-->
  </div>
  <!--end::Root-->
</template>
<script>
import api from "../config/api.js";
//import { useToast } from "vue-toastification/composition";
//const toast = useToast();

// import { ref } from "@vue/composition-api";
//import {reactive } from '@vue/composition-api'
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";

export default {
  name: "Login",

  data() {
    return {
      email: "",
      password: "",
      ffname: "",
      lname: "",

      form: {
        email: "",
        password: "",
      },

      error: null,
    };
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      form: {
        email: {
          required,
          email,
        },
        password: {
          required,
          min: minLength(6),
        },
      },
    };
  },

  methods: {
    async logIn() {
      try {
        let result = await api.post(`/admin/login`, {
          email: this.form.email,
          password: this.form.password,
        });

        localStorage.setItem("loginkey", result.data.token);
        // this.$store.commit("getname", result.data.user);
        // this.$store.commit("getkey", result.data.token);
        //console.log(result, "login data");
        localStorage.setItem("reload", 0);
        //this.$store.commit("getkey", result.data.token);
       // localStorage.setItem("loginkey", result.data.token);

        this.$toast.success("Welcome back." + result.data.message);
        
        // this.$router.push({ name: "users" });
        this.$router.push('/home/users' || '/home/users')
      } catch (error) {
        console.log(error);
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },
  },
};
</script>

<style scoped>
.link-primary {
  line-height: 30px;
}

.link-primary box-icon {
  float: left;
  margin-right: 8px;
}
</style>
