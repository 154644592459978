<template>
  <div>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      @click="closebtn()"
    >
      &times;
    </button>
    <div class="modalTop"><h3>Add Audio</h3></div>
    <div class="modalDesc">
      <div class="row">
        <div class="col-md-4"><label>Audio Name</label></div>
        <div class="col-md-8">
          <input type="catname" placeholder="Audio Name" v-model="aud_name" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-4"><label>Audio Description</label></div>
        <div class="col-md-8">
          <textarea
            type="catname"
            placeholder="Audio Description"
            v-model="aud_desc"
          />
        </div>
      </div>

      <div class="row imgboxx">
        <div class="col-md-4"><label>Audio File</label></div>
        <div class="col-md-8">
          <div class="imgbox">

            <audio controls v-if="audioFile !== ''">
              <source :src="audioFile" type="audio/mpeg" />
            </audio>

            <i class="fas fa-microphone" @click="$refs.audioFile.click()"></i>

            <div class="clear"></div>

            <input
              class="ml"
              type="file"
              accept="audio/mpeg, mpga, mp3, wav."
              @change="uploadAudio"
              ref="audioFile"
              style="display: none"
            />
          </div>
        </div>
      </div>

      <!-- <div class="row">
        <div class="col-md-4">Playback Duration</div>
        <div class="col-md-8" v-if="file_duration != 0">
          {{ file_duration }} Seconds
        </div>
      </div> -->

      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-8">
          <button
            class="btn btn-primary w-md mybutton myleft submitbtn"
            v-on:click="addAudio"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
    <div class="modalFooter"></div>
  </div>
</template>

<script>
import api from "../../config/api";
export default {
  name: "ModalAddAudio",
  props: {
    id: Number,
  },

  data() {
    return {
      audioFile: "",
      audio_el: "",
      aud_desc: "",
      aud_name: "",
      lib_id: "",
      file_duration: "",
    };
  },
  mounted() {
    this.lib_id = this.id;
  },
  methods: {
    async addAudio() {
      try {
        const formData = new FormData();
        formData.append("name", this.aud_name);
        formData.append("library_id", this.lib_id);
        formData.append("description", this.aud_desc);
        formData.append("duration", this.file_duration);

        if (this.$refs.audioFile.files[0]) {
          formData.append("path", this.$refs.audioFile.files[0]);
        }
        let result = await api.post("/admin/add-audio", formData);
        console.log(result);
        this.$toast.success(result.data.message);
        this.$root.$refs.Tabs.callme(this.lib_id);
        //this.$root.$refs.Hypnosis.callme();

        const list = document.getElementById("modals-container");
        list.removeChild(list.firstElementChild);

        //this.$refs.ServiceCategoreis.onload(); //like this
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    uploadAudio(e) {
      const audio = e.target.files[0];
      console.log(audio, "My Audio");
      const reader = new FileReader();
      reader.readAsDataURL(audio);
      var audio_el = document.createElement("audio");
      reader.onload = (e) => {
        this.audioFile = e.target.result;
        audio_el.src = e.target.result;
        audio_el.addEventListener(
          "loadedmetadata",
          () => {
            var duration = audio_el.duration;
            this.file_duration = duration;
          },  
        );
      };
    },

    uploadImage02(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.vpath = e.target.result;
        console.log(this.vpath);
      };
    },
    closebtn() {
      // this.$root.$refs.Tabs.callme(this.lib_id);
      //this.$root.$refs.Hypnosis.callme();
      const list = document.getElementById("modals-container");
      list.removeChild(list.firstElementChild);
    },
  },
};
</script>

<style scoped>
#modals-container .vm--modal {
  height: 600px !important;
}
.modalTop {
  width: 100%;
  background: #041e4f;

  color: #fff;
}
.modalTop h3 {
  font-weight: 600;
  color: #fff;
  font-size: 1.42188rem;
  text-align: center;
  padding: 15px 0;
}

.modalDesc {
  padding: 20px 40px;
  max-height: 700px;
  overflow-y: scroll;
}
.modalDesc label {
  display: inline-block;
  line-height: 34px;
  font-size: 15px;
  margin: 15px 0;
}
.modalDesc input {
  background: #fff;
  border: solid 1px #dadada;
  border-radius: 8px;
  padding: 8px 16px;
  color: #333;
  outline: none;
  width: 100%;
  margin: 15px 0;
}
.modalDesc audio {
  float: left;
  margin-right: 10px;
}
.submitbtn {
  margin-top: 30px;

  text-align: center;
  border-radius: 6px;
  padding: 8px 16px;

  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
.modalFooter {
  position: absolute;
  width: 100%;
  bottom: 0;
  width: 100%;
  background: #041e4f;
  height: 20px;
}
.fas {
  font-size: 30px;
  margin-top: 15px;
}

</style>
