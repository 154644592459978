<template>
  <div id="app">
    <loading
      :active="$store.state.isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <div><router-view></router-view></div>
  </div>
</template>

<script>
// import Home from "./Pages/Home.vue";
// import About from "./Pages/About.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "App",
  components: { Loading },
};
</script>

<style>
  .err{
    color: red;
  }
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
