<template>
  <!--begin::Root-->
  <div class="d-flex flex-column flex-root">
    <!--begin::Authentication - Password reset -->
    <div
      class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style="
        background-image: url('/metronic8/demo1/assets/media/illustrations/sketchy-1/14.png');
      "
    >
      <!--begin::Content-->
      <div
        class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20"
      >
        <!--begin::Logo-->
        <a href="/" class="mb-12">
          <img
            alt="Logo"
            src="/assets/media/logos/logo@03x.png"
            class="h-100px"
          />
        </a>
        <!--end::Logo-->
        <!--begin::Wrapper-->
        <div class="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
          <!--begin::Form-->

          <form
            class="form w-100"
            novalidate="novalidate"
            id="kt_sign_in_form"
            action="#"
            @submit.prevent="onSubmit"
          >
            <!--begin::Heading-->
            <div class="text-center mb-10">
              <!--begin::Title-->
              <h1 class="text-dark mb-3">Forgot Password ?</h1>
              <!--end::Title-->
              <!--begin::Link-->
              <div class="text-gray-400 fw-bold fs-4">
                Enter your email to reset your password.
              </div>
              <!--end::Link-->
            </div>
            <!--begin::Heading-->
            <!--begin::Input group-->
            <div class="fv-row mb-10">
              <label class="form-label fw-bolder text-gray-900 fs-6"
                >Email</label
              >
              <div
                class="form-group"
                :class="{ error: v$.form1.email.$errors.length }"
              >
                <input
                  class="form-control form-control-lg form-control-solid"
                  name="email"
                  autocomplete="off"
                  type="email"
                  placeholder="Enter Email Address..."
                  id="useremail"
                  aria-describedby="emailHelp"
                  v-model="v$.form1.email.$model"
                />

                <!--end::Input-->
                <div
                  class="input-errors"
                  v-for="(error, index) of v$.form1.email.$errors"
                  :key="index"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
            <!--end::Input group-->
            <!--begin::Actions-->
            <div class="d-flex flex-wrap justify-content-center pb-lg-0">
              <a href="/" class="btn btn-lg btn-light-primary fw-bolder me-4"
                >Cancel</a
              >
              <button
                type="button"
                id="kt_password_reset_submit"
                class="btn btn-lg btn-primary fw-bolder"
                v-on:click="forgot()"
                :disabled="v$.form1.$invalid"
              >
                <span class="indicator-label">Submit</span>
              </button>
            </div>
            <!--end::Actions-->
            <br />
            <div v-if="error" class="alert alert-danger">
              {{ error }}
            </div>
          </form>
          <!--end::Form-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Content-->
      <!--begin::Footer-->
      <div class="d-flex flex-center flex-column-auto p-10">
        <!--begin::Links-->
        <!-- <div class="d-flex align-items-center fw-bold fs-6">
          <a
            href="https://keenthemes.com/"
            class="text-muted text-hover-primary px-2"
            >About</a
          >
          <a
            href="mailto:support@keenthemes.com"
            class="text-muted text-hover-primary px-2"
            >Contact</a
          >
          <a
            href="https://1.envato.market/EA4JP"
            class="text-muted text-hover-primary px-2"
            >Contact Us</a
          >
        </div> -->
        <!--end::Links-->
      </div>
      <!--end::Footer-->
    </div>
    <!--end::Authentication - Password reset-->
  </div>
  <!--end::Root-->
</template>

<script>
import api from "../config/api.js";
//import { ref } from "@vue/composition-api";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
export default {
  name: "ForgetPassword",

  data() {
    return {
      email: "",
      error: null,

      form1: {
        email: "",
      },
    };
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      form1: {
        email: {
          required,
          email,
        },
      },
    };
  },

  methods: {
    async forgot() {
      try {
        this.error = null;
        let result = await api.post(`/admin/forgot-password`, {
          email: this.form1.email,
        });
        localStorage.setItem("loginkey", result.data.message);
        this.$toast.success(result.data.message + "Please Enter Verify Code");
        this.$store.commit("getemail", this.form1.email);
        this.$router.push({ name: "CodeVerify" });
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },
  },
};
</script>

<style scoped></style>
