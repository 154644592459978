<template>
  <div>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      @click="closebtn()"
    >
      &times;
    </button>
    <div class="modalTop"><h3>Add Audio</h3></div>
    <div class="modalDesc">
      <div class="row">
        <div class="col-md-4"><label>Audio Name</label></div>
        <div class="col-md-8">
          <input type="catname" placeholder="Audio Name" v-model="aud_name" />
          <p class="err" v-if="aud_name_err != ''">{{ aud_name_err }}</p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4"><label>Audio Description</label></div>
        <div class="col-md-8">
          <textarea
            type="catname"
            placeholder="Audio Description"
            v-model="aud_desc"
          />
          <p class="err" v-if="aud_desc_err != ''">{{ aud_desc_err }}</p>
        </div>
      </div>


      <div class="row">
        <div class="col-md-4"><label>Artist</label></div>
        <div class="col-md-8">
          <input type="catname" placeholder="Artist" v-model="aud_artist" />
          <p class="err" v-if="aud_artist_err != ''">{{ aud_artist_err }}</p>
        </div>
      </div>


      <div class="row imgboxx">
        <div class="col-md-4"><label>Cover Image</label></div>
        <div class="col-md-8">
          <div class="imgbox">
            <img
              v-if="audio_image_url == ''"
              :src="'/assets/media/services/base.jpg'"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <img
              v-if="audio_image_url !== ''"
              :src="audio_image_url"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <i class="fas fa-camera" @click="$refs.audio_image.click()"></i>
            <div class="clear"></div>
            <p class="err" v-if="audio_image_url_err != ''">{{ audio_image_url_err }}</p>
            <span v-if="audio_image_url == ''" previmg="false">
              <!-- Cover image is required -->
            </span>

            <input
              class="ml"
              type="file"
              accept="image/jpeg"
              @change="onAudioImageChange"
              ref="audio_image"
              style="display: none"
            />
          </div>
        </div>
      </div>

      <!-- conver image  -->
      <div class="row imgboxx">
        <div class="col-md-4"><label>Play Screen</label></div>
        <div class="col-md-8">
          <div class="imgbox">
            <img
              v-if="audio_cover_image_url == ''"
              :src="'/assets/media/services/base.jpg'"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <img
              v-if="audio_cover_image_url !== ''"
              :src="audio_cover_image_url"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <i class="fas fa-camera" @click="$refs.audio_cover_image.click()"></i>
            <div class="clear"></div>
            <p class="err" v-if="audio_cover_image_url_err != ''">{{ audio_cover_image_url_err }}</p>
            <span v-if="audio_cover_image_url == ''" previmg="false">
              <!-- Play screen is required -->
            </span>

            <input
              class="ml"
              type="file"
              accept="image/jpeg"
              @change="onAudioCoverImageChange"
              ref="audio_cover_image"
              style="display: none"
            />
          </div>
        </div>
      </div>
      <!-- cover image end -->

      <div class="row imgboxx">
        <div class="col-md-4"><label>Audio File</label></div>
        <div class="col-md-8">
          <div class="imgbox">

            <AudioFile :key="count" :audioSource="audioFile" />

            <i class="fas fa-microphone" @click="$refs.audioFile.click()"></i>

            <div class="clear"></div>

            <input
              class="ml"
              type="file"
              accept="audio/mpeg, mpga, mp3, wav."
              @change="uploadAudio"
              ref="audioFile"
              style="display: none"
            />

            <p class="err" v-if="audioFile_err != ''">{{ audioFile_err }}</p>
          </div>
        </div>
      </div>

      <!-- <div class="row">
        <div class="col-md-4">Playback Duration</div>
        <div class="col-md-8" v-if="file_duration != 0">
          {{ file_duration }} Seconds
        </div>
      </div> -->


      <Tag @updateTag="updateTagFromChild" oldtags="" />

      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-8">
          <p class="err" v-if="tags_err != ''">{{ tags_err }}</p>
          <button
            class="btn btn-primary w-md mybutton myleft submitbtn"
            v-on:click="addAudio"
          >
            Submit
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <progress-bar :value="progress" :options="options"/>
        </div>
      </div>
    </div>
    <div class="modalFooter"></div>
  </div>
</template>

<script>
import api from "../../config/api";
import AudioFile from "../Admin/AudioFile.vue";
import Tag from "../../components/Tag.vue";
export default {
  name: "ModalAddAudioHypnosis",
  props: {
    catid: Number,
  },
  components: {
    AudioFile,
    Tag,
  },
  data() {
    return {
      audioFile_err: "",
      audio_image_url_err: "",
      audio_cover_image_url_err: "",
      aud_desc_err: "",
      aud_name_err: "",
      tags_err: "",
      aud_artist_err: "",

      title_error : "",
      image_error : "",
      cover_error : "",
      desc_error : "",
      duration_error : "",
      path_error : "",

      audioFile: "",
      audio_image: "",
      audio_image_url: "",
      audio_cover_image: "",
      audio_cover_image_url: "",
      audio_el: "",
      aud_desc: "",
      aud_name: "",
      cat_id: "",
      file_duration: "",
      tagValue: '',
      tags: [],
      activeTag: null,
      aud_artist : "",
      count: 0,
      all_tags: [],
      progress: "0",
      options: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#333333',
          inverted: false
        },
        layout: {
          height: 35,
          width: 500,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line'
        }
      }
    };
  },
  mounted() {
    this.cat_id = this.catid;
    this.allTags();
  },
   watch: {
    aud_name(newVal) {
      if (newVal != "") {
        this.aud_name_err = "";
      }
    },
    aud_desc(newVal) {
      if (newVal != "") {
        this.aud_desc_err = "";
      }
    },
    aud_artist(newVal) {
      if (newVal != "") {
        this.aud_artist_err = "";
      }
    },
    // audio_image(newVal) {
    //   if (newVal != "") {
    //     this.audio_image_url_err = "";
    //   }
    // },
    // audio_cover_image(newVal) {
    //   if (newVal != "") {
    //     this.audio_cover_image_url_err = "";
    //   }
    // },
    // audioFile(newVal) {
    //   if (newVal != "") {
    //     this.audioFile_err = "";
    //   }
    // },
    tags(newVal) {
      if (newVal.length > 0) {
        this.tags_err = "";
      }
    }
  },
  methods: {
    updateTagFromChild(arr){
      this.tags = arr;
    },

    onAudioImageChange(e) {
      this.audio_image_url_err = "";
      const file = e.target.files[0];
      // console.log(file.type);
      var status = this.checkFileType(file, 'image1')
      if (status) {
        this.audio_image = file;
        this.audio_image_url = URL.createObjectURL(file)
      }
    },

    onAudioCoverImageChange(e) {
      this.audio_cover_image_url_err = "";
      const file = e.target.files[0];
      // console.log(file.type);
      var status = this.checkFileType(file, 'image2')
      if (status) {
        this.audio_cover_image = file;
        this.audio_cover_image_url = URL.createObjectURL(file)
      }
    },

    checkFileType(file, num) {
      var types = ['image/jpeg', 'image/png', 'image/jpg']
      var msg = "The image must be a file of type: jpeg, jpg, png";
      if (num == 'audio') {
        console.log('under chk');
        types = ['audio/mpeg', 'audio/ogg', 'audio/wav', 'audio/mp4', 'audio/mp3']
        if (!types.includes(file.type)) {
          this.audioFile_err = "The audio must be a file of type: mp3, wav, ogg"
          this.audioFile = ""
          return false;
        }
        return true;
      } else {
        if (!types.includes(file.type)) {
          if (num == 'image1') {
            this.audio_image_url_err = msg
            this.audio_image = "";
            this.audio_image_url = ""
          } else if (num == 'image2') {
            this.audio_cover_image_url_err = msg
            this.audio_cover_image = ""
            this.audio_cover_image_url = ""
          }
          return false;
        }
        return true;
      }
    },


    async addAudio() {
      if (this.audio_cover_image_url != ""
        && this.aud_artist != ""
        && this.aud_name != ""
        && this.aud_desc != ""
        && this.audio_image_url != ""
        && this.audioFile != ""
        && this.tags.length != 0
      ) {
      try {
        this.title_error = ""
        this.image_error = ""
        this.cover_error = ""
        this.desc_error = ""
        this.duration_error = ""
        this.path_error = ""

        const formData = new FormData();
        formData.append("name", this.aud_name);
        formData.append("cat_id", this.cat_id);
        formData.append("description", this.aud_desc);
        formData.append("duration", this.file_duration);
        formData.append("artist", this.aud_artist);

        if(typeof this.tags == 'string'){
          formData.append('tag[]', this.tags);
        }else{
          for (var i = 0; i < this.tags.length; i++) {
            formData.append('tag[]', this.tags[i]);
          }
        }

        if (this.$refs.audioFile.files[0]) {
          formData.append("path", this.$refs.audioFile.files[0]);
        }
        if(this.audio_image){
          formData.append("image", this.audio_image);
        }
        if(this.audio_cover_image){
          formData.append("cover_image", this.audio_cover_image);
        }
        let result = await api.post("/admin/add-meditation", formData, {
          onUploadProgress: function (progressEvent) {
            this.progress = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        });
        console.log(result);
        this.$toast.success(result.data.message);
        // this.$root.$refs.Tabs.callme(this.lib_id);
        this.$root.$refs.Meditations.callme();

        const list = document.getElementById("modals-container");
        list.removeChild(list.firstElementChild);

        //this.$refs.ServiceCategoreis.onload(); //like this
      } catch (error) {
        if (typeof error.response.data.errors.name !== "undefined") {
          this.title_error = error.response.data.errors.name[0];
        }
        if (typeof error.response.data.errors.description !== "undefined") {
          this.desc_error = error.response.data.errors.description[0];
        }
        if(typeof error.response.data.errors.image !== "undefined") {
          this.image_error = error.response.data.errors.image[0];
        }
        if(typeof error.response.data.errors.cover_image !== "undefined") {
          this.cover_error = error.response.data.errors.cover_image[0];
        }
        if (typeof error.response.data.errors.duration !== "undefined") {
          this.duration_error = error.response.data.errors.duration[0];
        }
        if (typeof error.response.data.errors.path !== "undefined") {
          this.path_error = "Audio file is required"
        }
        this.progress = 0;
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
      }
      else{
        this.aud_name_err = (this.aud_name == "" && this.aud_name_err == "") ? "title is required" : this.aud_name_err
        this.aud_desc_err = (this.aud_desc == "" && this.aud_desc_err == "") ? "Description is required" : this.aud_desc_err
        this.aud_artist_err = (this.aud_artist == "" && this.aud_artist_err == "") ? "Artist is required" : this.aud_artist_err
        this.audioFile_err = (this.audioFile == "" && this.audioFile_err == "") ? "Audio file is required" : this.audioFile_err;
        this.audio_image_url_err = (this.audio_image_url == "" && this.audio_image_url_err == "") ? "Image is required" : this.audio_image_url_err
        this.audio_cover_image_url_err = (this.audio_cover_image_url == "" && this.audio_cover_image_url_err == "") ? "Image is required" : this.audio_cover_image_url_err
        this.tags_err = (this.tags.length == 0 && this.tags_err == '') ? "Tags require" : this.tags_err
      }
    },

    uploadAudio(e) {
      this.audioFile_err = "";
      this.audioFile = "";
      const audio = e.target.files[0];
      var status = this.checkFileType(audio, 'audio');
      if (status) {
      const reader = new FileReader();
      reader.readAsDataURL(audio);
      // reader.onload = (e) => {

      // };
      var audio_el = document.createElement("audio");

      reader.onload = (e) => {
        this.audioFile = e.target.result;
        audio_el.src = e.target.result;
        audio_el.addEventListener("loadedmetadata", () => {
          // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
          var duration = audio_el.duration;
          // const audio_file = new Date(duration * 1000).toISOString().slice(14, 19);
          this.file_duration = duration;

          // example 12.3234 seconds
          console.log(
            "The duration of the song is of: " + this.file_duration + " seconds"
          );
          // Alternatively, just display the integer value with
          // parseInt(duration)
          // 12 seconds
        });
      };
      this.count++;
      //reader.readAsDataURL(audio);
      console.log(this.file_duration, "Song Duration");
      }
    },

    uploadImage02(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.vpath = e.target.result;
        console.log(this.vpath);
      };
    },
    closebtn() {
      // this.$root.$refs.Tabs.callme(this.lib_id);
      // this.$root.$refs.Meditations.callme();
      const list = document.getElementById("modals-container");
      list.removeChild(list.firstElementChild);
    },
  },
  directives: {
    focus: {
      inserted: (el) => {
        el.focus()
      }
    }
  }
};
</script>

<style scoped>
#modals-container .vm--modal {
  height: 600px !important;
}
.modalTop {
  width: 100%;
  background: #041e4f;

  color: #fff;
}
.modalTop h3 {
  font-weight: 600;
  color: #fff;
  font-size: 1.42188rem;
  text-align: center;
  padding: 15px 0;
}

.modalDesc {
  padding: 20px 40px;
  max-height: 700px;
  overflow-y: scroll;
}


/* multi tags work start */
.tag_box{
  background: #fff;
  border: solid 1px #dadada;
  border-radius: 8px;
  padding: 8px;
  color: #333;
  outline: none;
  width: 100%;
  height: 150px;
  overflow-y: auto;
  margin: 15px 0;
  word-break: break-word;
}

.tag_box span{
  display: inline-block;
}
.tag_box span span{
  border:1px solid grey;
  padding: 4px 10px;
  border-radius: 15px;
  margin: 10px 2px;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
}

/* multi tags work end */


.modalDesc label {
  display: inline-block;
  line-height: 34px;
  font-size: 15px;
  margin: 15px 0;
}
.modalDesc input {
  background: #fff;
  border: solid 1px #dadada;
  border-radius: 8px;
  padding: 8px 16px;
  color: #333;
  outline: none;
  width: 100%;
  margin: 15px 0;
}
.modalDesc audio {
  float: left;
  margin-right: 10px;
}
.submitbtn {
  margin-top: 30px;

  text-align: center;
  border-radius: 6px;
  padding: 8px 16px;

  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
.modalFooter {
  position: absolute;
  width: 100%;
  bottom: 0;
  width: 100%;
  background: #041e4f;
  height: 20px;
}
.fas {
  font-size: 30px;
  margin-top: 15px;
}



.tags-input-container {
    width: 100%;
    max-width: 600px;
    padding: 10px;
    /* background-color: #fff; */
    /* border : 1px solid black; */
}

.tags-input-container input {
  background: #fff;
border: solid 1px #dadada;
border-radius: 8px;
padding: 8px 16px;
color: #333;
outline: none;
width: 100%;
margin: 15px 0;
    }
.tags-input-container .fas{
    font-size: 21px !important;
}
    .tag {
      float: left;
      padding: 3px 5px;
      padding-left: 20px;
      padding-top: 10px;
      margin-bottom: 2px;
      margin-left: 2px;
      /* margin-right: 2px; */
      display: flex;
      justify-content: center;
      cursor: pointer;
      /* background-color: #dadada; */
      border-radius: 20px;
    }

    .tag:hover {
      background-color: #166d8c;
      /* border-radius: 5px; */
      color:white;
    }
    .tag span:first-child {
        margin-right: 8px;
    }
    .tag svg {
      color: #666;
    }
    .tag svg:hover{
      color: #333;
    }
</style>
