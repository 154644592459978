<template ref="libraries">
  <article>
    <header class="tabs">
      <ul>
        <li v-for="(tab, index) in tabs" :key="index">
          <div
            class="nav-item"
            :class="{ 'is-active': tab.isActive }"
            :style="{
              background: 'url(' + tab.bgimage + ')',
            }"
            @click="selectTab(tab), allAudios(tab.tab_id)"
          >
            <div class="title_tab">
              {{ tab.name }}
            </div>
            <div class="is_showbox" v-bind:class="{ 'is-show': tab.isActive }">
              <div class="dropdown">
                <a
                  href="#"
                  class="dropdown-toggle card-drop dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <box-icon name="dots-horizontal-rounded"></box-icon>
                </a>
                <div
                  class="dropdown-menu dropdown-menu-end dropdown-content w3-animate-zoom"
                >
                  <a
                    href="#"
                    @click="
                      showModal04(
                        tab.tab_libid,
                        tab.tab_libname,
                        tab.bgimage,
                        tab.tab_catid
                      ),
                        selectTab(tab)
                    "
                    class="listbox w3-bar-item w3-button"
                  >
                    <box-icon name="edit" class="myicon"></box-icon>
                    Update Library</a
                  >
                  <a href="#" @click="remove_lib(tab.tab_libid)" class="listbox"
                    ><box-icon
                      type="solid"
                      name="message-alt-x"
                      class="myicon"
                    ></box-icon
                    >Delete Now</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="ishidden" v-bind:class="{ 'is-show': tab.isActive }">
            <a href="#" @click="showModal02(tab.tab_id)" class="listbox">
              <box-icon type="logo" name="audible" class="myicon"></box-icon>
              Add Audio</a
            >
          </div>
        </li>
      </ul>
    </header>
    <section class="tabs-details">
      <slot></slot>
      <!-- <div v-for="(audio_item, index) in all_audios" :key="index">
        {{ audio_item.name }}Imran
      </div> -->
    </section>
  </article>
</template>

<script>
import api from "../config/api";
import ModalAddAudio from "../Pages/Modals/ModalAddAudio.vue";
import ModalEditLibrary from "../Pages/Modals/ModalEditLibrary.vue";
export default {
  name: "Tabs",

  data() {
    return {
      tabs: [],
      tabbid: "",
      all_audios: "",
      tablibid: "",
      tablibname: "",
      tablibimage: "",
      tabcatid: "",
      bigimg: "",
      componentkey: 0,
    };
  },
  created() {
    this.tabs = this.$children;
    this.$root.$refs.Tabs = this;
  },
  props: {
    callMethod: Function,
    callMethod02: Function,
    btnClickHandler: {
      type: Function,
    },
  },

  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach((tab) => {
        tab.isActive = tab.name === selectedTab.name;
        tab.bgimage = tab.image;
        this.tabbid = tab.id;
        this.tablibid = tab.id;
        this.tablibname = tab.name;
        this.tablibimage = tab.image;
        this.tabcatid = tab.tabcatid;
        //tab.bigimg = this.tablibimage;
        //console.log(this.tabcatid, "Tablibname");
        this.$root.$refs.Tab.forceRerender();
        tab.tab_libname = selectedTab.name;
      });
    },

    getid(id) {
      //console.log("hello");
      //this.$refs.libraries.allAudios();

      //this.$refs.libraries.allAudios();
      // },
      this.callMethod(id);
    },
    allAudios(id) {
      this.callMethod(id);
    },
    updateme() {
      //this.btnClickHandler();
      //console.log("yes this is working");

      this.btnClickHandler();
    },
    changeimg() {
      console.log("Image Change");
      this.selectTab.bgimage = this.tablibimage;
      //this.selectTab.name = this.tab_libname;
    },

    showModal02(id) {
      this.$modal.show(
        ModalAddAudio,
        { id: id },
        { draggable: true },
        {
          "before-close": () => {
            this.getid(id);
          },
        }
      );
    },

    showModal04(libid, libname, libimage, catid) {
      //libname = this.tablibname;
      this.$modal.show(
        ModalEditLibrary,
        { libid: libid, libname: libname, libimage: libimage, catid: catid },
        { draggable: true },
        {
          "before-close": () => {
            //this.$refs.libraries.btnClickHandler();
            //this.changeimg();
            //this.updateme();
            // this.alllibrary();
          },
        }
      );
    },

    async remove_lib(id) {
      try {
        this.$swal({
          title: "Are you sure?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete it!",
          cancelButtonText: "No, cancel!",
          confirmButtonClass: "btn btn-success",
          cancelButtonClass: "btn btn-danger",
          buttonsStyling: true,
        }).then(async (res) => {
          if (res.isConfirmed) {
            let result = await api.delete(`/admin/delete-library/` + id);
            this.$root.$refs.Libraries.callme02();
            this.$toast.success(result.response.data.message);

            console.log("I am in Delete mode", result);
          }
        });
      } catch (error) {
        this.$toast.error(error.response.data.message);
      }
    },
    callme(id) {
      this.getid(id);
    },

    callme03() {
      // this.componentKey += 1;
      this.$root.$refs.Libraries.callme02();
    },
  },
};
</script>

<style scoped>
.tabs {
  border-bottom: solid 1px #dadada;
  margin: 0 10px;
}

.tabs-details {
  padding: 12px;
}
ul {
  display: flex;
  padding: 0;
  list-style-type: none;
}
li {
  padding-right: 15px;
}
.nav-item {
  cursor: pointer;
  color: red;

  border: solid 1px #041e4f;
  border-radius: 8px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  min-height: 125px;
  font-size: 22px;
  max-width: 150px;
  line-height: 24px;
  text-align: center;
  font-weight: 700;
  /* vertical-align: middle; */
  position: relative;

  -webkit-text-stroke: 1px rgb(4, 30, 79);
  -webkit-text-fill-color: rgb(255, 255, 255);

  -webkit-animation: fill 0.5s infinite alternate;

  /* display: flex;
  text-align: center;
  justify-items: center; */
  align-items: center;
  display: block;
  transition: 0.4s all ease-out;
}
.title_tab {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px 0 5px 0;
  background: rgba(0, 0, 0, 0.4);
}
.nav-item:hover {
  color: blue;
  border: solid 3px #041e4f;
  -webkit-text-stroke: 1px rgb(255, 255, 255);
  -webkit-text-fill-color: rgb(4, 30, 79);

  -webkit-animation: fill 0.5s infinite alternate;
}
.nav-item.is-active {
  position: relative;
  color: blue;
  border: solid 3px #041e4f;
  -webkit-text-stroke: 1px rgb(255, 255, 255);
  -webkit-text-fill-color: rgb(4, 30, 79);

  -webkit-animation: fill 0.5s infinite alternate;
}
.bgimage {
  /* background: url(""); */
}
.is-show {
  display: block !important;
  /* margin: 15px auto; */
  visibility: visible !important;
}
.ishidden {
  visibility: hidden;
  /* margin: 15px auto; */
}
.is_showbox {
  display: block;
  position: absolute;
  padding: 5px;
  /* background: #fff; */
  /* top: -15px;
  right: 15px; */
}

.listbox {
  width: 12rem !important;
  font-weight: normal !important;
}

.listbox a {
  /* color: #333 !important; */
  font-weight: normal !important;
}
/* .dropdown-menu {
  min-width: 17rem;
  background-color: #fff;
}
.dropdown-menu a {
  color: #278d99 !important;
  -webkit-text-stroke: 0px rgb(255, 255, 255);
} */
.dropdown.box-icon {
  line-height: 20px !important;
}
.dropdown box-icon {
  line-height: 20px !important;
}

.modalDesc textarea {
  background: #fff;
  border: solid 1px #dadada;
  border-radius: 8px;
  padding: 8px 16px;
  color: #333;
  outline: none;
  width: 100%;
  margin: 15px 0;
}
</style>
