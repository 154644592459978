<template ref="libraries">
  <div>
    <button type="button" class="close" data-dismiss="modal" @click="closebtn()">
      &times;
    </button>
    <!-- <button @click="$emit('close')">Close</button> -->
    <div class="modalTop">
      <h3>
        Update <span class="toCapitalFirst">{{ type }}</span>
      </h3>
    </div>
    <div class="modalDesc">
      <div class="row">
        <div class="col-md-4"><label>Title</label></div>
        <div class="col-md-8">
          <input type="text" v-model="audio_name" />
          <!-- <p class="err" v-if="title_error != ''">{{ title_error }}</p> -->
          <p class="err" v-if="aud_name_err != ''">{{ aud_name_err }}</p>
        </div>
      </div>
      <br />
      <div class="row" v-if="type != 'text'">
        <div class="col-md-4"><label>Desctription</label></div>
        <div class="col-md-8">
          <textarea type="catname" v-model="aud_desc" />
          <p class="err" v-if="aud_desc_err != ''">{{ aud_desc_err }}</p>
        </div>
      </div>

      <div class="row imgboxx" v-else-if="m_type == 'text'">
        <div class="col-md-4"><label>Text</label></div>
        <div class="col-md-8">
          <vue-editor v-model="aud_desc" />
          <p class="err" v-if="aud_desc_err != ''">{{ aud_desc_err }}</p>
        </div>
      </div>

      <div class="row" v-if="m_type == 'video' || m_type == 'audio'">
        <div class="col-md-4"><label>Artist</label></div>
        <div class="col-md-8">
          <input type="artist" placeholder="" v-model="media_artist" />
          <p class="err" v-if="artist_err != ''">{{ artist_err }}</p>
        </div>
      </div>
      <br />
      <div class="row" v-if="m_type == 'pdf' || m_type == 'text'">
        <div class="col-md-4"><label>Author</label></div>
        <div class="col-md-8">
          <input type="artist" placeholder="" v-model="media_artist" />
          <p class="err" v-if="author_err != ''">{{ author_err }}</p>
        </div>
      </div>

      <!-- <div class="row" v-if="m_type == 'audio'">
        <div class="col-md-4"><label>Audio Image</label></div>
        <div class="col-md-8">
          <input type="file" placeholder="" @change="onAudioImageChange" />
          <div id="preview">
            <img v-if="audio_image_url" :src="audio_image_url" width="200" height="200" />
          </div>
        </div>payload
      </div> -->

      <div class="row imgboxx" v-if="m_type == 'audio'">
        <div class="col-md-4"><label>Image</label></div>
        <div class="col-md-8">
          <div class="imgbox">
            <img v-if="audio_image_url == ''" :src="'/assets/media/services/base.jpg'"
              class="img-thumbnail rounded-circle" alt="Image" style="height: 65px; width: 65px" />

            <img v-if="audio_image_url !== ''" :src="audio_image_url" class="img-thumbnail rounded-circle" alt="Image"
              style="height: 65px; width: 65px" />

            <i class="fas fa-camera" @click="$refs.audio_image.click()"></i>
            <div class="clear"></div>
            <p class="err" v-if="audio_image_url_err != ''">
              {{ audio_image_url_err }}
            </p>
            <span v-if="audio_image_url == ''" previmg="false">
              <!-- image is required -->
            </span>

            <input class="ml" type="file" accept="image/jpeg" @change="onAudioImageChange" ref="audio_image"
              style="display: none" />
          </div>
        </div>
      </div>

      <div class="row imgboxx" v-if="m_type == 'audio'">
        <div class="col-md-4"><label>Cover Image</label></div>
        <div class="col-md-8">
          <div class="imgbox">
            <img v-if="audio_image_url2 == ''" :src="'/assets/media/services/base.jpg'"
              class="img-thumbnail rounded-circle" alt="Image" style="height: 65px; width: 65px" />

            <img v-if="audio_image_url2 !== ''" :src="audio_image_url2" class="img-thumbnail rounded-circle" alt="Image"
              style="height: 65px; width: 65px" />

            <i class="fas fa-camera" @click="$refs.audio_image2.click()"></i>
            <div class="clear"></div>
            <p class="err" v-if="audio_cover_image_url_err != ''">
              {{ audio_cover_image_url_err }}
            </p>
            <span v-if="audio_image_url2 == ''" previmg="false">
              <!-- image is required -->
            </span>

            <input class="ml" type="file" accept="image/jpeg" @change="onAudioImageChange2" ref="audio_image2"
              style="display: none" />
          </div>
        </div>
      </div>

      <div class="row imgboxx" v-if="m_type == 'audio' || m_type == 'video'">
        <div class="col-md-4">
          <label v-if="m_type == 'audio'">Audio File</label>
          <label v-else-if="m_type == 'video'">Video File</label>
        </div>
        <div class="col-md-8">
          <div class="imgbox">
            <span v-if="m_type == 'audio'">
              <AudioFile :key="count" :audioSource="audioFile" />
              <i class="fas fa-microphone" @click="$refs.audioFile.click()"></i>
              <div class="clear"></div>
              <input class="ml" type="file" accept="audio/mpeg" @change="uploadAudio" ref="audioFile"
                style="display: none" />

              <p class="err" v-if="audioFile_err != ''">{{ audioFile_err }}</p>
            </span>

            <uploadVideos :fileType="['mp4']" @success="uploadSuccess" file_type_url="lesson-content/videos" />
          </div>
        </div>
      </div>

      <div class="row imgboxx" v-if="type == 'pdf'">
        <div class="col-md-4"><label>PDF File</label></div>
        <div class="col-md-8">
          <div class="imgbox">
            <a :href="this.audioFile" target="_blank">{{ audioFile }}</a>
            <input type="file" @change="onPdfChange" />

            <p class="err" v-if="pdfFile_err != ''">{{ pdfFile_err }}</p>
          </div>
        </div>
      </div>

      <br />

      <div class="row" v-if="type == 'text' || type == 'pdf'">
        <div class="col-md-4">Duration</div>
        <div class="col-md-8">
          <input type="duration" placeholder="" v-model="file_duration" />
          <p class="err" v-if="file_duration_err != ''">
            {{ file_duration_err }}
          </p>
        </div>
      </div>

      <Tag @updateTag="updateTagFromChild" :oldtags="tags" />

      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-8">
          <p class="err" v-if="tags_err != ''">{{ tags_err }}</p>
          <button class="btn btn-primary w-md mybutton myleft submitbtn" v-on:click="submitFunctions">
            Submit
          </button>
        </div>
        <!-- <div v-if="error" class="alert alert-danger">
          {{ error }}
        </div> -->
      </div>

      <div class="row" v-if="type == 'audio' || type == 'video' || type == 'pdf'">
        <div class="col-md-12">
          <progress-bar :value="progress" :options="options" />
        </div>
      </div>
    </div>
    <div class="modalFooter"></div>
  </div>
</template>

<script>
import api from "../../config/api";
// import useVuelidate from "@vuelidate/core";
// import { required, minLength } from "@vuelidate/validators";
// import { required } from "@vuelidate/validators";
import { VueEditor } from "vue2-editor";
import AudioFile from "../Admin/AudioFile.vue";
import Tag from "../../components/Tag.vue";
import uploadVideos from "../../components/UploadVideos.vue";

export default {
  name: "ModalEditLesson",

  components: {
    AudioFile,
    VueEditor,
    Tag,
    uploadVideos,
  },
  props: {
    // data_length: Number,
    aid: Number,
    aname: String,
    apath: String,
    adesc: String,
    catid: String,
    audlong: String,
    outside: Boolean,
    outside02: Boolean,
    type: String,
    image: String,
    artist: String,
    cover_image: String,
    atags: Array,
    // clickme: {
    //   type: Function,
    // },
  },

  data() {
    return {
      pdfFile_err: "",
      audioFile_err: "",
      audio_image_url_err: "",
      audio_cover_image_url_err: "",
      videoFile_err: "",
      aud_desc_err: "",
      aud_name_err: "",
      tags_err: "",
      artist_err: "",
      author_err: "",
      file_duration_err: "",

      image_error: "",
      title_error: "",
      cover_error: "",
      tag_error: "",
      desc_error: "",
      audio_error: "",
      artist_error: "",

      audio_name: "",
      aud_desc: "",
      audioFile: "",
      videoFile: "",
      current_id: "",
      cat_id: "",
      file_duration: "",
      aud_outside: "",
      aud_outside02: "",
      count: 0,
      m_type: "",
      tags: [],
      pdf_file: "",
      audio_image: "",
      audio_image_url: "",
      audio_image2: "",
      audio_image_url2: "",
      media_artist: "",
      progress: "0",
      options: {
        text: {
          color: "#FFFFFF",
          shadowEnable: true,
          shadowColor: "#000000",
          fontSize: 14,
          fontFamily: "Helvetica",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#2dbd2d",
          backgroundColor: "#333333",
          inverted: false,
        },
        layout: {
          height: 35,
          width: 500,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: "line",
        },
      },
      video_file_path: null,
      video_thumbnail: null,
      // ready_to_submit: false,
    };
  },

  created() {
    if (this.atags) this.tags = this.atags;
  },

  mounted() {
    this.m_type = this.type;

    // console.log('mounted check video');
    // console.log(this.type);
    this.file_duration_db = this.audlong;

    // console.log('end of mounted');
    // console.log(videotime);
    this.audio_name = this.aname;
    this.current_id = this.aid;
    this.aud_desc = this.adesc;
    this.audioFile = this.apath;
    this.videoFile = this.apath;
    this.cat_id = this.catid;
    if (this.m_type == "audio" || this.m_type == "video") {
      const videotime = new Date(this.audlong * 1000)
        .toISOString()
        .slice(14, 19);
      this.file_duration = videotime;
    } else {
      this.file_duration = this.audlong;
    }

    this.aud_outside = this.outside;
    this.aud_outside02 = this.outside02;

    this.audio_image_url = this.image;
    this.audio_image_url2 = this.cover_image;
    this.media_artist = this.artist;
    this.pdf_file_url = this.url;
  },

  watch: {
    audio_name(newVal) {
      if (newVal != "") {
        this.aud_name_err = "";
      }
    },
    aud_desc(newVal) {
      if (newVal != "") {
        this.aud_desc_err = "";
      }
    },
    media_artist(newVal) {
      if (newVal != "") {
        this.artist_err = "";
        this.author_err = "";
      }
    },
    // author(newVal) {
    //   if (newVal != "") {
    //     this.author_err = "";
    //   }
    // },
    file_duration(newVal) {
      if (newVal != "") {
        this.file_duration_err = "";
      }
    },
    tags(newVal) {
      if (newVal.length > 0) {
        this.tags_err = "";
      }
    },
  },

  methods: {
    checkFileType(file, num) {
      console.log(num);
      if (num == "video") {
        var types = [
          "video/mp4",
          "video/3gp",
          "video/mov",
          "video/avi",
          "video/mkv",
        ];
        if (!types.includes(file.type)) {
          this.videoFile_err =
            "The video must be a file of type: mp4, mov, avi";
          this.videoFile = "";
          return false;
        }
        return true;
      } else if (num == "audio") {
        types = [
          "audio/mpeg",
          "audio/ogg",
          "audio/wav",
          "audio/mp4",
          "audio/mp3",
        ];
        if (!types.includes(file.type)) {
          this.audioFile_err =
            "The audio must be a file of type: mp3, wav, ogg";
          this.audioFile = "";
          return false;
        }
        return true;
      } else if (num == "pdf") {
        types = ["application/pdf"];
        if (!types.includes(file.type)) {
          this.pdfFile_err = "The file must be a file of type: pdf";
          this.pdf_file = "";
          return false;
        }
        return true;
      } else {
        types = ["image/jpeg", "image/png", "image/jpg"];
        var msg = "The image must be a file of type: jpeg, jpg, png";
        if (!types.includes(file.type)) {
          if (num == "image1") {
            this.audio_image_url_err = msg;
            this.audio_image = "";
            this.audio_image_url = "";
          } else if (num == "image2") {
            this.audio_cover_image_url_err = msg;
            this.audio_cover_image = "";
            this.audio_image_url2 = "";
          }
          return false;
        }
        return true;
      }
    },

    submitFunctions() {
      if (this.type == "video") {
        this.updateVideo();
      }
      if (this.type == "audio") {
        this.updateAudio();
      }
      if (this.type == "pdf") {
        this.updatePdf();
      }
      if (this.type == "text") {
        this.updateText();
      }
    },

    updateTagFromChild(arr) {
      this.tags = arr;
    },
    onPdfChange(e) {
      this.pdfFile_err = "";
      const file = e.target.files[0];
      var status = this.checkFileType(file, "pdf");
      if (status) {
        this.pdf_file = file;
      }
    },
    // onAudioImageChange(e) {
    //   const file = e.target.files[0];
    //   this.audio_image = file;
    //   this.audio_image_url = URL.createObjectURL(file)
    // },
    // onAudioImageChange2(e) {
    //   const file = e.target.files[0];
    //   this.audio_image2 = file;
    //   this.audio_image_url2 = URL.createObjectURL(file)
    // },

    onAudioImageChange(e) {
      this.audio_image_url_err = "";
      const image = e.target.files[0];
      var status = this.checkFileType(image, "image1");
      if (status) {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = (e) => {
          this.audio_image_url = e.target.result;
        };
      }
    },

    onAudioImageChange2(e) {
      this.audio_cover_image_url_err = "";
      const image = e.target.files[0];
      var status = this.checkFileType(image, "image2");
      if (status) {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = (e) => {
          this.audio_image_url2 = e.target.result;
        };
      }
    },

    async updateVideo() {
      // console.log(this.audio_name);
      // console.log(this.aud_desc);
      // console.log(this.media_artist);
      // console.log(this.tags);
      // console.log(this.videoFile);
      // console.log('update video btn click');
      // console.log(this.current_id);
      if (
        this.audio_name != "" &&
        this.aud_desc != "" &&
        this.media_artist != "" &&
        // && this.videoFile != ""
        this.tags.length != 0
      ) {
        try {
          this.image_error = "";
          this.title_error = "";
          this.cover_error = "";
          this.tag_error = "";
          this.desc_error = "";
          this.audio_error = "";
          this.artist_error = "";

          // const formData03 = new FormData();
          // formData03.append("title", this.audio_name);
          // formData03.append("id", this.current_id);
          // formData03.append("lesson_id", this.cat_id);
          // formData03.append("description", this.aud_desc);
          // formData03.append("duration", this.file_duration_db);
          // formData03.append("type", this.m_type);
          // formData03.append("artist", this.media_artist);

          // if (typeof this.tags == "string") {
          //   formData03.append("tag[]", this.tags);
          // } else {
          //   for (var i = 0; i < this.tags.length; i++) {
          //     formData03.append("tag[]", this.tags[i]);
          //   }
          // }

          // if (this.$refs?.audioFile?.files[0]) {
          //   formData03.append("audio_file", this.$refs.audioFile.files[0]);
          // }

          // if (this.$refs?.videoFile?.files[0]) {
          //   formData03.append("video_file", this.$refs.videoFile.files[0]);
          // }

          // if (this.pdf_file) {
          //   formData03.append("pdf_file", this.pdf_file);
          // }

          // if (this.audio_image) {
          //   formData03.append("image", this.audio_image);
          // }

          // if (this.audio_image2) {
          //   formData03.append("cover_image", this.audio_image2);
          // }

          let payload = {
            id : this.current_id,
            title: this.audio_name,
            lesson_id: this.cat_id,
            description: this.aud_desc,
            type: this.m_type,
            artist: this.media_artist,
            author: this.author,
            duration: this.file_duration_db,
            // order: this.data_length + 1,
            video_thumbnail: this.video_thumbnail,
            video_file_path: this.video_file_path,
            tag: this.tags,
          };
          console.log('abc');
          console.log(payload);
          let resultss = await api.post(
            "/admin/update-lesson-content",
            payload,
            {
              onUploadProgress: function (progressEvent) {
                this.progress = parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 100)
                );
              }.bind(this),
            }
          );
          //console.log("Songs", resultss);
          if (resultss.status == 200) {
            this.$toast.success(resultss.data.message);
            // window.location.reload();
            this.$root.$refs.Content.callme03();
            const list = document.getElementById("modals-container");
            list.removeChild(list.firstElementChild);

            this.value02 = false;
            // this.$store.dispatch('getLessonContentData',this.$route.params.id)
            // this.$store.commit('changeFilterContentDataStatus');
          }
        } catch (error) {
          if (typeof error?.response?.data?.errors?.title !== "undefined") {
            this.title_error = error.response.data.errors.title[0];
          }
          if (
            typeof error?.response?.data?.errors?.description !== "undefined"
          ) {
            this.desc_error = error.response.data.errors.description[0];
          }
          if (typeof error?.response?.data?.errors?.tag !== "undefined") {
            this.tag_error = error.response.data.errors.tag[0];
          }
          if (typeof error?.response?.data?.errors?.artist !== "undefined") {
            this.artist_error = error.response.data.errors.artist[0];
          }
          if (
            typeof error?.response?.data?.errors?.audio_file !== "undefined"
          ) {
            this.audio_error = error.response.data.errors.audio_file[0];
          }
          if (
            typeof error?.response?.data?.errors?.cover_image !== "undefined"
          ) {
            this.cover_error = error.response.data.errors.cover_image[0];
          }
          if (typeof error?.response?.data?.errors?.image !== "undefined") {
            this.image_error = error.response.data.errors.image[0];
          }

          this.progress = 0;
          this.error = error.response.data.message;
          this.$toast.error(this.error);
        }
      } else {
        this.aud_name_err =
          this.audio_name == "" && this.aud_name_err == ""
            ? "title is required"
            : this.aud_name_err;
        this.aud_desc_err =
          this.aud_desc == "" && this.aud_desc_err == ""
            ? "Description is required"
            : this.aud_desc_err;
        this.artist_err =
          this.media_artist == "" && this.artist_err == ""
            ? "Artist is required"
            : this.artist_err;
        this.videoFile_err =
          this.videoFile == "" && this.videoFile_err == ""
            ? "Video file is required"
            : this.videoFile_err;
        this.tags_err =
          this.tags.length == 0 && this.tags_err == ""
            ? "Tags require"
            : this.tags_err;
      }
    },

    async updateAudio() {
      console.log("update audio btn");
      console.log(this.audio_image_url);
      console.log(this.audio_image_url2);
      if (
        this.audio_name != "" &&
        this.aud_desc != "" &&
        this.media_artist != "" &&
        this.audioFile != "" &&
        this.audio_image_url2 != "" &&
        this.audio_image_url != "" &&
        this.tags.length != 0
      ) {
        try {
          this.image_error = "";
          this.title_error = "";
          this.cover_error = "";
          this.tag_error = "";
          this.desc_error = "";
          this.audio_error = "";
          this.artist_error = "";

          const formData03 = new FormData();
          formData03.append("title", this.audio_name);
          formData03.append("id", this.current_id);
          formData03.append("lesson_id", this.cat_id);
          formData03.append("description", this.aud_desc);
          formData03.append("duration", this.file_duration_db);
          formData03.append("type", this.m_type);
          formData03.append("artist", this.media_artist);

          if (typeof this.tags == "string") {
            formData03.append("tag[]", this.tags);
          } else {
            for (var i = 0; i < this.tags.length; i++) {
              formData03.append("tag[]", this.tags[i]);
            }
          }

          if (this.$refs?.audioFile?.files[0]) {
            formData03.append("audio_file", this.$refs.audioFile.files[0]);
          }

          if (this.$refs?.videoFile?.files[0]) {
            formData03.append("video_file", this.$refs.videoFile.files[0]);
          }

          if (this.pdf_file) {
            formData03.append("pdf_file", this.pdf_file);
          }

          if (this.audio_image) {
            formData03.append("image", this.audio_image);
          }

          if (this.audio_image2) {
            formData03.append("cover_image", this.audio_image2);
          }

          let resultss = await api.post(
            "/admin/update-lesson-content",
            formData03,
            {
              onUploadProgress: function (progressEvent) {
                this.progress = parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 100)
                );
              }.bind(this),
            }
          );
          //console.log("Songs", resultss);
          if (resultss.status == 200) {
            this.$toast.success(resultss.data.message);
            // window.location.reload();
            this.$root.$refs.Content.callme03();
            const list = document.getElementById("modals-container");
            list.removeChild(list.firstElementChild);

            this.value02 = false;
            // this.$store.dispatch('getLessonContentData',this.$route.params.id)
            // this.$store.commit('changeFilterContentDataStatus');
          }
        } catch (error) {
          if (typeof error?.response?.data?.errors?.title !== "undefined") {
            this.title_error = error.response.data.errors.title[0];
          }
          if (
            typeof error?.response?.data?.errors?.description !== "undefined"
          ) {
            this.desc_error = error.response.data.errors.description[0];
          }
          if (typeof error?.response?.data?.errors?.tag !== "undefined") {
            this.tag_error = error.response.data.errors.tag[0];
          }
          if (typeof error?.response?.data?.errors?.artist !== "undefined") {
            this.artist_error = error.response.data.errors.artist[0];
          }
          if (
            typeof error?.response?.data?.errors?.audio_file !== "undefined"
          ) {
            this.audio_error = error.response.data.errors.audio_file[0];
          }
          if (
            typeof error?.response?.data?.errors?.cover_image !== "undefined"
          ) {
            this.cover_error = error.response.data.errors.cover_image[0];
          }
          if (typeof error?.response?.data?.errors?.image !== "undefined") {
            this.image_error = error.response.data.errors.image[0];
          }

          this.progress = 0;
          this.error = error.response.data.message;
          this.$toast.error(this.error);
        }
      } else {
        this.aud_name_err =
          this.audio_name == "" && this.aud_name_err == ""
            ? "title is required"
            : this.aud_name_err;
        this.aud_desc_err =
          this.aud_desc == "" && this.aud_desc_err == ""
            ? "Description is required"
            : this.aud_desc_err;
        this.audioFile_err =
          this.audioFile == "" && this.audioFile_err == ""
            ? "Audio file is required"
            : this.audioFile_err;
        this.artist_err =
          this.media_artist == "" && this.artist_err == ""
            ? "Artist is required"
            : this.artist_err;
        this.tags_err =
          this.tags.length == 0 && this.tags_err == ""
            ? "Tags require"
            : this.tags_err;
        this.audio_image_url_err =
          this.audio_image_url == "" && this.audio_image_url_err == ""
            ? "Image is required"
            : this.audio_image_url_err;
        this.audio_cover_image_url_err =
          this.audio_image_url2 == "" && this.audio_cover_image_url_err == ""
            ? "Image is required"
            : this.audio_cover_image_url_err;
      }
    },

    async updatePdf() {
      if (
        this.audio_name != "" &&
        this.aud_desc != "" &&
        this.pdfFile_err == "" &&
        // && this.pdf_file != ""
        this.media_artist != "" &&
        this.file_duration != "" &&
        this.tags.length != 0
      ) {
        try {
          this.image_error = "";
          this.title_error = "";
          this.cover_error = "";
          this.tag_error = "";
          this.desc_error = "";
          this.audio_error = "";
          this.artist_error = "";

          const formData03 = new FormData();
          formData03.append("title", this.audio_name);
          formData03.append("id", this.current_id);
          formData03.append("lesson_id", this.cat_id);
          formData03.append("description", this.aud_desc);
          formData03.append("duration", this.file_duration);
          formData03.append("type", this.m_type);
          formData03.append("artist", this.media_artist);

          if (typeof this.tags == "string") {
            formData03.append("tag[]", this.tags);
          } else {
            for (var i = 0; i < this.tags.length; i++) {
              formData03.append("tag[]", this.tags[i]);
            }
          }

          if (this.$refs?.audioFile?.files[0]) {
            formData03.append("audio_file", this.$refs.audioFile.files[0]);
          }

          if (this.$refs?.videoFile?.files[0]) {
            formData03.append("video_file", this.$refs.videoFile.files[0]);
          }

          if (this.pdf_file) {
            formData03.append("pdf_file", this.pdf_file);
          }

          if (this.audio_image) {
            formData03.append("image", this.audio_image);
          }

          if (this.audio_image2) {
            formData03.append("cover_image", this.audio_image2);
          }

          let resultss = await api.post(
            "/admin/update-lesson-content",
            formData03,
            {
              onUploadProgress: function (progressEvent) {
                this.progress = parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 100)
                );
              }.bind(this),
            }
          );
          //console.log("Songs", resultss);
          if (resultss.status == 200) {
            this.$toast.success(resultss.data.message);
            // window.location.reload();
            this.$root.$refs.Content.callme03();
            const list = document.getElementById("modals-container");
            list.removeChild(list.firstElementChild);

            this.value02 = false;
            // this.$store.dispatch('getLessonContentData',this.$route.params.id)
            // this.$store.commit('changeFilterContentDataStatus');
          }
        } catch (error) {
          if (typeof error?.response?.data?.errors?.title !== "undefined") {
            this.title_error = error.response.data.errors.title[0];
          }
          if (
            typeof error?.response?.data?.errors?.description !== "undefined"
          ) {
            this.desc_error = error.response.data.errors.description[0];
          }
          if (typeof error?.response?.data?.errors?.tag !== "undefined") {
            this.tag_error = error.response.data.errors.tag[0];
          }
          if (typeof error?.response?.data?.errors?.artist !== "undefined") {
            this.artist_error = error.response.data.errors.artist[0];
          }
          if (
            typeof error?.response?.data?.errors?.audio_file !== "undefined"
          ) {
            this.audio_error = error.response.data.errors.audio_file[0];
          }
          if (
            typeof error?.response?.data?.errors?.cover_image !== "undefined"
          ) {
            this.cover_error = error.response.data.errors.cover_image[0];
          }
          if (typeof error?.response?.data?.errors?.image !== "undefined") {
            this.image_error = error.response.data.errors.image[0];
          }

          this.progress = 0;
          this.error = error.response.data.message;
          this.$toast.error(this.error);
        }
      } else {
        this.aud_name_err =
          this.audio_name == "" && this.aud_name_err == ""
            ? "title is required"
            : this.aud_name_err;
        this.aud_desc_err =
          this.aud_desc == "" && this.aud_desc_err == ""
            ? "Description is required"
            : this.aud_desc_err;
        // this.pdfFile_err = (this.pdf_file == "" && this.pdfFile_err == "") ? "Pdf file is required" : this.pdfFile_err;
        this.author_err =
          this.media_artist == "" && this.author_err == ""
            ? "Author is required"
            : this.author_err;
        this.file_duration_err =
          this.file_duration == "" && this.file_duration_err == ""
            ? "Duration is required"
            : this.file_duration_err;
        this.tags_err =
          this.tags.length == 0 && this.tags_err == ""
            ? "Tags require"
            : this.tags_err;
      }
    },

    async updateText() {
      if (
        this.audio_name != "" &&
        this.aud_desc != "" &&
        this.file_duration != "" &&
        this.media_artist != "" &&
        this.tags.length != 0
      ) {
        try {
          this.image_error = "";
          this.title_error = "";
          this.cover_error = "";
          this.tag_error = "";
          this.desc_error = "";
          this.audio_error = "";
          this.artist_error = "";

          const formData03 = new FormData();
          formData03.append("title", this.audio_name);
          formData03.append("id", this.current_id);
          formData03.append("lesson_id", this.cat_id);
          formData03.append("description", this.aud_desc);
          formData03.append("duration", this.file_duration);
          formData03.append("type", this.m_type);
          formData03.append("artist", this.media_artist);

          if (typeof this.tags == "string") {
            formData03.append("tag[]", this.tags);
          } else {
            for (var i = 0; i < this.tags.length; i++) {
              formData03.append("tag[]", this.tags[i]);
            }
          }

          if (this.$refs?.audioFile?.files[0]) {
            formData03.append("audio_file", this.$refs.audioFile.files[0]);
          }

          if (this.$refs?.videoFile?.files[0]) {
            formData03.append("video_file", this.$refs.videoFile.files[0]);
          }

          if (this.pdf_file) {
            formData03.append("pdf_file", this.pdf_file);
          }

          if (this.audio_image) {
            formData03.append("image", this.audio_image);
          }

          if (this.audio_image2) {
            formData03.append("cover_image", this.audio_image2);
          }

          let resultss = await api.post(
            "/admin/update-lesson-content",
            formData03,
            {
              onUploadProgress: function (progressEvent) {
                this.progress = parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 100)
                );
              }.bind(this),
            }
          );
          //console.log("Songs", resultss);
          if (resultss.status == 200) {
            this.$toast.success(resultss.data.message);
            // window.location.reload();
            this.$root.$refs.Content.callme03();
            const list = document.getElementById("modals-container");
            list.removeChild(list.firstElementChild);

            this.value02 = false;
            // this.$store.dispatch('getLessonContentData',this.$route.params.id)
            // this.$store.commit('changeFilterContentDataStatus');
          }
        } catch (error) {
          if (typeof error?.response?.data?.errors?.title !== "undefined") {
            this.title_error = error.response.data.errors.title[0];
          }
          if (
            typeof error?.response?.data?.errors?.description !== "undefined"
          ) {
            this.desc_error = error.response.data.errors.description[0];
          }
          if (typeof error?.response?.data?.errors?.tag !== "undefined") {
            this.tag_error = error.response.data.errors.tag[0];
          }
          if (typeof error?.response?.data?.errors?.artist !== "undefined") {
            this.artist_error = error.response.data.errors.artist[0];
          }
          if (
            typeof error?.response?.data?.errors?.audio_file !== "undefined"
          ) {
            this.audio_error = error.response.data.errors.audio_file[0];
          }
          if (
            typeof error?.response?.data?.errors?.cover_image !== "undefined"
          ) {
            this.cover_error = error.response.data.errors.cover_image[0];
          }
          if (typeof error?.response?.data?.errors?.image !== "undefined") {
            this.image_error = error.response.data.errors.image[0];
          }

          this.progress = 0;
          this.error = error.response.data.message;
          this.$toast.error(this.error);
        }
      } else {
        this.aud_name_err =
          this.audio_name == "" && this.aud_name_err == ""
            ? "title is required"
            : this.aud_name_err;
        this.aud_desc_err =
          this.aud_desc == "" && this.aud_desc_err == ""
            ? "Description is required"
            : this.aud_desc_err;
        this.tags_err =
          this.tags.length == 0 && this.tags_err == ""
            ? "Tags require"
            : this.tags_err;
        this.author_err =
          this.media_artist == "" && this.author_err == ""
            ? "Author is required"
            : this.author_err;
        this.file_duration_err =
          this.file_duration == "" && this.file_duration_err == ""
            ? "Duration is required"
            : this.file_duration_err;
      }
    },
    uploadAudio(e) {
      this.audioFile_err = "";
      this.audioFile = "";
      const audio = e.target.files[0];
      var status = this.checkFileType(audio, "audio");
      if (status) {
        console.log(audio, "My Audio");
        const reader = new FileReader();
        reader.readAsDataURL(audio);
        var audio_el = document.createElement("audio");
        reader.onload = (e) => {
          this.audioFile = e.target.result;
          audio_el.src = e.target.result;
          audio_el.addEventListener("loadedmetadata", () => {
            var duration = audio_el.duration;
            this.file_duration = duration;
          });
        };
        reader.readAsDataURL(audio);
        this.count++;
      }
    },

    async uploadVideo(e) {
      this.videoFile_err = "";
      this.videoFile = "";
      this.videoFile = e.target.files[0];
      var status = this.checkFileType(this.videoFile, "video");
      if (status) {
        const res = await this.generateVideoThumbnail(this.videoFile);
        this.imagepath = res;
        this.blob = this.bast64ToFile(res);
        let video = document.getElementById("video-preview");
        let reader = new FileReader();
        reader.readAsDataURL(this.videoFile);
        const self = this;
        reader.addEventListener("load", function () {
          video.src = reader.result;
          var i = setInterval(
            function () {
              if (video.readyState > 0) {
                // var minutes = parseInt(video.duration / 60, 10);
                // var seconds = video.duration % 60;
                const audio_file = new Date(video.duration * 1000)
                  .toISOString()
                  .slice(14, 19);
                self.file_duration = audio_file;
                this.file_duration_db = video.duration;
                clearInterval(i);
              }
            }.bind(self),
            200
          );
        });
      }
    },

    bast64ToFile(new_file) {
      var arr = new_file.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], "filename", { type: mime });
    },

    generateVideoThumbnail(file) {
      return new Promise((resolve) => {
        const canvas = document.createElement("canvas");
        const video = document.createElement("video");

        // this is important
        video.autoplay = true;
        video.muted = true;
        video.src = URL.createObjectURL(file);

        video.onloadeddata = () => {
          let ctx = canvas.getContext("2d");

          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;

          ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
          video.pause();
          return resolve(canvas.toDataURL("image/png"));
        };
      });
    },

    onLoad() {
      this.updateAudio();
    },

    // uploadImage02(e) {
    //   const image = e.target.files[0];
    //   const reader = new FileReader();
    //   reader.readAsDataURL(image);
    //   reader.onload = (e) => {
    //     this.vpath = e.target.result;
    //     console.log(this.vpath);
    //   };
    // },
    closebtn() {
      const list = document.getElementById("modals-container");
      list.removeChild(list.firstElementChild);
    },

    uploadSuccess(data) {
      this.file_duration_db = data?.duration ?? "0";
      this.video_file_path = data.path ?? null;
      this.video_thumbnail = data.thumbnail ?? null;
      // console.log(data);
      // this.ready_to_submit = true;
    },
  },
};
</script>

<style scoped>
.vm--modal {
  height: auto !important;
}

.ql-editor {
  background-color: #166d8c;
  max-height: 100px;
}

.modalTop {
  width: 100%;
  background: #041e4f;

  color: #fff;
}

.modalTop h3 {
  font-weight: 600;
  color: #fff;
  font-size: 1.42188rem;
  text-align: center;
  padding: 15px 0;
}

.modalDesc {
  padding: 20px 40px;
  max-height: 700px;
  overflow-y: scroll;
}

.modalDesc label {
  display: inline-block;
  line-height: 34px;
  font-size: 15px;
}

.modalDesc input {
  background: #fff;
  border: solid 1px #dadada;
  border-radius: 8px;
  padding: 8px 16px;
  color: #333;
  outline: none;
  width: 100%;
}

.submitbtn {
  margin-top: 30px;

  text-align: center;
  border-radius: 6px;
  padding: 8px 16px;

  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}

.modalFooter {
  position: absolute;
  width: 100%;
  bottom: 0;
  width: 100%;
  background: #041e4f;
  height: 20px;
}

.fas {
  font-size: 30px;
  margin-top: 15px;
}
</style>
