<template>
  <div>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      @click="closebtn()"
    >
      &times;
    </button>
    <div class="modalTop"><h3>Add Subcategory</h3></div>
    <div class="modalDesc">
      <div class="row">
        <div class="col-md-4"><label>Title</label></div>
        <div class="col-md-8">
          <input type="catname" placeholder="Subcategory Title" v-model="aud_name" />
          <p class="err" v-if="title_error != ''">{{ title_error }}</p>
        </div>
      </div>

      <!-- <div class="row">
        <div class="col-md-4"><label>Description</label></div>
        <div class="col-md-8">
          <textarea
            type="catname"
            placeholder="Course Description"
            v-model="aud_desc"
          />
        </div>
      </div> -->

      <div class="row imgboxx">
        <div class="col-md-4"><label>Image</label></div>
        <div class="col-md-8">
          <div class="imgbox">
            <img
              v-if="previewImage == ''"
              :src="'/assets/media/services/base.jpg'"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <img
              v-if="previewImage !== ''"
              :src="previewImage"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <i class="fas fa-camera" @click="$refs.imageFile.click()"></i>
            <div class="clear"></div>
            <p class="err" v-if="image_error != ''">{{ image_error }}</p>
            <span v-if="previewImage == ''" previmg="false">
              <!-- image is required -->
            </span>

            <input
              class="ml"
              type="file"
              accept="image/jpeg"
              @change="uploadImage"
              ref="imageFile"
              style="display: none"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-8">
          <button
            class="btn btn-primary w-md mybutton myleft submitbtn"
            v-on:click="addAudio"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
    <div class="modalFooter"></div>
  </div>
</template>

<script>
import api from "../../config/api";
export default {
  name: "ModalAddAudioHypnosis",
  props: {
    // add_api : String,
  },

  data() {
    return {
      audio_image: "",
      previewImage: "",
      add_api : "",
      aud_name : "",
      title_error : "",
      image_error : "",
    };
  },

  watch: {
    aud_name(newVal) {
      if (newVal != "") {
        this.title_error = "";
      }
    },
    audio_image(newVal) {
      if (newVal != "") {
        this.image_error = "";
      }
    },
  },

  // mounted() {
  // },
  methods: {

    // onAudioImageChange(e){
    //   const file = e.target.files[0];
    //   this.audio_image = file;
    //   this.audio_image_url = URL.createObjectURL(file)
    // },

    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
      };
    },

    async addAudio() {
      try {
        this.title_error = "";
        this.image_error = "";
        const formData = new FormData();
        formData.append("name", this.aud_name);

        if (this.$refs.imageFile.files[0]) {
          formData.append("image", this.$refs.imageFile.files[0]);
        }
        let result = await api.post('/admin/add-hypnosis-cat', formData);
        this.$toast.success(result.data.message);
        this.$root.$refs.Hypnosis.callme();
        const list = document.getElementById("modals-container");
        list.removeChild(list.firstElementChild);

        //this.$refs.ServiceCategoreis.onload(); //like this
      } catch (error) {
        if (typeof error.response.data.errors.name !== "undefined") {
          this.title_error = error.response.data.errors.name[0];
        }
        if(typeof error.response.data.errors.image !== "undefined") {
          this.image_error = error.response.data.errors.image[0];
        }
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    closebtn() {
      // this.$root.$refs.Tabs.callme(this.lib_id);
      // this.$root.$refs.Hypnosis.callme();
      const list = document.getElementById("modals-container");
      list.removeChild(list.firstElementChild);
    },
  },
};
</script>

<style scoped>
#modals-container .vm--modal {
  height: 600px !important;
}
.modalTop {
  width: 100%;
  background: #041e4f;

  color: #fff;
}
.modalTop h3 {
  font-weight: 600;
  color: #fff;
  font-size: 1.42188rem;
  text-align: center;
  padding: 15px 0;
}

.modalDesc {
  padding: 20px 40px;
  max-height: 700px;
  overflow-y: scroll;
}
.modalDesc label {
  display: inline-block;
  line-height: 34px;
  font-size: 15px;
  margin: 15px 0;
}
.modalDesc input {
  background: #fff;
  border: solid 1px #dadada;
  border-radius: 8px;
  padding: 8px 16px;
  color: #333;
  outline: none;
  width: 100%;
  margin: 15px 0;
}
.modalDesc audio {
  float: left;
  margin-right: 10px;
}
.submitbtn {
  margin-top: 30px;

  text-align: center;
  border-radius: 6px;
  padding: 8px 16px;

  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
.modalFooter {
  position: absolute;
  width: 100%;
  bottom: 0;
  width: 100%;
  background: #041e4f;
  height: 20px;
}
.fas {
  font-size: 30px;
  margin-top: 15px;
}
</style>
