<template>
  <div>
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-sm-flex align-items-center justify-content-between"
        >
          <div class="page-title-right">
            <h4 class="mb-sm-0 font-size-18">Ebooks List</h4>
            <a
              @click="$router.go(-1)"
              class="btn btn-primary w-md mybutton myleft"
            >
              <box-icon
                name="chevrons-left"
                type="solid"
                color="#ffffff"
                class="myicon"
              ></box-icon
              >Back</a
            >
          </div>

          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item active">Ebooks List</li>
            </ol>
            <div>
              <button
                class="btn btn-primary waves-effect btn-label waves-light mybutton"
                @click="showModal()"
              >
                Add Ebook
              </button>
            </div>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
    <!-- end page title -->

    <div class="row">
      <div class="col-lg-12">
        <div class="">
          <div class="table-responsive">
            <div class="card">
              <div class="card-body">
                <table
                  class="table table-hover table-bordered"
                  id="service_table02"
                >
                  <thead>
                    <tr class="frown">
                      <th scope="col" >#</th>
                      <th scope="col" >Image</th>
                      <th scope="col" >Name</th>
                      <th scope="col" style="width: 50%">Description</th>
                      <th scope="col" style="width: 50%">URL</th>
                      <th scope="col">Status</th>
                      <th scope="col" style="width: 50%">Is Paid</th>
                      <th scope="col" >Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in ebooks" :key="index">
                      <td scope="row" class="sorting_1">{{ index + 1 }}</td>
                      <td>
                        <img :src="item.image" alt="" />
                      </td>
                      <td>
                        <!-- <h5 class="text-truncate font-size-14">
                          <router-link to="#" class="text-dark">{{
                            item.name
                          }}</router-link>
                        </h5> -->
                        <p class="mb-0">
                          {{ item.name }}
                        </p>
                      </td>

                      <td>
                        <p class="mb-0">
                          {{ item.description }}
                        </p>
                      </td>

                      <td>
                        <a :href="item.path" target="_blank" class="mb-0">
                          {{ item.path }}
                        </a>
                      </td>

                      <td>
                        <toggle-button @change="changeStatus(item)" :value="(item.status == 'Active') ? true : false" />
                      </td>

                      <td>
                        <toggle-button @change="changeSubscriptionStatus(item)" :value="(item.is_paid == 1) ? true : false" />
                      </td>

                      <td>
                        <!-- <div class="w3-dropdown-click mydropdown"> -->
                        <div class="dropdown">
                          <a
                            href="#"
                            class="dropdown-toggle card-drop"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <box-icon
                              name="dots-horizontal-rounded"
                              class="myddicon"
                            ></box-icon>
                          </a>
                          <!-- <button @click="myFunction()" class="w3-button"> -->
                          <!-- <box-icon
                              name="dots-horizontal-rounded"
                              class="myddicon"
                            ></box-icon> -->
                          <!-- </button> -->
                          <!-- <div
                            id="Demo"
                            class="w3-dropdown-content w3-bar-block w3-card-4 w3-animate-zoom"
                          > -->
                          <!-- <div
                            id="myDIV"
                            class="dropdown-content w3-animate-zoom"
                          > -->
                          <div
                            class="dropdown-menu dropdown-menu-end dropdown-content w3-animate-zoom"
                            id="myDIV"
                          >
                            <!-- <div
                              class="dropdown-menu dropdown-menu-end dropdown-content"
                            > -->
                            <div>
                              <!-- <router-link
                                :to="'libraries/' + item.id"
                                class="listbox w3-bar-item w3-button"
                                @click="getname_cat(item._id)"
                              > -->
                              <!-- <router-link
                                :to="'libraries/' + item.id"
                                class="listbox w3-bar-item w3-button"
                               
                              >
                                <box-icon
                                  name="library"
                                  class="myicon"
                                ></box-icon>
                                Libraries</router-link
                              > -->
                              <a
                                href="#"
                                @click="
                                  showModal02(
                                    item.id,
                                    item.name,
                                    item.description,
                                    item.image,
                                    item.artist,
                                    item.path,
                                    item.tag
                                  )
                                "
                                class="listbox w3-bar-item w3-button"
                              >
                                <box-icon name="edit" class="myicon"></box-icon>
                                Update Ebook</a
                              >
                              <a
                                href="#"
                                @click="remove_cat(item.id)"
                                class="listbox w3-bar-item w3-button"
                                ><box-icon
                                  type="solid"
                                  name="message-alt-x"
                                  class="myicon"
                                ></box-icon
                                >Delete Now</a
                              >
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Large modal -->

    <!-- Modal -->
  </div>
</template>

<script>
import ModalAddEbook from "../Modals/ModalAddEbook.vue";
import ModalEditEbook from "../Modals/ModalEditEbook.vue";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import api from "../../config/api.js";
import $ from "jquery";

//import { GDialog } from "gitart-vue-dialog";
//import "gitart-vue-dialog/dist/style.css";
//import { ref } from "vue";

export default {
  name: "ServiceCategories",
  //components: {GDialog },
  components: {},
  data() {
    return {
      is_active: true,
      ebooks: "",
    };
  },

  computed: {
    getkey() {
      return this.$store.state.token;
    },

    // user_name() {
    //   return this.$store.state.user.name;
    // }
  },

  mounted() {
    this.onLoad();
    // console.log(this.$store.state.name, "Mylog");
  },

  created() {
    this.$root.$refs.ServiceCategories = this;
  },

  methods: {

    async changeStatus(item){
      item.status = !item.status
      try {
        let result = await api.post(`/admin/ebook-status?id=${item.id}`);
        this.$toast.success(result.data.message);
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },


    async changeSubscriptionStatus(item){
      item.is_paid = !item.is_paid
      try {
        const data = {
          "type" : "Ebook",
          "id" : item.id
        }
        let result = await api.post('/admin/toggle-subscription-status',data);
        this.$toast.success(result.data.message);
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    async onLoad() {
      let result = await api.get(`/admin/all-ebooks`);
      //console.log(result, "Items");

      this.ebooks = result.data.all_ebooks;
      setTimeout(() => {
        $("#service_table02").DataTable();
      }, 0);
      console.log(this.ebooks);
    },

    async remove_cat(id) {
      try {
        this.$swal({
          title: "Are you sure?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete it!",
          cancelButtonText: "No, cancel!",
          confirmButtonClass: "btn btn-success",
          cancelButtonClass: "btn btn-danger",
          buttonsStyling: true,
        }).then(async (res) => {
          if (res.isConfirmed) {
            let result = await api.delete(`/admin/delete-ebook/` + id);
            this.$toast.success(result.data.message);
            // console.log("I am in Delete mode", result);
            this.onLoad();
          }
        });
      } catch (error) {
        this.$toast.error(error.response.data.message);
      }
    },
    async changeStatusnew(id) {
      try {
        let changeresult = await api.put(`/admin/cat-status`, {
          category_id: id,
        });
        console.log(changeresult.status);
        if (changeresult.status == 200) {
          if (this.cat_status === "Active") {
            this.checked = true;
          } else if (this.cat_status === "InActive") {
            this.checked = false;
          }
          this.onLoad();
          // console.log(this.cat_status);
          //this.$toast.success("sdjfkie");
        }

        this.onLoad();
      } catch (error) {
        this.$toast.error(error.response.data.message);
      }
    },

    showModal() {
      this.$modal.show(
        ModalAddEbook,
        { text: "This text is passed as a property" },
        { draggable: true },
        {
          "before-close": () => {
            this.onLoad();
          },
        }
      );
    },
    showModal02(id, name, description, image, artist, url, tag) {
      // console.log('from ebook page');
      // console.log(tag);
      let tags = JSON.parse(tag);
      this.$modal.show(
        ModalEditEbook,
        { eid:id, ename:name, edesc:description, eimage:image, eartist:artist, eurl:url, atags:tags },
        { draggable: true },
        {
          "before-close": () => {
            this.onLoad();
          },
        }
      );
    },

    callme() {
      this.onLoad();
    },
    // myFunction() {
    //   var x = document.getElementById("myDIV");
    //   if (x.style.display === "none") {
    //     x.style.display = "block";
    //   } else {
    //     x.style.display = "none";
    //   }
    // },
  },
};
</script>

<style scoped>
.mybutton {
  margin-top: 15px;
}
.switch {
  /* pointer-events: none; */
}
.myicon {
  padding-top: 0px;
}
.myicon {
  width: 24px;
  height: 24px;
  float: left !important;
  margin-right: 8px;
  margin-top: -2px;
}
.table img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background: #106d87;
}

.mybtn {
  color: #fff;
}
#service_table02 h5 {
  line-height: 65px;
}
#service_table02 .switch {
  margin-top: 25px;
}
#service_table02 .dropdown {
  margin-top: 15px;
}
.mydropdown box-icon.myddicon {
  line-height: 85px;
}
.w3-show {
  position: absolute;
  bottom: 0;
}
.w3-dropdown-click {
  position: relative !important;
  width: 25px;
  height: 10px;
}
.demo {
  width: 25px;
  height: 10px;
  position: absolute !important;
}
#myDIV {
  position: absolute;
  top: 65% !important;
}
.w3-dropdown-content {
  position: absolute;
  display: none;
}
</style>
