<template>
  <div :key="$route.path">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-sm-flex align-items-center justify-content-between"
        >
          <div class="page-title-right">
            <h4 class="mb-sm-0 font-size-18">
              All Scripts
            <!-- - {{ $route.params.title }} -->
            </h4>
            <a
              @click="$router.go(-1)"
              class="btn btn-primary w-md mybutton myleft"
            >
              <box-icon
                name="chevrons-left"
                type="solid"
                color="#ffffff"
                class="myicon"
              ></box-icon
              >Back</a
            >
          </div>

          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item">
                <!-- <a href="">Services</a> -->
                <router-link to="/home/all_scripts" class="waves-effect">
                  All Subcategories List
                </router-link>
              </li>
              <li class="breadcrumb-item active">All Scripts List</li>
            </ol>
            <div>
              <button
                class="btn btn-primary waves-effect btn-label waves-light mybutton"
                @click="showModal(videos_cat_id)"
              >
                Add Script
              </button>
            </div>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
    <!-- end page title -->

    <div class="row">
      <div class="col-lg-12">
        <div class="">
          <div class="table-responsive">
            <div class="card">
              <div class="card-body">
                <table
                  class="table table-hover table-bordered"
                  id="audios_table"
                >
                  <thead>
                    <tr class="frown">
                      <th scope="col" style="width: 5%">#</th>
                      <th scope="col" style="width: 10%">Image</th>
                      <th scope="col" style="width: 20%">Title</th>
                      <th scope="col" style="width: 50%">Description</th>
                      <th scope="col" style="width: 50%">Timestamp</th>
                      <th scope="col" style="width: 50%">Status</th>
                      <th scope="col" style="width: 50%">Is Paid</th>
                      <th scope="col" style="width: 5%">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in all_chapters" :key="index">
                      <td scope="row" class="sorting_1">{{ index + 1 }}</td>
                      <td>
                        <img :src="item.image" alt="" />
                      </td>
                      <td>
                        <h5>
                          {{ item.name }}
                        </h5>
                      </td>

                      <td>
                        <p>
                          {{ item.description.substring(0,500)+".." }}
                        </p>
                      </td>

                      <td>
                        <p>
                          {{ item.created_at | moment("dddd, MMMM Do YYYY, h:mm:ss a") }}
                        </p>
                      </td>

                      <td>
                        <toggle-button @change="changeStatus(item)" :value="(item.status == 'Active') ? true : false" />
                      </td>

                      <td>
                        <toggle-button @change="changeSubscriptionStatus(item)" :value="(item.is_available == 1) ? true : false" />
                      </td>

                      <td>
                        <!-- <div class="w3-dropdown-click mydropdown"> -->
                        <div class="dropdown">
                          <a
                            href="#"
                            class="dropdown-toggle card-drop"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <box-icon
                              name="dots-horizontal-rounded"
                              class="myddicon"
                            ></box-icon>
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-end dropdown-content w3-animate-zoom"
                            id="myDIV"
                          >
                        
                            <div>
                              <!-- <a
                                @click="navigate_series(item.id, item.name)"
                                class="listbox w3-bar-item w3-button"
                              >
                                <box-icon name="edit" class="myicon"></box-icon>
                                All Chapters</a
                              > -->
                              <a
                                href="#"
                                @click="
                                  showModal04(
                                    item.id,
                                    item.name,
                                    item.description,
                                    item.image,
                                    item.text,
                                    item.duration,
                                    item.author,
                                    item.tag,
                                  )
                                "
                                class="listbox w3-bar-item w3-button"
                              >
                                <box-icon name="edit" class="myicon"></box-icon>
                                Update Script</a
                              >
                              <a
                                href="#"
                                @click="remove_aud(item.id)"
                                class="listbox w3-bar-item w3-button"
                                ><box-icon
                                  type="solid"
                                  name="message-alt-x"
                                  class="myicon"
                                ></box-icon
                                >Delete Now</a
                              >
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
// import VueVideoThumbnail from "vue-video-thumbnail";
// import ModalVideobox from "../Modals/ModalVideobox.vue";
import ModalAddChapter from "../Modals/ModalAddChapter.vue";
import ModalEditChapter from "../Modals/ModalEditChapter.vue";
import api from "../../config/api.js";
import $ from "jquery";

//import $ from "jquery";
export default {
  name: "Series",
  // components: { VueVideoThumbnail },
  data() {
    return {
      is_active: true,
      all_chapters: [],
      videos_cat_id: "",
      catname: "",
      // snapshot: "",
      // frontModal: true,
      showPlayButton: false,
      front_modal: false,
      content:'',
    };
  },

  created() {
    this.$root.$refs.Chapters = this;
    this.videos_cat_id = this.$route.params.id;
    // console.log(this.$route.params, "Alicreated");

    this.$watch(
      () => this.$route.params,
      () => {
        this.videos_cat_id = this.$route.params.id;
        //this.$router.go();
        this.allChapters();
        //this.sayname(name)
        // console.log(this.$route.params);
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
  mounted() {
    //this.allaudios();
    this.videos_cat_id = this.$route.params.id;
    this.allChapters();
    
    // this.frontModal =true;
    // this.$root.$refs.ModalVideobox.callme(this.frontModal);
  },

  methods: {
    async changeStatus(item){
      item.status = !item.status
      try {
        let result = await api.post(`/admin/chapter-status?id=${item.id}`);
        this.$toast.success(result.data.message);
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    async changeSubscriptionStatus(item){
      item.is_paid = !item.is_paid
      try {
        const data = {
          "type" : "Chapter",
          "id" : item.id
        }
        let result = await api.post('/admin/toggle-subscription-status_is_avilable',data);
        this.$toast.success(result.data.message);
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    async allChapters() {
      try {
        let result = await api.get(`/admin/script-chapters`, {
          params: { script_id: this.videos_cat_id },
        });

        setTimeout(() => {
          $("#audios_table").DataTable();
        }, 0);

        this.all_chapters = result.data.chapters;

        // console.log(result);
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },
    async remove_vid(id) {
      this.front_modal = true;

      try {
        this.$swal({
          title: "Are you sure?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete it!",
          cancelButtonText: "No, cancel!",
          confirmButtonClass: "btn btn-success",
          cancelButtonClass: "btn btn-danger",
          buttonsStyling: true,
        }).then(async (res) => {
          if (res.isConfirmed) {
            let result = await api.delete(`/admin/delete-series/` + id);
            this.allChapters();
            this.$toast.success(result.data.message);
            //this.onLoad();
          }
        });
      } catch (error) {
        this.$toast.error(error.response.data.message);
      }
    },

    showModal(catid) {
      this.$modal.show(
        ModalAddChapter,
        { catid: catid },
        { draggable: false },
        {
          "before-close": () => {
            this.onLoad();
          },
        }
      );
    },


     async remove_aud(id) {
      try {
        this.$swal({
          title: "Are you sure to delete this Script ?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete it!",
          cancelButtonText: "No, cancel!",
          confirmButtonClass: "btn btn-success",
          cancelButtonClass: "btn btn-danger",
          buttonsStyling: true,
        }).then(async (res) => {
          if (res.isConfirmed) {
            let result = await api.delete(`/admin/delete-chapter/` + id);
            this.allChapters();
            this.$toast.success(result.data.message);
            // console.log("I am in Delete mode", result);
            //this.onLoad();
          }
        });
      } catch (error) {
        this.$toast.error(error.response.data.message);
      }
    },

    // showModal03(aid, aname, apath) {
    //   if (!this.front_modal)
    //     this.$modal.show(
    //       ModalVideobox,
    //       {
    //         aid: aid,
    //         aname: aname,
    //         apath: apath,
    //       },
    //       { draggable: true },
    //       {
    //         "before-close": () => {
    //           //this.allAudios(libid);
    //         },
    //       }
    //     );
    //   this.front_modal = false;
    // },

    showModal04(cid,cname,cdesc,cimage,ctext,cduration,cauthor,tags){
      // console.log(atags);
      this.front_modal = true;
      // this.$root.$refs.ModalVideobox.callme(this.frontModal);
      this.$modal.show(
        ModalEditChapter,
        {
          cid: cid,
          cname: cname,
          cdesc: cdesc,
          cimage: cimage,
          ctext: ctext,
          cduration: cduration,
          cauthor: cauthor,
          atags:  JSON.parse(tags),
        },
        { draggable: false },
        {
          "before-close": () => {
            //this.allAudios(libid);
          },
        }
      );
    },
    sendme() {
      this.$root.$refs.ModalVideobox.callme(this.frontModal);
    },
    onload(id) {
      this.allHypnosisAudios(id);
    },
    callme() {
      this.allChapters();
    },
    sayname(name) {
      this.catname = name;
    },
    snapshotReady() {
      console.log("Snape Ready");
    },
  },
};
</script>

<style scoped>
#audios_table h5 {
  /* line-height: 65px; */
  margin-top: 15px;
}
#audios_table .switch {
  margin-top: 25px;
}
#audios_table .dropdown {
  margin-top: 15px;
}

.video-section {
  margin-top: 30px;
}
.videobox {
  /* padding: 12px; */
  border: solid 1px #dadada;
  /* min-width: 375px;
  min-height: 250px; */
  position: relative;
  /* float: left; */
  border-radius: 8px;
  margin: 15px 0;
}

.videobox .titlebox {
  position: absolute;
  max-width: 320px;
  z-index: 99;
  left: 8px;
  bottom: 40px;
  padding: 6px 15px 4px 15px;
  background: rgba(0, 0, 0, 0.6);
  -webkit-border-top-right-radius: 8px;
  -webkit-border-bottom-right-radius: 8px;
  -moz-border-radius-topright: 8px;
  -moz-border-radius-bottomright: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #fff;
}
.videobox .titlebox h2 {
  color: #fff;
  font-weight: 400;
  font-size: 17px;
}

.videobox .titlebox p {
  color: #fff;
  line-height: 17px;
  font-size: 13px;
}

.videobox .actionbox {
  position: absolute;
  z-index: 99;
  right: 10px;
  top: 0;
  display: block;
  padding: 5px;
}

.videobox img {
  z-index: 9999999999;
  width: 375px;
  height: 250px;
  max-width: 100%;
}

.videobox .actionbox .dropdown-content {
  top: 0px;
  z-index: 999999999999999999 !important;
}

.snapshot-generator {
  position: relative;
  display: block;
  z-index: 9;
  width: 100%;
  height: 100%;
}

/* .snapshot-generator__hidden {
    height: 250px;
    width: 375px;
    overflow: visible !important; 
     position: relative !important;
    bottom: 0;
    right: 0;
    z-index: 999999999999999999999999999999;
} */

.card .card-body {
  padding: 8px !important;
}
.clear {
  clear: both;
}

.videobox .dropdown .box-icon {
  color: #fff !important;
  z-index: 99999;
}

.dropdown box-icon {
  line-height: 40px;
}
.dropdown .box-icon {
  line-height: 40px;
}

@layer utilities {
  /* high-priority layers win by default */
  .maroon {
    color: maroon;
  }
}

/* @import "~vue2-editor/dist/vue2-editor.css"; */

/* Import the Quill styles you want */
/* @import '~quill/dist/quill.core.css'; */
/* @import '~quill/dist/quill.bubble.css'; */
/* @import '~quill/dist/quill.snow.css'; */
</style>
