<template>
  <div class="d-flex flex-column flex-root">
    <!--begin::Page-->
    <div class="page d-flex flex-row flex-column-fluid">
      <!--begin::Aside-->
      <Sidebar />
      <!--end::Aside-->
      <!--begin::Wrapper-->

      <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
        <!--begin::Header-->
        <Header />
        <!--end::Header-->
        <!--begin::Content-->

        <div
          class="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <!--begin::Toolbar-->
          <Topbar />
          <!--end::Toolbar-->
          <!--begin::Post-->
          <div class="post d-flex flex-column-fluid" id="kt_post">
            <!--begin::Container-->
            <div id="kt_content_container" class="container-xxl">
              <router-view />
            </div>
          </div>
        </div>
        <!--end::Content-->
        <!--begin::Footer-->
        <Footer />
        <!--end::Footer-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Page-->
  </div>
  <!--end::Root-->
</template>
<script>
import api from "../config/api.js";
import Header from "../components/Header.vue";
import Sidebar from "../components/SideBar.vue";
import Topbar from "../components/TopBar.vue";
import Footer from "../components/Footer.vue";

//import { nextTick } from "vue";
export default {
  name: "Home",

  components: {
    Header,
    Sidebar,
    Topbar,
    Footer,
    // api,
  },

  data() {
    return {
      utoken: "",
      user_name: "",
      user_email: "",
      user_image: "",
      user_type: "",
      user_status: "",
    };
  },

  mounted() {
    // this.$router.push({ name: "Login" });
    //localStorage.setItem("loginkey", result.data.token);

    let reload = localStorage.getItem("reload");

    if (!reload) {
      localStorage.setItem("reload", 1);
      window.location.reload();
    } else if (reload != 1) {
      localStorage.setItem("reload", 1);
      window.location.reload();
    }

    this.setStore();
  },

  methods: {
    async setStore() {
      try {
        let result = await api.get("admin/verify");

        this.user_name = result.data.name;
        this.user_email = result.data.email;
        this.user_image = result.data.profile_image;
        this.user_type = result.data.type;
        this.user_status = result.data.status;

        this.utoken = localStorage.getItem("loginkey");

        this.$store.commit("getname", this.user_name);
        this.$store.commit("getkey", this.utoken);
        this.$store.commit("getimage", this.user_image);
        this.$store.commit("gettype", this.user_type);
        this.$store.commit("getemail", this.user_email);
        this.$store.commit("getstatus", this.user_status);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped></style>
