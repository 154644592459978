<template>
  <div :key="$route.path">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
          <div class="page-title-right">
            <h4 class="mb-sm-0 font-size-18">
              All Videos of {{ $route.params.title }}
            </h4>
            <a @click="$router.go(-1)" class="btn btn-primary w-md mybutton myleft">
              <box-icon name="chevrons-left" type="solid" color="#ffffff" class="myicon"></box-icon>Back
            </a>
          </div>

          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item">
                <!-- <a href="">Services</a> -->
                <router-link to="/home/all_libraries" class="waves-effect">
                  All Libraries List
                </router-link>
              </li>
              <li class="breadcrumb-item active">All Videos' List</li>
            </ol>
            <div>
              <button class="btn btn-primary waves-effect btn-label waves-light mybutton"
                @click="showModal($route.params.id)">
                Add Video
              </button>
            </div>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
    <!-- end page title -->
    <div class="video-section">
      <div class="container">
        <div class="row">

          <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-3" v-for="item in all_videos" :key="item">

            <div style="background-color: gray; padding: 2px 8px;">
              <div>
                Is Active ?
                <toggle-button @change="changeStatus(item)" :value="(item.status == 'Active') ? true : false" />
              </div>
              <div>
                Is paid ?
                <toggle-button @change="changeSubscriptionStatus(item)" :value="(item.is_paid == 1) ? true : false" />
              </div>
            </div>

            <div class="videobox" @click="showModal03(item.id, item.name, item.path)">
              <div class="card">
                <div class="card-body">
                  <div>
                    <div class="titlebox">
                      <h2>{{ item.name }}</h2>
                      <p>{{ item.description }}</p>
                    </div>
                    <!-- <img :src="item.image" alt=''/> -->
                    <VueVideoThumbnail :video-src="item.path" show-play-button="false"
                      :snapshot-at-duration-percent="70" :width="375" :height="250" :chunks-quantity="10">
                      <!-- <template #snapshot="{ snapshot }">
                        <img v-if="snapshot" :src="snapshot" alt="snapshot" />
                      </template> -->
                    </VueVideoThumbnail>
                    <div class="actionbox">
                      <div class="dropdown">
                        <a href="#" class="dropdown-toggle card-drop dropdown" data-bs-toggle="dropdown"
                          aria-expanded="false">
                          <box-icon color="#ffffff" name="dots-horizontal-rounded"></box-icon>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end dropdown-content w3-animate-zoom">
                          <a href="#" @click="
                            showModal04(
                              item.id,
                              item.name,
                              item.path,
                              item.description,
                              item.cat_id,
                              item.duration,
                              item.tag
                            ),
                              sendme()
                          " class="listbox w3-bar-item w3-button">
                            <box-icon name="edit" class="myicon"></box-icon>
                            Update Video
                          </a>
                          <a href="#" @click="remove_vid(item.id)" class="listbox">
                            <box-icon type="solid" name="message-alt-x" class="myicon"></box-icon>Delete Video
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="clear"></div>
        </div>
      </div>
      <div class="clear"></div>
    </div>

  </div>
</template>

<script>
import VueVideoThumbnail from "vue-video-thumbnail";
import ModalVideobox from "../Modals/ModalVideobox.vue";
import ModalAddVideos from "../Modals/ModalAddVideos.vue";
import ModalEditVideo from "../Modals/ModalEditVideo.vue";
import api from "../../config/api.js";

//import $ from "jquery";
export default {
  name: "Videos",
  components: { VueVideoThumbnail },
  data() {
    return {
      is_active: true,
      all_videos: [],
      videos_cat_id: "",
      catname: "",
      // snapshot: "",
      // frontModal: true,
      showPlayButton: false,
      front_modal: false,
      content: '',
    };
  },

  created() {
    this.$root.$refs.Videos = this;
    this.videos_cat_id = this.$route.params.id;
    this.$watch(
      () => this.$route.params,
      () => {
        this.videos_cat_id = this.$route.params.id;
        //this.$router.go();
        this.allVideos();
        //this.sayname(name)
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
  mounted() {
    //this.allaudios();
    this.videos_cat_id = this.$route.params.id;
    this.allVideos();
    // this.frontModal =true;
    // this.$root.$refs.ModalVideobox.callme(this.frontModal);
  },

  methods: {
    async changeStatus(item) {
      item.status = !item.status
      try {
        let result = await api.post(`/admin/video-status?id=${item.id}`);
        this.$toast.success(result.data.message);
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    async changeSubscriptionStatus(item){
      item.is_paid = !item.is_paid
      try {
        const data = {
          "type" : "Video",
          "id" : item.id
        }
        let result = await api.post('/admin/toggle-subscription-status',data);
        this.$toast.success(result.data.message);
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },


    async allVideos() {
      try {
        let result = await api.get(`/admin/video-cat-videos`, {
          params: { video_cat_id: this.videos_cat_id },
        });

        this.all_videos = result.data.videos;

        //console.log(result);
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },
    async remove_vid(id) {
      this.front_modal = true;

      try {
        this.$swal({
          title: "Are you sure?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete it!",
          cancelButtonText: "No, cancel!",
          confirmButtonClass: "btn btn-success",
          cancelButtonClass: "btn btn-danger",
          buttonsStyling: true,
        }).then(async (res) => {
          if (res.isConfirmed) {
            let result = await api.delete(`/admin/delete-video/` + id);
            this.allVideos();
            this.$toast.success(result.data.message);
            // console.log("I am in Delete mode", result);
            //this.onLoad();
          }
        });
      } catch (error) {
        this.$toast.error(error.response.data.message);
      }
    },

    showModal(catid) {
      this.$modal.show(
        ModalAddVideos,
        { catid: catid },
        { draggable: true },
        {
          "before-close": () => {
            this.onLoad();
          },
        }
      );
    },

    showModal03(aid, aname, apath) {
      if (!this.front_modal)
        this.$modal.show(
          ModalVideobox,
          {
            aid: aid,
            aname: aname,
            apath: apath,
          },
          { draggable: true },
          {
            "before-close": () => {
              //this.allAudios(libid);
            },
          }
        );
      this.front_modal = false;
    },

    showModal04(aid, aname, apath, adesc, catid, videolong, tag) {
      this.front_modal = true;
      // this.$root.$refs.ModalVideobox.callme(this.frontModal);
      this.$modal.show(
        ModalEditVideo,
        {
          aid: aid,
          aname: aname,
          apath: apath,
          adesc: adesc,
          catid: catid,
          videolog: videolong,
          atags: tag
        },
        { draggable: true },
        {
          "before-close": () => {
            //this.allAudios(libid);
          },
        }
      );
    },
    sendme() {
      this.$root.$refs.ModalVideobox.callme(this.frontModal);
    },
    onload(id) {
      this.allHypnosisAudios(id);
    },
    callme() {
      this.allVideos();
    },
    sayname(name) {
      this.catname = name;
    },
    snapshotReady() {
      // console.log("Snape Ready");
    },
  },
};
</script>

<style scoped>
#audios_table h5 {
  /* line-height: 65px; */
  margin-top: 15px;
}

#audios_table .switch {
  margin-top: 25px;
}

#audios_table .dropdown {
  margin-top: 15px;
}

.video-section {
  margin-top: 30px;
}

.videobox {
  /* padding: 12px; */
  border: solid 1px #dadada;
  /* min-width: 375px;
  min-height: 250px; */
  position: relative;
  /* float: left; */
  border-radius: 8px;
  margin: 15px 0;
}

.videobox .titlebox {
  position: absolute;
  max-width: 320px;
  z-index: 99;
  left: 8px;
  bottom: 40px;
  padding: 6px 15px 4px 15px;
  background: rgba(0, 0, 0, 0.6);
  -webkit-border-top-right-radius: 8px;
  -webkit-border-bottom-right-radius: 8px;
  -moz-border-radius-topright: 8px;
  -moz-border-radius-bottomright: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #fff;
}

.videobox .titlebox h2 {
  color: #fff;
  font-weight: 400;
  font-size: 17px;
}

.videobox .titlebox p {
  color: #fff;
  line-height: 17px;
  font-size: 13px;
}

.videobox .actionbox {
  position: absolute;
  z-index: 99;
  right: 10px;
  top: 0;
  display: block;
  padding: 5px;
}

.videobox img {
  z-index: 9999999999;
  width: 375px;
  height: 250px;
  max-width: 100%;
}

.videobox .actionbox .dropdown-content {
  top: 0px;
  z-index: 999999999999999999 !important;
}

.snapshot-generator {
  position: relative;
  display: block;
  z-index: 9;
  width: 100%;
  height: 100%;
}

/* .snapshot-generator__hidden {
    height: 250px;
    width: 375px;
    overflow: visible !important; 
     position: relative !important;
    bottom: 0;
    right: 0;
    z-index: 999999999999999999999999999999;
} */

.card .card-body {
  padding: 8px !important;
}

.clear {
  clear: both;
}

.videobox .dropdown .box-icon {
  color: #fff !important;
  z-index: 99999;
}

.dropdown box-icon {
  line-height: 40px;
}

.dropdown .box-icon {
  line-height: 40px;
}

@layer utilities {

  /* high-priority layers win by default */
  .maroon {
    color: maroon;
  }
}

/* @import "~vue2-editor/dist/vue2-editor.css"; */

/* Import the Quill styles you want */
/* @import '~quill/dist/quill.core.css'; */
/* @import '~quill/dist/quill.bubble.css'; */
/* @import '~quill/dist/quill.snow.css'; */
</style>
