<template ref="libraries">
  <div>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      @click="closebtn()"
    >
      &times;
    </button>
    <!-- <button @click="$emit('close')">Close</button> -->
    <div class="modalTop"><h3>Update Tune</h3></div>
    <div class="modalDesc">
      <div class="row">
        <div class="col-md-4"><label>Title</label></div>
        <div class="col-md-8">
         
            <input
              type="text"
              placeholder="Title"
              v-model="title"
            />
            <p class="err" v-if="aud_name_err != ''">{{ aud_name_err }}</p>
        </div>
      </div>
      <br /><br />
      <div class="row">
        <div class="col-md-4"><label>Artist</label></div>
        <div class="col-md-8">
            <textarea
              type="catname"
              placeholder="Artist"
              v-model="artist"
            />
            <p class="err" v-if="aud_artist_err != ''">{{ aud_artist_err }}</p>
        </div>
      </div>

       <!-- <div class="row">
        <div class="col-md-4"><label>Audio Image</label></div>
        <div class="col-md-8">
          <input type="file" placeholder="" @change="onAudioImageChange" />
          <div id="preview">
            <img v-if="audio_image_url" :src="audio_image_url" width="200" height="200"/>
          </div>
        </div>
      </div> -->


      <div class="row imgboxx">
        <div class="col-md-4"><label>Image</label></div>
        <div class="col-md-8">
          <div class="imgbox">
            <img
              v-if="audio_image_url == ''"
              :src="'/assets/media/services/base.jpg'"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <img
              v-if="audio_image_url !== ''"
              :src="audio_image_url"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <i class="fas fa-camera" @click="$refs.audio_image.click()"></i>
            <div class="clear"></div>
            <p class="err" v-if="audio_image_url_err != ''">{{ audio_image_url_err }}</p>
            <span v-if="audio_image_url == ''" previmg="false">
              <!-- image is required -->
            </span>

            <input
              class="ml"
              type="file"
              accept="image/jpeg"
              @change="onAudioImageChange"
              ref="audio_image"
              style="display: none"
            />
          </div>
        </div>
      </div>


      <div class="row imgboxx">
        <div class="col-md-4"><label>Audio File</label></div>
        <div class="col-md-8">
          <div class="imgbox">
            <!-- <img
              v-if="audioFile == ''"
              :src="'/assets/images/services/base.jpg'"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            /> -->

            <!-- <img
              v-if="previewImage !== ''"
              :src="previewImage"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            /> -->

            <!-- <audio controls v-if="audioFile !== ''">
              <source :src="audioFile" type="audio/mpeg" />
            </audio> -->
            <AudioFile :key="count" :audioSource="audioFile" />

            <i class="fas fa-microphone" @click="$refs.audioFile.click()"></i>

            <div class="clear"></div>

            <input
              class="ml"
              type="file"
              accept="audio/mpeg"
              @change="uploadAudio"
              ref="audioFile"
              style="display: none"
            />
            <p class="err" v-if="audioFile_err != ''">{{ audioFile_err }}</p>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-md-4">Playback Duration</div>
        <div class="col-md-8" v-if="file_duration != 0">
          {{ file_duration }} Seconds
        </div>
      </div> -->
      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-8">
          <button
            class="btn btn-primary w-md mybutton myleft submitbtn"
            v-on:click="updateAudio"
          >
            Submit
          </button>
        </div>
        <div v-if="error" class="alert alert-danger">
          {{ error }}
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <progress-bar :value="progress" :options="options" />
        </div>
      </div>

    </div>
    <div class="modalFooter"></div>
  </div>
</template>

<script>
import api from "../../config/api";
// import useVuelidate from "@vuelidate/core";
// import { required } from "@vuelidate/validators";
import AudioFile from "../Admin/AudioFile.vue";
export default {
  name: "ModalEditAudio",

  components: {
    AudioFile,
  },
  props: {
    tid: Number,
    ttitle: String,
    turl: String,
    tartist: String,
    timage : String,
  },

  data() {
    return {
      aud_name_err : "",
      aud_artist_err : "",
      audio_image_url_err : "",
      audioFile_err : "",

      title: "",
      audio_image: "",
      audio_image_url: "",
      artist: "",
      audioFile: "",
      current_id: "",
      cat_id: "",
      file_duration: "",
      count: 0,
      progress: "0",
      options: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#333333',
          inverted: false
        },
        layout: {
          height: 35,
          width: 500,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line'
        }
      }
    };
  },

  watch: {
    title(newVal) {
      if (newVal != "") {
        this.aud_name_err = "";
      }
    },
  
    artist(newVal) {
      if (newVal != "") {
        this.aud_artist_err = "";
      }
    },

  },

  mounted() {
    //this.onLoad();
    this.title = this.ttitle;
    this.current_id = this.tid;
    this.artist = this.tartist;
    this.audioFile = this.turl;
    this.cat_id = this.tid;
    this.audio_image_url = this.timage;
    // this.file_duration = this.audlong;
  },

  methods: {
     onAudioImageChange(e){
      this.audio_image_url_err = "";
      const file = e.target.files[0];
      var status = this.checkFileType(file, 'image1')
      if (status) {
      this.audio_image = file;
      this.audio_image_url = URL.createObjectURL(file)
      }
    },
    // onAudioCoverImageChange(e){
    //   const file = e.target.files[0];
    //   this.audio_cover_image = file;
    //   this.audio_cover_image_url = URL.createObjectURL(file)
    // },

    checkFileType(file, num) {
      var types = ['image/jpeg', 'image/png', 'image/jpg']
      var msg = "The image must be a file of type: jpeg, jpg, png";
      if (num == 'audio') {
        console.log('under chk');
        types = ['audio/mpeg', 'audio/ogg', 'audio/wav', 'audio/mp4', 'audio/mp3']
        if (!types.includes(file.type)) {
          this.audioFile_err = "The audio must be a file of type: mp3, wav, ogg"
          this.audioFile = ""
          return false;
        }
        return true;
      } else {
        if (!types.includes(file.type)) {
          if (num == 'image1') {
            this.audio_image_url_err = msg
            this.audio_image = "";
            this.audio_image_url = ""
          } else if (num == 'image2') {
            this.audio_cover_image_url_err = msg
            this.audio_cover_image = ""
            this.audio_cover_image_url = ""
          }
          return false;
        }
        return true;
      }
    },


    
    async updateAudio() {
      if (this.artist != ""
        && this.title != ""
        && this.audio_image_url != ""
        && this.audioFile != ""
      ) {
      try {
        // const credentials = {
        //   title: this.state.sname,
        //   category_id: this.current_id,
        // };

        const formData03 = new FormData();
        formData03.append("title", this.title);
        formData03.append("id", this.current_id);
        formData03.append("artist", this.artist);
      
        if (this.$refs.audioFile.files[0]) {
          formData03.append("url", this.$refs.audioFile.files[0]);
        }

        if(this.audio_image){
          formData03.append("image", this.audio_image);
        }

        let resultss = await api.post("/admin/update-med-ringtone", formData03, {
          onUploadProgress: function (progressEvent) {
            this.progress = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        });
      
        if (resultss.status == 200) {
          this.$toast.success(resultss.data.message);
          //this.$root.$refs.Libraries.callme(this.lib_id);
          //this.$root.$refs.LibrarysAudios.onload(this.lib_id);
          this.$root.$refs.Hypnosis.callme();

          const list = document.getElementById("modals-container");
          list.removeChild(list.firstElementChild);

          this.value02 = false;
        }
      } catch (error) {
        this.progress = 0;
        this.$toast.error(error.response.data.message);
      }
    } else {
        this.aud_name_err = (this.title == "" && this.aud_name_err == "") ? "title is required" : this.aud_name_err
        this.aud_artist_err = (this.artist == "" && this.aud_artist_err == "") ? "Artist is required" : this.aud_artist_err
        this.audioFile_err = (this.audioFile == "" && this.audioFile_err == "") ? "Audio file is required" : this.audioFile_err;
        this.audio_image_url_err = (this.audio_image_url == "" && this.audio_image_url_err == "") ? "Image is required" : this.audio_image_url_err
      }
    },


    uploadAudio(e) {
      this.audioFile_err = "";
      this.audioFile = "";
      const audio = e.target.files[0];
      var status = this.checkFileType(audio, 'audio');
      if (status) {
      // console.log(audio, "My Audio");
      const reader = new FileReader();
      reader.readAsDataURL(audio);
      // reader.onload = (e) => {

      // };
      var audio_el = document.createElement("audio");

      reader.onload = (e) => {
        this.audioFile = e.target.result;
        audio_el.src = e.target.result;
        audio_el.addEventListener(
          "loadedmetadata",
          () => {
            // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
            // var duration = audio_el.duration;
            // this.file_duration = duration;

            // example 12.3234 seconds
            // console.log(
            //   "The duration of the song is of: " +
            //     this.file_duration +
            //     " seconds"
            // );
            // Alternatively, just display the integer value with
            // parseInt(duration)
            // 12 seconds
          },
          
        );
      };

      //reader.readAsDataURL(audio);
      console.log(this.file_duration, "Song Duration");
      }
    },

    // onLoad() {
    //   this.updateAudio();
    // },

    // uploadImage02(e) {
    //   const image = e.target.files[0];
    //   const reader = new FileReader();
    //   reader.readAsDataURL(image);
    //   reader.onload = (e) => {
    //     this.vpath = e.target.result;
    //     console.log(this.vpath);
    //   };
    // },
    closebtn() {
      // this.$root.$refs.Hypnosis.callme();

      const list = document.getElementById("modals-container");
      list.removeChild(list.firstElementChild);
    },
  },
};
</script>

<style scoped>
.vm--modal {
  height: auto !important;
}
.modalTop {
  width: 100%;
  background: #041e4f;

  color: #fff;
}
.modalTop h3 {
  font-weight: 600;
  color: #fff;
  font-size: 1.42188rem;
  text-align: center;
  padding: 15px 0;
}

.modalDesc {
  padding: 20px 40px;
  max-height: 700px;
  overflow-y: scroll;
}
.modalDesc label {
  display: inline-block;
  line-height: 34px;
  font-size: 15px;
}
.modalDesc input {
  background: #fff;
  border: solid 1px #dadada;
  border-radius: 8px;
  padding: 8px 16px;
  color: #333;
  outline: none;
  width: 100%;
}
.submitbtn {
  margin-top: 30px;

  text-align: center;
  border-radius: 6px;
  padding: 8px 16px;

  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
.modalFooter {
  position: absolute;
  width: 100%;
  bottom: 0;
  width: 100%;
  background: #041e4f;
  height: 20px;
}
.fas {
  font-size: 30px;
  margin-top: 15px;
}
</style>
