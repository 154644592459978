<template>
  <div>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      @click="closebtn()"
    >
      &times;
    </button>
    <!-- <button @click="$emit('close')">Close</button> -->
    <div class="modalTop"><h3>Update Content</h3></div>
    <div class="modalDesc">
      <div class="row">
        <div class="col-md-4"><label>Title</label></div>
        <div class="col-md-8">
          
            <input
              type="text"
              placeholder="Title"
              v-model="video_name"
            />
            <p class="err" v-if="aud_name_err != ''">{{ aud_name_err }}</p>
        </div>
      </div>
      <br /><br />
      <div class="row">
        <div class="col-md-4"><label>Desctription</label></div>
        <div class="col-md-8">
         
            <textarea
              type="catname"
              placeholder="Description"
              v-model="video_desc"
            />
            <p class="err" v-if="aud_desc_err != ''">{{ aud_desc_err }}</p>
        </div>
      </div>


      <div class="row imgboxx" v-if="type == 'video'">
        <div class="col-md-4"><label>Video File</label></div>
        <div class="col-md-8">
          <div class="imgbox">

            <!-- <video id="video-preview" width="100" height="75" controls>
              <source :src="videoFile" type="video/mp4" />
            </video>

            <i class="fas fa-video" @click="$refs.videoFile.click()"></i>

            <div class="clear"></div>

            <input class="ml" type="file" accept="video/mp4, web, ogg, wav." @change="uploadVideo" ref="videoFile"
              style="display: none" />
              <p class="err" v-if="videoFile_err != ''">{{ videoFile_err }}</p> -->

              <uploadVideos :fileType="['mp4']" @success="uploadSuccess" file_type_url="videos" />
          </div>
        </div>
      </div>

      <span v-else>
        <div class="row imgboxx">
        <div class="col-md-4"><label>Audio File</label></div>
        <div class="col-md-8">
          <div class="imgbox">

             <AudioFile :key="count" :audioSource="videoFile" />

            <i class="fas fa-microphone" @click="$refs.videoFile.click()"></i>

            <div class="clear"></div>

            <input class="ml" type="file" accept="audio/mpeg" @change="uploadAudio" ref="videoFile"
              style="display: none" />
              <p class="err" v-if="audioFile_err != ''">{{ audioFile_err }}</p>
          </div>
        </div>
      </div>

      

      <div class="row imgboxx">
        <div class="col-md-4"><label>Image</label></div>
        <div class="col-md-8">
          <div class="imgbox">
            <img
              v-if="audio_image_url == ''"
              :src="'/assets/media/services/base.jpg'"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <img
              v-if="audio_image_url !== ''"
              :src="audio_image_url"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <i class="fas fa-camera" @click="$refs.audio_image.click()"></i>
            <div class="clear"></div>
            <p class="err" v-if="audio_image_url_err != ''">{{ audio_image_url_err }}</p>
            <span v-if="audio_image_url == ''" previmg="false">
              <!-- Cover image is required -->
            </span>

            <input
              class="ml"
              type="file"
              accept="image/jpeg"
              @change="onAudioImageChange"
              ref="audio_image"
              style="display: none"
            />
          </div>
        </div>
      </div>
      <br /><br />
      <div class="row">
        <div class="col-md-4"><label>Artist</label></div>
        <div class="col-md-8">
          <input type="catname" placeholder="Artist" v-model="aartist" />
          <p class="err" v-if="artist_err != ''">{{ artist_err }}</p>
        </div>
      </div>
      <br /><br />
    </span>
      
      <div class="row" v-if="type == 'video'">
        <div class="col-md-4">Playback Duration</div>
        <div class="col-md-8" v-if="file_duration != 0">
          {{ file_duration }}
        </div>
      </div>

      <Tag @updateTag="updateTagFromChild" :oldtags="tags"/>

      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-8">
          <p class="err" v-if="tags_err != ''">{{ tags_err }}</p>
          <button
            class="btn btn-primary w-md mybutton myleft submitbtn"
            v-on:click="submitFunctions"
          >
            Submit
          </button>
        </div>
        <div v-if="error" class="alert alert-danger">
          {{ error }}
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <progress-bar :value="progress" :options="options" />
        </div>
      </div>

    </div>
    <div class="modalFooter"></div>
  </div>
</template>

<script>
import api from "../../config/api";
// import useVuelidate from "@vuelidate/core";
// import { required } from "@vuelidate/validators";
import Tag from "../../components/Tag.vue";
import AudioFile from "../Admin/AudioFile.vue";
import uploadVideos from "../../components/UploadVideos.vue";

export default {
  name: "ModalEditVideo",
  components: {
    AudioFile,
    Tag,
    uploadVideos,
  },
  props: {
    aid: Number,
    aname: String,
    apath: String,
    adesc: String,
    catid: Number,
    videolog: String,
    artist : String,
    image : String,
    type: String,
    atags : Array,
    // clickme: {
    //   type: Function,
    // },
  },

  data() {
    return {
      pdfFile_err : "",
      audioFile_err: "",
      audio_image_url_err: "",
      audio_cover_image_url_err: "",
      videoFile_err: "",
      aud_desc_err: "",
      aud_name_err: "",
      tags_err: "",
      artist_err: "",
      author_err: "",
      file_duration_err : "",

      audio_image_url: "",
      video_name: "",
      video_desc: "",
      videoFile: "",
      current_id: "",
      cat_id: "",
      file_duration: "",
      file_duration_db : "",
      video_outside: "",
      video_outside02: "",
      aartist : "",
      aimage : "",
      audio_image : "",
      count: 0,
      error: "",
      tags: [],
      progress: "0",
      options: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#333333',
          inverted: false
        },
        layout: {
          height: 35,
          width: 500,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line'
        }
      },
      video_file_path: null,
      video_thumbnail: null,
    };
  },

   watch: {
    video_name(newVal) {
      if (newVal != "") {
        this.aud_name_err = "";
      }
    },
    video_desc(newVal) {
      if (newVal != "") {
        this.aud_desc_err = "";
      }
    },
    aartist(newVal) {
      if (newVal != "") {
        this.artist_err = "";
      }
    },
   
    tags(newVal) {
      if (newVal.length > 0) {
        this.tags_err = "";
      }
    }
  },

  mounted() {
    //this.onLoad();
    console.log('cbv');
    console.log(this.apath);
    this.video_name = this.aname;
    this.current_id = this.aid;
    this.video_desc = this.adesc;
    this.videoFile = this.apath;
    this.aartist = this.artist;
    // this.aimage = this.image;
    this.audio_image_url = this.image;
    this.cat_id = this.catid;
    if(this.type == "audio" || this.type == "video"){
      const videotime = new Date(this.videolog * 1000).toISOString().slice(14, 19); 
      this.file_duration = videotime;
    }else{
      this.file_duration = this.videolog;
    }
    this.file_duration_db = this.videolog;
    this.video_outside = this.outside;
    this.video_outside02 = this.outside02;

    console.log(this.artist);
    console.log(this.image);
    console.log(this.apath);

    // console.log("mounted from modal edit series");
    // console.log(this.aid);
  },

   created() {
    this.$root.$refs.ModalEditVideo = this;
    if(this.atags)
      this.tags = this.atags;
  },

  methods: {

    uploadSuccess(data) {
      this.file_duration_db = data?.duration ?? "0";
      this.video_file_path = data.path ?? null;
      this.video_thumbnail = data.thumbnail ?? null;
      // console.log(data);
      // this.ready_to_submit = true;
    },

    submitFunctions(){
      if(this.type == "video"){
        this.updateVideo();
      }if(this.type == "audio"){
        this.updateAudio();
      }
    },

    updateTagFromChild(arr){
      this.tags = arr;
    },

    // uploadAudio(e) {
    //   this.audioFile_err = "";
    //   this.audioFile = "";
    //   const audio = e.target.files[0];
    //   var status = this.checkFileType(audio, 'audio');
    //   if (status) {
    //   const reader = new FileReader();
    //   reader.readAsDataURL(audio);
    //   var audio_el = document.createElement("audio");
    //   reader.onload = (e) => {
    //     this.videoFile = e.target.result;
    //     audio_el.src = e.target.result;
    //     audio_el.addEventListener(
    //       "loadedmetadata",
    //       () => {
    //         var duration = audio_el.duration;
    //         const audio_file = new Date(duration * 1000).toISOString().slice(14, 19);
    //         this.file_duration = audio_el.duration;
    //         this.file_duration_db = audio_file;
    //       },  
    //     );
    //   };
    //   }
    // },


    uploadAudio(e) {
      this.audioFile_err = "";
      this.videoFile = "";
      const audio = e.target.files[0];
      var status = this.checkFileType(audio, 'audio');
      if (status) {
      console.log(audio, "My Audio");
      const reader = new FileReader();
      reader.readAsDataURL(audio);
      var audio_el = document.createElement("audio");
      reader.onload = (e) => {
        this.videoFile = e.target.result;
        audio_el.src = e.target.result;
        audio_el.addEventListener("loadedmetadata", () => {
          var duration = audio_el.duration;
            const audio_file = new Date(duration * 1000).toISOString().slice(14, 19);
            this.file_duration = audio_el.duration;
            this.file_duration_db = audio_file;
        });
      };

      reader.readAsDataURL(audio);
      //console.log(this.file_duration, "Song Duration");
      this.count++;
      }
    },

    onAudioImageChange(e){
      this.audio_image_url_err = "";
      const file = e.target.files[0];
      var status = this.checkFileType(file, 'image1')
      if (status) {
      this.audio_image = file;
      this.audio_image_url = URL.createObjectURL(file)
      }
    },


    async updateVideo() {
      if (this.video_name != ""
        && this.video_desc != ""
        && this.aartist != ""
        && this.videoFile != ""
        && this.tags.length != 0
      ) {
      try {
        // const credentials = {
        //   title: this.state.sname,
        //   category_id: this.current_id,
        // };

        // const formData03 = new FormData();
        // formData03.append("name", this.video_name);
        // formData03.append("id", this.current_id);
        // formData03.append("cat_id", this.cat_id);
        // formData03.append("description", this.video_desc);

        // if(typeof this.tags == 'string'){
        //   formData03.append('tag[]', this.tags);
        // }else{
        //   for (var i = 0; i < this.tags.length; i++) {
        //     formData03.append('tag[]', this.tags[i]);
        //   }
        // }

        // if(this.aartist){
        //   formData03.append("artist", this.aartist);
        // }
        
        // if(this.audio_image){
        //   formData03.append("image", this.audio_image);
        // }

        // formData03.append("duration", this.file_duration_db);
        // if (this.$refs.videoFile.files[0]) {
        //   formData03.append("path", this.$refs.videoFile.files[0]);
        // }


        let payload = {
            name: this.video_name,
            id : this.current_id,
            cat_id: this.cat_id,
            description: this.video_desc,
            artist: this.aartist,
            duration: this.file_duration_db,
            video_thumbnail: this.video_thumbnail,
            video_file_path: this.video_file_path,
            tag: this.tags,
            type: this.type,
          };
        
        let resultss = await api.post("/admin/update-series", payload, {
          onUploadProgress: function (progressEvent) {
            this.progress = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        });
        if (resultss.status == 200) {
          this.$toast.success(resultss.data.message);
          this.$root.$refs.Videos.callme();

          const list = document.getElementById("modals-container");
          list.removeChild(list.firstElementChild);

          this.value02 = false;
        }
      } catch (error) {
        this.progress = 0;
        this.$toast.error(error.response.data.message);
      }
    }
      else {
        this.aud_name_err = (this.video_name == "" && this.aud_name_err == "") ? "title is required" : this.aud_name_err
        this.aud_desc_err = (this.video_desc == "" && this.aud_desc_err == "") ? "Description is required" : this.aud_desc_err
        this.artist_err = (this.aartist == "" && this.artist_err == "") ? "Artist is required" : this.artist_err
        this.videoFile_err = (this.videoFile == "" && this.videoFile_err == "") ? "Video file is required" : this.videoFile_err;
        this.tags_err = (this.tags.length == 0 && this.tags_err == '') ? "Tags require" : this.tags_err
      }
    },


    async updateAudio() {
      console.log(this.video_name);
      console.log(this.video_desc);
      console.log(this.aartist);
      console.log(this.tags);
      console.log(this.audioFile_err);
      console.log(this.audio_image_url_err);

      if (this.video_name != ""
        && this.video_desc != ""
        && this.aartist != ""
        && this.audioFile_err == ""
        && this.audio_image_url_err == ""
        // && this.audio_image_url2 != ""
        // && this.audio_image_url != ""
        && this.tags.length != 0
      ) {
      try {
        // const credentials = {
        //   title: this.state.sname,
        //   category_id: this.current_id,
        // };

        const formData03 = new FormData();
        formData03.append("name", this.video_name);
        formData03.append("id", this.current_id);
        formData03.append("cat_id", this.cat_id);
        formData03.append("description", this.video_desc);

        if(typeof this.tags == 'string'){
          formData03.append('tag[]', this.tags);
        }else{
          for (var i = 0; i < this.tags.length; i++) {
            formData03.append('tag[]', this.tags[i]);
          }
        }

        if(this.aartist){
          formData03.append("artist", this.aartist);
        }
        
        if(this.audio_image){
          formData03.append("image", this.audio_image);
        }

        formData03.append("duration", this.file_duration_db);
        if (this.$refs.videoFile.files[0]) {
          formData03.append("path", this.$refs.videoFile.files[0]);
        }
        
        let resultss = await api.post("/admin/update-series", formData03, {
          onUploadProgress: function (progressEvent) {
            this.progress = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        });
        if (resultss.status == 200) {
          this.$toast.success(resultss.data.message);
          this.$root.$refs.Videos.callme();

          const list = document.getElementById("modals-container");
          list.removeChild(list.firstElementChild);

          this.value02 = false;
        }
      } catch (error) {
        this.progress = 0;
        this.$toast.error(error.response.data.message);
      }
    }
      else {
        this.aud_name_err = (this.video_name == "" && this.aud_name_err == "") ? "title is required" : this.aud_name_err
        this.aud_desc_err = (this.video_desc == "" && this.aud_desc_err == "") ? "Description is required" : this.aud_desc_err
        // this.audioFile_err = (this.videoFile == "" && this.audioFile_err == "") ? "Audio file is required" : this.audioFile_err;
        this.artist_err = (this.aartist == "" && this.artist_err == "") ? "Artist is required" : this.artist_err
        this.tags_err = (this.tags.length == 0 && this.tags_err == '') ? "Tags require" : this.tags_err
        // this.audio_image_url_err = (this.audio_image == "" && this.audio_image_url_err == "") ? "Image is required" : this.audio_image_url_err
      }
    },



    async uploadVideo(e) {
      this.videoFile_err = "";
      this.videoFile = "";
      this.videoFile = e.target.files[0];
      var status = this.checkFileType(this.videoFile, 'video');
      if (status) {
      const res = await this.generateVideoThumbnail(this.videoFile);
      this.imagepath = res;
      this.blob = this.bast64ToFile(res);
      let video = document.getElementById('video-preview');
      let reader = new FileReader();
      reader.readAsDataURL(this.videoFile);
      const self = this;
      reader.addEventListener('load', function () {
        video.src = reader.result;
        var i = setInterval(function () {
          if (video.readyState > 0) {
            // var minutes = parseInt(video.duration / 60, 10);
        
            const audio_file = new Date(video.duration * 1000).toISOString().slice(14, 19);
            self.file_duration = audio_file;
            this.file_duration_db = video.duration;

            clearInterval(i);
          }
        }.bind(self), 200);
      });
    }
    },



    checkFileType(file, num) {
      if (num == 'video') {
        var types = ['video/mp4', 'video/3gp', 'video/mov', 'video/avi', 'video/mkv']
        if (!types.includes(file.type)) {
          this.videoFile_err = "The video must be a file of type: mp4, mov, avi"
          this.videoFile = ""
          return false;
        }
        return true;
      } else if (num == "audio") {
        types = ['audio/mpeg', 'audio/ogg', 'audio/wav', 'audio/mp4', 'audio/mp3']
        if (!types.includes(file.type)) {
          this.audioFile_err = "The audio must be a file of type: mp3, wav, ogg"
          this.videoFile = ""
          return false;
        }
        return true;
      } else if (num == "pdf") {
        types = ['application/pdf']
        if (!types.includes(file.type)) {
          this.pdfFile_err = "The file must be a file of type: pdf"
          this.pdf_file = ""
          return false;
        }
        return true;
      }
      else {
        types = ['image/jpeg', 'image/png', 'image/jpg']
        var msg = "The image must be a file of type: jpeg, jpg, png";
        if (!types.includes(file.type)) {
          if (num == 'image1') {
            this.audio_image_url_err = msg
            this.audio_image = "";
            this.audio_image_url = ""
          } else if (num == 'image2') {
            this.audio_cover_image_url_err = msg
            this.audio_cover_image = ""
            this.audio_image_url2 = ""
          }
          return false;
        }
        return true;
      }
    },


    generateVideoThumbnail(file) {
      return new Promise((resolve) => {
        const canvas = document.createElement("canvas");
        const video = document.createElement("video");

        // this is important
        video.autoplay = true;
        video.muted = true;
        video.src = URL.createObjectURL(file);

        video.onloadeddata = () => {
          let ctx = canvas.getContext("2d");

          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;

          ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
          video.pause();
          // const new_file = canvas.toDataURL("image/png");
          // //

          // var arr = new_file.split(","),
          //   mime = arr[0].match(/:(.*?);/)[1],
          //   bstr = atob(arr[1]),
          //   n = bstr.length,
          //   u8arr = new Uint8Array(n);

          // while (n--) {
          //   u8arr[n] = bstr.charCodeAt(n);
          // }

          // let blob = new File([u8arr], "filename", { type: mime });
          return resolve(canvas.toDataURL("image/png"));
          // return resolve({ blob, image: new_file });
        };
      });
    },

    bast64ToFile(new_file) {
      var arr = new_file.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], "filename", { type: mime });
    },
    onLoad() {
      this.updateAudio();
    },

    // uploadImage02(e) {
    //   const image = e.target.files[0];
    //   const reader = new FileReader();
    //   reader.readAsDataURL(image);
    //   reader.onload = (e) => {
    //     this.vpath = e.target.result;
    //     console.log(this.vpath);
    //   };
    // },
    closebtn() {
      // this.$root.$refs.Videos.callme();

      const list = document.getElementById("modals-container");
      list.removeChild(list.firstElementChild);
    },
  },
};
</script>

<style scoped>
.vm--container {
  z-index: 9999999999999999 !important;
}

.vm--modal {
  height: auto !important;
}
.modalTop {
  width: 100%;
  background: #041e4f;

  color: #fff;
}
.modalTop h3 {
  font-weight: 600;
  color: #fff;
  font-size: 1.42188rem;
  text-align: center;
  padding: 15px 0;
}

.modalDesc {
  padding: 20px 40px;
  max-height: 700px;
  overflow-y: scroll;
}


.modalDesc label {
  display: inline-block;
  line-height: 34px;
  font-size: 15px;
}
.modalDesc input {
  background: #fff;
  border: solid 1px #dadada;
  border-radius: 8px;
  padding: 8px 16px;
  color: #333;
  outline: none;
  width: 100%;
}
.submitbtn {
  margin-top: 30px;

  text-align: center;
  border-radius: 6px;
  padding: 8px 16px;

  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
.modalFooter {
  position: absolute;
  width: 100%;
  bottom: 0;
  width: 100%;
  background: #041e4f;
  height: 20px;
}
.fas {
  font-size: 30px;
  margin-top: 15px;
}
</style>
