<template>
  <!--begin::Footer-->
  <div class="footer py-4 d-flex flex-lg-column" id="kt_footer">
    <!--begin::Container-->
    <div
      class="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between"
    >
      <!--begin::Copyright-->
      <div class="text-dark order-2 order-md-1">
        <span class="text-muted fw-bold me-1">Copyright {{ cyear }} © </span>
        <a href="/" target="_blank" class="text-gray-800 text-hover-primary"
          >believe.</a
        >
      </div>
      <!--end::Copyright-->
      <!--begin::Menu-->

      <div class="menu menu-gray-600 menu-hover-primary fw-bold order-1">
        Designed & Developed by
        <a href="https://www.anideos.com/" target="_blank"> Anideos</a>
      </div>

      <!--end::Menu-->
    </div>
    <!--end::Container-->
    <!--begin::Scrolltop-->
    <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
      <!--begin::Svg Icon | path: icons/duotune/arrows/arr066.svg-->
      <span class="svg-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <rect
            opacity="0.5"
            x="13"
            y="6"
            width="13"
            height="2"
            rx="1"
            transform="rotate(90 13 6)"
            fill="black"
          />
          <path
            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
            fill="black"
          />
        </svg>
      </span>
      <!--end::Svg Icon-->
    </div>
    <!--end::Scrolltop-->
  </div>
  <!--end::Footer-->
</template>

<script>
export default {
  name: "Footer",
  computed: {
    cyear() {
      let currentYear = new Date().getFullYear();
      return currentYear;
    },
  },
};
</script>

<style scoped></style>
