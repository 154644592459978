var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',[_c('header',{staticClass:"tabs"},[_c('ul',_vm._l((_vm.tabs),function(tab,index){return _c('li',{key:index},[_c('div',{staticClass:"nav-item",class:{ 'is-active': tab.isActive },style:({
            background: 'url(' + tab.bgimage + ')',
          }),on:{"click":function($event){_vm.selectTab(tab), _vm.allAudios(tab.tab_id)}}},[_c('div',{staticClass:"title_tab"},[_vm._v(" "+_vm._s(tab.name)+" ")]),_c('div',{staticClass:"is_showbox",class:{ 'is-show': tab.isActive }},[_c('div',{staticClass:"dropdown"},[_c('a',{staticClass:"dropdown-toggle card-drop dropdown",attrs:{"href":"#","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('box-icon',{attrs:{"name":"dots-horizontal-rounded"}})],1),_c('div',{staticClass:"dropdown-menu dropdown-menu-end dropdown-content w3-animate-zoom"},[_c('a',{staticClass:"listbox w3-bar-item w3-button",attrs:{"href":"#"},on:{"click":function($event){_vm.showModal04(
                      tab.tab_libid,
                      tab.tab_libname,
                      tab.bgimage,
                      tab.tab_catid
                    ),
                      _vm.selectTab(tab)}}},[_c('box-icon',{staticClass:"myicon",attrs:{"name":"edit"}}),_vm._v(" Update Library")],1),_c('a',{staticClass:"listbox",attrs:{"href":"#"},on:{"click":function($event){return _vm.remove_lib(tab.tab_libid)}}},[_c('box-icon',{staticClass:"myicon",attrs:{"type":"solid","name":"message-alt-x"}}),_vm._v("Delete Now")],1)])])])]),_c('div',{staticClass:"ishidden",class:{ 'is-show': tab.isActive }},[_c('a',{staticClass:"listbox",attrs:{"href":"#"},on:{"click":function($event){return _vm.showModal02(tab.tab_id)}}},[_c('box-icon',{staticClass:"myicon",attrs:{"type":"logo","name":"audible"}}),_vm._v(" Add Audio")],1)])])}),0)]),_c('section',{staticClass:"tabs-details"},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }