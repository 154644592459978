<template>
  <div :key="$route.path">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-sm-flex align-items-center justify-content-between"
        >
          <div class="page-title-right">
            <h4 class="mb-sm-0 font-size-18">
              All Subcategories 
            <!-- - {{ $route.params.title }} -->
            </h4>
            <a
              @click="$router.go(-1)"
              class="btn btn-primary w-md mybutton myleft"
            >
              <box-icon
                name="chevrons-left"
                type="solid"
                color="#ffffff"
                class="myicon"
              ></box-icon
              >Back</a
            >
          </div>

          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <!-- <li class="breadcrumb-item">
                <router-link to="/home/all_libraries" class="waves-effect">
                  All Libraries List
                </router-link>
              </li> -->
              <!-- <li class="breadcrumb-item active">All Scripts' List</li> -->
            </ol>
            <div>
              <button
                class="btn btn-primary waves-effect btn-label waves-light mybutton"
                @click="showModal()"
              >
                Add Subcategory
              </button>
            </div>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
    <!-- end page title -->

    <div class="row">
      <div class="col-lg-12">
        <div class="">
          <div class="table-responsive">
            <div class="card">
              <div class="card-body">
                <table
                  class="table table-hover table-bordered"
                  id="audios_table"
                >
                  <thead>
                    <tr class="frown">
                      <th scope="col" style="width: 5%">#</th>
                      <th scope="col" style="width: 10%">Image</th>
                      <th scope="col" style="width: 20%">Title</th>
                      <th scope="col" style="width: 50%">Description</th>
                      <th scope="col" style="width: 50%">Timestamp</th>
                      <th scope="col" style="width: 50%">Status</th>
                      <th scope="col" style="width: 5%">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in all_scripts" :key="index">
                      <td scope="row" class="sorting_1">{{ index + 1 }}</td>
                      <td>
                        <img :src="item.image" alt="" />
                      </td>
                      <td>
                        <h5>
                          {{ item.name }}
                        </h5>
                      </td>

                      <td>
                        <p>
                          {{ item.description }}
                        </p>
                      </td>

                      <td>
                        <p>
                          {{ item.created_at | moment("dddd, MMMM Do YYYY, h:mm:ss a") }}
                        </p>
                      </td>

                      <td>
                        <toggle-button @change="changeStatus(item)" :value="(item.status == 'Active') ? true : false" />
                      </td>

                      <td>
                        <!-- <div class="w3-dropdown-click mydropdown"> -->
                        <div class="dropdown">
                          <a
                            href="#"
                            class="dropdown-toggle card-drop"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <box-icon
                              name="dots-horizontal-rounded"
                              class="myddicon"
                            ></box-icon>
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-end dropdown-content w3-animate-zoom"
                            id="myDIV"
                          >
                        
                            <div>
                              <a
                                @click="navigate_series(item.id, item.name)"
                                class="listbox w3-bar-item w3-button"
                              >
                                <box-icon name="edit" class="myicon"></box-icon>
                                All Scripts</a
                              >
                              <a
                                href="#"
                                @click="
                                  showModal03(
                                    item.id,
                                    item.name,
                                    item.description,
                                    item.image,
                                    item.type,
                                  )
                                "
                                class="listbox w3-bar-item w3-button"
                              >
                                <box-icon name="edit" class="myicon"></box-icon>
                                Update</a
                              >
                              <a
                                href="#"
                                @click="remove_aud(item.id)"
                                class="listbox w3-bar-item w3-button"
                                ><box-icon
                                  type="solid"
                                  name="message-alt-x"
                                  class="myicon"
                                ></box-icon
                                >Delete</a
                              >
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import ModalEditScript from "../Modals/ModalEditScript.vue";
import ModalAddScript from "../Modals/ModalAddScript.vue";
import api from "../../config/api.js";
import $ from "jquery";
export default {
  name: "AllScripts",
  data() {
    return {
      is_active: true,
      all_scripts: [],
      hypnosis_cat_id: "",
      catname: "",
    };
  },

  created() {
    this.$root.$refs.Scripts = this;
    this.hypnosis_cat_id = this.$route.params.id;

    this.$watch(
      () => this.$route.params,
      () => {
        this.hypnosis_cat_id = this.$route.params.id;
        //this.$router.go();
        this.allScripts();
        //this.sayname(name)
        // console.log(this.$route.params)
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
  mounted() {
    //this.allaudios();
    this.hypnosis_cat_id = this.$route.params.id;
    this.allScripts();
  },

  methods: {

    async changeStatus(item){
      item.status = !item.status
      try {
        let result = await api.post(`/admin/script-status?id=${item.id}`);
        this.$toast.success(result.data.message);
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    navigate_series(id, title) {
      this.$router.push({
        name: "chapters",
        params: { id: id, title },
      });
    },

    async allScripts() {
      try {
        let result = await api.get(`/admin/all-scripts`);
        setTimeout(() => {
          $("#audios_table").DataTable();
        }, 0);
        this.all_scripts = result.data.categories;
        // console.log("abc");
        // console.log(this.all_scripts);
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    async remove_aud(id) {
      try {
        this.$swal({
          title: "Are you sure to delete this Subcategory and all its scripts ?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete it!",
          cancelButtonText: "No, cancel!",
          confirmButtonClass: "btn btn-success",
          cancelButtonClass: "btn btn-danger",
          buttonsStyling: true,
        }).then(async (res) => {
          if (res.isConfirmed) {
            let result = await api.delete(`/admin/delete-script/` + id);
            this.allScripts();
            this.$toast.success(result.data.message);
            // console.log("I am in Delete mode", result);
            //this.onLoad();
          }
        });
      } catch (error) {
        this.$toast.error(error.response.data.message);
      }
    },

    showModal() {
      this.$modal.show(
        ModalAddScript,
        {},
        { draggable: true },
        {
          "before-close": () => {
            this.onLoad();
          },
        }
      );
    },



    showModal03(aid, aname, adesc, image, type) {
      this.$modal.show(
        ModalEditScript,
        {
          sid: aid,
          sname: aname,
          sdesc: adesc,
          simage: image,
          stype : type,
        },
        { draggable: true },
        {
          "before-close": () => {
            //this.allAudios(libid);
          },
        }
      );
    },

    onload() {
      this.allScripts();
    },
    callme() {
      this.allScripts();
    },
    sayname(name) {
      this.catname = name;
    },
  },
};
</script>

<style scoped>
#audios_table h5 {
  /* line-height: 65px; */
  margin-top: 15px;
}
#audios_table .switch {
  margin-top: 25px;
}
#audios_table .dropdown {
  margin-top: 15px;
}
</style>
