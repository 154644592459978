<template>
  <div>
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-sm-flex align-items-center justify-content-between"
        >
          <div class="page-title-right">
            <h4 class="mb-sm-0 font-size-18">Users List</h4>
            <a
              @click="$router.go(-1)"
              class="btn btn-primary w-md mybutton myleft"
            >
              <box-icon
                name="chevrons-left"
                type="solid"
                color="#ffffff"
                class="myicon"
              ></box-icon
              >Back</a
            >
          </div>
          <div class="page-title-right">
            <!-- <ol class="breadcrumb m-0">
              <li class="breadcrumb-item">
                <router-link to="/home/servicecategories" class="waves-effect">
                  Service Categories
                </router-link>
              </li>
              <li class="breadcrumb-item active">Users List</li>
            </ol> -->
          </div>
        </div>
      </div>

      <!-- end row -->

      <!-- Begin Page Content -->

      <!-- /.container-fluid -->
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="">
          <div class="table-responsive">
            <div class="card">
              <div class="card-body">
                <table
                  class="table project-list-table table-nowrap align-middle table-borderless"
                  id="user_table"
                >
                  <thead class="vthead">
                    <tr class="frown">
                      <th scope="col" style="width: 5%">#</th>
                      <th scope="col" style="width: 15%">Picture</th>
                      <th scope="col" style="width: 35%">User Name</th>
                      <th scope="col" style="width: 20%">User's Email</th>
                      <th scope="col" style="width: 20%">Subscribe</th>
                      <th scope="col" style="width: 10%">Status</th>
                      <th scope="col" style="width: 15%">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in users" :key="index">
                      <th scope="row">{{ index + 1 }}</th>
                      <td>
                        <img :src="item.profile_image" alt="" />
                      </td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.email }}</td>
                      <td>{{ item.is_subscribed }}</td>
                      <td>
                        <label class="switch">
                          <input
                            type="checkbox"
                            :checked="item.status == 'Active'"
                            @change="changeStatusnew(item.id)"
                          />
                          <span class="slider round"></span>
                        </label>
                        <!-- <button
                          @click="changeStatusnew(item._id)"
                          class="badge"
                          v-bind:class="{
                            'bg-success': item.status == 'active',
                            'badge-danger': item.status == 'in-active',
                          }"
                        >
                          {{ item.status }}
                        </button> -->
                      </td>
                      <!-- <td v-else>
                        <span class="badge badge-danger">{{
                          item.status
                        }}</span>
                      </td> -->
                      <td>
                        <div class="dropdown">
                          <a
                            href="#"
                            class="dropdown-toggle card-drop"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <box-icon
                              name="dots-horizontal-rounded"
                              class="myddicon"
                            ></box-icon>
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-end dropdown-content w3-animate-zoom"
                            id="myDIV"
                          >
                            <a
                              href="#"
                              class="listbox w3-bar-item w3-button"
                              @click="showModal03(item.id)"
                            >
                              <box-icon
                                name="show"
                                type="solid"
                                flip="horizontal"
                                color="#1c1c1c"
                                class="myicon"
                              ></box-icon>
                              View</a
                            >

                            <a
                              href="#"
                              class="listbox w3-bar-item w3-button"
                              @click="showModal04(item.id)"
                            >
                              <box-icon
                                name="show"
                                type="solid"
                                flip="horizontal"
                                color="#1c1c1c"
                                class="myicon"
                              ></box-icon>
                              Set discount</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalUsers from "../Modals/ModalUsers.vue";
import ModalUserDiscount from "../Modals/ModalUserDiscount.vue";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import api from "../../config/api.js";
import $ from "jquery";

export default {
  name: "Users",

  data() {
    return {
      users: "",
      productAttr: "",
      status: "",
    };
  },

  created() {
    this.$root.$refs.Users = this;
  },

  mounted() {
    this.onLoad();
    //this.$modal.show("example");
  },

  methods: {

    showModal04(id) {
      this.$modal.show(
        ModalUserDiscount,
        {
          id
        },
        { draggable: true },
        {
          "before-close": () => {
            //this.allAudios(libid);
          },
        }
      );
    },

    
    async onLoad() {
      let results = await api.get(`/admin/users`);

      this.users = results.data.all_users;
      this.status = results.data.all_users[0].status;
      // console.log("Users", results);
      setTimeout(() => {
        $("#user_table").DataTable();
      }, 0);
    },

    showModal03(id) {
      this.$modal.show(ModalUsers, { id: id });
    },

    async changeStatusnew(id) {
      try {
        let changeresult = await api.put(`/admin/user-status`, {
          user_id: id,
        });
        // console.log(changeresult.status, "Status");
        if (changeresult.status == 200) {
          this.$toast.success(changeresult.data.message);
          if (this.status === "Active") {
            this.checked = true;
          } else if (this.status === "InActive") {
            this.checked = false;
          }
          this.onLoad();
          // console.log(this.status);
          //this.$toast.success("sdjfkie");
        }

        this.onLoad();
      } catch (error) {
        this.$toast.error(error.response.data.message);
      }
    },
  },
};
</script>

<style scoped>
.mybutton {
  margin-top: 15px;
}
/* .switch {
   pointer-events: none; 
} */
.modal-backdrop {
  opacity: 0 !important;
  display: none !important;
}
.card-body img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  border: solid 2px #fff;
}
/* .listbox {
  float: left;
  margin-bottom: 8px;
  border-bottom: solid 1px #dadada;
  width: 100%;
  padding: 8px 0px;
  line-height: 30px;
} */
.myicon {
  width: 24px;
  height: 24px;
  float: left !important;
  margin-right: 8px;
  margin-top: -2px;
}
</style>
