<template>
  <div>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      @click="closebtn()"
    >
      &times;
    </button>
    <div class="modalTop"><h3>User Profile</h3></div>
    <div class="modalDesc">
      <div class="row">
        <div class="col-md-12">
          <div class="imgboxx">
            <img
              v-if="user_pic"
              :src="user_pic"
              alt=""
              class="img-thumb"
              height="64"
            />
          </div>
        </div>
      </div>
      <div class="clear"></div>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <h4>Personal Information</h4>
          <div class="row inner">
            <div class="col-sm-6 col-md-3 col-lg-3">
              <strong>Name:</strong>
            </div>

            <div class="col-sm-6 col-md-9 col-lg-9">
              {{ user_name }}
            </div>
          </div>
          <div class="row inner">
            <div class="col-sm-6 col-md-3 col-lg-3">
              <strong>Email :</strong>
            </div>

            <div class="col-sm-6 col-md-9 col-lg-9">
              {{ user_email }}
            </div>
          </div>
          <div class="row inner">
            <div class="col-sm-6 col-md-3 col-lg-3">
              <strong>Type :</strong>
            </div>

            <div class="col-sm-6 col-md-9 col-lg-9">
              {{ user_type }}
            </div>
          </div>
          <div class="row inner">
            <div class="col-sm-6 col-md-3 col-lg-3">
              <strong>Status :</strong>
            </div>

            <div class="col-sm-6 col-md-9 col-lg-9">
              {{ user_status }}
            </div>
          </div>

          <div class="row inner" v-if="subscription_name != ''">
            <div class="col-sm-6 col-md-3 col-lg-3">
              <strong>Plan Name :</strong>
            </div>

            <div class="col-sm-6 col-md-9 col-lg-9">
              {{ subscription_name }}
            </div>
          </div>

          <div class="row inner" v-if="subscription_start != ''">
            <div class="col-sm-6 col-md-3 col-lg-3">
              <strong>Subscription Start:</strong>
            </div>

            <div class="col-sm-6 col-md-9 col-lg-9">
              {{ subscription_start | moment('ll')}}
            </div>
          </div>

          <div class="row inner" v-if="subscription_end != ''">
            <div class="col-sm-6 col-md-3 col-lg-3">
              <strong>Subscription End :</strong>
            </div>

            <div class="col-sm-6 col-md-9 col-lg-9">
              {{ subscription_end | moment('ll')}}
            </div>
          </div>

          <div class="row inner" v-if="subscription_status != ''">
            <div class="col-sm-6 col-md-3 col-lg-3">
              <strong>Subscription Status :</strong>
            </div>

            <div class="col-sm-6 col-md-9 col-lg-9">
              {{ subscription_status }}
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="modalFooter"></div>
  </div>
</template>

<script>
import api from "../../config/api";
export default {
  name: "ModalUsers",
  props: {
    id: Number,
  },
  data() {
    return {
      user_name: "",
      user_email: "",

      user_status: "",
      user_pic: "",
      user_id: "",
      user_type: "",
      subscription_name : "",
      subscription_start : "",
      subscription_end : "",
      subscription_status : "",
    };
  },

  methods: {
    async getUserDetail(user_id) {
      try {
        let result = await api.get("/admin/user-details/?user_id=" + user_id);

        console.log("Result", result);

        this.user_name = result.data["user_detail"].name;
        this.user_email = result.data["user_detail"].email;
        this.user_pic = result.data["user_detail"].profile_image;
        this.user_status = result.data["user_detail"].status;
        this.user_type = result.data["user_detail"].type;
        if(result.data["user_detail"].subscripted != null){
          this.subscription_name = result.data["user_detail"].subscripted?.plans?.title;
          this.subscription_end = result.data["user_detail"].subscripted?.trial_ends_at;
          this.subscription_start = result.data["user_detail"].subscripted?.created_at;
          this.subscription_status = "Active"
        }
        this.$toast.success(result.data.message);
        // const list = document.getElementById("modals-container");
        //      list.removeChild(list.firstElementChild);
        //console.log("User Name", this.user_pic);
      } catch (error) {
        this.$toast.error(error.response.data.message);
        console.log(this.user_name);
      }
    },

    closebtn() {
      //this.$root.$refs.ServiceCategories.callme();
      const list = document.getElementById("modals-container");
      list.removeChild(list.firstElementChild);
    },
  },

  mounted() {
    this.user_id = this.id;

    this.getUserDetail(this.user_id);
  },
};
</script>

<style scoped>
#modals-container .vm--modal {
  height: 600px !important;
}
.modalTop {
  width: 100%;
  background: #041e4f;

  color: #fff;
}
.modalTop h3 {
  font-weight: 600;
  color: #fff;
  font-size: 1.42188rem;
  text-align: center;
  padding: 15px 0;
}

.modalDesc {
  padding: 20px 40px;
  max-height: 700px;
  overflow-y: scroll;
}
.modalDesc label {
  display: inline-block;
  line-height: 34px;
  font-size: 15px;
}
.modalDesc input {
  background: #fff;
  border: solid 1px #dadada;
  border-radius: 8px;
  padding: 8px 16px;
  color: #333;
  outline: none;
  width: 100%;
}
.submitbtn {
  margin-top: 30px;

  text-align: center;
  border-radius: 6px;
  padding: 8px 16px;

  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
.modalFooter {
  position: absolute;
  width: 100%;
  bottom: 0;
  width: 100%;
  background: #041e4f;
  height: 20px;
}
/* .fas {
  font-size: 30px;
  margin-top: 15px;
} */

.imgboxx {
  margin-top: 15px;
  display: table;
  margin: 0 auto 20px auto;
}

.imgboxx label {
  margin-top: 20px;
  line-height: 24px;
}

/* .imgbox {
  margin-right: 15px;
  display: table;
}
.imgbox img {
  margin-right: 15px;
  float: left;
}
.imgbox .fas {
  float: right;
} */
/* .glamproDetail .img-thumbnail {
  float: left;
} */
/* .img-thumbnail {
  margin-right: 15px;
} */
.clear {
  clear: both;
}
.row.inner {
  border-top: solid 1px #dadada;
  border-bottom: solid 1px #fff;
  line-height: 24px;
  padding: 10px 0;
  width: 100%;
}
.areabox {
  float: left;
  margin-right: 6px;
}
.row.inner::first-child {
  border-top: none;
}
.row.inner::last-child {
  border-bottom: none;
}
/* .row h4 {
  margin-bottom: 30px;
} */
</style>
