<template>
  <div>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      @click="closebtn()"
    >
      &times;
    </button>
    <!-- <button @click="$emit('close')">Close</button> -->
    <div class="modalTop"><h3>Update Subcategory</h3></div>
    <div class="modalDesc">
      <div class="row">
        <div class="col-md-4"><label>Subcategory Name</label></div>
        <div class="col-md-8">
          <div
                class="form-group"
                :class="{ error: v$.cat_name.$errors.length }"
              >
          <input
            type="catname"
            placeholder="Categroy Name"
            v-model="v$.cat_name.$model"
          />
          <div
                  class="input-errors"
                  v-for="(error, index) of v$.cat_name.$errors"
                  :key="index"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
                </div>
        </div>
      </div>
      <div class="row imgboxx">
        <div class="col-md-4"><label>Audio Name</label></div>
        <div class="col-md-8">
           
          <div class="imgbox">
            <img
              v-if="previewImage == ''"
              :src="'/assets/media/services/base.jpg'"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <img
              v-if="previewImage !== ''"
              :src="previewImage"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />
            <i class="fas fa-camera" @click="$refs.imageFile.click()"></i>
            <div class="clear"></div>
            <span v-if="previewImage == ''" previmg="false">
              image is required
            </span>

            <input
              class="ml"
              type="file"
              accept="image/jpeg"
              @change="uploadImage"
              ref="imageFile"
              style="display: none"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-8">
          <button
            class="btn btn-primary w-md mybutton myleft submitbtn"
            v-on:click="updateCategory"
            :disabled="v$.$invalid"
          >
            Submit
          </button>
        </div>
      </div>
       <div v-if="error" class="alert alert-danger">
              {{ error }}
            </div>
    </div>
    <div class="modalFooter"></div>
  </div>
</template>

<script>
import api from "../../config/api";
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";

export default {
  name: "EditCategory",
  props: {
    aid: Number,
    name: String,
    audpath: String,
    libid: Number,
    id: Number,
    image: String,
    status: String,
  },

  data() {
    return {
      cat_name: "",
      previewImage: "",
      current_id: "",
      cat_status: "",
    };
  },

   setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      
        cat_name: {
          required,
          min: minLength(4),
        },
     
    };
  },


  mounted() {
    //this.onLoad();
    //console.log(this.name, this.image);
    this.cat_name = this.name;
    this.current_id = this.id;
    this.previewImage = this.image;
    this.cat_status = this.status;
    // this.previewImage02 =
    //   "https://api-believe.staginganideos.com/" + this.previewImage;
    console.log(this.previewImage);
  },

  methods: {
    async updateCategory() {
      try {
        // const credentials = {
        //   title: this.state.sname,
        //   category_id: this.current_id,
        // };

        const formDatan = new FormData();
        formDatan.append("name", this.cat_name);
        formDatan.append("id", this.current_id);
        formDatan.append("status", this.cat_status);

        if (this.$refs.imageFile.files[0]) {
          console.log("sending image");
          formDatan.append("image", this.$refs.imageFile.files[0]);
        }

        // let resultss = await api.post("/admin/edit-category", credentials);

        //let resultss = await api.post("/admin/update-audio", formDatan);
        let resultss = await api.post("/admin/update-category", formDatan);
        //console.log(resultss, "Hello");
        if (resultss.status == 200) {
          this.$toast.success(resultss.data.message);
          this.$root.$refs.ServiceCategories.callme();
          const list = document.getElementById("modals-container");
          list.removeChild(list.firstElementChild);

          this.value02 = false;
        }
      } catch (error) {
        this.$toast.error(error.response.data.message);
      }
    },
    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
        //console.log(this.previewImage);
      };
    },

    onLoad() {
      this.updateCategory();
    },

    closebtn() {
      // this.$root.$refs.ServiceCategories.callme();
      const list = document.getElementById("modals-container");
      list.removeChild(list.firstElementChild);
    },

    // uploadImage02(e) {
    //   const image = e.target.files[0];
    //   const reader = new FileReader();
    //   reader.readAsDataURL(image);
    //   reader.onload = (e) => {
    //     this.vpath = e.target.result;
    //     console.log(this.vpath);
    //   };
    // },
    // closebtn() {
    //   console.log("dsif");
    //   document
    //     .getElementById("modals-container")
    //     .classList.contains("democlass");
    // },
  },
};
</script>

<style scoped>
.vm--modal {
  height: auto !important;
}
.modalTop {
  width: 100%;
  background: #041e4f;

  color: #fff;
}
.modalTop h3 {
  font-weight: 600;
  color: #fff;
  font-size: 1.42188rem;
  text-align: center;
  padding: 15px 0;
}
/* .close {
  position: absolute;
  z-index: 99999999999999;
  right: -1px;
  top: -1px;
  visibility: visible;
  overflow: visible;
} */
.modalDesc {
  padding: 20px 40px;
  max-height: 700px;
  overflow-y: scroll;
}
.modalDesc label {
  display: inline-block;
  line-height: 34px;
  font-size: 15px;
}
.modalDesc input {
  background: #fff;
  border: solid 1px #dadada;
  border-radius: 8px;
  padding: 8px 16px;
  color: #333;
  outline: none;
  width: 100%;
}
.submitbtn {
  margin-top: 30px;
  /* background: #019df5; */
  /* color: #fff; */
  text-align: center;
  border-radius: 6px;
  padding: 8px 16px;
  /* border: solid 2px #0a7ebf; */
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
.modalFooter {
  position: absolute;
  width: 100%;
  bottom: 0;
  width: 100%;
  background: #041e4f;
  height: 20px;
}
/* .fas {
  font-size: 30px;
  margin-top: 15px;
} */

/* .glamproDetail .img-thumbnail {
  float: left;
} */
</style>
