<template ref="libraries">
  <div>
    <button type="button" class="close" data-dismiss="modal" @click="closebtn()">
      &times;
    </button>
    <!-- <button @click="$emit('close')">Close</button> -->
    <div class="modalTop">
      <h3>Update Background</h3>
    </div>
    <div class="modalDesc">
      <div class="row">
        <div class="col-md-4"><label>Title</label></div>
        <div class="col-md-8">
            <input type="text" placeholder="Image Title" v-model="aud_name" />
            <p class="err" v-if="title_error != ''">{{ title_error }}</p>
        </div>
      </div>
      <br /><br />
  
      <div class="row imgboxx">
        <div class="col-md-4"><label>Event Image</label></div>
        <div class="col-md-8">
          <div class="imgbox">
            <img
              v-if="audio_image_url == ''"
              :src="'/assets/media/services/base.jpg'"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <img
              v-if="audio_image_url !== ''"
              :src="audio_image_url"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <i class="fas fa-camera" @click="$refs.audio_image.click()"></i>
            <div class="clear"></div>
            <p class="err" v-if="image_error != ''">{{ image_error }}</p>
            <span v-if="audio_image_url == ''" previmg="false">
              <!-- image is required -->
            </span>

            <input
              class="ml"
              type="file"
              accept="image/jpeg/gif"
              @change="onAudioImageChange"
              ref="audio_image"
              style="display: none"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-8">
          <button class="btn btn-primary w-md mybutton myleft submitbtn" v-on:click="updateAudio">
            Submit
          </button>
        </div>
        <div v-if="error" class="alert alert-danger">
          {{ error }}
        </div>
      </div>
    </div>
    <div class="modalFooter"></div>
  </div>
</template>

<script>
import api from "../../config/api";
// import useVuelidate from "@vuelidate/core";
// import { required, minLength } from "@vuelidate/validators";
// import MeditationBackground from "../Admin/MeditationBackground.vue";
export default {
  name: "ModalEditMeditationBackground",

  // components: {
  //   MeditationBackground,
  // },
  props: {
    id: Number,
    title: String,
    background_image: String,
  },

  data() {
    return {
      title_error : "",
      image_error : "",

      aud_id: "",
      audio_image: "",
      audio_image_url: "",
      aud_name: "",
    };
  },

  mounted() {
    this.aud_name = this.title;
    this.aud_id = this.id;
    this.audio_image_url = this.background_image;
  },

  watch: {
    aud_name(newVal) {
      if (newVal != "") {
        this.title_error = "";
      }
    },
    audio_image_url(newVal) {
      if (newVal != "") {
        this.image_error = "";
      }
    },
  },

  methods: {
    onAudioImageChange(e){
      const file = e.target.files[0];
      this.audio_image = file;
      this.audio_image_url = URL.createObjectURL(file)
    },
    async updateAudio() {
      try {
        this.image_error = "";
        this.title_error = "";
        const formData03 = new FormData();
        formData03.append("title", this.aud_name);
        formData03.append("id", this.aud_id);
        if(this.audio_image){
          formData03.append("background_image", this.audio_image);
        }
        let resultss = await api.post("/admin/update-med-background", formData03);
        if (resultss.status == 200) {
          this.$toast.success(resultss.data.message);
          this.$root.$refs.MeditationBackground.callme();
          const list = document.getElementById("modals-container");
          list.removeChild(list.firstElementChild);
          this.value02 = false;
        }
      } catch (error) {
        if (typeof error?.response?.data?.errors?.title !== "undefined") {
          this.title_error = error.response.data.errors.title[0];
        }
        if (typeof error?.response?.data?.errors?.background_image !== "undefined") {
          this.image_error = error.response.data.errors.background_image[0];
        }   
        this.$toast.error("Invalid content");
      }
    },
    uploadAudio(e) {
      this.audioFile = "";
      const audio = e.target.files[0];
      console.log(audio, "My Audio");
      const reader = new FileReader();
      reader.readAsDataURL(audio);
      // reader.onload = (e) => {

      // };
      var audio_el = document.createElement("audio");

      reader.onload = (e) => {
        this.audioFile = e.target.result;
        audio_el.src = e.target.result;
        audio_el.addEventListener("loadedmetadata", () => {
          // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
          var duration = audio_el.duration;
          this.file_duration = duration;
        });
      };

      reader.readAsDataURL(audio);
      //console.log(this.file_duration, "Song Duration");
      this.count++;
    },

    onLoad() {
      this.updateAudio();
    },
    closebtn() {
      // this.$root.$refs.MeditationBackground.callme();

      const list = document.getElementById("modals-container");
      list.removeChild(list.firstElementChild);
    },
  },
};
</script>

<style scoped>
.vm--modal {
  height: auto !important;
}
.modalTop {
  width: 100%;
  background: #041e4f;

  color: #fff;
}
.modalTop h3 {
  font-weight: 600;
  color: #fff;
  font-size: 1.42188rem;
  text-align: center;
  padding: 15px 0;
}

.modalDesc {
  padding: 20px 40px;
  max-height: 700px;
  overflow-y: scroll;
}
.modalDesc label {
  display: inline-block;
  line-height: 34px;
  font-size: 15px;
}
.modalDesc input {
  background: #fff;
  border: solid 1px #dadada;
  border-radius: 8px;
  padding: 8px 16px;
  color: #333;
  outline: none;
  width: 100%;
}
.submitbtn {
  margin-top: 30px;

  text-align: center;
  border-radius: 6px;
  padding: 8px 16px;

  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
.modalFooter {
  position: absolute;
  width: 100%;
  bottom: 0;
  width: 100%;
  background: #041e4f;
  height: 20px;
}
.fas {
  font-size: 30px;
  margin-top: 15px;
}
</style>
