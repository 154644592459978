<template>
  <div>
     <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-sm-flex align-items-center justify-content-between"
        >
          <div class="page-title-right">
            <h4 class="mb-sm-0 font-size-18">All Libraries' List</h4>
            <a
              @click="$router.go(-1)"
              class="btn btn-primary w-md mybutton myleft"
            >
              <box-icon
                name="chevrons-left"
                type="solid"
                color="#ffffff"
                class="myicon"
              ></box-icon
              >Back</a
            >
          </div>

          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item">
                <!-- <a href="">Services</a> -->
                <router-link to="/home/servicecategories" class="waves-effect">
                  Categories
                </router-link>
              </li>
              <li class="breadcrumb-item active">All Libraries List</li>
            </ol>
            <div>
              <!-- <button
                class="btn btn-primary waves-effect btn-label waves-light mybutton"
                @click="showModal()"
              >
                Add Library
              </button> -->
            </div>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
    <!-- end page title -->

     <div class="row">
      <div class="col-lg-12">
        <div class="">
          <div class="table-responsive">
            <div class="card">
              <div class="card-body">
                <table
                  class="table table-hover table-bordered"
                  id="library_table"
                >
                  <thead>
                    <tr class="frown">
                      <th scope="col" style="width: 5%">#</th>
                      <th scope="col" style="width: 20%">Image</th>
                      <th scope="col" style="width: 50%">Library Name</th>

                      <th scope="col" style="width: 10%">Status</th>
                      <th scope="col" style="width: 15%">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in all_libraries" :key="index">
                      <td scope="row" class="sorting_1">{{ index + 1 }}</td>
                      <td>
                        <img :src="item.image" alt="" />
                      </td>
                      <td>
                        <h5 class="text-truncate font-size-14">
                          <router-link to="#" class="text-dark">{{
                            item.name
                          }}</router-link>
                        </h5>
                        <!-- <p class="text-muted mb-0 text-ellipse-250">
                          {{ item.name }}
                        </p> -->
                      </td>

                      <td>
                        <label class="switch">
                          <input
                            type="checkbox"
                            :checked="item.status == 'Active'"
                            @change="changeStatusnew(item.id)"
                          />
                          <span class="slider round"></span>
                        </label>
                      </td>

                      <td>
                        <!-- <div class="w3-dropdown-click mydropdown"> -->
                        <div class="dropdown">
                          <a
                            href="#"
                            class="dropdown-toggle card-drop"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <box-icon
                              name="dots-horizontal-rounded"
                              class="myddicon"
                            ></box-icon>
                          </a>
                          <!-- <button @click="myFunction()" class="w3-button"> -->
                          <!-- <box-icon
                              name="dots-horizontal-rounded"
                              class="myddicon"
                            ></box-icon> -->
                          <!-- </button> -->
                          <!-- <div
                            id="Demo"
                            class="w3-dropdown-content w3-bar-block w3-card-4 w3-animate-zoom"
                          > -->
                          <!-- <div
                            id="myDIV"
                            class="dropdown-content w3-animate-zoom"
                          > -->
                          <div
                            class="dropdown-menu dropdown-menu-end dropdown-content w3-animate-zoom"
                            id="myDIV"
                          >
                            <!-- <div
                              class="dropdown-menu dropdown-menu-end dropdown-content"
                            > -->
                            <div>
                              <router-link
                                :to="'libraries/' + item.category_id"
                                class="listbox w3-bar-item w3-button"
                                
                              >
                                <box-icon
                                  name="library"
                                  class="myicon"
                                ></box-icon>
                                Library's Category</router-link
                              >

                              <router-link
                                :to="'librarys_audios/' + item.id"
                                class="listbox w3-bar-item w3-button"
                                
                                
                              >
                                <box-icon
                                  name="podcast"
                                  class="myicon"
                                ></box-icon>
                                Library's Audios</router-link
                              >
                              <a
                                href="#"
                                @click="
                                  showModal02(
                                    item.id,
                                    item.name,
                                    item.image,                                    
                                    item.category_id
                                  )
                                "

                                 
                                class="listbox w3-bar-item w3-button"
                              >
                                <box-icon name="edit" class="myicon"></box-icon>
                                Update Library</a
                              >
                              <a
                                href="#"
                                @click="remove_lab(item.id)"
                                class="listbox w3-bar-item w3-button"
                                ><box-icon
                                  type="solid"
                                  name="message-alt-x"
                                  class="myicon"
                                ></box-icon
                                >Delete Now</a
                              >
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import ModalAddLibrary from "../Modals/ModalAddLibrary.vue";
import ModalEditLibrary from "../Modals/ModalEditLibrary.vue";
import api from "../../config/api.js";
import $ from "jquery";
export default {
    name:"AllLibraries",
    data(){
        return{
all_libraries:[],

        }
    },

    created() {
    this.$root.$refs.AllLibraries = this;
  },

    mounted(){
      this.alllibrarieslist();
    },

    methods:{
     
        async alllibrarieslist(){
           try {
        let result = await api.get(`/admin/all-libraries`);
        this.all_libraries = result.data.all_libraries;
         setTimeout(() => {
        $("#library_table").DataTable();
      }, 0);

      
        
      } catch (error) {
        this.$toast.error(error.response.data.message);
      }
        },
      

       async changeStatusnew(id) {
      try {
        let changeresult = await api.put(`/admin/lib-status`, {
          library_id: id,
        });
        console.log(changeresult.status);
        if (changeresult.status == 200) {
          if (this.cat_status === "Active") {
            this.checked = true;
          } else if (this.cat_status === "InActive") {
            this.checked = false;
          }
          this.onLoad();
          // console.log(this.cat_status);
          //this.$toast.success("sdjfkie");
        }

        this.onLoad();
      } catch (error) {
        this.$toast.error(error.response.data.message);
      }
    },

    async remove_lab(id) {
      try {
        this.$swal({
          title: "Are you sure?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete it!",
          cancelButtonText: "No, cancel!",
          confirmButtonClass: "btn btn-success",
          cancelButtonClass: "btn btn-danger",
          buttonsStyling: true,
        }).then(async (res) => {
          if (res.isConfirmed) {
            let result = await api.delete(`/admin/delete-library/` + id);
            this.alllibrarieslist();
            this.$toast.success(result.data.message);
            // console.log("I am in Delete mode", result);
            
          }
        });
      } catch (error) {
        this.$toast.error(error.response.data.message);
      }
    },

   showModal02(libid, libname, libimage, catid) {
      //libname = this.tablibname;
      this.$modal.show(
        ModalEditLibrary,
        { libid: libid, libname: libname, libimage: libimage, catid: catid, outside:true },
        { draggable: true},
        {
          "before-close": () => {
            this.alllibrarieslist();
          },
        }
      );
    },

    showModal() {
      this.$modal.show(
        ModalAddLibrary,
        { id: this.cat_id },
        { draggable: true },
        {
          "before-close": () => {
            this.alllibrary();
          },
        }
      );
    },

    onload(){
      this.alllibrarieslist();
    },

    },

}
</script>

<style scoped>

#library_table h5 {
  line-height: 65px;
}
#library_table .switch {
  margin-top: 25px;
}
#library_table .dropdown {
  margin-top: 15px;
}
</style>

