<template>
  <!--begin::Root-->
  <div class="d-flex flex-column flex-root">
    <!--begin::Authentication - Two-stes -->
    <div
      class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
    >
      <!--begin::Content-->
      <div
        class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20"
      >
        <!--begin::Logo-->
        <a href="/" class="mb-12">
          <img
            alt="Logo"
            src="/assets/media/logos/logo@03x.png"
            class="h-100px"
          />
        </a>
        <!--end::Logo-->
        <!--begin::Wrapper-->
        <div class="w-lg-600px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
          <!--begin::Form-->
          <form
            class="form w-100 mb-10"
            novalidate="novalidate"
            id="kt_sing_in_two_steps_form"
          >
            <!--begin::Icon-->
            <div class="text-center mb-10">
              <img
                alt="Logo"
                class="mh-125px"
                src="assets/media/svg/misc/smartphone.svg"
              />
            </div>
            <!--end::Icon-->
            <!--begin::Heading-->
            <div class="text-center mb-10">
              <!--begin::Title-->
              <h1 class="text-dark mb-3">Two Step Verification</h1>
              <!--end::Title-->
              <!--begin::Sub-title-->
              <div class="text-muted fw-bold fs-5 mb-5">
                Enter the verification code we sent to
              </div>
              <!--end::Sub-title-->
              <!--begin::Mobile no-->
              <div class="fw-bolder text-dark fs-3">******7859</div>
              <!--end::Mobile no-->
            </div>
            <!--end::Heading-->
            <!--begin::Section-->
            <div class="mb-10 px-md-10">
              <!--begin::Label-->
              <div class="fw-bolder text-start text-dark fs-6 mb-1 ms-1">
                Type your 6 digit security code
              </div>
              <!--end::Label-->
              <!--begin::Input group-->

              <div class="d-flex flex-wrap flex-stack">
                <input
                  type="number"
                  data-inputmask="'mask': '9', 'placeholder': ''"
                  maxlength="1"
                  class="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
                  value="3"
                  v-model="vcode[0]"
                  required
                  ref="vcode0"
                  @keydown="onKeydown"
                  v-on:keyup="$event.target.nextElementSibling.focus()"
                />

                <input
                  type="number"
                  data-inputmask="'mask': '9', 'placeholder': ''"
                  maxlength="1"
                  class="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
                  value="0"
                  v-model="vcode[1]"
                  required
                  ref="vcode1"
                  @keydown="onKeydown"
                  v-on:keyup="$event.target.nextElementSibling.focus()"
                />

                <input
                  type="number"
                  data-inputmask="'mask': '9', 'placeholder': ''"
                  maxlength="1"
                  class="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
                  value="7"
                  v-model="vcode[2]"
                  required
                  @keydown="onKeydown"
                  v-on:keyup="$event.target.nextElementSibling.focus()"
                />

                <input
                  type="number"
                  data-inputmask="'mask': '9', 'placeholder': ''"
                  maxlength="1"
                  class="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
                  value=""
                  v-model="vcode[3]"
                  required
                  @keydown="onKeydown"
                  v-on:keyup="$event.target.nextElementSibling.focus()"
                />

                <input
                  type="number"
                  data-inputmask="'mask': '9', 'placeholder': ''"
                  maxlength="1"
                  class="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
                  value=""
                  v-model="vcode[4]"
                  required
                  @keydown="onKeydown"
                  v-on:keyup="$event.target.nextElementSibling.focus()"
                />

                <input
                  type="number"
                  data-inputmask="'mask': '9', 'placeholder': ''"
                  maxlength="1"
                  class="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
                  value=""
                  v-model="vcode[5]"
                  required
                  @keydown="onKeydown"
                  v-on:keyup="$event.target.nextElementSibling.focus()"
                />
              </div>
              <!--begin::Input group-->
            </div>
            <!--end::Section-->
            <!--begin::Submit-->
            <div class="d-flex flex-center">
              <button
                type="button"
                id="kt_sing_in_two_steps_submit"
                class="btn btn-lg btn-primary fw-bolder"
                v-on:click="sendCode"
              >
                <span class="indicator-label">Submit</span>
                <span class="indicator-progress"
                  >Please wait...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span
                ></span>
              </button>
              <br />
              <!-- <div v-if="error" class="alert alert-danger">
                {{ error }}
              </div> -->
            </div>
            <!--end::Submit-->
          </form>
          <!--end::Form-->
          <!--begin::Notice-->
          <div class="text-center fw-bold fs-5">
            <span class="text-muted me-1">Didn’t get the code ?</span>
            <a class="link-primary fw-bolder fs-5 me-1" href="/forgetPassword"
              >Resend</a
            >
            <span class="text-muted me-1">or</span>
            <a href="#" class="link-primary fw-bolder fs-5">Call Us</a>
          </div>
          <!--end::Notice-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Content-->
      <!--begin::Footer-->
      <div class="d-flex flex-center flex-column-auto p-10">
        <!--begin::Links-->
        <!-- <div class="d-flex align-items-center fw-bold fs-6">
          <a
            href="https://keenthemes.com/"
            class="text-muted text-hover-primary px-2"
            >About</a
          >
          <a
            href="mailto:support@keenthemes.com"
            class="text-muted text-hover-primary px-2"
            >Contact</a
          >
          <a
            href="https://1.envato.market/EA4JP"
            class="text-muted text-hover-primary px-2"
            >Contact Us</a
          >
        </div> -->
        <!--end::Links-->
      </div>
      <!--end::Footer-->
    </div>
    <!--end::Authentication - Two-stes-->
  </div>
  <!--end::Root-->
</template>

<script>
import api from "../config/api.js";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  name: "CodeVerify",

  data() {
    return {
      vcode: [],

      error: null,
      vfcode: [],
      email: "",
      token: "",
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      vcode: { [0]: required },
    };
  },
  mounted() {
    // this.$refs.vcode0.$el.focus();
    this.$refs.vcode0.focus();
  },

  methods: {
    onKeydown(event) {
      const char = String.fromCharCode(event.keyCode);
      if (!/[0-9]/.vcode(char)) {
        event.preventDefault();
        event.target.nextElementSibling.focus();
      }
    },

    async sendCode() {
      try {
        this.email = this.$store.state.email;
        this.error = null;

        this.vfcode =
          this.vcode[0] +
          this.vcode[1] +
          this.vcode[2] +
          this.vcode[3] +
          this.vcode[4] +
          this.vcode[5];

        let result = await api.post(`/admin/send-token`, {
          email: this.email,
          reset_code: this.vfcode,
        });
        console.log(result);
        this.token = result.data.token;
        this.$toast.success(result.data.message + "Please Set New Password");
        this.$store.commit("gettoken", this.token);
        this.$router.push({ name: "ConfirmPassword" });
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    resendCode() {
      try {
        this.sendCode();
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },
  },
};
</script>

<style scoped></style>
