<template>
  <div>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      @click="closebtn()"
    >
      &times;
    </button>
    <!-- <button @click="$emit('close')">Close</button> -->
    <div class="modalTop"><h3>Update Library</h3></div>
    <div class="modalDesc">
      <div class="row">
        <div class="col-md-4"><label>Library Name</label></div>
        <div class="col-md-8">
          <div
            class="form-group"
            :class="{ error: v$.tab_libname.$errors.length }"
          >
            <input
              type="text"
              placeholder="Library Name"
              v-model="v$.tab_libname.$model"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.tab_libname.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row imgboxx">
        <div class="col-md-4"><label>Library Image</label></div>
        <div class="col-md-8">
          <div class="imgbox">
            <img
              v-if="tab_libimage == ''"
              :src="'/assets/images/services/base.jpg'"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <img
              v-if="tab_libimage !== ''"
              :src="tab_libimage"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <!-- <audio controls v-if="audioFile !== ''">
              <source :src="audioFile" type="audio/mpeg" />
            </audio> -->

            <i class="fas fa-camera" @click="$refs.imageFile.click()"></i>

            <div class="clear"></div>

            <input
              class="ml"
              type="file"
              accept="image/jpeg"
              @change="uploadImage"
              ref="imageFile"
              style="display: none"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-8">
          <button
            class="btn btn-primary w-md mybutton myleft submitbtn"
            v-on:click="updateLibrary"
            :disabled="v$.$invalid"
          >
            Submit
          </button>
        </div>
        <div v-if="error" class="alert alert-danger">
              {{ error }}
            </div>
      </div>
    </div>
    <div class="modalFooter"></div>
  </div>
</template>

<script>
import api from "../../config/api";
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
export default {
  name: "ModalEditLibrary",
  props: {
    libid: Number,
    libname: String,
    libimage: String,
    catid: Number,
    outside:Boolean
  },

  data() {
    return {
      tab_libid: "",
      tab_libname: "",
      tab_libimage: "",
      tab_catid: "",
      tab_outside:"",
    };
  },

  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      tab_libname: {
        required,
        min: minLength(4),
      },
    };
  },

  mounted() {
    //this.onLoad();

    this.tab_libname = this.libname;
    this.tab_libid = this.libid;
    this.tab_libimage = this.libimage;
    this.tab_catid = this.catid;
    this.tab_outside = this.outside;
    console.log(this.tab_libname, "LibName");
  },

  methods: {
    async updateLibrary() {
      try {
        //console.log("Songs", resultss);
        // const credentials = {
        //   title: this.state.sname,
        //   category_id: this.current_id,
        // };

        const formData03 = new FormData();
        formData03.append("name", this.tab_libname);
        formData03.append("id", this.tab_libid);
        formData03.append("category_id", this.tab_catid);
        if (this.$refs.imageFile.files[0]) {
          formData03.append("image", this.$refs.imageFile.files[0]);
        }

        // let resultss = await api.post("/admin/edit-category", credentials);

        let resultss = await api.post("/admin/update-library", formData03);
        console.log("Songs", resultss);
        if (resultss.status == 200) {
          this.$toast.success(resultss.data.message);
          this.$root.$refs.Tabs.callme03();
          const list = document.getElementById("modals-container");
          list.removeChild(list.firstElementChild);

          this.value02 = false;
        }
      } catch (error) {
        this.$toast.error(error.response.data.message);
      }
    },
    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.tab_libimage = e.target.result;
        //console.log(this.previewImage);
      };
    },

    onLoad() {
      this.updateAudio();
      this.updateLibrary();
    },

    closebtn() {
      if(!this.tab_outside){
      // this.$root.$refs.Tabs.callme03();
      // this.$root.$refs.Tab.forceRerender();
      const list = document.getElementById("modals-container");
      list.removeChild(list.firstElementChild);
      }else{
        // this.$root.$refs.AllLibraries.onload();
        const list = document.getElementById("modals-container");
      list.removeChild(list.firstElementChild);
      }
    },

    // uploadImage02(e) {
    //   const image = e.target.files[0];
    //   const reader = new FileReader();
    //   reader.readAsDataURL(image);
    //   reader.onload = (e) => {
    //     this.vpath = e.target.result;
    //     console.log(this.vpath);
    //   };
    // },
    // closebtn() {
    //   console.log("dsif");
    //   document
    //     .getElementById("modals-container")
    //     .classList.contains("democlass");
    // },
  },
};
</script>

<style scoped>
.vm--modal {
  height: auto !important;
}
.modalTop {
  width: 100%;
  background: #041e4f;

  color: #fff;
}
.modalTop h3 {
  font-weight: 600;
  color: #fff;
  font-size: 1.42188rem;
  text-align: center;
  padding: 15px 0;
}

.modalDesc {
  padding: 20px 40px;
  max-height: 700px;
  overflow-y: scroll;
}
.modalDesc label {
  display: inline-block;
  line-height: 34px;
  font-size: 15px;
}
.modalDesc input {
  background: #fff;
  border: solid 1px #dadada;
  border-radius: 8px;
  padding: 8px 16px;
  color: #333;
  outline: none;
  width: 100%;
}
.submitbtn {
  margin-top: 30px;

  text-align: center;
  border-radius: 6px;
  padding: 8px 16px;

  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
.modalFooter {
  position: absolute;
  width: 100%;
  bottom: 0;
  width: 100%;
  background: #041e4f;
  height: 20px;
}
</style>
