import { render, staticRenderFns } from "./ModalEditTag.vue?vue&type=template&id=23efdd6b&scoped=true&"
import script from "./ModalEditTag.vue?vue&type=script&lang=js&"
export * from "./ModalEditTag.vue?vue&type=script&lang=js&"
import style0 from "./ModalEditTag.vue?vue&type=style&index=0&id=23efdd6b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23efdd6b",
  null
  
)

export default component.exports