<template>
    <div :key="$route.path">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div
            class="page-title-box d-sm-flex align-items-center justify-content-between"
          >
            <div class="page-title-right">
             <h4 class="mb-sm-0 font-size-18">All Meditations's Backgrounds</h4>
              <a
                @click="$router.go(-1)"
                class="btn btn-primary w-md mybutton myleft"
              >
                <box-icon
                  name="chevrons-left"
                  type="solid"
                  color="#ffffff"
                  class="myicon"
                ></box-icon
                >Back</a
              >
            </div>
  
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <!-- <li class="breadcrumb-item">
                
                  <router-link to="/home/all_libraries" class="waves-effect">
                    All Libraries List
                  </router-link>
                </li> -->
                <li class="breadcrumb-item active">All Background's List</li>
              </ol>
              <div>
                <!-- :disabled='isDisabled' -->
                <button
                  class="btn btn-primary waves-effect btn-label waves-light mybutton"
                  @click="showModal()"
                >
                  Add Meditation Background
                </button>
              </div>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
      <!-- end page title -->
  
      <div class="row">
        <div class="col-lg-12">
          <div class="">
            <div class="table-responsive">
              <div class="card">
                <div class="card-body">
                  <table
                    class="table table-hover table-bordered"
                    id="audios_table"
                  >
                    <thead>
                      <tr class="frown">
                        <th scope="col" style="width: 5%">#</th>
                        <th scope="col" style="width: 10%">Image</th>
                        <th scope="col" style="width: 20%">Title</th>
                        <th scope="col" style="width: 20%">Status</th>
                        <th scope="col" style="width: 5%">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in all_meditation_backgrounds" :key="index">
                        <td scope="row" class="sorting_1">{{ index + 1 }}</td>
                        <td>
                          <img :src="item.background_image" alt="" />
                        </td>
                        <td>
                          <h5>
                            {{ item.title }}
                          </h5>
                          <!-- <p class="text-muted mb-0 text-ellipse-250">
                            {{ item.name }}
                          </p> -->
                        </td>
  
                        <td>
                        <toggle-button @change="changeStatus(item)" :value="(item.status == 'Active') ? true : false" />
                      </td>

                        <td>
                          <!-- <div class="w3-dropdown-click mydropdown"> -->
                          <div class="dropdown">
                            <a
                              href="#"
                              class="dropdown-toggle card-drop"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <box-icon
                                name="dots-horizontal-rounded"
                                class="myddicon"
                              ></box-icon>
                            </a>
                            <div
                              class="dropdown-menu dropdown-menu-end dropdown-content w3-animate-zoom"
                              id="myDIV"
                            >
                              <!-- <div
                                class="dropdown-menu dropdown-menu-end dropdown-content"
                              > -->
                              <div>
                                <a
                                  href="javascript:;"
                                  @click="
                                    showModal03(
                                      item.id,
                                      item.title,
                                      item.background_image,
                                    )
                                  "
                                  class="listbox w3-bar-item w3-button"
                                >
                                  <box-icon name="edit" class="myicon"></box-icon>
                                  Update</a
                                >
                                <a
                                  href="javascript:;"
                                  @click="remove_aud(item.id)"
                                  class="listbox w3-bar-item w3-button"
                                  ><box-icon
                                    type="solid"
                                    name="message-alt-x"
                                    class="myicon"
                                  ></box-icon
                                  >Delete Now</a
                                >
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import "datatables.net-dt/js/dataTables.dataTables";
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  import ModalEditMeditationBackground from "../Modals/ModalEditMeditationBackground.vue";
  import ModalAddMeditationBackground from "../Modals/ModalAddMeditationBackground.vue";
  import api from "../../config/api.js";
  import $ from "jquery";
  export default {
    name: "Meditations",
    data() {
      return {
        is_active: true,
        all_meditation_backgrounds: [],
        // isDisabled : false,
      };
    },
  
    created() {
      this.$root.$refs.MeditationBackground = this;
      
  
      this.meditation_cat_id = this.$route.params.id;
      console.log(this.$route.params, "Alicreated");
  
      this.$watch(
        () => this.$route.params,
        () => {
          this.meditation_cat_id = this.$route.params.id;
          //this.$router.go();
          this.allMeditationsAudios();
        },
        // fetch the data when the view is created and the data is
        // already being observed
        { immediate: true }
      );
    },
    mounted() {
      //this.allaudios();
      this.meditation_cat_id = this.$route.params.id;
      this.meditationBackground();
    },
  
    methods: {
      async changeStatus(item){
      item.status = !item.status
      try {
        let result = await api.post(`/admin/med-background-status?id=${item.id}`);
        this.$toast.success(result.data.message);
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },
      async meditationBackground() {
        try {
          let result = await api.get(`/admin/all-med-backgrounds`);
          setTimeout(() => {
            $("#audios_table").DataTable();
          }, 0);
          this.all_meditation_backgrounds = result.data.all_meditation_backgrounds;
        //   if(result.data.all_meditation_backgrounds.length > 0){
        //     this.isDisabled = true;
        //   }
          //console.log(result);
        } catch (error) {
          this.error = error.response.data.message;
          this.$toast.error(this.error);
        }
      },
  
      async remove_aud(id) {
        try {
          this.$swal({
            title: "Are you sure?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Delete it!",
            cancelButtonText: "No, cancel!",
            confirmButtonClass: "btn btn-success",
            cancelButtonClass: "btn btn-danger",
            buttonsStyling: true,
          }).then(async (res) => {
            if (res.isConfirmed) {
              let result = await api.delete(
                `/admin/delete-med-background/` + id
              );
              this.meditationBackground();
              this.$toast.success(result.data.message);
              // console.log("I am in Delete mode", result);
              //this.onLoad();
            }
          });
        } catch (error) {
          this.$toast.error(error.response.data.message);
        }
      },
  
      showModal() {
        this.$modal.show(
          ModalAddMeditationBackground,
          {},
          { draggable: true },
          {
            "before-close": () => {
              this.onLoad();
            },
          }
        );
      },
  
      showModal03(id, title, background_image) {
        this.$modal.show(
        ModalEditMeditationBackground,
          {
            id: id,
            title: title,
            background_image : background_image,
          },
          { draggable: true },
          {
            "before-close": () => {
              //this.allAudios(libid);
            },
          }
        );
      },
  
      callme() {
        setTimeout(() => {
          $("#audios_table").DataTable();
        }, 0);
        this.meditationBackground();
      },
    //    sayname(name){
    //     this.catname = name;
    //   },
    },
  };
  </script>
  
  <style scoped>
  #audios_table h5 {
    /* line-height: 65px; */
    margin-top: 15px;
  }
  #audios_table .switch {
    margin-top: 25px;
  }
  #audios_table .dropdown {
    margin-top: 15px;
  }
  </style>
  