import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from './Pages/Login.vue'
import Home from './Pages/Home.vue'
import About from './Pages/About.vue'
import ForgetPassword from './Pages/ForgetPassword.vue'
import CodeVerify from './Pages/CodeVerify.vue'
import ConfirmPassword from './Pages/ConfirmPassword.vue'
import Users from './Pages/Admin/Users.vue'
import ServiceCategories from './Pages/Admin/ServiceCategories.vue'
import AllLibraries from './Pages/Admin/AllLibraries.vue'
import LibrarysAudios from './Pages/Admin/LibrarysAudios.vue'
import AllAudios from './Pages/Admin/AllAudios.vue'
import AllMeditationTunes from './Pages/Admin/MeditationTunes.vue'
import Hypnosis from './Pages/Admin/Hypnosis.vue'
import Videos from './Pages/Admin/Videos.vue'
import AllSeries from './Pages/Admin/AllSeries.vue'
import AllCourses from './Pages/Admin/AllCourses.vue'
import AllCoursesCategories from './Pages/Admin/AllCoursesCategories.vue'
import AllTags from './Pages/Admin/AllTags.vue'
import AllVideos from './Pages/Admin/AllVideos.vue'

import HypnosisCategory from './Pages/Admin/AllAudioCategoriesHypnosis.vue'
import MeditationCategory from './Pages/Admin/AllAudioCategoriesMeditation.vue'
import AffirmationCategory from './Pages/Admin/AllAudioCategoriesAffirmation.vue'

import AllScripts from './Pages/Admin/AllScripts.vue'
import Series from './Pages/Admin/Series.vue'
import Ebooks from './Pages/Admin/Ebooks.vue'
import Chapters from './Pages/Admin/Chapters.vue'
import Courses from './Pages/Admin/Courses.vue'
import Meditations from './Pages/Admin/Meditations.vue'
import MeditationBackground from './Pages/Admin/MeditationBackground.vue'
import Affirmations from './Pages/Admin/Affirmations.vue'
import Events from './Pages/Admin/Events.vue'
import Stream from './Pages/Admin/Stream.vue'
import Libraries from './Pages/Admin/Libraries.vue'
import Content from './Pages/Admin/Content.vue'
import PathNotFound from "./Pages/PathNotFound.vue"
import { store } from './Store/index.js'
import api from "./config/api.js"

function rguard(to, from, next) {
    // console.log("test")
    api.get("/admin/verify")
        .then((res) => {

            //store.commit("user", res.data.user);
            console.log(res.data.type);
            store.commit("gettype", res.data.type);
            console.log(store.state.type, "User Type");
            if (store.state.type == 'admin') {
                next('/home/users');
                console.log(store.state.type, "User Type Again");

            }
        })
        .catch(() => next());
}


const routes = [

    { name: "Login", path: "/", component: Login, beforeEnter: rguard },
    { path: '/codeVerify', name: 'CodeVerify', component: CodeVerify, beforeEnter: rguard },
    { name: "ForgetPassword", path: "/forgetpassword", component: ForgetPassword, beforeEnter: rguard },
    { path: '/confirmpassword', name: 'ConfirmPassword', component: ConfirmPassword, beforeEnter: rguard },
    {
        path: '/home', name: 'Home', component: Home,


        children: [
            { path: 'about', name: 'About', component: About },
            { path: 'users', name: 'Users', component: Users },
            { path: 'servicecategories', name: 'ServiceCategories', component: ServiceCategories, props: true, },
            { path: 'all_libraries', name: 'AllLibraries', component: AllLibraries, props: true, },
            { path: 'all_audios', name: 'AllAudios', component: AllAudios, props: true, },
            { path: 'all_meditation_tunes', name: 'AllMeditationTunes', component: AllMeditationTunes, },
            { path: 'libraries/:id', name: 'Libraries', component: Libraries, props: true, },            
            { path: 'librarys_audios/:id', name: 'LibrarysAudios', component: LibrarysAudios, props: true, },
            { path: 'hypnosis/:title/:id', name: 'hypnosis', component: Hypnosis, props: true, },
            { path: 'meditations/:title/:id', name: 'meditations', component: Meditations, props: true, },
            { path: 'meditation_background', name: 'meditation_background', component: MeditationBackground},
            { path: 'affirmations/:title/:id', name: 'affirmations', component: Affirmations, props: true, },
            { path: 'videos/:title/:id', name: 'videos', component: Videos, props: true, },
            { path: 'all_series', name: 'AllSeries', component: AllSeries},
            { path: 'all_courses/:title/:id', name: 'AllCourses', component: AllCourses, props: true,},
            { path: 'all_categories_courses', name: 'AllCoursesCategories', component: AllCoursesCategories},

            { path: 'tags', name: 'AllTags', component: AllTags},
            

            { path: 'all_videos', name: 'AllVideos', component: AllVideos},

            { path: 'hypnosis_categories', name: 'HypnosisCategory', component: HypnosisCategory},
            { path: 'meditations_categories', name: 'MeditationCategory', component: MeditationCategory},
            { path: 'affirmations_categories', name: 'AffirmationCategory', component: AffirmationCategory},
            
            { path: 'all_scripts', name: 'AllScripts', component: AllScripts},
            { path: 'series/:title/:id', name: 'series', component: Series, props: true, },
            { path: 'ebooks', name: 'AllEbooks', component: Ebooks, },
            { path: 'chapters/:title/:id', name: 'chapters', component: Chapters, props: true, },
            { path: 'courses/:title/:id', name: 'courses', component: Courses, props: true, },
            { path: 'content/:title/:id', name: 'content', component: Content, props: true, },
            { path: 'events', name: 'Events', component: Events, props: true, },
            { path: 'stream', name: 'Stream', component: Stream, props: true, },
            // path: 'stream/:link',
        ],


        // async beforeEnter(to, from, next) {
        //     try {

        //         // let res = await api.get(`/admin/verify`)
        //         // store.commit("getname", res.data.name);
        //         // console.log("responsefrom guard", res);
        //         next();
        //     } catch (error) {
        //         console.log(error)
        //         next();

        //     }
        // },

        beforeEnter: (to, from, next) => {
            api.get(`/verify`)
                .then((res) => {
                    //this.$store.commit("getname", res.data.user);
                    store.commit("getname", res.data.user);
                    //console.log("responsefrom guard", res);
                    next();
                })
                .catch(() => next("/"));
        }

        // beforeEnter: (to, from, next) =>
        //     api.get(`/admin/verify`)
        //         .then((res) => {
        //             //this.$store.commit("getname", res.data.user);
        //             //store.commit("getname", res.data.name);
        //             console.log("responsefrom guard", res);



        //             next();
        //         })
        //         .catch(() => next("/login"))


    },



    { path: '/:pathMatch(.*)*', component: PathNotFound },


]



const router = new VueRouter({
    mode: 'history',
    routes
});

Vue.use(VueRouter);


export default router;