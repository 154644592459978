<template>
  <div>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      @click="closebtn()"
    >
      &times;
    </button>
    <!-- <button @click="$emit('close')">Close</button> -->
    <div class="modalTop"><h3>Update Ebook</h3></div>
    <div class="modalDesc">
      <!-- <div class="row">
        <div class="col-md-4"><label>Category Name</label></div>
        <div class="col-md-8">
          <div
                class="form-group"
                :class="{ error: v$.name.$errors.length }"
              >
          <input
            type="catname"
            placeholder="Categroy Name"
            v-model="v$.name.$model"
          />
          <div
                  class="input-errors"
                  v-for="(error, index) of v$.name.$errors"
                  :key="index"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
                </div>
        </div>
      </div> -->




      <div class="row">
        <div class="col-md-4"><label>Name</label></div>
        <div class="col-md-8">
          <input type="catname" placeholder="Ebook Name" v-model="name"/>
          <p class="err" v-if="aud_name_err != ''">{{ aud_name_err }}</p>
      </div>
      </div>
      <div class="row">
        <div class="col-md-4"><label>Description</label></div>
        <div class="col-md-8">
        
            <textarea placeholder="Ebook Description" v-model="desc"></textarea>
            <p class="err" v-if="aud_desc_err != ''">{{ aud_desc_err }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4"><label>Author</label></div>
        <div class="col-md-8">
          
          <input type="catname" placeholder="Ebook Author" v-model="author"/>
         
          <p class="err" v-if="author_err != ''">{{ author_err }}</p>
      </div>
      </div>
      <br /><br />
      <div class="row">
        <div class="col-md-4"><label>File</label></div>
        <div class="col-md-8">
          <div class="form-group">
          <input type="file" @change="onPdfChange"/>
          <p class="err" v-if="pdfFile_err != ''">{{ pdfFile_err }}</p>
        </div>
        </div>
      </div>


      <div class="row imgboxx">
        <div class="col-md-4"><label>Image</label></div>
        <div class="col-md-8">
          <div class="imgbox">
            <img
              v-if="audio_image_url == ''"
              :src="'/assets/media/services/base.jpg'"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <img
              v-if="audio_image_url !== ''"
              :src="audio_image_url"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <i class="fas fa-camera" @click="$refs.audio_image.click()"></i>
            <div class="clear"></div>
            <p class="err" v-if="audio_image_url_err != ''">{{ audio_image_url_err }}</p>
            <span v-if="audio_image_url == ''" previmg="false">
              <!-- image is required -->
            </span>

            <input
              class="ml"
              type="file"
              accept="image/jpeg"
              @change="onAudioImageChange"
              ref="audio_image"
              style="display: none"
            />
          </div>
        </div>
      </div>

      <Tag @updateTag="updateTagFromChild" :oldtags="tags"/>

      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-8">
          <p class="err" v-if="tags_err != ''">{{ tags_err }}</p>
          <button
            class="btn btn-primary w-md mybutton myleft submitbtn"
            v-on:click="updateCategory"
          >
            Submit
          </button>
        </div>
      </div>
       <div v-if="error" class="alert alert-danger">
              {{ error }}
            </div>
    </div>
    <div class="modalFooter"></div>
  </div>
</template>

<script>
import api from "../../config/api";
// import useVuelidate from "@vuelidate/core";
// import { required, minLength } from "@vuelidate/validators";
import Tag from "../../components/Tag.vue";

export default {
  name: "ModalEditEbook",
  components: {  Tag },
  props: {
    eid: Number,
    ename: String,
    edesc: String,
    eimage: String,
    eartist: String,
    eurl : String,
    atags : Array,
  },

  data() {
    return {
      pdfFile_err : "",
      audioFile_err: "",
      audio_image_url_err: "",
      audio_cover_image_url_err: "",
      aud_desc_err: "",
      aud_name_err: "",
      tags_err: "",
      author_err: "",

      name: "",
      desc: "",
      audio_image: "",
      audio_image_url: "",
      author: "",
      url: "",
      current_id : "",
      tags: [],
    };
  },

    watch: {
    name(newVal) {
      if (newVal != "") {
        this.aud_name_err = "";
      }
    },
    desc(newVal) {
      if (newVal != "") {
        this.aud_desc_err = "";
      }
    },
    author(newVal) {
      if (newVal != "") {
        this.author_err = "";
      }
    },

    tags(newVal) {
      if (newVal.length > 0) {
        this.tags_err = "";
      }
    }
  },


  created() {
    // console.log('from ebook');
    //   console.log(this.atags);
    if(this.atags)
      this.tags = this.atags;
    this.name = this.ename;
    this.desc = this.edesc;
    this.current_id = this.eid;
    this.audio_image_url = this.eimage;
    this.author = this.eartist;
    this.url = this.eurl;

    // this.previewImage02 =
    //   "https://api-believe.staginganideos.com/" + this.previewImage;
    // console.log(this.previewImage);
  },

  methods: {
    updateTagFromChild(arr){
      this.tags = arr;
    },
    async updateCategory() {
      if (this.previewImage != ""
        && this.author != ""
        && this.name != ""
        && this.desc != ""
        && this.audioFile != ""
        && this.tags.length != 0
      ) {
      try {
        const formDatan = new FormData();
        formDatan.append("name", this.name);
        formDatan.append("id", this.current_id);
        formDatan.append("description", this.desc);
        formDatan.append("author", this.author);

        if(typeof this.tags == 'string'){
          formDatan.append('tag[]', this.tags);
        }else{
          for (var i = 0; i < this.tags.length; i++) {
            formDatan.append('tag[]', this.tags[i]);
          }
        }

        if(this.audio_image){
          formDatan.append("image", this.audio_image);
        }
        if(this.url){
          formDatan.append("url",this.url);
        }
        let resultss = await api.post("/admin/update-ebook", formDatan);
        if (resultss.status == 200) {
          this.$toast.success(resultss.data.message);
          this.$root.$refs.ServiceCategories.callme();
          const list = document.getElementById("modals-container");
          list.removeChild(list.firstElementChild);
          this.value02 = false;
        }
      } catch (error) {
        this.$toast.error(error.response.data.message);
      }
    }
      else{
        this.aud_name_err = (this.name == "" && this.aud_name_err == "") ? "title is required" : this.aud_name_err
        this.aud_desc_err = (this.desc == "" && this.aud_desc_err == "") ? "Description is required" : this.aud_desc_err
        this.author_err = (this.author == "" && this.author_err == "") ? "Author is required" : this.author_err
        this.audioFile_err = (this.audioFile == "" && this.audioFile_err == "") ? "Audio file is required" : this.audioFile_err;
        this.pdfFile_err = (this.url == "" && this.pdfFile_err == "") ? "Pdf file is required" : this.pdfFile_err;
        this.audio_image_url_err = (this.previewImage == "" && this.audio_image_url_err == "") ? "Image is required" : this.audio_image_url_err
        this.tags_err = (this.tags.length == 0 && this.tags_err == '') ? "Tags require" : this.tags_err
      }
    },
    onAudioImageChange(e){
      this.audio_image_url_err = "";
      const file = e.target.files[0];
      var status = this.checkFileType(file, 'image1')
      if (status) {
      this.audio_image = file;
      this.audio_image_url = URL.createObjectURL(file)
      }
    },

    onPdfChange(e){
      this.pdfFile_err = "";
      this.url = "";
      const file = e.target.files[0];
      var status = this.checkFileType(file, 'pdf')
      if (status) {
      this.url = file;
      }
    },


    checkFileType(file, num) {
      if (num == 'video') {
        var types = ['video/mp4', 'video/3gp', 'video/mov', 'video/avi', 'video/mkv']
        if (!types.includes(file.type)) {
          this.videoFile_err = "The video must be a file of type: mp4, mov, avi"
          this.videoFile = ""
          return false;
        }
        return true;
      } else if (num == "audio") {
        types = ['audio/mpeg', 'audio/ogg', 'audio/wav', 'audio/mp4', 'audio/mp3']
        if (!types.includes(file.type)) {
          this.audioFile_err = "The audio must be a file of type: mp3, wav, ogg"
          this.audioFile = ""
          return false;
        }
        return true;
      } else if (num == "pdf") {
        types = ['application/pdf']
        if (!types.includes(file.type)) {
          this.pdfFile_err = "The file must be a file of type: pdf"
          this.url = ""
          return false;
        }
        return true;
      }
      else {
        types = ['image/jpeg', 'image/png', 'image/jpg']
        var msg = "The image must be a file of type: jpeg, jpg, png";
        if (!types.includes(file.type)) {
          if (num == 'image1') {
            this.audio_image_url_err = msg
            this.audio_image = "";
            this.audio_image_url = ""
          } else if (num == 'image2') {
            this.audio_cover_image_url_err = msg
            this.audio_cover_image = ""
            this.audio_image_url2 = ""
          }
          return false;
        }
        return true;
      }
    },

    onLoad() {
      this.updateCategory();
    },

    closebtn() {
      // this.$root.$refs.ServiceCategories.callme();
      const list = document.getElementById("modals-container");
      list.removeChild(list.firstElementChild);
    },

    // uploadImage02(e) {
    //   const image = e.target.files[0];
    //   const reader = new FileReader();
    //   reader.readAsDataURL(image);
    //   reader.onload = (e) => {
    //     this.vpath = e.target.result;
    //     console.log(this.vpath);
    //   };
    // },
    // closebtn() {
    //   console.log("dsif");
    //   document
    //     .getElementById("modals-container")
    //     .classList.contains("democlass");
    // },
  },
};
</script>

<style scoped>
.vm--modal {
  height: auto !important;
}
.modalTop {
  width: 100%;
  background: #041e4f;

  color: #fff;
}
.modalTop h3 {
  font-weight: 600;
  color: #fff;
  font-size: 1.42188rem;
  text-align: center;
  padding: 15px 0;
}
/* .close {
  position: absolute;
  z-index: 99999999999999;
  right: -1px;
  top: -1px;
  visibility: visible;
  overflow: visible;
} */
.modalDesc {
  padding: 20px 40px;
  max-height: 700px;
  overflow-y: scroll;
}
.modalDesc label {
  display: inline-block;
  line-height: 34px;
  font-size: 15px;
}
.modalDesc input {
  background: #fff;
  border: solid 1px #dadada;
  border-radius: 8px;
  padding: 8px 16px;
  color: #333;
  outline: none;
  width: 100%;
}
.submitbtn {
  margin-top: 30px;
  /* background: #019df5; */
  /* color: #fff; */
  text-align: center;
  border-radius: 6px;
  padding: 8px 16px;
  /* border: solid 2px #0a7ebf; */
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
.modalFooter {
  position: absolute;
  width: 100%;
  bottom: 0;
  width: 100%;
  background: #041e4f;
  height: 20px;
}
/* .fas {
  font-size: 30px;
  margin-top: 15px;
} */

/* .glamproDetail .img-thumbnail {
  float: left;
} */
</style>
