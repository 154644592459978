<template>
    <div>
      <button type="button" class="close" data-dismiss="modal" @click="closebtn()">
        &times;
      </button>
      <!-- <button @click="$emit('close')">Close</button> -->
      <div class="modalTop">
        <h3>Set Discount for User</h3>
      </div>
      <div class="modalDesc">
        <div class="row">
          <div class="col-md-4"><label>Discount</label></div>
          <div class="col-md-8">
            <!-- <div class="form-group" :class="{ error: v$.series_name.$errors.length }"> -->
              <input type="number" min="0" max="99" placeholder="" v-model="series_name" />
              <p class="err" v-if="title_error != ''">{{ title_error }}</p>
              <!-- <div class="input-errors" v-for="(error, index) of v$.series_name.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div> -->
            <!-- </div> -->
          </div>
        </div>
        <br /><br />
  
  
        <div class="row">
          <div class="col-md-4"></div>
          <div class="col-md-8">
            <button class="btn btn-primary w-md mybutton myleft submitbtn" v-on:click="updateVideo">
              Submit
            </button>
          </div>
          <div v-if="error" class="alert alert-danger">
            {{ error }}
          </div>
        </div>
      </div>
      <div class="modalFooter"></div>
    </div>
  </template>
  
  <script>
  import api from "../../config/api";
  // import useVuelidate from "@vuelidate/core";
  // import { required, minLength } from "@vuelidate/validators";
  //import AudioFile from "../Admin/AudioFile.vue";
  export default {
    name: "ModalEditVideo",
  
    props: {
      id: Number,
    },
  
    data() {
      return {
        edit_api : "",
        series_name: "",
        current_id: "",
        title_error : "",
      };
    },
  

    mounted() {
      this.current_id = this.id;
    },
  
    created() {
      this.$root.$refs.ModalEditVideo = this;
    },
  
    methods: {
      async updateVideo() {
        try {
          this.title_error = "";
          const formData03 = new FormData();
          formData03.append("discount_percent", this.series_name);
          formData03.append("user_id", this.current_id);
          let resultss = await api.post('/admin/send-discount-link', formData03);
          console.log('before if');
          console.log(resultss);
          if (resultss.status == 200) {
            console.log('under if');
            this.$toast.success(resultss.data.message);
            console.log('after toast');
            // this.$root.$refs.Hypnosis.callme();
            const list = document.getElementById("modals-container");
            list.removeChild(list.firstElementChild);
            this.value02 = false;
          }
        } catch (error) {
          if (typeof error?.response?.data?.errors?.name !== "undefined") {
            this.title_error = error.response.data.errors.name[0];
          }
          this.$toast.error(error.response.data.message);
        }
      },
  
    //   onLoad() {
    //     this.updateAudio();
    //   },
  
      closebtn() {
        // this.$root.$refs.Videos.callme();
  
        const list = document.getElementById("modals-container");
        list.removeChild(list.firstElementChild);
      },
    },
  };
  </script>
  
  <style scoped>
  .vm--container {
    z-index: 9999999999999999 !important;
  }
  
  .vm--modal {
    height: auto !important;
  }
  
  .modalTop {
    width: 100%;
    background: #041e4f;
  
    color: #fff;
  }
  
  .modalTop h3 {
    font-weight: 600;
    color: #fff;
    font-size: 1.42188rem;
    text-align: center;
    padding: 15px 0;
  }
  
  .modalDesc {
    padding: 20px 40px;
    max-height: 700px;
    overflow-y: scroll;
  }
  
  .modalDesc label {
    display: inline-block;
    line-height: 34px;
    font-size: 15px;
  }
  
  .modalDesc input {
    background: #fff;
    border: solid 1px #dadada;
    border-radius: 8px;
    padding: 8px 16px;
    color: #333;
    outline: none;
    width: 100%;
  }
  
  .submitbtn {
    margin-top: 30px;
  
    text-align: center;
    border-radius: 6px;
    padding: 8px 16px;
  
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  
  .modalFooter {
    position: absolute;
    width: 100%;
    bottom: 0;
    width: 100%;
    background: #041e4f;
    height: 20px;
  }
  
  .fas {
    font-size: 30px;
    margin-top: 15px;
  }
  </style>
  