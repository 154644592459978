<template :key="componentKey">
  <div v-show="isActive">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Tab",
  props: {
    name: { required: true },
    selected: { default: false },
    image: String,
    tabid: Number,
    tabcatid: {
      type: String,
    },
  },

  created() {
    this.$root.$refs.Tab = this;
  },

  data() {
    return {
      isActive: false,
      bgimage: "",
      tab_id: "",
      tab_libid: "",
      tab_libname: "",
      tab_image: "",
      tab_catid: "",
      tablibimage: "",
      componentKey: 0,
    };
  },
  mounted() {
    this.isActive = this.selected;
    this.bgimage = this.image;
    this.tab_id = this.tabid;
    this.tab_libid = this.tabid;
    this.tab_libname = this.name;
    this.tablibimage = this.image;
    this.tab_catid = this.tabcatid;
    //this.$store.commit("gettabid", this.tab_id);

    //console.log(this.bgimage, "Image");
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
      // this.$root.$refs.Tabs.callme03();
      console.log("I am in Tab");
      console.log(this.tab_libname, "TabName");
    },
  },
};
</script>

<style scoped></style>
