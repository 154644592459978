import Vue from 'vue'
import Vuex from 'vuex'
import api from "../config/api.js";
Vue.use(Vuex)

export const store = new Vuex.Store({

    //export default new Vuex.Store({
    state: {

        //baseurl: 'https://api-believe.staginganideos.com/api',
        baseurl: 'https://virtualrealitycreators.com/Believe-Backend/api',

        isLoading: false,
        country_n: "",
        city_n: "",
        user_name: "",
        booking: "",
        name: "",
        email: "",
        token: "",
        type: "",
        image: '',
        status: '',
        tabid: "",
        user: {

            name: "",
            email: "",
            type: ""

        },
        lesson_content_data : null,
        filterLessonContentData : false,
    },
    getters: {},

    mutations: {
        //     loader(state, isLoading) {

        //         this.state.isLoading = isLoading;
        //     }
        

        getname(state, uname) {
            this.state.name = uname;

        },
        getemail(state, email) {
            this.state.email = email;

        },

        getkey(state, token) {
            this.state.token = token;
            //console.log(this.state.token, "Log Token")

        },
        gettype(state, type) {
            this.state.type = type;

        },
        getimage(state, uimage) {
            this.state.image = uimage;

        },
        getstatus(state, status) {
            this.state.status = status;
        },

        gettabid(state, tabid) {
            this.state.type = tabid;

        },
        updateLessonContentData(state,data){
            state.lesson_content_data = data;
        },
        changeFilterContentDataStatus(state){
            state.filterLessonContentData = !state.filterLessonContentData;
        },
        // insertLessonContentData(state,data){
        //     console.log('insert');
        //     state.lesson_content_data.data.content.push(data);
        //     // state.lesson_content_data.push(data);
        //     // state.lesson_content_data = data;
        // },
    },
    actions: {
        async getLessonContentData({ commit },id){
            const response = await api.get(`/admin/lesson-content`, {
                params: { lesson_id: id },
            });
            commit('updateLessonContentData',response);
        }
    },
    modules: {
    }
})
