<template>
  <div>
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-sm-flex align-items-center justify-content-between"
        >
          <div class="page-title-right">
            <h4 class="mb-sm-0 font-size-18">Libraries of {{ cat_name }}</h4>
            <a
              @click="$router.go(-1)"
              class="btn btn-primary w-md mybutton myleft"
            >
              <box-icon
                name="chevrons-left"
                type="solid"
                color="#ffffff"
                class="myicon"
              ></box-icon
              >Back</a
            >
          </div>

          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item">
                <!-- <a href="">Services</a> -->
                <router-link to="/home/servicecategories" class="waves-effect">
                  Categories
                </router-link>
              </li>
              <li class="breadcrumb-item active">Libraries' List</li>
            </ol>
            <div>
              <button
                class="btn btn-primary waves-effect btn-label waves-light mybutton"
                @click="showModal()"
              >
                Add Library
              </button>
            </div>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
    <div class="descriptionbox">
      <Tabs
        :callMethod="this.allAudios"
        :btnClickHandler="this.alllibrary"
        :key="componentkey"
      >
        <Tab
          v-for="(item, index) in alllibraries"
          :key="index"
          :name="item.name"
          :image="item.image"
          :tabid="item.id"
          :tabcatid="item.category_id"
        >
          <table
            class="table table-hover table-bordered"
            :id="'service' + index"
          >
            <thead>
              <tr class="frown">
                <th scope="col" style="width: 5% !important">#</th>

                <th scope="col" style="width: 150px !important">Audio Name</th>
                <th scope="col" style="width: 100px !important">Audio Image</th>

                <th scope="col" style="width: 250px !important">
                  Audio Detail
                </th>

                <th scope="col" style="width: 75px !important">Audio File</th>
                <th scope="col" style="width: 100px !important">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(audio_item, index) in all_audios" :key="index">
                <td scope="row" class="indexbox">{{ index + 1 }}</td>
                <td class="linebox">
                  <h4>{{ audio_item.name }}</h4>
                </td>
                <td>
                  <img :src="item.image" alt="Library Pic" class="imgbox" />
                </td>
                <td>
                  <p>{{ audio_item.description }}</p>
                </td>
                <td>
                  <audio controls>
                    <source :src="audio_item.path" type="audio/mpeg" />
                  </audio>
                </td>
                <td>
                  <div class="dropdown">
                    <a
                      href="#"
                      class="dropdown-toggle card-drop"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <box-icon name="dots-horizontal-rounded"></box-icon>
                    </a>
                    <div
                      class="dropdown-menu dropdown-menu-end dropdown-content w3-animate-zoom"
                      id="myDIV"
                    >
                      <a
                        href="#"
                        @click="
                          showModal03(
                            audio_item.id,
                            audio_item.name,
                            audio_item.path,
                            audio_item.description,
                            item.id
                          )
                        "
                        class="listbox"
                      >
                        <box-icon name="edit" class="myicon"></box-icon>
                        Update Audio</a
                      >
                      <a
                        href="#"
                        @click="remove_cat(audio_item.id, item.id)"
                        class="listbox"
                        ><box-icon
                          type="solid"
                          name="message-alt-x"
                          class="myicon"
                        ></box-icon
                        >Delete Now</a
                      >
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Tab>
      </Tabs>
    </div>
  </div>
</template>

<script>
import Tab from "../../components/Tab.vue";
import Tabs from "../../components/Tabs.vue";
import api from "../../config/api";
import ModalAddLibrary from "../Modals/ModalAddLibrary.vue";
import ModalEditAudio from "../Modals/ModalEditAudio.vue";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

export default {
  name: "Libraries",
  components: { Tab, Tabs },

  data: () => ({
    alllibraries: "",
    bgimage: "",
    all_audios: "",
    cat_id: "",
    cat_name: "",
    lib_id: "",
    key: 0,
    componentkey: 0,
  }),
  mounted() {
    this.cat_id = this.$route.params.id;
    this.getname_cat(this.cat_id);
    this.alllibrary();
  },

  computed: {},
  created() {
    this.$root.$refs.Libraries = this;
  },

  methods: {
    async alllibrary() {
      try {
        let { data } = await api.get(`/admin/cat-lib`, {
          params: {
            category_id: this.$route.params.id,
          },
        });
        this.alllibraries = data.libraries;
        setTimeout(() => {
          this.alllibraries.forEach((_, ind) =>
            $(`#service${ind}`).DataTable()
          );
        }, 0);
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    async allAudios(id) {
      try {
        let audResult = await api.get(`/admin/lib-audios`, {
          params: {
            library_id: id,
          },
        });

        this.all_audios = audResult.data.audios;
        
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    showModal() {
      this.$modal.show(
        ModalAddLibrary,
        { id: this.cat_id },
        { draggable: true },
        {
          "before-close": () => {
            this.alllibrary();
          },
        }
      );
    },

    showModal03(aid, aname, apath, adesc, libid) {
      this.$modal.show(
        ModalEditAudio,
        {
          aid: aid,
          aname: aname,
          apath: apath,
          adesc: adesc,
          libid: libid,
        },
        { draggable: true },
        {
          "before-close": () => {
            //this.allAudios(libid);
          },
        }
      );
    },

    callme(id) {
      this.allAudios(id);
      this.alllibrary();
    },

    callme02() {
      this.componentkey += 1;
      //this.$root.$refs.tab.forceRerender();
      this.alllibrary();
    },

    async remove_cat(id, lib_id) {
      try {
        this.$swal({
          title: "Are you sure?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete it!",
          cancelButtonText: "No, cancel!",
          confirmButtonClass: "btn btn-success",
          cancelButtonClass: "btn btn-danger",
          buttonsStyling: true,
        }).then(async (res) => {
          if (res.isConfirmed) {
            let result = await api.delete(`/admin/delete-audio/` + id);
            this.allAudios(lib_id);
            this.$toast.success(result.data.message);
          }
        });
      } catch (error) {
        this.$toast.error(error.response.data.message);
      }
    },
    async getname_cat(id) {
      let { data } = await api.get(`admin/category-details`, {
        params: {
          category_id: id,
        },
      });
      this.cat_name = data["category_detail"]?.name;
      this.cat_id = data["category_detail"]?.id;
    },
  },
};
</script>

<style scroped>
.thumbnails {
  margin: auto;
  max-width: 300px;
}

.thumbnails .vueperslide {
  box-sizing: border-box;
  border: 1px solid #fff;
  transition: 0.3s ease-in-out;
  opacity: 0.7;
  cursor: pointer;
}

.thumbnails .vueperslide--active {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  opacity: 1;
  border-color: #000;
}
.libbox {
  padding: 30px;
  max-width: 300px;
  max-height: 300px;
  min-height: 100px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #041e4f;
  background: #009ef7;
  margin: 10px 8px;
  float: left;
  background-size: cover;
  border: solid 1px #041e4f;
  border-radius: 8px;
}
.descriptionbox {
  margin-top: 30px;
}
.dataTables_empty {
  padding: 10px 0 20px 0;
  visibility: hidden;
}
#service_table02 h4 {
  line-height: 50px;
}
.indexbox {
  line-height: 50px;
}
.dropdown box-icon {
  line-height: 50px;
}
.listbox {
  float: left;
  margin-bottom: 8px;
  border-bottom: solid 1px #dadada;
  width: 100%;
  padding: 8px 0px;
}
.imgbox {
  /* width: 75px; */
  height: 50px;
  border-radius: 50%;
}
.dropdown,
.dropend,
.dropstart,
.dropup {
  position: relative;
  background: #fff;
  border-radius: 50%;
  padding: 0px 5px;
  margin: 0 !important;
  padding: 0 !important;
  line-height: 20px;
}
h4 {
  /* line-height: 50px !important; */
  font-size: 1.15rem;
}
/* p {
  line-height: 50px !important;
} */
.linebox {
  padding-top: 25px !important;
}
</style>
