<template>
  <div>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      @click="closebtn()"
    >
      &times;
    </button>
    <div class="modalTop"><h3>Add Ebook</h3></div>
    <div class="modalDesc">
      <div class="row">
        <div class="col-md-4"><label>Name</label></div>
        <div class="col-md-8">
          <div class="form-group">
          <input
            type="catname"
            placeholder="Ebook Name"
            v-model="name"
          />
          <p class="err" v-if="aud_name_err != ''">{{ aud_name_err }}</p>
        </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4"><label>Description</label></div>
        <div class="col-md-8">
          <div class="form-group">
            <textarea placeholder="Ebook Description" v-model="desc"></textarea>
            <p class="err" v-if="aud_desc_err != ''">{{ aud_desc_err }}</p>
        </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4"><label>Author</label></div>
        <div class="col-md-8">
          <div class="form-group">
          <input
            type="catname"
            placeholder="Ebook Author"
            v-model="author"
          />
          <p class="err" v-if="author_err != ''">{{ author_err }}</p>
        </div>
      </div>
      </div>
      <br /><br />
      <div class="row">
        <div class="col-md-4"><label>File</label></div>
        <div class="col-md-8">
          <div class="form-group">
          <input type="file" @change="onPdfChange"/>
          <p class="err" v-if="pdfFile_err != ''">{{ pdfFile_err }}</p>
        </div>
        </div>
      </div>

      <div class="row imgboxx">
        <div class="col-md-4"><label>Image</label></div>
        <div class="col-md-8">
          <div class="imgbox">
            <img
              v-if="previewImage == ''"
              :src="'/assets/media/services/base.jpg'"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <img
              v-if="previewImage !== ''"
              :src="previewImage"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <i class="fas fa-camera" @click="$refs.imageFile.click()"></i>
            <div class="clear"></div>
            <p class="err" v-if="audio_image_url_err != ''">{{ audio_image_url_err }}</p>
            <span v-if="previewImage == ''" previmg="false">
              <!-- image is required -->
            </span>

            <input
              class="ml"
              type="file"
              accept="image/jpeg"
              @change="uploadImage"
              ref="imageFile"
              style="display: none"
            />
          </div>
        </div>
      </div>

      <Tag @updateTag="updateTagFromChild" oldtags="" />

      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-8">
          <p class="err" v-if="tags_err != ''">{{ tags_err }}</p>
          <button
            class="btn btn-primary w-md mybutton myleft submitbtn"
            v-on:click="addCategory"
          >
            Submit
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <progress-bar :value="progress" :options="options" />
        </div>
      </div>

      <!-- <div v-if="error" class="alert alert-danger">
              {{ error }}
            </div> -->
    </div>
    <div class="modalFooter"></div>
  </div>
</template>

<script>
import api from "../../config/api";
import Tag from "../../components/Tag.vue";
// import useVuelidate from "@vuelidate/core";
// import { required, minLength } from "@vuelidate/validators";


export default {
  name: "ModalAddEbook",
  components:{
    Tag,
  },

  data() {
    return {
      pdfFile_err : "",
      audioFile_err: "",
      audio_image_url_err: "",
      audio_cover_image_url_err: "",
      aud_desc_err: "",
      aud_name_err: "",
      tags_err: "",
      author_err: "",

      title_error : "",
      desc_error : "",
      image_error : "",
      author_error : "",
      url_error : "",
      tag_error : "",

      tags: [],
      name: "",
      desc: "",
      author: "",
    
      url: "",
      image : "",
      previewImage: "",
      progress: "0",
      options: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#333333',
          inverted: false
        },
        layout: {
          height: 35,
          width: 500,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line'
        }
      }
    };
  },

  //  setup() {
  //   return { v$: useVuelidate() };
  // },


  // validations() {
  //   return {
  //       name: {
  //         required,
  //         min: minLength(4),
  //       },
  //       desc: {
  //         required,
  //         min: minLength(4),
  //       },
  //       author: {
  //         required,
  //       },
  //       url: {
  //         required,
  //       },
  //   };
  // },

  watch: {
    name(newVal) {
      if (newVal != "") {
        this.aud_name_err = "";
      }
    },
    desc(newVal) {
      if (newVal != "") {
        this.aud_desc_err = "";
      }
    },
    author(newVal) {
      if (newVal != "") {
        this.author_err = "";
      }
    },

    tags(newVal) {
      if (newVal.length > 0) {
        this.tags_err = "";
      }
    }
  },

  methods: {
    updateTagFromChild(arr){
      this.tags = arr;
    },
    async addCategory() {
      if (this.previewImage != ""
        && this.author != ""
        && this.name != ""
        && this.desc != ""
        && this.audioFile != ""
        && this.tags.length != 0
        && this.pdfFile_err == ""
        && this.url != ""
      ) {
      try {
        this.title_error = ""
        this.desc_error = ""
        this.image_error = ""
        this.author_error = ""
        this.url_error = ""
        this.tag_error = ""

        const formData01 = new FormData();
        formData01.append("name", this.name);
        formData01.append("description", this.desc);
        formData01.append("author", this.author);
        formData01.append("url", this.url);

        if(typeof this.tags == 'string'){
          formData01.append('tag[]', this.tags);
        }else{
          for (var i = 0; i < this.tags.length; i++) {
            formData01.append('tag[]', this.tags[i]);
          }
        }

        if (this.$refs.imageFile.files[0]) {
          formData01.append("image", this.$refs.imageFile.files[0]);
        }
        if(this.url){
          formData01.append("url",this.url);
        }
        let result = await api.post("/admin/add-ebook", formData01, {
          onUploadProgress: function (progressEvent) {
            this.progress = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        });
        console.log(result);
        this.$toast.success(result.data.message);
        this.$root.$refs.ServiceCategories.callme();
        const list = document.getElementById("modals-container");
        list.removeChild(list.firstElementChild);

        // this.$refs.ServiceCategoreis.onload(); //like this
      } catch (error) {
        if (typeof error.response.data.errors.name !== "undefined") {
          this.title_error = error.response.data.errors.name[0];
        }
        if (typeof error.response.data.errors.description !== "undefined") {
          this.desc_error = error.response.data.errors.description[0];
        }
        if(typeof error.response.data.errors.image !== "undefined") {
          this.image_error = error.response.data.errors.image[0];
        }
        if(typeof error.response.data.errors.url !== "undefined") {
          // error.response.data.errors.url[0];
          this.url_error = "The File is required"
        }
        if (typeof error.response.data.errors.author !== "undefined") {
          this.author_error = error.response.data.errors.author[0];
        }
        if (typeof error.response.data.errors.tag !== "undefined") {
          this.tag_error = error.response.data.errors.tag[0];
        }
        
        this.progress = 0;
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    }
      else{
        this.aud_name_err = (this.name == "" && this.aud_name_err == "") ? "title is required" : this.aud_name_err
        this.aud_desc_err = (this.desc == "" && this.aud_desc_err == "") ? "Description is required" : this.aud_desc_err
        this.author_err = (this.author == "" && this.author_err == "") ? "Author is required" : this.author_err
        this.audioFile_err = (this.audioFile == "" && this.audioFile_err == "") ? "Audio file is required" : this.audioFile_err;
        this.pdfFile_err = (this.url == "" && this.pdfFile_err == "") ? "Pdf file is required" : this.pdfFile_err;
        this.audio_image_url_err = (this.previewImage == "" && this.audio_image_url_err == "") ? "Image is required" : this.audio_image_url_err
        this.tags_err = (this.tags.length == 0 && this.tags_err == '') ? "Tags require" : this.tags_err
      }
    },

    onPdfChange(e){
      this.pdfFile_err = "";
      this.url = "";
      const file = e.target.files[0];
      var status = this.checkFileType(file, 'pdf')
      if (status) {
        this.url = file;
      }
    },

    uploadImage(e) {
      this.audio_image_url_err = "";
      const image = e.target.files[0];
      var status = this.checkFileType(image, 'image1')
      if (status) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
        console.log(this.previewImage);
      };
      }
    },


    checkFileType(file, num) {
      if (num == 'video') {
        var types = ['video/mp4', 'video/3gp', 'video/mov', 'video/avi', 'video/mkv']
        if (!types.includes(file.type)) {
          this.videoFile_err = "The video must be a file of type: mp4, mov, avi"
          this.videoFile = ""
          return false;
        }
        return true;
      } else if (num == "audio") {
        types = ['audio/mpeg', 'audio/ogg', 'audio/wav', 'audio/mp4', 'audio/mp3']
        if (!types.includes(file.type)) {
          this.audioFile_err = "The audio must be a file of type: mp3, wav, ogg"
          this.audioFile = ""
          return false;
        }
        return true;
      } else if (num == "pdf") {
        types = ['application/pdf']
        if (!types.includes(file.type)) {
          this.pdfFile_err = "The file must be a file of type: pdf"
          this.url = ""
          return false;
        }
        return true;
      }
      else {
        types = ['image/jpeg', 'image/png', 'image/jpg']
        var msg = "The image must be a file of type: jpeg, jpg, png";
        if (!types.includes(file.type)) {
          if (num == 'image1') {
            this.audio_image_url_err = msg
            this.audio_image = "";
            this.audio_image_url = ""
          } else if (num == 'image2') {
            this.audio_cover_image_url_err = msg
            this.audio_cover_image = ""
            this.audio_image_url2 = ""
          }
          return false;
        }
        return true;
      }
    },

    uploadImage02(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.vpath = e.target.result;
        console.log(this.vpath);
      };
    },

    mounted() {
      this.onLoad();
      //this.$modal.show("example");
      // document.getElementsByClassName("vm--modal").style.height = "500px";
      //     "none !important";
    },

    closebtn() {
      // this.$root.$refs.ServiceCategories.callme();
      const list = document.getElementById("modals-container");
      list.removeChild(list.firstElementChild);
    },
  },
};
</script>

<style scoped>
#modals-container .vm--modal {
  height: 600px !important;
}
.modalTop {
  width: 100%;
  background: #041e4f;

  color: #fff;
}
.modalTop h3 {
  font-weight: 600;
  color: #fff;
  font-size: 1.42188rem;
  text-align: center;
  padding: 15px 0;
}

.modalDesc {
  padding: 20px 40px;
  max-height: 700px;
  overflow-y: scroll;
}
.modalDesc label {
  display: inline-block;
  line-height: 34px;
  font-size: 15px;
}
.modalDesc input {
  background: #fff;
  border: solid 1px #dadada;
  border-radius: 8px;
  padding: 8px 16px;
  color: #333;
  outline: none;
  width: 100%;
}
.submitbtn {
  margin-top: 30px;
  /* background: #019df5; */
  /* color: #fff; */
  text-align: center;
  border-radius: 6px;
  padding: 8px 16px;
  /* border: solid 2px #0a7ebf; */
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
.modalFooter {
  position: absolute;
  width: 100%;
  bottom: 0;
  width: 100%;
  background: #041e4f;
  height: 20px;
}

.imgboxx {
  margin-top: 15px;
}

.imgboxx label {
  margin-top: 20px;
  line-height: 24px;
}
</style>
