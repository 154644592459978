<template>
  <div>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      @click="closebtn()"
    >
      &times;
    </button>
    <div class="modalTop"><h3>Add Tag</h3></div>
    <div class="modalDesc">
      <div class="row">
        <div class="col-md-4"><label>Name</label></div>
        <div class="col-md-8">
          <input type="catname" placeholder="Tag Name" v-model="aud_name" />
          <p class="err" v-if="tag_error != ''">{{ tag_error }}</p>
        </div>
      </div>

      <!-- <div class="row">
        <div class="col-md-4"><label>Description</label></div>
        <div class="col-md-8">
          <textarea
            type="catname"
            placeholder="Category Description"
            v-model="aud_desc"
          />
        </div>
      </div> -->

      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-8">
          <button
            class="btn btn-primary w-md mybutton myleft submitbtn"
            v-on:click="addAudio"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
    <div class="modalFooter"></div>
  </div>
</template>

<script>
import api from "../../config/api";
export default {
  name: "ModalAddTag",
  // props: {
  //   catid: Number,
  // },

  data() {
    return {
      tag_error : "",
      // audioFile: "",
      // audio_image: "",
      // previewImage: "",
      // audio_el: "",
      // aud_desc: "",
      aud_name: "",
    };
  },
  mounted() {
    // this.cat_id = this.catid;
  },
  watch: {
    aud_name(newVal) {
      if (newVal != "") {
        this.tag_error = "";
      }
    },
  },
  methods: {

    async addAudio() {
      try {
        this.tag_error = "";
        const formData = new FormData();
        formData.append("tag", this.aud_name);
        // formData.append("status", 'Active');
        // formData.append("description", this.aud_desc);

        let result = await api.post("/admin/add-tag", formData);
        this.$toast.success(result.data.message);
        this.$root.$refs.Hypnosis.callme();
        const list = document.getElementById("modals-container");
        list.removeChild(list.firstElementChild);

        //this.$refs.ServiceCategoreis.onload(); //like this
      } catch (error) {
          this.tag_error = "The tag field is required";
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    closebtn() {
      // this.$root.$refs.Tabs.callme(this.lib_id);
      // this.$root.$refs.Hypnosis.callme();
      const list = document.getElementById("modals-container");
      list.removeChild(list.firstElementChild);
    },
  },
};
</script>

<style scoped>
#modals-container .vm--modal {
  height: 600px !important;
}
.modalTop {
  width: 100%;
  background: #041e4f;

  color: #fff;
}
.modalTop h3 {
  font-weight: 600;
  color: #fff;
  font-size: 1.42188rem;
  text-align: center;
  padding: 15px 0;
}

.modalDesc {
  padding: 20px 40px;
  max-height: 700px;
  overflow-y: scroll;
}
.modalDesc label {
  display: inline-block;
  line-height: 34px;
  font-size: 15px;
  margin: 15px 0;
}
.modalDesc input {
  background: #fff;
  border: solid 1px #dadada;
  border-radius: 8px;
  padding: 8px 16px;
  color: #333;
  outline: none;
  width: 100%;
  margin: 15px 0;
}
.modalDesc audio {
  float: left;
  margin-right: 10px;
}
.submitbtn {
  margin-top: 30px;

  text-align: center;
  border-radius: 6px;
  padding: 8px 16px;

  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
.modalFooter {
  position: absolute;
  width: 100%;
  bottom: 0;    
  width: 100%;
  background: #041e4f;
  height: 20px;
}
.fas {
  font-size: 30px;
  margin-top: 15px;
}
</style>
