<template>
  <div>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      @click="closebtn()"
    >
      &times;
    </button>
    <div class="modalTop"><h3>Add Course</h3></div>
    <div class="modalDesc">
      <div class="row">
        <div class="col-md-4"><label>Title</label></div>
        <div class="col-md-8">
          <input type="catname" placeholder="Course Title" v-model="aud_name" />
          <p class="err" v-if="title_error != ''">{{ title_error }}</p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4"><label>Description</label></div>
        <div class="col-md-8">
          <textarea
            type="catname"
            placeholder="Course Description"
            v-model="aud_desc"
          />
          <p class="err" v-if="desc_error != ''">{{ desc_error }}</p>
        </div>
      </div>

      <!-- <div class="row">
        <div class="col-md-4"><label>Image</label></div>
        <div class="col-md-8">
          <input type="file" placeholder="" @change="onAudioImageChange" />
          <div id="preview">
            <img v-if="audio_image_url" :src="audio_image_url" width="200" height="200"/>
          </div>
        </div>
      </div> -->

      <div class="row imgboxx">
        <div class="col-md-4"><label>Image</label></div>
        <div class="col-md-8">
          <div class="imgbox">
            <img
              v-if="previewImage == ''"
              :src="'/assets/media/services/base.jpg'"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <img
              v-if="previewImage !== ''"
              :src="previewImage"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <i class="fas fa-camera" @click="$refs.imageFile.click()"></i>
            <div class="clear"></div>
            <p class="err" v-if="image_error != ''">{{ image_error }}</p>
            <span v-if="previewImage == ''" previmg="false">
              <!-- image is required -->
            </span>

            <input
              class="ml"
              type="file"
              accept="image/jpeg"
              @change="uploadImage"
              ref="imageFile"
              style="display: none"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-8">
          <button
            class="btn btn-primary w-md mybutton myleft submitbtn"
            v-on:click="addAudio"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
    <div class="modalFooter"></div>
  </div>
</template>

<script>
import api from "../../config/api";
export default {
  name: "ModalAddAudioHypnosis",
  props: {
    catid: Number,
  },

  data() {
    return {
      title_error : "",
      image_error : "",
      desc_error : "",
      // audioFile: "",
      audio_image: "",
      previewImage: "",
      audio_el: "",
      aud_desc: "",
      aud_name: "",
    };
  },
  mounted() {
    this.cat_id = this.catid;
  },

  watch: {
    aud_name(newVal) {
      if (newVal != "") {
        this.title_error = "";
      }
    },
    aud_desc(newVal) {
      if (newVal != "") {
        this.desc_error = "";
      }
    },
    previewImage(newVal) {
      if (newVal != "") {
        this.image_error = "";
      }
    },
  },
  methods: {

    // onAudioImageChange(e){
    //   const file = e.target.files[0];
    //   this.audio_image = file;
    //   this.audio_image_url = URL.createObjectURL(file)
    // },

    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
      };
    },

    async addAudio() {
      try {
        this.title_error = "";
        this.desc_error = "";
        this.image_error = "";
        const formData = new FormData();
        formData.append("title", this.aud_name);
        formData.append("description", this.aud_desc);

        formData.append("course_cat_id", this.$route.params.id);

        if (this.$refs.imageFile.files[0]) {
          formData.append("image", this.$refs.imageFile.files[0]);
        }
        let result = await api.post("/admin/add-course", formData);
        this.$toast.success(result.data.message);
        this.$root.$refs.Hypnosis.callme();
        const list = document.getElementById("modals-container");
        list.removeChild(list.firstElementChild);

        //this.$refs.ServiceCategoreis.onload(); //like this
      } catch (error) {
        if (typeof error.response.data.errors.title !== "undefined") {
          this.title_error = error.response.data.errors.title[0];
        }
        if (typeof error.response.data.errors.description !== "undefined") {
          this.desc_error = error.response.data.errors.description[0];
        }
        if(typeof error.response.data.errors.image !== "undefined") {
          this.image_error = error.response.data.errors.image[0];
        }
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    uploadAudio(e) {
      const audio = e.target.files[0];
      console.log(audio, "My Audio");
      const reader = new FileReader();
      reader.readAsDataURL(audio);
      // reader.onload = (e) => {

      // };
      var audio_el = document.createElement("audio");

      reader.onload = (e) => {
        this.audioFile = e.target.result;
        audio_el.src = e.target.result;
        audio_el.addEventListener("loadedmetadata", () => {
          // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
          var duration = audio_el.duration;
          this.file_duration = duration;

          // example 12.3234 seconds
          console.log(
            "The duration of the song is of: " + this.file_duration + " seconds"
          );
          // Alternatively, just display the integer value with
          // parseInt(duration)
          // 12 seconds
        });
      };

      //reader.readAsDataURL(audio);
      console.log(this.file_duration, "Song Duration");
    },

    uploadImage02(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.vpath = e.target.result;
        console.log(this.vpath);
      };
    },
    closebtn() {
      // this.$root.$refs.Tabs.callme(this.lib_id);
      // this.$root.$refs.Hypnosis.callme();
      const list = document.getElementById("modals-container");
      list.removeChild(list.firstElementChild);
    },
  },
};
</script>

<style scoped>
#modals-container .vm--modal {
  height: 600px !important;
}
.modalTop {
  width: 100%;
  background: #041e4f;

  color: #fff;
}
.modalTop h3 {
  font-weight: 600;
  color: #fff;
  font-size: 1.42188rem;
  text-align: center;
  padding: 15px 0;
}

.modalDesc {
  padding: 20px 40px;
  max-height: 700px;
  overflow-y: scroll;
}
.modalDesc label {
  display: inline-block;
  line-height: 34px;
  font-size: 15px;
  margin: 15px 0;
}
.modalDesc input {
  background: #fff;
  border: solid 1px #dadada;
  border-radius: 8px;
  padding: 8px 16px;
  color: #333;
  outline: none;
  width: 100%;
  margin: 15px 0;
}
.modalDesc audio {
  float: left;
  margin-right: 10px;
}
.submitbtn {
  margin-top: 30px;

  text-align: center;
  border-radius: 6px;
  padding: 8px 16px;

  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
.modalFooter {
  position: absolute;
  width: 100%;
  bottom: 0;
  width: 100%;
  background: #041e4f;
  height: 20px;
}
.fas {
  font-size: 30px;
  margin-top: 15px;
}
</style>
