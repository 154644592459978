<template>
  <!--begin::Aside-->
  <div
    id="mySidenav"
    class="aside aside-dark aside-hoverable"
    data-kt-drawer="true"
    data-kt-drawer-name="aside"
    data-kt-drawer-activate="{default: true, lg: false}"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'185px', '300px': '250px'}"
    data-kt-drawer-direction="start"
    data-kt-drawer-toggle="#kt_aside_mobile_toggle"
  >
    <a href="javascript:void(0)" class="closebtn" @click="closeNav()" id="cbtn"
      ><box-icon name="chevrons-left"></box-icon
    ></a>
    <!--begin::Brand-->
    <div class="aside-logo flex-column-auto" id="kt_aside_logo">
      <!--begin::Logo-->
      <a href="/">
        <img
          alt="Logo"
          src="/assets/media/logos/logo@3x.png"
          class="h-85px logo"
          id="blogo"
        />
        <img
          alt="Logo"
          src="/assets/media/logos/slogo.png"
          class="h-85px logo"
          id="slogo"
        />
      </a>

      <!--end::Logo-->
      <!--begin::Aside toggler-->
      <div
        id="kt_aside_toggle"
        class="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle"
        data-kt-toggle="true"
        data-kt-toggle-state="active"
        data-kt-toggle-target="body"
        data-kt-toggle-name="aside-minimize"
      >
        <!--begin::Svg Icon | path: icons/duotune/arrows/arr079.svg-->
        <span class="svg-icon svg-icon-1 rotate-180">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              opacity="0.5"
              d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z"
              fill="black"
            />
            <path
              d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z"
              fill="black"
            />
          </svg>
        </span>
        <!--end::Svg Icon-->
      </div>
      <!--end::Aside toggler-->
    </div>
    <!--end::Brand-->
    <!--begin::Aside menu-->

    <div class="aside-menu flex-column-fluid">
      <!--begin::Aside Menu-->

      <div
        class="hover-scroll-overlay-y my-5 my-lg-5"
        id="kt_aside_menu_wrapper"
        data-kt-scroll="true"
        data-kt-scroll-activate="{default: false, lg: true}"
        data-kt-scroll-height="auto"
        data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
        data-kt-scroll-wrappers="#kt_aside_menu"
        data-kt-scroll-offset="0"
      >
        <!--begin::Menu-->
        <div
          class="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
          id="#kt_aside_menu"
          data-kt-menu="true"
          data-kt-menu-expand="false"
        >
          <div
            data-kt-menu-trigger="click"
            class="menu-item here show menu-accordion"
          >
            <span class="menu-link">
              <span class="menu-icon">
                <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg-->
                <span class="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      x="2"
                      y="2"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="13"
                      y="2"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="13"
                      y="13"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="2"
                      y="13"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->
              </span>
              <router-link to="/home/users" class="waves-effect">
                <!-- <i class="bx bxs-detail"></i> -->
                <span class="menu-title">Users</span></router-link
              >
              <!-- <span class="menu-arrow"></span> -->
            </span>
          </div>
          <!-- <div
            data-kt-menu-trigger="click"
            class="menu-item here show menu-accordion dropdown"
          > -->
          <div class="dropdown menu-item here">
            <span class="menu-link mymenudisplay">
              <span class="menu-icon">
                <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg-->
                <span class="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      x="2"
                      y="2"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="13"
                      y="2"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="13"
                      y="13"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="2"
                      y="13"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->
              </span>
              <a href="javascript:;" class="waves-effect" 
              @click="navigate_audio_categories_hypnosis()">
                <!-- <box-icon name="detail" class="myicon"></box-icon> -->
                <span class="menu-title" id="subslide">Hypnosis</span></a
              >

              <!-- <span class="menu-arrow"></span> -->
            </span>
          </div>

  

          <div
            data-kt-menu-trigger="click"
            class="menu-item here show menu-accordion"
          >
            <span class="menu-link">
              <span class="menu-icon">
                <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg-->
                <span class="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      x="2"
                      y="2"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="13"
                      y="2"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="13"
                      y="13"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="2"
                      y="13"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->
              </span>
              <a class="waves-effect" @click="navigate_med_back()">
                <span class="menu-title">Meditation Background</span>
              </a>
              <!-- <span class="menu-arrow"></span> -->
            </span>
          </div>

          

          <div
            data-kt-menu-trigger="click"
            class="menu-item here show menu-accordion"
          >
            <span class="menu-link">
              <span class="menu-icon">
                <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg-->
                <span class="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      x="2"
                      y="2"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="13"
                      y="2"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="13"
                      y="13"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="2"
                      y="13"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->
              </span>
              <a class="waves-effect" @click="navigate_med_tune()">
                <span class="menu-title">Meditation Tunes</span>
              </a>
              <!-- <span class="menu-arrow"></span> -->
            </span>
          </div>



          <!-- <div
            data-kt-menu-trigger="click"
            class="menu-item here show menu-accordion dropdown"
          > -->
          <div class="dropdown menu-item here">
            <span class="menu-link mymenudisplay">
              <span class="menu-icon">
                <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg-->
                <span class="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      x="2"
                      y="2"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="13"
                      y="2"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="13"
                      y="13"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="2"
                      y="13"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->
              </span>
              <a href="javascript:;" class="waves-effect"  @click="navigate_audio_categories_med()">
                <!-- <box-icon name="detail" class="myicon"></box-icon> -->
                <span class="menu-title" id="subslide">Meditations</span></a
              >

              <!-- <span class="menu-arrow"></span> -->
            </span>
          </div>

          <!-- Not Required for now -->

          <!-- <div
            data-kt-menu-trigger="click"
            class="menu-item here show menu-accordion dropdown"
          > -->
          <div class="dropdown menu-item here">
            <span class="menu-link mymenudisplay">
              <span class="menu-icon">
                <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg-->
                <span class="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      x="2"
                      y="2"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="13"
                      y="2"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="13"
                      y="13"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="2"
                      y="13"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->
              </span>
              <a href="javascript:;" class="waves-effect"  
              @click="navigate_audio_categories_aff()">
                <!-- <box-icon name="detail" class="myicon"></box-icon> -->
                <span class="menu-title" id="subslide">Affirmations</span></a
              >

              <!-- <span class="menu-arrow"></span> -->
            </span>
          </div>

          <!-- Not Required for now -->

          <!-- <div
            data-kt-menu-trigger="click"
            class="menu-item here show menu-accordion dropdown"
          > -->
          <div class="dropdown menu-item here">
            <span class="menu-link mymenudisplay">
              <span class="menu-icon">
                <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg-->
                <span class="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      x="2"
                      y="2"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="13"
                      y="2"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="13"
                      y="13"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="2"
                      y="13"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->
              </span>
              <a href="javascript:;" @click="navigate_allvideos()" class="waves-effect">
                <!-- <box-icon name="detail" class="myicon"></box-icon> -->
                <span class="menu-title" id="subslide">Videos</span></a
              >

              <!-- <span class="menu-arrow"></span> -->
            </span>
            <!-- <div
              id="myDIV"
              class="menu-sub menu-sub-accordion menu-active-bg dropdown-content w3-animate-zoom myDIV-new ddcontent slides"
            >
            

              <div v-for="itemv in all_videos" :key="itemv.id">
                <div class="menu-item">
                  <a
                    class="menu-link"
                    @click="navigate_vid(itemv.id, itemv.name)"
                  >
                    <span class="menu-bullet">
                      <span class="bullet bullet-dot"></span>
                    </span>
                    <span class="menu-title">{{ itemv.name }}</span>
                  </a>
               
                </div>

                
              </div>
            </div> -->
          </div>

          <!-- Not Required for now -->

          <!-- Not Required for now -->

          <!-- <div
            data-kt-menu-trigger="click"
            class="menu-item here show menu-accordion dropdown"
          > -->
          <div class="dropdown menu-item here">
            <span class="menu-link mymenudisplay">
              <span class="menu-icon">
                <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg-->
                <span class="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      x="2"
                      y="2"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="13"
                      y="2"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="13"
                      y="13"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="2"
                      y="13"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->
              </span>
              <a href="javascript:;" @click="navigate_allcourses()" class="waves-effect">
                <!-- <box-icon name="detail" class="myicon"></box-icon> -->
                <span class="menu-title" id="subslide">Course</span></a
              >
            </span>
          </div>

          <div class="dropdown menu-item here">
            <span class="menu-link mymenudisplay">
              <span class="menu-icon">
                <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg-->
                <span class="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      x="2"
                      y="2"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="13"
                      y="2"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="13"
                      y="13"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="2"
                      y="13"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->
              </span>
              <a href="javascript:;" @click="navigate_alltags()" class="waves-effect">
                <!-- <box-icon name="detail" class="myicon"></box-icon> -->
                <span class="menu-title" id="subslide">Tags</span></a
              >
            </span>
          </div>

          <!-- Not Required for now -->

          <div
            data-kt-menu-trigger="click"
            class="menu-item here show menu-accordion"
          >
            <span class="menu-link">
              <span class="menu-icon">
                <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg-->
                <span class="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      x="2"
                      y="2"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="13"
                      y="2"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="13"
                      y="13"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="2"
                      y="13"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->
              </span>
              <router-link to="/home/events" class="waves-effect">
                <!-- <i class="bx bxs-detail"></i> -->
                <span class="menu-title">Events</span></router-link
              >
              <!-- <span class="menu-arrow"></span> -->
            </span>
          </div>



          <div class="dropdown menu-item here">
            <span class="menu-link mymenudisplay">
              <span class="menu-icon">
                <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg-->
                <span class="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      x="2"
                      y="2"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="13"
                      y="2"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="13"
                      y="13"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="2"
                      y="13"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->
              </span>
              <a 
              class="waves-effect" 
              @click="navigate_series()"
              >
                <!-- <box-icon name="detail" class="myicon"></box-icon> -->
                <span class="menu-title" id="subslide">Series</span></a
              >

              <!-- <span class="menu-arrow"></span> -->
            </span>
            <div
              id="myDIV"
              class="menu-sub menu-sub-accordion menu-active-bg dropdown-content w3-animate-zoom myDIV-new ddcontent slides"
            >
            </div>
          </div>

          <div class="dropdown menu-item here">
            <span class="menu-link mymenudisplay">
              <span class="menu-icon">
                <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg-->
                <span class="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      x="2"
                      y="2"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="13"
                      y="2"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="13"
                      y="13"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="2"
                      y="13"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->
              </span>
              <a 
              class="waves-effect" 
              @click="navigate_ebooks()"
              >
                <!-- <box-icon name="detail" class="myicon"></box-icon> -->
                <span class="menu-title" id="subslide">Ebooks</span></a
              >

              <!-- <span class="menu-arrow"></span> -->
            </span>
            <div
              id="myDIV"
              class="menu-sub menu-sub-accordion menu-active-bg dropdown-content w3-animate-zoom myDIV-new ddcontent slides"
            >
            </div>
          </div>



          <div class="dropdown menu-item here">
            <span class="menu-link mymenudisplay">
              <span class="menu-icon">
                <span class="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      x="2"
                      y="2"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="13"
                      y="2"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="13"
                      y="13"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                    <rect
                      opacity="0.3"
                      x="2"
                      y="13"
                      width="9"
                      height="9"
                      rx="2"
                      fill="black"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->
              </span>
              <a 
              class="waves-effect" 
              @click="navigate_scripts()"
              >
                <span class="menu-title" id="subslide">Scripts</span></a
              >

              <!-- <span class="menu-arrow"></span> -->
            </span>
            <div
              id="myDIV"
              class="menu-sub menu-sub-accordion menu-active-bg dropdown-content w3-animate-zoom myDIV-new ddcontent slides"
            >
            </div>
          </div>



          <!-- dsfsdf -->
        </div>
        <!--end::Menu-->
      </div>

      <!--end::Aside Menu-->
    </div>
    <!--end::Aside menu-->
    <span @click="openNav()" id="obtn"
      ><box-icon name="chevrons-right"></box-icon
    ></span>
  </div>
  <!--end::Aside-->
  <!--begin::Wrapper-->
</template>

<script>
import api from "../config/api.js";
export default {
  name: "SideBar",
  data() {
    return {
      all_hypnosis: [],
      all_meditations: [],
      all_affirmations: [],
      all_videos: [],
      all_series: [],
      all_scripts : [],
      name: "",
    };
  },

  mounted() {
    this.allHypnosis();
    this.allMeditations();
    this.allAffirmations();
    this.allVideos();
    this.allSeries();
    this.allScripts();
    // this.allCourses();
  },

  methods: {
    /* Set the width of the side navigation to 250px and the left margin of the page content to 250px */
    openNav() {
      if (window.matchMedia("(max-width: 1366px)").matches) {
        document.getElementById("mySidenav").style.width = "185px";
        document.getElementById("kt_wrapper").style.marginLeft = "0px";
        document.getElementById("kt_header").style.marginLeft = "0px";
        document.getElementById("kt_toolbar").style.marginLeft = "0px";
        document.getElementById("cbtn").style.display = "block";
        document.getElementById("obtn").style.display = "none";
        document.getElementById("blogo").style.display = "block";
        document.getElementById("slogo").style.display = "none";
      } else {
        document.getElementById("mySidenav").style.width = "300px";
        document.getElementById("kt_wrapper").style.marginLeft = "0px";
        document.getElementById("kt_header").style.marginLeft = "0px";
        document.getElementById("kt_toolbar").style.marginLeft = "0px";
        document.getElementById("cbtn").style.display = "block";
        document.getElementById("obtn").style.display = "none";
        document.getElementById("blogo").style.display = "block";
        document.getElementById("slogo").style.display = "none";
      }
    },

    /* Set the width of the side navigation to 0 and the left margin of the page content to 0 */
    closeNav() {
      if (window.matchMedia("(max-width: 1366px)").matches) {
        document.getElementById("mySidenav").style.width = "100px";

        document.getElementById("kt_wrapper").style.marginLeft = "-85px";
        document.getElementById("kt_header").style.marginLeft = "-85px";
        document.getElementById("kt_toolbar").style.marginLeft = "-85px";

        document.getElementById("cbtn").style.display = "none";
        document.getElementById("obtn").style.display = "block";
        document.getElementById("slogo").style.display = "block";
        document.getElementById("blogo").style.display = "none";
      } else {
        document.getElementById("mySidenav").style.width = "100px";

        document.getElementById("kt_wrapper").style.marginLeft = "-200px";
        document.getElementById("kt_header").style.marginLeft = "-200px";
        document.getElementById("kt_toolbar").style.marginLeft = "-200px";
        document.getElementById("cbtn").style.display = "none";
        document.getElementById("obtn").style.display = "block";
        document.getElementById("slogo").style.display = "block";
        document.getElementById("blogo").style.display = "none";
      }
    },

    // Close the dropdown if the user clicks outside of it

    async allHypnosis() {
      try {
        let result = await api.get(`/admin/all-hypno-cat`);
        // console.log(result);
        this.all_hypnosis = result.data.categories;
      } catch (error) {
        console.log(error);
      }
    },

    async allMeditations() {
      try {
        let result = await api.get(`/admin/all-meditation-cat`);
        // console.log(result);
        this.all_meditations = result.data.categories;
      } catch (error) {
        console.log(error);
      }
    },

    async allAffirmations() {
      try {
        let result = await api.get(`/admin/all-affirmation-cat`);
        // console.log(result);
        this.all_affirmations = result.data.categories;
      } catch (error) {
        console.log(error);
      }
    },

    async allVideos() {
      try {
        let result = await api.get(`/admin/all-video-cat`);
        // console.log(result);
        this.all_videos = result.data.categories;
      } catch (error) {
        console.log(error);
      }
    },

    async allSeries() {
      try {
        let result = await api.get(`/admin/all-series-cat`);
        // console.log("all series category");
        // console.log(result);
        this.all_series = result.data.categories;
      } catch (error) {
        console.log(error);
      }
    },
    async allScripts() {
      try {
        let result = await api.get(`/admin/all-scripts`);
        // console.log("all scripts");
        // console.log(result);
        this.all_scripts = result.data.scripts;
      } catch (error) {
        console.log(error);
      }
    },


    navigate(id, title) {
      this.$router.push({
        name: "hypnosis",
        params: { id: id, title },
      });
      this.$root.$refs.Hypnosis.sayname(title);
    },


    navigate_med_back() {
      this.$router.push({
        name: "meditation_background"
      });
      // this.$root.$refs.Meditations.sayname(title);
    },

    navigate_audio_categories_hypnosis(){
      this.$router.push({
        name: 'HypnosisCategory',
      }).catch((error)=>{
        console.log(error);
      });
    },

    navigate_audio_categories_med(){
      this.$router.push({
        name: 'MeditationCategory',
      }).catch((error)=>{
        console.log(error);
      });
    },

    navigate_audio_categories_aff(){
      this.$router.push({
        name: 'AffirmationCategory',
      }).catch((error)=>{
        console.log(error);
      });
    },

    navigate_med_tune() {
      this.$router.push({
        name: "AllMeditationTunes"
      });
    },

    navigate_med(id, title) {
      this.$router.push({
        name: "meditations",
        params: { id: id, title },
      });
      this.$root.$refs.Meditations.sayname(title);
    },

    navigate_aff(id, title) {
      this.$router.push({
        name: "affirmations",
        params: { id: id, title },
      });
      this.$root.$refs.Affirmations.sayname(title);
    },

    navigate_vid(id, title) {
      //this.name = this.all_videos[id - 1].name;
      this.$router.push({
        name: "videos",
        params: { id: id, title },
      });
      //this.$router.push("/home/hypnosis/" + id);
      //this.$root.$refs.Hypnosis.onload(id);

      //console.log(this.name, "NAme");
      //this.$root.$refs.Affirmations.sayname(this.name);
    },

    navigate_series() {
      this.$router.push({
        name: "AllSeries",
      });
    },

    navigate_allcourses() {
      this.$router.push({
        name: "AllCoursesCategories",
      });
    },

    navigate_alltags() {
      this.$router.push({
        name: "AllTags",
      });
    },

    navigate_allvideos(){
      this.$router.push({
        name: "AllVideos",
      });
    },
    
    navigate_scripts() {
      this.$router.push({
        name: "AllScripts",
      });
    },

    navigate_ebooks() {
      console.log('ebooks');
      this.$router.push({
        name: "AllEbooks",
      });
    },

    navigate_course(id, title) {
      //this.name = this.all_videos[id - 1].name;
      this.$router.push({
        name: "courses",
        params: { id: id, title },
      });
      //this.$router.push("/home/hypnosis/" + id);
      //this.$root.$refs.Hypnosis.onload(id);

      //console.log(this.name, "NAme");
      //this.$root.$refs.Affirmations.sayname(this.name);
    },
  },
};
</script>

<style scoped>
.logo {
  margin: 15px 5px 25px 5px;
}
.aside .aside-logo {
  height: auto !important;
}

/* The side navigation menu */
.sidenav {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0;
  left: 0;
  /* background-color: #111; Black */
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}

/* The navigation menu links */
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  width: 25px;
  height: 25px;
}
a.closebtn {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 25px;
  top: 50px;
}
span#obtn {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 25px;
  top: 50px;
  display: none;
  cursor: pointer;
}
#slogo {
  width: 30px;
  height: 48px !important;
  display: none;
}

#kt_aside_toggle {
  display: none;
}

.mymenudisplay {
  flex: none !important;
  display: flex !important;
}

.myDIV-new {
  position: relative !important;
  /* top: 65% !important; */
  width: 100%;
  max-height: 325px;
  overflow-y: scroll;
}

.myDIV-new::webkit-scrollbar {
  width: 1em;
}

.myDIV-new::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 1);
}

.myDIV-new::-webkit-scrollbar-thumb {
  background-color: #03314c;
  outline: 1px solid #03314c;
}

.ddcontent {
  background: none !important;
}
.waves-effect .ddcontent {
  transition: all 0.5s ease 3s;
}
/* #kt_aside_menu_wrapper {
} */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1191a0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #032335;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #032335;
}
</style>
