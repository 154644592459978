<template>
  <div>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      @click="closebtn()"
    >
      &times;
    </button>
    <div class="modalTop"><h3>Add Script</h3></div>
    <div class="modalDesc">
      <div class="row">
        <div class="col-md-4"><label>Script Name</label></div>
        <div class="col-md-8">
          <input type="catname" placeholder="Script Name" v-model="vid_name" />
          <p class="err" v-if="name_err != ''">{{ name_err }}</p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4"><label>Script Description</label></div>
        <div class="col-md-8">
          <textarea
            type="catname"
            placeholder="Script Description"
            v-model="vid_desc"
          />
          <p class="err" v-if="desc_err != ''">{{ desc_err }}</p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4"><label>Script Text</label></div>
        <div class="col-md-8">
          <vue-editor v-model="vid_text" />
          <p class="err" v-if="text_err != ''">{{ text_err }}</p>
        </div>
      </div>

      <!-- <div class="row">
        <div class="col-md-4"><label>Text Duration</label></div>
        <div class="col-md-8">
          <input type="catname" placeholder="Chapter text duration" v-model="text_duration" />
        </div>
      </div> -->

      <div class="row">
        <div class="col-md-4"><label>Script Author</label></div>
        <div class="col-md-8">
          <input type="catname" placeholder="Script Author" v-model="chapter_author" />
          <p class="err" v-if="author_err != ''">{{ author_err }}</p>
        </div>
      </div>


      <div class="row imgboxx">
        <div class="col-md-4"><label>Script Image</label></div>
        <div class="col-md-8">
          <div class="imgbox">
            <img
              v-if="audio_image_url == ''"
              :src="'/assets/media/services/base.jpg'"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <img
              v-if="audio_image_url !== ''"
              :src="audio_image_url"
              class="img-thumbnail rounded-circle"
              alt="Image"
              style="height: 65px; width: 65px"
            />

            <i class="fas fa-camera" @click="$refs.audio_image.click()"></i>
            <div class="clear"></div>
            <p class="err" v-if="audio_image_url_err != ''">{{ audio_image_url_err }}</p>
            <span v-if="audio_image_url == ''" previmg="false">
              <!-- Cover image is required -->
            </span>

            <input
              class="ml"
              type="file"
              accept="image/jpeg"
              @change="onAudioImageChange"
              ref="audio_image"
              style="display: none"
            />
          </div>
        </div>
      </div>


  
      <Tag @updateTag="updateTagFromChild" oldtags="" />

      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-8">
          <p class="err" v-if="tags_error != ''">{{ tags_err }}</p>
          <button
            class="btn btn-primary w-md mybutton myleft submitbtn"
            v-on:click="addVideo"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
    <div class="modalFooter"></div>
  </div>
</template>

<script>
import api from "../../config/api";
import Tag from "../../components/Tag.vue";
import { VueEditor } from "../../../node_modules/vue2-editor";
// import VueVideoThumbnail from "vue-Chapter-thumbnail";

export default {
  name: "ModalAddVideos",
  //   components: { VueVideoThumbnail },
  props: {
    catid: Number,
    
  },

  created() {
    this.$root.$refs.ModalAddVideos = this;
  },
  components: {
    VueEditor,
    Tag,
  },

  data() {
    return {
      audio_image_url_err: "",
      text_err: "",
      image_err: "",
      desc_err: "",
      name_err: "",
      tags_err: "",
      author_err: "",

      title_error: "",
      desc_error: "",
      text_error: "",
      author_error: "",
      image_error: "",
      tag_error: "",

      audio_image: "",
      audio_image_url: "",
      videoFile: "",
      videoPreview: "",
      video_el: "",
      vid_desc: "",
      text_duration: "",
      chapter_author : "",
      vid_text : "",
      vid_name: "",
      cat_id: "",
      file_duration: "",
      imagepath: null,
      blob: null,
      previewImage: "",
      imageFile: "",
      tags: [],
    };
  },
  mounted() {
    this.cat_id = this.catid;
    this.callme;
  },

  watch: {
    vid_name(newVal) {
      if (newVal != "") {
        this.name_err = "";
      }
    },
    vid_desc(newVal) {
      if (newVal != "") {
        this.desc_err = "";
      }
    },
    previewImage(newVal) {
      if (newVal != "") {
        this.image_err = "";
      }
    },
    vid_text(newVal) {
      if (newVal != "") {
        this.text_err = "";
      }
    },
    chapter_author(newVal) {
      if (newVal != "") {
        this.author_err = "";
      }
    },
    tags(newVal) {
      if (newVal.length > 0) {
        this.tags_err = "";
      }
    }
  },

  methods: {

    onAudioImageChange(e) {
      this.audio_image_url_err = "";
      const file = e.target.files[0];
      // console.log(file.type);
      var status = this.checkFileType(file, 'image1')
      if (status) {
        this.audio_image = file;
        this.audio_image_url = URL.createObjectURL(file)
      }
    },


    updateTagFromChild(arr){
      this.tags = arr;
    },
    imagehere(path) {
      console.log(path);
    },
    async addVideo() {
      if (this.chapter_author != ""
        && this.vid_text != ""  
       && this.vid_name != ""
        && this.vid_desc != ""
        && this.audio_image_url != ""
        && this.tags.length != 0
      ) {
      try {
        this.title_error = "";
        this.desc_error = "";
        this.image_error = "";
        this.text_error = "";
        this.author_error = "";
        this.tag_error = "";

        const formData = new FormData();
        formData.append("name", this.vid_name);
        formData.append("script_id", this.cat_id);
        formData.append("description", this.vid_desc);
        formData.append("duration", this.text_duration);
        formData.append("author", this.chapter_author);
        formData.append("text", this.vid_text);
        // formData.append("duration", this.file_duration);
        formData.append("image", this.blob);

        if(typeof this.tags == 'string'){
          formData.append('tag[]', this.tags);
        }else{
          for (var i = 0; i < this.tags.length; i++) {
            formData.append('tag[]', this.tags[i]);
          }
        }

        // if (this.$refs.videoFile.files[0]) {
        //   formData.append("path", this.$refs.videoFile.files[0]);
        // }

        //  if (this.$refs.imageFile.files[0]) {
        //   formData.append("image", this.$refs.imageFile.files[0]);
        // }

        if(this.audio_image){
          formData.append("image", this.audio_image);
        }

        console.log('jj');

        let result = await api.post("/admin/add-chapter", formData);
        this.$toast.success(result.data.message);
        this.$root.$refs.Chapters.callme();
        const list = document.getElementById("modals-container");
        list.removeChild(list.firstElementChild);

        //this.$refs.ServiceCategoreis.onload(); //like this
      } catch (error) {

        if (typeof error.response.data.errors.name !== "undefined") {
          this.title_error = error.response.data.errors.name[0];
        }
        if (typeof error.response.data.errors.description !== "undefined") {
          this.desc_error = error.response.data.errors.description[0];
        }
        if(typeof error.response.data.errors.image !== "undefined") {
          this.image_error = error.response.data.errors.image[0];
        }
        if(typeof error.response.data.errors.text !== "undefined") {
          this.text_error = error.response.data.errors.text[0];
        }
        if (typeof error.response.data.errors.author !== "undefined") {
          this.author_error = error.response.data.errors.author[0];
        }
        if (typeof error.response.data.errors.tag !== "undefined") {
          this.tag_error = error.response.data.errors.tag[0];
        }
       

        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    }
      else{
        this.name_err = (this.vid_name == "" && this.name_err == "") ? "title is required" : this.name_err
        this.text_err = (this.vid_name == "" && this.text_err == "") ? "text is required" : this.text_err
        this.desc_err = (this.vid_desc == "" && this.desc_err == "") ? "Description is required" : this.desc_err
        this.author_err = (this.chapter_author == "" && this.author_err == "") ? "Author is required" : this.author_err
        this.audio_image_url_err = (this.audio_image_url == "" && this.audio_image_url_err == "") ? "Image is required" : this.audio_image_url_err
        this.tags_err = (this.tags.length == 0 && this.tags_err == '') ? "Tags require" : this.tags_err
      }
    },


     uploadImage(e) {
      const image = e.target.files[0];
      var status = this.checkFileType(image, 'image1')
      if (status) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
      };
    }
    },


    checkFileType(file, num) {
      var types = ['image/jpeg', 'image/png', 'image/jpg']
      var msg = "The image must be a file of type: jpeg, jpg, png";
      if (num == 'audio') {
        types = ['audio/mpeg', 'audio/ogg', 'audio/wav', 'audio/mp4', 'audio/mp3']
        if (!types.includes(file.type)) {
          this.audioFile_err = "The audio must be a file of type: mp3, wav, ogg"
          this.audioFile = ""
          return false;
        }
        return true;
      } else {
        if (!types.includes(file.type)) {
          if (num == 'image1') {
            this.audio_image_url_err = msg
            this.previewImage = "";
          } else if (num == 'image2') {
            this.audio_cover_image_url_err = msg
            this.audio_cover_image = ""
            this.audio_cover_image_url = ""
          }
          return false;
        }
        return true;
      }
    },

    async uploadVideo(e) {
      const Chapter = e.target.files[0];
      this.videoPreview = this.$refs.videoFile.files[0];
      const res = await this.generateVideoThumbnail(Chapter);
      //   console.log(thumbnail);
      this.imagepath = res;
      this.blob = this.bast64ToFile(res);
      // console.log(Chapter, "My Chapter");
      const reader = new FileReader();
      reader.readAsDataURL(Chapter);
      // reader.onload = (e) => {

      // };
      var video_el = document.createElement("Chapter");

      reader.onload = (e) => {
        this.videoFile = e.target.result;
        video_el.src = e.target.result;
        video_el.addEventListener("loadedmetadata", () => {
          // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
          var duration = video_el.duration;
          this.file_duration = duration;
         
          // example 12.3234 seconds
          console.log(
            "The duration of the song is of: " + this.file_duration + " seconds"
          );
          // Alternatively, just display the integer value with
          // parseInt(duration)
          // 12 seconds
        });
      };

      //reader.readAsDataURL(audio);
      console.log(this.file_duration, "Song Duration");
    },

    uploadImage02(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.vpath = e.target.result;
        console.log(this.vpath);
      };
    },
    closebtn() {
      // this.$root.$refs.Chapters.callme();
      const list = document.getElementById("modals-container");
      list.removeChild(list.firstElementChild);
    },

    generateVideoThumbnail(file) {
      return new Promise((resolve) => {
        const canvas = document.createElement("canvas");
        const Chapter = document.createElement("Chapter");

        // this is important
        Chapter.autoplay = true;
        Chapter.muted = true;
        Chapter.src = URL.createObjectURL(file);

        Chapter.onloadeddata = () => {
          let ctx = canvas.getContext("2d");

          canvas.width = Chapter.videoWidth;
          canvas.height = Chapter.videoHeight;

          ctx.drawImage(Chapter, 0, 0, Chapter.videoWidth, Chapter.videoHeight);
          Chapter.pause();
          // const new_file = canvas.toDataURL("image/png");
          // //

          // var arr = new_file.split(","),
          //   mime = arr[0].match(/:(.*?);/)[1],
          //   bstr = atob(arr[1]),
          //   n = bstr.length,
          //   u8arr = new Uint8Array(n);

          // while (n--) {
          //   u8arr[n] = bstr.charCodeAt(n);
          // }

          // let blob = new File([u8arr], "filename", { type: mime });
          return resolve(canvas.toDataURL("image/png"));
          // return resolve({ blob, image: new_file });
        };
      });
    },

    bast64ToFile(new_file) {
      var arr = new_file.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], "filename", { type: mime });
    },
  },
};
</script>

<style scoped>
#modals-container .vm--modal {
  height: 600px !important;
}
.modalTop {
  width: 100%;
  background: #041e4f;

  color: #fff;
}
.modalTop h3 {
  font-weight: 600;
  color: #fff;
  font-size: 1.42188rem;
  text-align: center;
  padding: 15px 0;
}

.modalDesc {
  padding: 20px 40px;
  max-height: 700px;
  overflow-y: scroll;
}
.modalDesc label {
  display: inline-block;
  line-height: 34px;
  font-size: 15px;
  margin: 15px 0;
}
.modalDesc input {
  background: #fff;
  border: solid 1px #dadada;
  border-radius: 8px;
  padding: 8px 16px;
  color: #333;
  outline: none;
  width: 100%;
  margin: 15px 0;
}
.modalDesc audio {
  float: left;
  margin-right: 10px;
}
.submitbtn {
  margin-top: 30px;

  text-align: center;
  border-radius: 6px;
  padding: 8px 16px;

  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
.modalFooter {
  position: absolute;
  width: 100%;
  bottom: 0;
  width: 100%;
  background: #041e4f;
  height: 20px;
}
.fas {
  font-size: 30px;
  margin-top: 15px;
  margin-right: 15px;
}
</style>
