<template>
  <div>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      @click="closebtn()"
    >
      &times;
    </button>
    <!-- <button @click="$emit('close')">Close</button> -->
    <div class="modalTop"><h3>Update Script</h3></div>
    <div class="modalDesc">
      <div class="row">
        <div class="col-md-4"><label>Title</label></div>
        <div class="col-md-8">
          
            <input
              type="text"
              placeholder="Script Title"
              v-model="name"
            />
          
            <p class="err" v-if="name_err != ''">{{ name_err }}</p>
        </div>
      </div>
      <br /><br />
      <div class="row">
        <div class="col-md-4"><label>Desctription</label></div>
        <div class="col-md-8">
        
            <textarea
              type="catname"
              placeholder="Script Description"
              v-model="desc"
            />
        
            <p class="err" v-if="desc_err != ''">{{ desc_err }}</p>
            
        </div>
      </div>

       <div class="row">
        <div class="col-md-4"><label>Text</label></div>
        <div class="col-md-8">
          
            <vue-editor v-model="text" />
            <p class="err" v-if="text_err != ''">{{ text_err }}</p>

        </div>
      </div>
      <br /><br />
     
      <!-- <img v-if="imagepath" :src="imagepath" alt="" /> -->

      <div class="row">
        <div class="col-md-4"><label>Image</label></div>
        <div class="col-md-8">
          <input type="file" placeholder="" @change="onSeriesImageChange" />
          <p class="err" v-if="audio_image_url_err != ''">{{ audio_image_url_err }}</p>
          <div id="preview">
            <img v-if="image_url" :src="image_url" width="200" height="200"/>
          </div>
        </div>
      </div>

      <Tag @updateTag="updateTagFromChild" :oldtags="tags"/>
      
      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-8">
          <p class="err" v-if="tags_err != ''">{{ tags_err }}</p>
          <button
            class="btn btn-primary w-md mybutton myleft submitbtn"
            v-on:click="updateVideo"
          >
            Submit
          </button>
        </div>
        <div v-if="error" class="alert alert-danger">
          {{ error }}
        </div>
      </div>
    </div>
    <div class="modalFooter"></div>
  </div>
</template>

<script>
import api from "../../config/api";
// import useVuelidate from "@vuelidate/core";
// import { required } from "@vuelidate/validators";
//import AudioFile from "../Admin/AudioFile.vue";
import { VueEditor } from "vue2-editor";
import Tag from "../../components/Tag.vue";

export default {
  name: "ModalEditVideo",
  components: { VueEditor , Tag },
  props: {
    cid: Number,
    cname: String,
    cdesc: String,
    cimage: String,
    ctext: String,
    cduration: String,
    cauthor: String,
    atags : Array,
  },

  data() {
    return {
      audio_image_url_err: "",
      text_err: "",
      image_err: "",
      desc_err: "",
      name_err: "",
      tags_err: "",
      author_err: "",

      image: "",
      image_url: "",
      desc: "",
      name: "",
      previewImage: "",
      current_id : "",
      text : "",
      author : "",
      duration : "",
      error: "",
      tags: [],
    };
  },

  watch: {
    name(newVal) {
      if (newVal != "") {
        this.name_err = "";
      }
    },
    desc(newVal) {
      if (newVal != "") {
        this.desc_err = "";
      }
    },
    previewImage(newVal) {
      if (newVal != "") {
        this.image_err = "";
      }
    },
    text(newVal) {
      if (newVal != "") {
        this.text_err = "";
      }
    },
    // chapter_author(newVal) {
    //   if (newVal != "") {
    //     this.author_err = "";
    //   }
    // },
    tags(newVal) {
      if (newVal.length > 0) {
        this.tags_err = "";
      }
    }
  },

  mounted() {
    //this.onLoad();
    this.name = this.cname;
    this.current_id = this.cid;
    this.desc = this.cdesc;
    this.image_url = this.cimage;
    this.text = this.ctext;
    this.duration = this.cduration;
    this.author = this.cauthor;
  },

  created() {
    if(this.atags)
      console.log('from created edit chapter');
      console.log(typeof this.atags);
      this.tags = this.atags;
    this.$root.$refs.ModalEditVideo = this;
  },

  methods: {
    updateTagFromChild(arr){
      this.tags = arr;
    },

    onSeriesImageChange(e){
      this.audio_image_url_err = "";
      const file = e.target.files[0];
      var status = this.checkFileType(file, 'image1')
      if (status) {
      this.image = file;
      this.image_url = URL.createObjectURL(file)
      }
    },


    checkFileType(file, num) {
      var types = ['image/jpeg', 'image/png', 'image/jpg']
      var msg = "The image must be a file of type: jpeg, jpg, png";
      if (num == 'audio') {
        types = ['audio/mpeg', 'audio/ogg', 'audio/wav', 'audio/mp4', 'audio/mp3']
        if (!types.includes(file.type)) {
          this.audioFile_err = "The audio must be a file of type: mp3, wav, ogg"
          this.audioFile = ""
          return false;
        }
        return true;
      } else {
        if (!types.includes(file.type)) {
          if (num == 'image1') {
            this.audio_image_url_err = msg
            this.previewImage = "";
          } else if (num == 'image2') {
            this.audio_cover_image_url_err = msg
            this.audio_cover_image = ""
            this.audio_cover_image_url = ""
          }
          return false;
        }
        return true;
      }
    },

    async updateVideo() {
      if (this.text != ""  
       && this.name != ""
        && this.desc != ""
        && this.image_url != ""
        && this.tags.length != 0
      ) {
      try {
    

        const formData03 = new FormData();
        formData03.append("name", this.name);
        formData03.append("id", this.current_id);
        formData03.append("description", this.desc);

        formData03.append("text", this.text);
        formData03.append("author", this.author);
        formData03.append("duration", this.duration);

        if(typeof this.tags == 'string'){
          formData03.append('tag[]', this.tags);
        }else{
          for (var i = 0; i < this.tags.length; i++) {
            formData03.append('tag[]', this.tags[i]);
          }
        }
    
        if(this.image){
          formData03.append("image", this.image);
        }

        let resultss = await api.post("/admin/update-chapter", formData03);
        if (resultss.status == 200) {
          this.$toast.success(resultss.data.message);
          this.$root.$refs.Chapters.callme();
          const list = document.getElementById("modals-container");
          list.removeChild(list.firstElementChild);
          this.value02 = false;
        }
      } catch (error) {
        this.$toast.error(error.response.data.message);
      }
      }
      else{
        this.name_err = (this.name == "" && this.name_err == "") ? "title is required" : this.name_err
        this.text_err = (this.text == "" && this.text_err == "") ? "text is required" : this.text_err
        this.desc_err = (this.desc == "" && this.desc_err == "") ? "Description is required" : this.desc_err
        // this.audio_image_url_err = (this.image == "" && this.audio_image_url_err == "") ? "Image is required" : this.audio_image_url_err
        this.tags_err = (this.tags.length == 0 && this.tags_err == '') ? "Tags require" : this.tags_err
      }
    },
  
    onLoad() {
      this.updateAudio();
    },

    // uploadImage02(e) {
    //   const image = e.target.files[0];
    //   const reader = new FileReader();
    //   reader.readAsDataURL(image);
    //   reader.onload = (e) => {
    //     this.vpath = e.target.result;
    //     console.log(this.vpath);
    //   };
    // },
    closebtn() {
      // this.$root.$refs.Chapters.callme();
      const list = document.getElementById("modals-container");
      list.removeChild(list.firstElementChild);
    },
  },
};
</script>

<style scoped>
.vm--container {
  z-index: 9999999999999999 !important;
}

.vm--modal {
  height: auto !important;
}
.modalTop {
  width: 100%;
  background: #041e4f;

  color: #fff;
}
.modalTop h3 {
  font-weight: 600;
  color: #fff;
  font-size: 1.42188rem;
  text-align: center;
  padding: 15px 0;
}

.modalDesc {
  padding: 20px 40px;
  max-height: 700px;
  overflow-y: scroll;
}
.modalDesc label {
  display: inline-block;
  line-height: 34px;
  font-size: 15px;
}
.modalDesc input {
  background: #fff;
  border: solid 1px #dadada;
  border-radius: 8px;
  padding: 8px 16px;
  color: #333;
  outline: none;
  width: 100%;
}
.submitbtn {
  margin-top: 30px;

  text-align: center;
  border-radius: 6px;
  padding: 8px 16px;

  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
.modalFooter {
  position: absolute;
  width: 100%;
  bottom: 0;
  width: 100%;
  background: #041e4f;
  height: 20px;
}
.fas {
  font-size: 30px;
  margin-top: 15px;
}
</style>
